import React from "react";
import { Link } from "react-router-dom";
import { convertFromRaw, convertToRaw } from "draft-js";
import { formatDate } from "utils";
import { IBragSheet } from "types";
import "./bragSheetItem.css";

interface BragSheetItemProps {
	index: number;
	bragSheet: IBragSheet;
	hidden?: boolean;
}

export const BragSheetItem: React.FC<BragSheetItemProps> = (props) => {
	const { bragSheet } = props;
	
	const bragSheetRawContentState = convertFromRaw(JSON.parse(bragSheet.content));
	
	const plainTextBlocks = convertToRaw(bragSheetRawContentState).blocks;
	
	const plainText = plainTextBlocks
		.map((block) => (!block.text.trim() && "\n") || block.text)
		.join("<br>");

	return (
		<div className={`bragsheet-picker-item  ${props.hidden && "bragsheet-picker-item--hidden"}`} key={bragSheet.itemId}>
			<Link className="bragsheet-picker-link" to={bragSheet.itemId} title={bragSheet.bragSheetName}>
				<div className="bragsheet-content">
					<h2 className="type--heading-6">{bragSheet.bragSheetName}</h2>
					<p className="bragsheet-text type--body--standard"  dangerouslySetInnerHTML={{ __html: plainText }}>
					</p>
				</div>
			</Link>
			<div className="bragsheet-picker-meta">
				<time className="type--body--standard">
					{formatDate(bragSheet.dateCreated)}
				</time>
			</div>
		</div>
	);
};
