/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "aws-exports";

Amplify.configure(awsconfig);

const useCredentialsContainer = () => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [user, setUser] = useState<any>(null);

	const checkUser = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			setUser(user);
			setEmail(user.attributes.email);
		} catch (err) {
			console.log("checkUser error", err);
		}
	};

	const checkAuthenticated = async () => {
		const res = await Auth.currentUserCredentials();
		setIsAuthenticated(res.authenticated);
		return res;
	};

	useEffect(() => {
		if (user) {
			checkAuthenticated();
		}
	}, [user]);

	return {
		checkAuthenticated,
		checkUser,
		email,
		isAuthenticated,
		user,
		setEmail,
		setIsAuthenticated,
		setUser,
	};
};

export const CredentialsContainer = createContainer(useCredentialsContainer);
