import { IJob, IWorkExperience, IGoal } from "types";

interface RelevantWorkExperience {
  experience: string;
}

interface Like {
  like: string;
}

interface Dislike {
  dislike: string;
}

interface Goal {
  goal: string;
  description: string;
}

interface QuestionsObject {
  jobDescription: string;
  relevantWorkExperience: RelevantWorkExperience[];
  likes: Like[];
  dislikes: Dislike[];
  goals: Goal[];
}

const prepExperiences = (experiences: IWorkExperience[]): RelevantWorkExperience[] => {
  const relevantExperiences: RelevantWorkExperience[] = experiences
    .slice(0, 15)
    .map((experience: IWorkExperience) => {
      const relevantExperience: RelevantWorkExperience = {
        experience: experience.description,
      };
      return relevantExperience;
    });
  return relevantExperiences;
};

const prepLikes = (jobs: IJob[]): Like[] => {
  const likes: Like[] = [];
  jobs.map((job: IJob) => {
    const jobLikes = job.pluses ? JSON.parse(job.pluses) : [];
    jobLikes.map((plus: string) => {
      const like: Like = {
        like: plus,
      };
      likes.push(like);
    });
  });
  return likes;
};

const prepDislikes = (jobs: IJob[]): Dislike[] => {
  const dislikes: Dislike[] = [];
  jobs.map((job: IJob) => {
    const jobDislikes = job.minuses ? JSON.parse(job.minuses) : [];
    jobDislikes.map((plus: string) => {
      const like: Dislike = {
        dislike: plus,
      };
      dislikes.push(like);
    });
  });
  return dislikes;
};

const prepGoals = (goals: IGoal[]): Goal[] => {
  const goalsForPayload: Goal[] = [];
  goals.map((goal: IGoal) => {
    const goalForPayload: Goal = {
      goal: goal.goal,
      description: goal.details,
    };
    goalsForPayload.push(goalForPayload);
  });
  return goalsForPayload;
};

export const prepInterviewQuestionsPayload = (
  jobs: IJob[],
  jobDescription: string,
  experiences: IWorkExperience[],
  goals: IGoal[],
): QuestionsObject => {
  const payload: QuestionsObject = {
    jobDescription: jobDescription,
    relevantWorkExperience: prepExperiences(experiences),
    likes: prepLikes(jobs),
    dislikes: prepDislikes(jobs),
    goals: prepGoals(goals),
  };
  return payload;
};
