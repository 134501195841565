import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconRefresh } from "../../core/assets/img/refresh.svg";
import { IReview } from "types";
import { formatDate } from "utils/dateUtils";
import "./reviewItem.css";

export interface ReviewItemProps {
	hidden?: boolean;
	index: number;
	key: string;
	review: IReview;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewItem: React.FC<ReviewItemProps> = ({
	index,
	key,
	review,
	hidden,
}) => {
	const today = new Date();
	
	return (
		<div className={`review-item-outer-wrapper ${hidden && "review-item-outer-wrapper--hidden"}`}>
			<Link to={"/reviews/" + review.itemId} key={key + index}>
				<div className="review-item-wrapper review-item">
					<div className="review-item__content">
						<h2 className="type--heading-3">{review.name}</h2>
					</div>
					<div className="review-item__meta">
						{review.recurring && new Date(review.nextKeyDate).valueOf() > new Date(new Date().setDate(today.getDate())).valueOf() ? 
							<div className="recurring-meta">
								<IconRefresh />
								<p className="type--body--standard">Next Review Date: {formatDate(review.nextKeyDate)}</p>
							</div>
						: 
							<p className="type--body--standard">Review Date: {formatDate(review.nextKeyDate)}</p>
						}
					</div>
				</div>
			</Link>
		</div>
	);
};