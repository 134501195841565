import React from "react";
import { CredentialsContainer, DataContainer } from "./";

const UnstatedNextProviders: React.FC = (props) => {
	return (
		<CredentialsContainer.Provider>
			<DataContainer.Provider>{props.children}</DataContainer.Provider>
		</CredentialsContainer.Provider>
	);
};

export default UnstatedNextProviders;
