import React from "react";
import { GoalItem } from "components/core";
import { SentimentTimelineChart } from "./SentimentTimelineChart";
import { CategoryScale, Chart, PointElement, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ReactComponent as IconInfo } from "../../core/assets/img/info.svg";
import { ReactComponent as SentimentometerBackground } from "../../core/assets/img/sentimentometer.svg";
import { ReactComponent as SentimentometerPointer } from "../../core/assets/img/sentimentometer-pointer.svg";
import { DataContainer } from "containers/DataContainer";
import { IReview, ISentiment, IGoal, IJob, IWorkExperience } from "types";
import { getUnixTimestamp } from "utils";
import "./reviewTrendsTab.css";

export interface ReviewTrendsTabProps {
	review: IReview;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewTrendsTab: React.FC<ReviewTrendsTabProps> = ({
	review,
}) => {
	const {
		sentiments,
		jobs,
		goals,
		workExperiences,
	} = DataContainer.useContainer();
	
	Chart.register(CategoryScale, PointElement, ArcElement, Tooltip);
	
	const earliestJobDate = jobs.sort((job1: IJob, job2: IJob) => getUnixTimestamp(job1.startDate) - getUnixTimestamp(job2.startDate))[0].startDate;
	
	const earliestExperience = workExperiences.sort((experience1: IWorkExperience, experience2: IWorkExperience) => getUnixTimestamp(experience1.completionDate ? experience1.completionDate : experience1.dateCreated) - getUnixTimestamp(experience2.completionDate ? experience2.completionDate : experience2.dateCreated))[0];
	
	const earliestExperienceDate = earliestExperience.completionDate ? earliestExperience.completionDate : earliestExperience.dateCreated;
	
	const datesToCompare = [earliestJobDate, earliestExperienceDate];
	
	const earliestDate = datesToCompare.sort((date1: string, date2: string) => getUnixTimestamp(date1) - getUnixTimestamp(date2))[0];
	
	const applicableSentiments = sentiments.filter((sentiment: ISentiment) => getUnixTimestamp(sentiment.dateCreated) > getUnixTimestamp(review.startDate ? review.startDate : earliestDate) && getUnixTimestamp(sentiment.dateCreated) < getUnixTimestamp(review.nextKeyDate)).sort((a: ISentiment, b: ISentiment) => getUnixTimestamp(a.dateCreated) - getUnixTimestamp(b.dateCreated));
	
	const applicableExperiences = workExperiences.filter((experience: IWorkExperience) => getUnixTimestamp(experience.completionDate ? experience.completionDate : experience.dateCreated) > getUnixTimestamp(review.startDate ? review.startDate : earliestDate) && getUnixTimestamp(experience.completionDate ? experience.completionDate : experience.dateCreated) < getUnixTimestamp(review.nextKeyDate)).sort((a: IWorkExperience, b: IWorkExperience) => getUnixTimestamp(a.completionDate ? a.completionDate : a.dateCreated) - getUnixTimestamp(b.completionDate ? b.completionDate : b.dateCreated));
	
	let totalSentiment = 0;
	
	applicableSentiments.map((sentiment: ISentiment) => {
		totalSentiment = totalSentiment + sentiment.feeling;
	});
	
	const applicableSentimentAverage: number = Math.round((180 * (totalSentiment / applicableSentiments.length)) / 100);
	
	const startDate = new Date(review.startDate && review.type && review.type !== "overall" ? review.startDate : earliestDate);
	const endDate = new Date(review.nextKeyDate);
	
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const goalChartOptions = {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		responsive: true as const,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		cutout: "80%" as const,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		layout: {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			padding: 10 as const,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		plugins: {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			tooltip: {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				titleFont: {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					family: "'Fira Sans', sans-serif" as const,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					size: 10 as const,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					weight: 300 as const,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
				},
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				bodyFont: {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					family: "'Fira Sans', sans-serif" as const,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					size: 10 as const,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					weight: 600 as const,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
				},
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				displayColors: false as const,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
			} as const
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	};
	
	const goalChartsSetup = (): any[] => {
		const chartData: any[] = [];
		
		goals.map((goal: IGoal) => {
			const experiencesForGoal = applicableExperiences.filter((experience: IWorkExperience) => (experience.goalId && experience.goalId) === goal.itemId);
			
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const data = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				labels: [
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					"Related",
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					"Unrelated"
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
				],
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				datasets: [{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					label: "Experiences",
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					data: [experiencesForGoal.length, (applicableExperiences.length - experiencesForGoal.length)],
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					backgroundColor: [
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						"#EAB315",
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						"#F8E6B2"
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
					],
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					hoverOffset: 4,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					clip: 40,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					options: {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						animation: false,
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
					}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				}]
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			};
			
			chartData.push(data);
		});
		
		return chartData;
	};
	
	const goalCharts = goalChartsSetup();
	
	return (
		<div className="review-trends-content">
			<h2 className="type--heading-3 review-tab-subheading">Sentiment</h2>
			<div className="review-content-split--major-minor-container">
				<div className="split--major">
					<h3 className="type--heading-4">Timeline</h3>
					<SentimentTimelineChart
						applicableSentiments={applicableSentiments}
						startDate={startDate}
						endDate={endDate}
					/>
				</div>
				<div className="split--minor">
					<h3 className="type--heading-4">Average Sentiment</h3>
					<div className="minor-card">
						<div className="sentimentometer">
							<SentimentometerBackground className="sentimentometer__background" />
							<SentimentometerPointer
								style={{transform: `rotate(${applicableSentimentAverage}deg)`}}
								className="sentimentometer__pointer"
							/>
						</div>
						<p className="type--data">Sentimentometer</p>
					</div>
				</div>
			</div>
			<div className="info-row">
				<IconInfo />
				<p className="type--body--standard">The quality of the Sentiment trends depend on how often you record sentiments.</p>
			</div>
			{goals.length &&
				<div className="review-goals-content">
					<h2 className="type--heading-3 review-tab-subheading">Goals</h2>
					{goals.map((goal: IGoal, index: number) => {
						return (
							<div className="review-content-split--major-minor-container" key={goal.itemId}>
								<div className="split--major">
									<GoalItem
										index={index}
										newGoal={false}
										setNewGoal={() => console.log("um")}
										goal={goal}
										editable={false}
										useTheseRelatedExperience={applicableExperiences.filter((experience: IWorkExperience) => (experience.goalId && experience.goalId) === goal.itemId)}
									/>
								</div>
								<div className="split--minor">
									<h3 className="type--heading-4">Experience Related to Goal</h3>
									<div className="minor-card minor-card--horizontal">
										<div className="goal-chart-wrapper">
											{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore */}
											<Doughnut data={goalCharts[index]} options={goalChartOptions} />
										</div>
										<p className="type--display-stat--standard">{Math.round((applicableExperiences.filter((experience: IWorkExperience) => (experience.goalId && experience.goalId) === goal.itemId).length / applicableExperiences.length * 100))}%</p>
									</div>
								</div>
							</div>
						)
					})}	
				</div>
			}
		</div>
	);
};