import React from "react";
import { Button } from "components/core";
import { ReactComponent as IconClose } from "../assets/img/close.svg";
import "./searchField.css";

export interface SearchFieldProps {
	clearSearch: () => void;
	fieldOnChange: (values: string) => void;
	searchValue: string;
	shouldAutoFocus?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const SearchField: React.FC<SearchFieldProps> = ({
	clearSearch,
	fieldOnChange,
	searchValue,
	shouldAutoFocus = false
}) => {
	const IconCloseSVG = <IconClose />;
	return (
		<div className="search-field-wrapper">
			<input
				placeholder="Search"
				className="pattern--input-field search-field"
				type="text"
				value={searchValue}
				onChange={(e)=>{fieldOnChange(e.target.value)}}
				autoComplete="off" 
				autoCorrect="off" 
				autoCapitalize="off" 
				spellCheck="false"
				autoFocus={shouldAutoFocus}
			/>
			{searchValue !== "" &&
				<div className="clear-search-button">
					<Button
						label="Clear"
						buttonTitle="Clear Search"
						buttonType="icon-only"
						leadingIcon={IconCloseSVG}
						onClick={clearSearch}
					/>
				</div>
			}
		</div>
	);
};