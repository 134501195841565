export const formatDate = (dateToConvert: string): string => {
  const initialDate = new Date(dateToConvert);
  const convertedDate = new Date(dateToConvert).toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const today = new Date();
  const date = initialDate.getDate();
  const monthsAgo = today.getMonth() - initialDate.getMonth();
  const yearsAgo = today.getFullYear() - initialDate.getFullYear();
  const daysAgo = today.getDate() - date;
  if (
    initialDate.getDate() == today.getDate() &&
    initialDate.getMonth() == today.getMonth() &&
    initialDate.getFullYear() == today.getFullYear()
  ) {
    return "Today";
  } else if (yearsAgo === 0 && monthsAgo === 0 && daysAgo === 1) {
    return "Yesterday";
  } else {
    return convertedDate;
  }
};

export const getYear = (dateToConvert: string): string => {
  const initialDate = new Date(dateToConvert);

  const year = initialDate.getFullYear();

  return year as unknown as string;
};

export const getDaysAgo = (date: string): number => {
  const initialDate = new Date(date);

  const today = new Date();
  const differenceInTime = today.getTime() - initialDate.getTime();
  const daysAgo = differenceInTime / (1000 * 3600 * 24);
  return daysAgo;
};

export const getDaysFromNow = (date: string): number => {
  const futureDate = new Date(date);

  const today = new Date();
  const differenceInTime = futureDate.getTime() - today.getTime();
  const daysAgo = differenceInTime / (1000 * 3600 * 24);
  return daysAgo;
};

export const getPastDate = (
  date: string,
  timeAgoInDays: number,
  formatted: boolean,
): string => {
  const sourceDate = new Date(date);

  const pastDate = new Date(date);
  pastDate.setDate(sourceDate.getDate() - timeAgoInDays);

  if (formatted) {
    const pastDateString = formatDate(pastDate.toString());
    return pastDateString;
  } else {
    return pastDate.toString();
  }
};

export const getFutureDate = (
  date: string,
  timeFromNowInDays: number,
  formatted: boolean,
): string => {
  const sourceDate = new Date(date);

  const futureDate = new Date(date);
  futureDate.setDate(sourceDate.getDate() + timeFromNowInDays);

  if (formatted) {
    const pastDateString = formatDate(futureDate.toString());
    return pastDateString;
  } else {
    return futureDate.toString();
  }
};

export const getUnixTimestamp = (date: string): number => {
  const sourceDate = new Date(date);

  const timeInMilliseconds = sourceDate.getTime();

  const unixTimestamp = Math.floor(timeInMilliseconds / 1000);

  return unixTimestamp;
};

export const isSameDate = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getDatesBetween = (start: Date, end: Date): string[] => {
  const dates: string[] = [];
  const current = new Date(start);

  current.setDate(current.getDate() + ((7 - current.getDay()) % 7));

  while (current <= end) {
    dates.push(new Date(current).toUTCString());
    current.setDate(current.getDate() + 7);
  }

  return dates;
};
