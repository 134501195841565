import React from "react";
import { ErrorMessage, Field } from "formik";
import "./textarea.css";

export interface TextareaProps {
	label?: string;
	helperPrompt?: unknown;
	name?: string;
	onChange?: (value: string) => void;
	placeholder: string;
	value?: string;
	maxLength?: number;
	rows?: number;
}

export const Textarea: React.FC<TextareaProps> = (props) => {
	const { label, name, placeholder, helperPrompt } = props;

	return (
		<div className="field-wrapper pattern--field-wrapper">
			<label className="input-container">
				{label && (
					<span className="pattern--label type--heading-4">
						{label}
						{helperPrompt && helperPrompt}
					</span>
				)}
				<Field
					as="textarea"
					className="pattern--input-field"
					name={name}
					placeholder={placeholder ? placeholder : ""}
					rows={props.rows ? props.rows : 5}
					maxLength={props.maxLength && props.maxLength}
				/>
				<ErrorMessage component="div" className="pattern--field-error-message" name={name as string} />
			</label>
		</div>
	);
};
