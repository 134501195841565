import { API } from "aws-amplify";
import { IGoal } from "types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const similarity = require("compute-cosine-similarity");

const apiName = "careerminder";

export const getEmbedding = async (text: string): Promise<string> => {
    try {
        const response = await API.post(apiName, "/ai/embedding", { body: [text] });
        return JSON.stringify(response.data[0].embedding);
    } catch (err) {
        console.log("err", err);
        return JSON.stringify("Could not retreive embedding");
    }
};

export const uploadResume = async (file: File): Promise<string> => {
    if (!file) return JSON.stringify("There is no file");

    try {
        console.log("trying upload");
        const fileContent = await file.arrayBuffer();
        // Convert ArrayBuffer to Base64 string
        const base64 = btoa(
            new Uint8Array(fileContent).reduce((data, byte) => data + String.fromCharCode(byte), ""),
        );

        const response = await API.post(apiName, "/ai/resumeupload", {
            body: {
                fileContent: base64,
                fileName: file.name,
                fileType: file.type,
                fileSize: file.size,
            },
            headers: {
                "Content-Type": "application/json",
            },
        });
        return JSON.stringify(response);
    } catch (err) {
        console.log("err", err);
        return JSON.stringify("Could not get resume data");
    }
};

export const getCompletion = async (text: string, type: string): Promise<string> => {
    try {
        const response = await API.post(apiName, "/ai/completion", {
            body: {
                completionType: type,
                message: text,
            },
        });
        return JSON.stringify(response);
    } catch (err) {
        console.log("err", err);
        return JSON.stringify("Could not retreive completion");
    }
};

export const pickSuggestedGoalForVector = async (vector: string, goals: IGoal[]): Promise<string> => {
    // Set up array for cosine similarity scores
    const goalScores: number[] = [];

    if (goals.length) {
        // Create cosine similarity scores for all goals
        goals.map((goal: IGoal) => {
            const score = similarity(JSON.parse(vector), JSON.parse(goal.vector));
            goalScores.push(score);
        });

        // Find the index of the higest cosine similarity score
        const indexOfMax = goalScores.reduce(
            (maxIndex, current, index, array) => (current > array[maxIndex] ? index : maxIndex),
            0,
        );

        // Return the itemId of the highest scoring goal
        return goals[indexOfMax].itemId;
    } else {
        return "";
    }
};
