import React from "react";
import "./sentimentOption.css";

interface SentimentOptionProps {
  type: string;
  component: React.FC;
  selected: boolean;
  small: boolean;
  onClick: (type: string) => void;
}

export const SentimentOption: React.FC<SentimentOptionProps> = (props) => {
  const { type, component: Component, selected, small, onClick } = props;

  const handleClick = () => {
    onClick(type);
  };

  return (
    <button
      className={`sentiment-option-button ${
        small ? "sentiment-option-button--small" : ""
      } ${selected ? "sentiment-option-button--selected" : ""}`}
      onClick={handleClick}
    >
      <Component />
    </button>
  );
};
