import { convertFromRaw, convertToRaw } from "draft-js";
import { IBragSheet, IWorkExperience, IJob, ICompany, IProject, IGoal, IEducation, ISchool, ICertification } from "types";

export interface IBragSheetForExport {
	brag_sheet_name: string;
	brag_sheet_content: string;
	brag_sheet_date_created: string;
}

export interface ICertificationForExport {
	certification_name: string;
	certification_verification_link: string;
	certification_expiration_date: string;
	certification_date_created: string;
}

export interface IEducationForExport {
	education_degree: string;
	education_description: string;
	education_school: string;
	education_completion_date: string;
}

export interface IGoalForExport {
	goals_goal: string;
	goals_description: string;
	goals_date_created: string;
	goals_completion_date: string;
}

export interface IWorkExperienceForExport {
	experience_experience: string | number;
	experience_job: string;
	experience_project: string;
	experience_goal: string;
	experience_date_created: string | number;
	experience_completion_date: string | number;
}

export const generateDataCSV = (bragSheets: IBragSheet[], workExperiences: IWorkExperience[], jobs: IJob[], companies: ICompany[], projects: IProject[], goals: IGoal[], educations: IEducation[], schools: ISchool[], certifications: ICertification[]): string => {
	let csv = "";
	
	const bragSheetsFiltered: IBragSheetForExport[] = bragSheets.map(el => ({
		brag_sheet_name: '"' + el.bragSheetName.replace(/"/g, "\"\"") + '"',
		brag_sheet_content: '"' + convertToRaw(convertFromRaw(JSON.parse(el.content))).blocks.map((block) => (!block.text.trim() && "\n" || block.text)).join("\n").replace(/"/g, "\"\"") + '"',
		brag_sheet_date_created: '"' + el.dateCreated + '"',
	}));
	
	csv += "BRAG SHEET NAME" + "," + "BRAG SHEET CONTENT" + "," + "BRAG SHEET DATE CREATED" + "\r\n";
	
	bragSheetsFiltered.forEach((bragSheet) => {
		csv += bragSheet.brag_sheet_name + ',' + bragSheet.brag_sheet_content + "," + bragSheet.brag_sheet_date_created + "\r\n";
	});
	
	const certificationsFiltered: ICertificationForExport[] = certifications.map(el => ({
		certification_name: '"' + el.certificationName.replace(/"/g, "\"\"") + '"',
		certification_verification_link: '"' + el.verificationLink + '"',
		certification_expiration_date: '"' + el.expirationDate + '"',
		certification_date_created: '"' + el.dateCreated + '"',
	}));
	
	csv += "CERTIFICATION NAME" + "," + "CERTIFICATION VERIFICATION LINK" + "," + "CERTIFICATION EXPIRATION DATE" + "," + "CERTIFICATION DATE CREATED" + "\r\n";
	
	certificationsFiltered.forEach((certification) => {
		csv += certification.certification_name + ',' + certification.certification_verification_link + "," + certification.certification_expiration_date + "," + certification.certification_date_created + "\r\n";
	});
	
	const educationsFiltered: IEducationForExport[] = educations.map(el => ({
		education_degree: '"' + el.degree.replace(/"/g, "\"\"") + '"',
		education_description: '"' + el.description.replace(/"/g, "\"\"") + '"',
		education_school: '"' + schools.find(({ itemId }) => itemId === el.schoolId)?.schoolName.replace(/"/g, "\"\"") + '"',
		education_completion_date: '"' + el.completionDate + '"',
	}));
	
	csv += "EDUCATION DEGREE" + "," + "EDUCATION DESCRIPTION" + "," + "EDUCATION SCHOOL" + "," + "EDUCATION COMPLETION DATE" + "\r\n";
	
	educationsFiltered.forEach((education) => {
		csv += education.education_degree + ',' + education.education_description + "," + education.education_school + "," + education.education_completion_date + "\r\n";
	});
	
	const goalsFiltered: IGoalForExport[] = goals.map(el => ({
		goals_goal: '"' + el.goal.replace(/"/g, "\"\"") + '"',
		goals_description: '"' + el.details.replace(/"/g, "\"\"") + '"',
		goals_date_created: '"' + el.dateCreated + '"',
		goals_completion_date: '"' + el.completionDate + '"',
	}));
	
	csv += "GOAL" + "," + "GOAL DESCRIPTION" + "," + "GOAL DATE CREATED" + "," + "GOAL COMPLETION DATE" + "\r\n";
	
	goalsFiltered.forEach((goal) => {
		csv += goal.goals_goal + ',' + goal.goals_description + "," + goal.goals_date_created + "," + goal.goals_completion_date + "\r\n";
	});
	
	const experienceFiltered: IWorkExperienceForExport[] = workExperiences.map(el => ({
		experience_experience: el.description.length && '"' + el.description.replace(/"/g, "\"\"") + '"',
		experience_job: el.description.length && el.jobId.length ? '"' + jobs.find(({ itemId }) => itemId === el.jobId)?.jobTitle.replace(/"/g, "\"\"") + " • " + companies.find(
			(company) => company.itemId === jobs.find((job) => job.itemId === el.jobId)?.companyId)?.companyName.replace(/"/g, "\"\"") + '"' : "",
		experience_project: el.description.length && el.projectId.length ? '"' + projects.find(({ itemId }) => itemId === el.projectId)?.projectName.replace(/"/g, "\"\"") + '"' : "",
		experience_goal: el.description.length && el.goalId.length ? '"' + goals.find(({ itemId }) => itemId === el.goalId)?.goal.replace(/"/g, "\"\"") + '"' : "",
		experience_date_created: el.description.length && '"' + el.dateCreated + '"',
		experience_completion_date: el.description.length && '"' + el.completionDate + '"',
	}));
	
	csv += "EXPERIENCE" + "," + "EXPERIENCE JOB" + "," + "EXPERIENCE PROJECT" + "," + "EXPERIENCE GOAL" + "," + "EXPERIENCE DATE CREATED" + "," + "EXPERIENCE COMPLETION DATE" + "\r\n";
	
	experienceFiltered.forEach((experience) => {
		if (experience.experience_experience !== 0) {
			csv += experience.experience_experience + ',' + experience.experience_job + "," + (experience.experience_project !== "undefined" && experience.experience_project) + "," + experience.experience_goal + "," + experience.experience_date_created + "," + experience.experience_completion_date + "\r\n";
		}
	});
	
	return csv;
};