import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ExperienceItem, SequenceStepper } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as SentimentNegative } from "../../core/assets/img/sentiment-negative.svg";
import { ReactComponent as SentimentNeutral } from "../../core/assets/img/sentiment-neutral.svg";
import { ReactComponent as SentimentPositive } from "../../core/assets/img/sentiment-positive.svg";
import { ICatchUp, ISentiment, IWorkExperience } from "types";
import { convertToHtml } from "utils";
import "./onboardingCatchupReview.css";

export interface OnboardingCatchupReviewProps {
  catchUpId?: string;
}

export const OnboardingCatchupReview: React.FC<OnboardingCatchupReviewProps> = (props) => {
  const { catchUpId = "" } = props;
  const {
    catchups,
    sentiments,
    workExperiences,
    userDataLoaded,
    EmptyCatchup,
    setSentiments,
    user,
    userEmail,
  } = DataContainer.useContainer();

  const navigate = useNavigate();

  const catchUpLoaded = useRef<boolean>(false);

  const setUpCatchUp = (): ICatchUp => {
    if (userDataLoaded && !catchUpLoaded.current) {
      if (catchUpId === "") {
        return new EmptyCatchup(user);
      } else {
        const catchUp = catchups.filter((item: ICatchUp) => item.itemId === catchUpId)[0];
        return catchUp;
      }
    }
    return new EmptyCatchup(user);
  };

  const catchUp: ICatchUp = setUpCatchUp();

  const [currentSentiment, setCurrentSentiment] = useState<ISentiment>(
    sentiments.filter((sentiment: ISentiment) => sentiment.itemId === catchUp.sentiment)[0],
  );

  const relatedExperienceIDs: string[] = catchUp.experiences ? JSON.parse(catchUp.experiences) : [];

  const buildRelatedExperiences = (): IWorkExperience[] => {
    const relatedExperienceSet: IWorkExperience[] = [];
    relatedExperienceIDs.map((experienceId: string) => {
      workExperiences
        .filter((experience: IWorkExperience) => experience.itemId === experienceId)
        .map((experience: IWorkExperience) => {
          relatedExperienceSet.push(experience);
        });
    });
    return relatedExperienceSet;
  };

  const [relatedExperiences, setRelatedExperiences] = useState<IWorkExperience[]>(buildRelatedExperiences());

  if (
    process.env.REACT_APP_USER_BRANCH !== "master" ||
    (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
  ) {
    console.log("catchUp", catchUp);
    console.log("relatedSentiment", currentSentiment);
    console.log("relatedExperiences", relatedExperiences);
  }

  const buildRelatedExperienceIndexes = (): number[] => {
    const relatedExperienceIndexSet: number[] = [];
    relatedExperiences.map((experience: IWorkExperience) => {
      workExperiences.map((workExperience: IWorkExperience, index: number) => {
        if (experience.itemId === workExperience.itemId) {
          relatedExperienceIndexSet.push(index);
        }
      });
    });
    return relatedExperienceIndexSet;
  };

  const [relatedExperienceIndexes, setRelatedExperienceIndexes] = useState<number[]>(
    buildRelatedExperienceIndexes(),
  );

  useEffect(() => {
    setRelatedExperiences(buildRelatedExperiences());
    setRelatedExperienceIndexes(buildRelatedExperienceIndexes());
  }, [workExperiences]);

  const [summary] = useState<string>(catchUp.summary);

  const [newExperienceItem, setNewExperienceItem] = useState<boolean>(false);

  const [isEditingSentiment, setIsEditingSentiment] = useState<boolean>(false);

  const updateSentiment = (sentimentValue: number) => {
    if (currentSentiment.feeling !== sentimentValue) {
      const updatedSentiment = {
        ...currentSentiment,
        feeling: sentimentValue,
      };

      // Create a new array with the updated sentiment
      const updatedSentiments = sentiments.map((sentiment) =>
        sentiment.itemId === currentSentiment.itemId ? updatedSentiment : sentiment,
      );

      setCurrentSentiment(updatedSentiment);
      setSentiments(updatedSentiments);
    }
    setIsEditingSentiment(false);
  };

  return (
    <section className="pattern--container--flow pattern--container--flow-narrow">
      <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
        <h1 className="type--heading-1">Catch Up</h1>
        <div className="accessory--resizeable">
          <SequenceStepper length={6} currentIndex={5} />
        </div>
      </div>
      <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
        <h2 className="type--heading-3">Summary</h2>
        {!summary ? (
          <p className="type--body--large">No summary</p>
        ) : (
          <div
            className="catchup-summary-wrapper__content--review"
            dangerouslySetInnerHTML={{
              __html: convertToHtml(summary),
            }}
          ></div>
        )}
        {currentSentiment && (
          <div className="onboarding-sentiment-wrapper">
            <div className="complementary-sentiment complimentary-sentiment--onboarding">
              <div className="sentiment-toggle-wrapper">
                <button
                  className="sentiment-editing-toggle"
                  onClick={() => setIsEditingSentiment(!isEditingSentiment)}
                >
                  {currentSentiment.feeling !== 0 ? (
                    currentSentiment.feeling === 100 ? (
                      <SentimentPositive />
                    ) : (
                      <SentimentNeutral />
                    )
                  ) : (
                    <SentimentNegative />
                  )}
                </button>
                {isEditingSentiment && (
                  <div className="sentiment-editing-wrapper">
                    <button className="sentiment-update-button" onClick={() => updateSentiment(0)}>
                      <SentimentNegative />
                    </button>
                    <button className="sentiment-update-button" onClick={() => updateSentiment(50)}>
                      <SentimentNeutral />
                    </button>
                    <button className="sentiment-update-button" onClick={() => updateSentiment(100)}>
                      <SentimentPositive />
                    </button>
                  </div>
                )}
              </div>
              <p className="type--body--standard">
                From your description it seems like you are feeling{" "}
                {currentSentiment.feeling !== 0
                  ? currentSentiment.feeling === 100
                    ? "positive"
                    : "neutral"
                  : "negative"}{" "}
                about your work right now. Change your sentiment if that’s not accurate.
              </p>
            </div>
          </div>
        )}
        <h2 className="type--heading-3">Experience</h2>
        {relatedExperiences.length > 0 && (
          <div className="complementary-experiences">
            <p className="type--body--large">
              Here are some suggested experiences from your description. They may be rephrased, so make sure
              they are written how you would say them yourself.
            </p>
            <div className="experience-list-wrapper">
              {relatedExperiences.map((experience: IWorkExperience, index: number) => {
                return (
                  <ExperienceItem
                    index={relatedExperienceIndexes[index]}
                    key={experience.itemId}
                    newExperienceItem={newExperienceItem}
                    setNewExperienceItem={setNewExperienceItem}
                    workExperience={experience}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className="pattern--button-row">
          <Button
            label="Back"
            buttonTitle="Back to Resume Review"
            buttonType="utility"
            onClick={() => navigate("/onboarding/catchup")}
          />
          <Button
            label="Proceed to Dashboard"
            buttonTitle="Proceed to Dashboard"
            buttonType="secondary"
            type="button"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </section>
  );
};
