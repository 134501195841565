// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const experiencePrompts : any = [
	"Describe a time when you had to juggle multiple priority tasks. How did you do it?",
	"Describe when you had to adapt quickly to a change in priorities and you how did it.",
	"When have you failed at something while work and how did you recover?",
	"Describe a time that you led or influenced others while working.",
	"When have you imporved a situation with an angry coworker or customer? How?",
	"Describe a time when you achieved a goal and how you did it.",
	"What was a time when you made an unpopular decision that turned out well?",
	"Describe a time when you unexpectedly had to step up as a leader.",
	"Describe a time when you had to think on your feet.",
	"What was a long-term project you have worked on and how did you keep it on track?",
	"Describe a time when you successfully persuaded colleagues to take a course of action they would not have done otherwise.",
	"Describe a project or idea (not necessarily your own) that was implemented primarily because of your efforts.",
	"When have you worked well under pressure?",
	"Describe a time when you delegated tasks during a project.",
	"What was the last thing your boss complemented you for doing?",
	"Describe a time when you mentored other people you work with. How did you do it?",
	"When have you stepped up for a colleague to make sure things were done?",
	"Describe a time you succeeded at something you had not previously done.",
	"When have you changed the way things were done at your workplace?",
	"When have you gotten up to speed in a new situation and contributed quickly?",
	"Describe a time when you have handled an unexpected challenge.",
	"What was a time when you worked particularly well with a team?",
	"Describe when you have gone above and beyond at work.",
	"How have you gotten up to speed when you haven’t had specific training for a task.",
	"What is something you have done that others recognized for its creativity?",
	"When have you had to make a hard decision that turned out well? What happened?",
	"When have you felt proud of something you’ve done at work?",
	"What is the most impactful thing you have done for your company?",
	"Describe a time when you successfully pitched an idea in a more senior position.",
	"When have you successfully delivered against a tight deadline? How tight was it?",
	"When leading, how have you motivated those who follow you?",
	"What professional achievement do you wish more people recognized?",
	"Describe a time when you helped a failing project turnaround and succeed.",
	"What were you doing the last time you excited and energized in your work?",
	"When have you been able to succeed despite a lack of resources?",
	"When have you solved a problem that others might have escalated to your boss? Think helpful, not nefarious.",
	"Describe a time where you helpful in getting a new customer. How much did the company make?",
	"Describe a time where you helpful in retaining a customer who may have left. How much did you save the company?",
	"When have you had to fill in for somebody else and succeeded?",
	"What have you done to provide insights that were not obvious inside your company?",
	"What’s the best experience you’ve had with a customer?",
	"Describe how you have been able to adapt to change in your workplace.",
	"How many responsibilities have you had to handle at once? What were they?",
	"When and what have you contributed to an organizational goal you did not set yourself?",
	"When have you written something that directly contributed to an important decision?",
	"For what do your colleauges consider you an expert? How have you shared your knowledge?",
	"What is a successful presentation you have made? What impact did it have?",
	"When have you taken the initiative to solve a problem that was ignored?",
	"When have you contributed more than your share to a success?",
	"Describe a time when you were able to succeed in an ambiguous situation without much supervision.",
];

// Generated from https://www.random.org/integer-sets/?mode=advanced

export const promptSets = [
	[ 5, 6, 9, 25, 30],
	[ 15, 17, 32, 36, 40],
	[ 11, 14, 43, 49, 50],
	[ 4, 12, 26, 29, 33],
	[ 10, 26, 34, 37, 50],
	[ 9, 19, 30, 47, 48],
	[ 6, 7, 16, 20, 37],
	[ 5, 27, 40, 45, 48],
	[ 8, 28, 44, 48, 49],
	[ 7, 19, 32, 38, 50],
	[ 3, 5, 33, 37, 46],
	[ 3, 40, 42, 45, 46],
	[ 11, 21, 29, 47, 49],
	[ 21, 23, 40, 48, 50],
	[ 6, 16, 24, 44, 47],
	[ 1, 6, 22, 36, 47],
	[ 13, 22, 29, 47, 49],
	[ 20, 21, 22, 23, 50],
	[ 4, 7, 9, 19, 42],
	[ 15, 24, 44, 45, 50],
	[ 16, 17, 18, 28, 36],
	[ 3, 4, 13, 17, 29],
	[ 6, 24, 34, 38, 45],
	[ 19, 20, 28, 43, 50],
	[ 17, 19, 41, 44, 48],
	[ 5, 7, 11, 17, 38],
	[ 9, 37, 40, 41, 45],
	[ 24, 28, 29, 36, 37],
	[ 4, 9, 15, 25, 28],
	[ 17, 26, 34, 42, 48],
	[ 4, 11, 23, 39, 47],
	[ 9, 15, 28, 32, 34],
	[ 14, 16, 41, 42, 47],
	[ 8, 14, 18, 30, 36],
	[ 3, 18, 25, 42, 50],
	[ 18, 21, 31, 34, 47],
	[ 4, 24, 36, 46, 49],
	[ 5, 6, 33, 39, 47],
	[ 24, 34, 35, 42, 48],
	[ 28, 42, 43, 46, 49],
	[ 2, 11, 19, 22, 40],
	[ 13, 22, 33, 35, 47],
	[ 3, 5, 26, 32, 45],
	[ 7, 15, 23, 24, 38],
	[ 22, 31, 35, 42, 44],
	[ 10, 24, 28, 31, 47],
	[ 15, 18, 23, 35, 45],
	[ 1, 30, 38, 41, 49],
	[ 4, 13, 15, 30, 35],
	[ 5, 20, 33, 34, 47],
	[ 3, 9, 11, 37, 38],
	[ 14, 20, 23, 37, 47],
	[ 9, 30, 31, 34, 50],
	[ 21, 31, 36, 43, 45],
	[ 7, 10, 11, 24, 35],
	[ 3, 8, 10, 21, 26],
	[ 11, 14, 21, 24, 26],
	[ 16, 18, 19, 23, 32],
	[ 12, 25, 40, 47, 49],
	[ 1, 7, 13, 17, 44],
	[ 3, 10, 17, 23, 50],
	[ 1, 2, 4, 18, 42],
	[ 8, 16, 35, 36, 45],
	[ 2, 12, 20, 27, 28],
	[ 4, 19, 21, 35, 40],
	[ 1, 6, 9, 10, 16],
	[ 2, 5, 8, 32, 47],
	[ 1, 27, 38, 39, 43],
	[ 19, 21, 29, 35, 48],
	[ 10, 15, 32, 33, 45],
	[ 3, 8, 21, 22, 47],
	[ 6, 9, 19, 43, 44],
	[ 18, 31, 32, 34, 44],
	[ 6, 7, 24, 26, 46],
	[ 2, 8, 28, 39, 49],
	[ 5, 17, 23, 34, 39],
	[ 10, 19, 29, 30, 48],
	[ 1, 24, 31, 37, 40],
	[ 19, 24, 26, 33, 47],
	[ 18, 33, 40, 43, 44],
	[ 7, 14, 31, 41, 43],
	[ 1, 3, 18, 41, 45],
	[ 5, 19, 27, 30, 45],
	[ 15, 18, 20, 26, 40],
	[ 29, 30, 31, 34, 36],
	[ 7, 8, 12, 19, 45],
	[ 7, 18, 33, 34, 35],
	[ 11, 18, 20, 30, 33],
	[ 10, 26, 35, 44, 48],
	[ 1, 14, 17, 30, 34],
	[ 1, 10, 15, 16, 45],
	[ 7, 15, 27, 35, 47],
	[ 4, 10, 12, 18, 23],
	[ 9, 24, 25, 27, 40],
	[ 8, 22, 24, 43, 45],
	[ 13, 28, 30, 41, 46],
	[ 9, 14, 33, 37, 50],
	[ 4, 15, 18, 22, 35],
	[ 3, 8, 19, 21, 35],
	[ 4, 15, 29, 35, 43],
	[ 12, 21, 34, 40, 43],
	[ 2, 15, 21, 43, 44],
	[ 3, 5, 10, 41, 45],
	[ 5, 12, 18, 21, 42],
	[ 9, 25, 39, 43, 49],
	[ 31, 33, 35, 40, 48],
	[ 1, 8, 28, 34, 39],
	[ 25, 27, 37, 40, 41],
	[ 7, 12, 19, 21, 45],
	[ 19, 22, 37, 39, 47],
	[ 5, 13, 25, 32, 36],
	[ 4, 35, 36, 37, 38],
	[ 2, 12, 43, 45, 49],
	[ 11, 18, 22, 29, 30],
	[ 9, 13, 31, 38, 48],
	[ 2, 4, 7, 8, 9],
	[ 2, 15, 28, 30, 33],
	[ 6, 21, 27, 43, 50],
	[ 2, 8, 15, 17, 48],
	[ 31, 32, 44, 45, 47],
	[ 3, 14, 17, 35, 39],
	[ 5, 10, 25, 30, 48],
	[ 5, 20, 23, 29, 40],
	[ 30, 34, 37, 39, 50],
	[ 3, 16, 27, 31, 40],
	[ 13, 36, 37, 41, 43],
	[ 7, 11, 28, 36, 48],
	[ 2, 34, 35, 37, 49],
	[ 19, 33, 34, 44, 46],
	[ 8, 12, 25, 33, 37],
	[ 2, 13, 19, 21, 29],
	[ 21, 22, 24, 36, 39],
	[ 6, 9, 12, 17, 41],
	[ 2, 9, 11, 36, 45],
	[ 11, 13, 32, 48, 50],
	[ 3, 7, 46, 47, 48],
	[ 9, 14, 25, 36, 43],
	[ 1, 17, 35, 37, 46],
	[ 1, 10, 12, 42, 50],
	[ 3, 4, 37, 39, 47],
	[ 1, 3, 32, 40, 46],
	[ 39, 40, 47, 49, 50],
	[ 6, 17, 21, 36, 37],
	[ 8, 10, 34, 38, 47],
	[ 17, 22, 40, 43, 48],
	[ 2, 5, 6, 22, 35],
	[ 11, 32, 34, 44, 45],
	[ 2, 9, 39, 47, 49],
	[ 5, 12, 29, 39, 47],
	[ 1, 6, 12, 20, 26],
	[ 8, 11, 20, 30, 42],
	[ 5, 12, 28, 34, 45],
	[ 17, 29, 38, 41, 44],
	[ 11, 28, 30, 35, 43],
	[ 11, 13, 21, 25, 28],
	[ 2, 15, 16, 17, 28],
	[ 9, 13, 30, 33, 37],
	[ 5, 13, 23, 42, 49],
	[ 3, 4, 10, 28, 40],
	[ 14, 33, 34, 37, 48],
	[ 2, 16, 21, 36, 42],
	[ 11, 20, 36, 43, 47],
	[ 1, 8, 32, 41, 47],
	[ 11, 31, 35, 41, 45],
	[ 6, 14, 29, 31, 36],
	[ 5, 14, 23, 33, 46],
	[ 14, 21, 22, 24, 48],
	[ 1, 9, 24, 27, 41],
	[ 5, 35, 41, 46, 48],
	[ 7, 8, 10, 12, 23],
	[ 1, 13, 15, 25, 27],
	[ 9, 26, 42, 45, 48],
	[ 8, 11, 12, 43, 45],
	[ 5, 9, 30, 36, 37],
	[ 1, 23, 31, 43, 47],
	[ 11, 28, 32, 40, 49],
	[ 9, 37, 42, 45, 46],
	[ 1, 8, 18, 35, 46],
	[ 6, 8, 28, 31, 34],
	[ 3, 12, 20, 21, 23],
	[ 4, 12, 27, 32, 34],
	[ 29, 30, 36, 44, 50],
	[ 5, 41, 43, 44, 48],
	[ 9, 23, 46, 49, 50],
	[ 11, 17, 25, 28, 30],
	[ 5, 25, 36, 42, 47],
	[ 8, 18, 26, 29, 36],
	[ 2, 5, 17, 47, 49],
	[ 1, 20, 26, 38, 42],
	[ 17, 23, 31, 33, 43],
	[ 1, 17, 32, 36, 43],
	[ 8, 12, 28, 37, 38],
	[ 2, 10, 12, 47, 50],
	[ 1, 9, 14, 16, 47],
	[ 12, 23, 27, 40, 49],
	[ 3, 19, 29, 38, 50],
	[ 9, 11, 14, 24, 37],
	[ 6, 10, 13, 19, 27],
	[ 8, 14, 18, 48, 49],
	[ 1, 10, 12, 21, 41],
	[ 6, 12, 27, 45, 47],
	[ 13, 25, 33, 38, 50],
	[ 20, 23, 26, 35, 39],
	[ 3, 11, 14, 27, 46],
	[ 9, 16, 28, 40, 45],
	[ 6, 32, 38, 43, 45],
	[ 15, 24, 32, 33, 36],
	[ 29, 30, 32, 39, 43],
	[ 14, 25, 36, 40, 50],
	[ 3, 5, 10, 14, 41],
	[ 4, 17, 20, 33, 44],
	[ 4, 12, 19, 36, 50],
	[ 12, 15, 17, 29, 39],
	[ 4, 31, 33, 36, 50],
	[ 2, 8, 9, 20, 40],
	[ 8, 11, 33, 43, 44],
	[ 19, 21, 33, 38, 47],
	[ 2, 9, 39, 45, 49],
	[ 18, 25, 30, 36, 38],
	[ 10, 16, 33, 43, 49],
	[ 10, 19, 20, 21, 24],
	[ 9, 14, 25, 28, 50],
	[ 17, 27, 28, 32, 44],
	[ 8, 21, 22, 34, 41],
	[ 25, 32, 37, 41, 50],
	[ 6, 22, 29, 34, 37],
	[ 2, 5, 8, 25, 27],
	[ 12, 15, 22, 36, 45],
	[ 2, 27, 34, 37, 44],
	[ 8, 9, 28, 35, 43],
	[ 10, 12, 18, 27, 30],
	[ 3, 5, 12, 37, 50],
	[ 10, 12, 28, 30, 47],
	[ 6, 8, 25, 28, 48],
	[ 10, 26, 28, 36, 38],
	[ 4, 14, 30, 42, 44],
	[ 3, 12, 33, 45, 50],
	[ 14, 15, 18, 32, 48],
	[ 3, 18, 29, 33, 49],
	[ 16, 28, 36, 38, 45],
	[ 3, 15, 17, 40, 42],
	[ 16, 25, 28, 37, 41],
	[ 4, 14, 29, 32, 46],
	[ 1, 4, 6, 9, 38],
	[ 1, 24, 37, 45, 49],
	[ 13, 20, 26, 30, 32],
	[ 19, 28, 29, 34, 43],
	[ 1, 3, 14, 23, 38],
	[ 3, 10, 12, 34, 39],
	[ 11, 18, 33, 36, 44],
	[ 14, 19, 27, 30, 41],
	[ 9, 13, 21, 36, 46],
	[ 17, 19, 27, 35, 43],
	[ 6, 13, 16, 27, 36],
	[ 2, 6, 26, 42, 44],
	[ 11, 23, 40, 42, 44],
	[ 7, 14, 16, 18, 42],
	[ 24, 26, 31, 39, 44],
	[ 1, 15, 17, 24, 45],
	[ 5, 16, 24, 44, 46],
	[ 6, 15, 20, 37, 39],
	[ 12, 18, 34, 42, 47],
	[ 2, 3, 9, 21, 49],
	[ 13, 21, 28, 29, 40],
	[ 2, 12, 46, 47, 48],
	[ 1, 19, 32, 40, 42],
	[ 1, 4, 5, 20, 48],
	[ 22, 23, 41, 45, 49],
	[ 7, 10, 13, 31, 34],
	[ 21, 24, 26, 32, 46],
	[ 2, 7, 11, 20, 38],
	[ 13, 25, 26, 41, 44],
	[ 6, 18, 20, 26, 35],
	[ 2, 26, 27, 44, 50],
	[ 7, 14, 16, 19, 48],
	[ 17, 25, 37, 45, 50],
	[ 13, 23, 34, 41, 49],
	[ 1, 3, 14, 39, 49],
	[ 3, 21, 27, 34, 43],
	[ 2, 10, 32, 41, 48],
	[ 7, 15, 27, 30, 44],
	[ 1, 2, 16, 32, 49],
	[ 16, 24, 28, 41, 43],
	[ 2, 4, 9, 25, 41],
	[ 17, 29, 31, 39, 44],
	[ 5, 14, 27, 29, 47],
	[ 26, 30, 38, 42, 46],
	[ 17, 35, 44, 46, 47],
	[ 1, 2, 9, 39, 47],
	[ 2, 17, 19, 31, 49],
	[ 10, 24, 33, 34, 37],
	[ 3, 4, 18, 41, 50],
	[ 29, 39, 43, 48, 49],
	[ 1, 11, 26, 40, 45],
	[ 9, 14, 15, 18, 41],
	[ 4, 15, 18, 21, 47],
	[ 1, 9, 28, 42, 43],
	[ 8, 9, 15, 34, 42],
	[ 3, 9, 10, 22, 37],
	[ 12, 25, 26, 27, 38],
	[ 2, 14, 20, 26, 45],
	[ 9, 18, 30, 40, 41],
	[ 1, 2, 22, 25, 44],
	[ 9, 22, 23, 34, 37],
	[ 5, 12, 20, 30, 46],
	[ 6, 7, 32, 42, 46],
	[ 3, 6, 23, 34, 46],
	[ 4, 7, 44, 45, 46],
	[ 6, 7, 15, 16, 20],
	[ 7, 13, 29, 36, 50],
	[ 3, 5, 13, 44, 49],
	[ 10, 18, 19, 47, 48],
	[ 3, 6, 18, 34, 43],
	[ 2, 23, 27, 33, 36],
	[ 6, 14, 22, 25, 27],
	[ 21, 26, 27, 29, 44],
	[ 10, 37, 38, 39, 44],
	[ 16, 20, 40, 44, 50],
	[ 4, 7, 26, 27, 33],
	[ 20, 28, 37, 39, 40],
	[ 18, 19, 31, 32, 50],
	[ 5, 12, 22, 36, 42],
	[ 1, 4, 26, 30, 46],
	[ 15, 21, 32, 36, 50],
	[ 5, 35, 39, 44, 47],
	[ 1, 12, 13, 15, 46],
	[ 28, 31, 38, 39, 48],
	[ 3, 6, 29, 37, 43],
	[ 1, 6, 11, 12, 48],
	[ 3, 9, 13, 42, 45],
	[ 3, 11, 12, 19, 32],
	[ 5, 8, 23, 37, 41],
	[ 11, 24, 27, 41, 47],
	[ 20, 26, 43, 45, 47],
	[ 2, 17, 30, 43, 49],
	[ 12, 16, 21, 25, 42],
	[ 11, 17, 20, 21, 34],
	[ 14, 19, 37, 48, 49],
	[ 5, 6, 20, 45, 46],
	[ 6, 11, 13, 14, 46],
	[ 10, 20, 24, 30, 38],
	[ 8, 20, 32, 39, 46],
	[ 7, 40, 42, 47, 49],
	[ 12, 28, 32, 33, 49],
	[ 4, 12, 31, 32, 38],
	[ 5, 16, 21, 45, 48],
	[ 11, 13, 18, 26, 33],
	[ 11, 15, 16, 19, 22],
	[ 2, 22, 25, 28, 37],
	[ 11, 28, 32, 36, 45],
	[ 3, 10, 21, 27, 42],
	[ 11, 17, 24, 28, 36],
	[ 6, 14, 21, 30, 31],
	[ 1, 16, 19, 44, 49],
	[ 1, 9, 18, 29, 40],
	[ 4, 5, 9, 15, 49],
	[ 13, 19, 38, 42, 46],
	[ 6, 8, 18, 26, 41],
	[ 17, 28, 30, 35, 48],
	[ 19, 31, 33, 43, 48],
	[ 6, 30, 37, 39, 47],
	[ 2, 18, 19, 30, 47],
	[ 4, 5, 17, 41, 42],
	[ 15, 22, 31, 48, 50],
	[ 12, 19, 25, 38, 49],
];

export const getTodaysPrompts = (): string[] => {
	const now = new Date();
	const daysIntoYear: number = (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) - Date.UTC(now.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
	
	const indexes = promptSets[daysIntoYear];
	
	const prompts:string[] = [];
	
	indexes.forEach((item) => {
		prompts.push(experiencePrompts[item]);
	});
	
	return prompts;
};