import React, { useState, useEffect } from "react";
import { BackLink, Button, Checkbox } from "components/core";
import { ExperienceRecommender } from "../ExperienceRecommender";
import {
  ICompany,
  IJob,
  IProject,
  IWorkExperience,
  ISchool,
  IEducation,
  ICertification,
} from "types";
import { DataContainer } from "containers/DataContainer";
import { formatDate, getYear } from "utils";
import "./resumeBuilder.css";

interface ScoredExperience {
  itemId: string;
  score: number;
}

interface ResumeDataItem {
  content: string;
  itemId: string;
  picked: boolean;
  indent: 0 | 1 | 2;
  type:
    | "job"
    | "education"
    | "certification"
    | "experience"
    | "project"
    | "header";
  score?: number;
  children?: ResumeDataItem[];
}

export interface ResumeBuilderProps {
  setIsOpened: (value: boolean) => void;
  setResumeText: (value: string) => void;
}

interface ResumeContentProps {
  rows: ResumeDataItem[];
}

/**
 * Primary UI component for user interaction
 */
export const ResumeBuilder: React.FC<ResumeBuilderProps> = ({
  setIsOpened,
  setResumeText,
}) => {
  const {
    fullName,
    jobTitle,
    jobs,
    companies,
    projects,
    workExperiences,
    educations,
    certifications,
    schools,
    userEmail,
  } = DataContainer.useContainer();

  const [scoredExperience, setScoredExperience] = useState<ScoredExperience[]>(
    [],
  );
  const [filterExperience, setFilterExperience] = useState<boolean>(false);
  const [filterAmount, setFilterAmount] = useState<number>(10);

  const seedData = (): ResumeDataItem[] => {
    const data: ResumeDataItem[] = [];
    if (jobs.length > 0) {
      jobs
        .sort((jobA: IJob, jobB: IJob) => jobA.sortOrder - jobB.sortOrder)
        .map((job: IJob) => {
          let companyText = "";
          if (
            job.companyId !== "" &&
            job.companyId !== undefined &&
            companies.length > 0
          ) {
            companies.map((company: ICompany) => {
              if (company.itemId === job.companyId) {
                companyText = " • " + company.companyName;
              }
            });
          }

          const jobData: ResumeDataItem = {
            itemId: job.itemId,
            content: job.jobTitle + companyText,
            picked: true,
            indent: 0,
            type: "job",
            children: [],
          };

          const unassociatedJobWorkExperiences = workExperiences.filter(
            (experience: IWorkExperience) =>
              experience.jobId === job.itemId &&
              !projects.some(
                (project: IProject) => experience.projectId === project.itemId,
              ),
          );
          if (unassociatedJobWorkExperiences.length > 0) {
            unassociatedJobWorkExperiences.map(
              (workExperience: IWorkExperience) => {
                const unassociatedJobWorkExperiencesResumeItem: ResumeDataItem =
                  {
                    itemId: workExperience.itemId,
                    content: workExperience.description,
                    picked: true,
                    indent: 1,
                    type: "experience",
                  };
                jobData.children?.push(
                  unassociatedJobWorkExperiencesResumeItem,
                );
              },
            );
          }
          const associatedProjects = projects.filter(
            (project: IProject) => project.jobId.indexOf(job.itemId) !== -1,
          );
          if (associatedProjects.length > 0) {
            associatedProjects.map((project: IProject) => {
              const projectData: ResumeDataItem = {
                itemId: project.itemId,
                content: project.projectName,
                picked: true,
                indent: 1,
                type: "project",
                children: [],
              };
              const projectWorkExperiences = workExperiences.filter(
                (experience: IWorkExperience) =>
                  experience.projectId === project.itemId,
              );
              if (projectWorkExperiences.length > 0) {
                projectWorkExperiences.map(
                  (workExperience: IWorkExperience) => {
                    const projectWorkExperienceResumeItem: ResumeDataItem = {
                      itemId: workExperience.itemId,
                      content: workExperience.description,
                      picked: true,
                      indent: 2,
                      type: "experience",
                    };
                    projectData.children?.push(projectWorkExperienceResumeItem);
                  },
                );
                jobData.children?.push(projectData);
              }
            });
          }

          data.push(jobData);
        });
    }
    const unassociatedWorkExperiences = workExperiences.filter(
      (experience: IWorkExperience) =>
        experience.jobId === "" && experience.description !== "",
    );
    if (unassociatedWorkExperiences.length > 0) {
      const unassociatedExperienceResumeItem: ResumeDataItem = {
        itemId: "other-experience",
        content: "Experience",
        picked: true,
        indent: 0,
        type: "header",
        children: [],
      };
      unassociatedWorkExperiences.map((workExperience: IWorkExperience) => {
        const unassociatedWorkExperiences: ResumeDataItem = {
          itemId: workExperience.itemId,
          content: workExperience.description,
          picked: true,
          indent: 1,
          type: "experience",
        };
        unassociatedExperienceResumeItem.children?.push(
          unassociatedWorkExperiences,
        );
      });
      data.push(unassociatedExperienceResumeItem);
    }
    if (certifications.length > 0) {
      const certificationHeaderItem: ResumeDataItem = {
        itemId: "certification-header",
        content: "Certifications",
        picked: true,
        indent: 0,
        type: "header",
        children: [],
      };
      certifications.map((certification: ICertification) => {
        let contentString = certification.certificationName;
        if (
          certification.expirationDate &&
          certification.expirationDate != ""
        ) {
          contentString =
            contentString +
            `: Expires ` +
            (certification.expirationDate &&
              formatDate(certification.expirationDate));
        }
        const certificationResumeItem: ResumeDataItem = {
          itemId: certification.itemId,
          content: contentString,
          picked: true,
          indent: 1,
          type: "certification",
        };
        certificationHeaderItem.children?.push(certificationResumeItem);
      });
      data.push(certificationHeaderItem);
    }
    if (educations.length > 0) {
      const educationHeaderItem: ResumeDataItem = {
        itemId: "education-header",
        content: "Education",
        picked: true,
        indent: 0,
        type: "header",
        children: [],
      };
      educations.map((education: IEducation) => {
        let contentString = education.degree;
        if (education.schoolId !== "") {
          schools.map((school: ISchool) => {
            if (school.itemId === education.schoolId) {
              contentString =
                contentString + `: ` + (school.schoolName && school.schoolName);
            }
          });
          if (education.completionDate !== "") {
            contentString =
              contentString +
              ` • ` +
              (education.completionDate && getYear(education.completionDate));
          }
        } else {
          if (education.completionDate !== "") {
            contentString =
              contentString +
              ` • ` +
              (education.completionDate && getYear(education.completionDate));
          }
        }
        const educationResumeItem: ResumeDataItem = {
          itemId: education.itemId,
          content: contentString,
          picked: true,
          indent: 1,
          type: "education",
        };
        educationHeaderItem.children?.push(educationResumeItem);
      });
      data.push(educationHeaderItem);
    }
    return data;
  };

  const [resumeData, setResumeData] = useState<ResumeDataItem[]>(seedData());

  const [itemCount, setItemCount] = useState<number>(workExperiences.length);

  const unPickAllExperience = () => {
    const newResumeData: ResumeDataItem[] = [];
    resumeData.map((resumeItem: ResumeDataItem) => {
      const newItem = resumeItem;
      newItem.picked = newItem.type === "experience" ? false : newItem.picked;
      const newChildItems: ResumeDataItem[] = [];
      resumeItem.children?.map((childResumeItem: ResumeDataItem) => {
        const childItem = childResumeItem;
        childItem.picked =
          childItem.type === "experience" || childItem.type === "project"
            ? false
            : newItem.picked;
        const newGrandChildItems: ResumeDataItem[] = [];
        childItem.children?.map((grandChildResumeItem: ResumeDataItem) => {
          const grandChildItem = grandChildResumeItem;
          grandChildItem.picked =
            grandChildItem.type === "experience" ? false : newItem.picked;
          newGrandChildItems.push(grandChildItem);
        });
        newGrandChildItems.length > 0
          ? (childItem.children = newGrandChildItems)
          : (childItem.children = []);
        newChildItems.push(childItem);
      });
      newChildItems.length > 0
        ? (newItem.children = newChildItems)
        : (newItem.children = []);
      newResumeData.push(newItem);
    });
    setResumeData(newResumeData);
  };

  const changeItemPick = (itemId: string) => {
    const newResumeData: ResumeDataItem[] = [];
    resumeData.map((resumeItem: ResumeDataItem) => {
      const newItem = resumeItem;
      let valueForChildren: boolean | null = null;
      if (resumeItem.itemId === itemId) {
        newItem.picked = !newItem.picked;
        valueForChildren = newItem.picked;
      }
      const newChildItems: ResumeDataItem[] = [];
      resumeItem.children?.map((childResumeItem: ResumeDataItem) => {
        const childItem = childResumeItem;
        let valueForGrandChildren: boolean | null = null;
        if (valueForChildren !== null) {
          childItem.picked = valueForChildren;
        } else if (childItem.itemId === itemId) {
          childItem.picked = !childItem.picked;
          valueForGrandChildren = childItem.picked;
        }
        let experienceCountForProject = 0;
        const newGrandChildItems: ResumeDataItem[] = [];

        childItem.children?.map((grandChildResumeItem: ResumeDataItem) => {
          const grandChildItem = grandChildResumeItem;
          if (valueForGrandChildren !== null) {
            grandChildItem.picked = valueForGrandChildren;
          } else if (grandChildItem.itemId === itemId) {
            grandChildItem.picked = !grandChildItem.picked;
          }
          newGrandChildItems.push(grandChildItem);
          if (grandChildItem.picked) {
            experienceCountForProject = experienceCountForProject + 1;
          }
        });
        if (childItem.type === "project" && experienceCountForProject === 0) {
          childItem.picked = false;
        }
        newGrandChildItems.length > 0
          ? (childItem.children = newGrandChildItems)
          : (childItem.children = []);
        newChildItems.push(childItem);
      });
      newChildItems.length > 0
        ? (newItem.children = newChildItems)
        : (newItem.children = []);
      newResumeData.push(newItem);
    });
    setResumeData(newResumeData);
  };

  const buildResumeLine = (
    resumeItem: ResumeDataItem,
    first?: boolean,
    last?: boolean,
  ): string => {
    let content = "";

    switch (resumeItem.type) {
      case "job": {
        const job: IJob | undefined = jobs.find(
          ({ itemId }) => itemId === resumeItem.itemId,
        );
        content = content + `<p><strong>` + job?.jobTitle;
        if (job?.companyId) {
          const company: ICompany | undefined = companies.find(
            ({ itemId }) => itemId === job.companyId,
          );
          content = content + ` &bull; ` + company?.companyName;
        }
        const startDate = job?.startDate
          ? formatDate(job.startDate)
          : "Unknown Date";
        const endDate = job?.endDate ? formatDate(job.endDate) : "Present";
        content =
          content +
          `</strong></p><p>` +
          startDate +
          ` &ndash; ` +
          endDate +
          `</p>`;
        break;
      }
      case "education": {
        const education: IEducation | undefined = educations.find(
          ({ itemId }) => itemId === resumeItem.itemId,
        );
        content =
          content +
          (first === true ? "<ul>" : "") +
          `<li><strong>` +
          (education?.degree && education?.degree) +
          `:</strong>`;
        if (education?.schoolId) {
          const school: ISchool | undefined = schools.find(
            ({ itemId }) => itemId === education.schoolId,
          );
          content = content + ` ` + (school?.schoolName && school?.schoolName);
        }
        if (education?.completionDate) {
          content =
            content +
            ` &bull; ` +
            (education.completionDate && getYear(education.completionDate));
        }
        content = content + `</li>` + (last === true ? "</ul>" : "");
        break;
      }
      case "certification": {
        const certification: ICertification | undefined = certifications.find(
          ({ itemId }) => itemId === resumeItem.itemId,
        );
        content =
          content +
          (first === true ? "<ul>" : "") +
          `<li><strong>` +
          (certification?.certificationName &&
            certification.certificationName) +
          `</strong>`;
        if (
          certification?.expirationDate &&
          certification?.expirationDate !== ""
        ) {
          content =
            content +
            `: Expires ` +
            (certification.expirationDate &&
              formatDate(certification.expirationDate));
        }
        content = content + `</li>` + (last === true ? "</ul>" : "");
        break;
      }
      case "experience": {
        content =
          content +
          (first === true ? "<ul>" : "") +
          `<li>` +
          resumeItem.content +
          `</li>` +
          (last === true ? "</ul>" : "");
        break;
      }
      case "project": {
        const project: IProject | undefined = projects.find(
          ({ itemId }) => itemId === resumeItem.itemId,
        );
        content = content + `<p><em>` + project?.projectName + `</em></p>`;
        break;
      }
      case "header": {
        content =
          content + `<p><strong>` + resumeItem.content + `</p></strong>`;
        break;
      }
      default: {
        if (
          process.env.REACT_APP_USER_BRANCH !== "master" ||
          (process.env.REACT_APP_USER_BRANCH === "master" &&
            userEmail === "releasetest@careerminder.io")
        ) {
          console.log("resumeItem", resumeItem);
        }
        break;
      }
    }
    return content;
  };

  const buildResume = () => {
    let resumeTextForBuilding =
      `<p><strong>` + fullName + `</strong></p><p>` + jobTitle + `</p><br />`;
    resumeData.map((resumeItem: ResumeDataItem, index: number) => {
      if (resumeItem.picked) {
        resumeTextForBuilding =
          resumeTextForBuilding +
          buildResumeLine(
            resumeItem,
            index === 0 ? true : false,
            index === resumeData.length - 1 ? true : false,
          );
      }
      resumeItem.children?.map(
        (childResumeItem: ResumeDataItem, childIndex: number) => {
          if (
            resumeTextForBuilding.substr(resumeTextForBuilding.length - 5) ===
            "</li>"
          ) {
            resumeTextForBuilding = resumeTextForBuilding + "</ul>";
          }
          let childArrayLength = -1;
          if (resumeItem.children !== undefined) {
            childArrayLength = resumeItem.children?.length;
          }
          if (childResumeItem.picked) {
            resumeTextForBuilding =
              resumeTextForBuilding +
              buildResumeLine(
                childResumeItem,
                childIndex === 0 ? true : false,
                childIndex === childArrayLength - 1 ? true : false,
              );
          }
          childResumeItem.children?.map(
            (grandChildResumeItem: ResumeDataItem, grandChildIndex: number) => {
              if (
                resumeTextForBuilding.substr(
                  resumeTextForBuilding.length - 5,
                ) === "</li>"
              ) {
                resumeTextForBuilding = resumeTextForBuilding + "</ul>";
              }
              let grandChildArrayLength = -1;
              if (childResumeItem.children !== undefined) {
                grandChildArrayLength = childResumeItem.children?.length;
              }
              if (grandChildResumeItem.picked) {
                resumeTextForBuilding =
                  resumeTextForBuilding +
                  buildResumeLine(
                    grandChildResumeItem,
                    grandChildIndex === 0 ? true : false,
                    grandChildIndex === grandChildArrayLength - 1
                      ? true
                      : false,
                  );
              }
            },
          );
        },
      );
    });
    if (
      resumeTextForBuilding.substr(resumeTextForBuilding.length - 5) === "</li>"
    ) {
      resumeTextForBuilding = resumeTextForBuilding + "</ul>";
    }
    setResumeText(resumeTextForBuilding);
  };

  const pickItem = (itemId: string, score: number) => {
    const newResumeData: ResumeDataItem[] = [];
    resumeData.map((resumeItem: ResumeDataItem) => {
      const newItem = resumeItem;
      if (resumeItem.itemId === itemId) {
        newItem.picked = true;
        newItem.score = score;
      }
      const newChildItems: ResumeDataItem[] = [];
      resumeItem.children?.map((childResumeItem: ResumeDataItem) => {
        const childItem = childResumeItem;
        if (childItem.itemId === itemId) {
          childItem.picked = true;
          childItem.score = score;
        }
        const newGrandChildItems: ResumeDataItem[] = [];
        childItem.children?.map((grandChildResumeItem: ResumeDataItem) => {
          const grandChildItem = grandChildResumeItem;
          if (grandChildItem.itemId === itemId) {
            grandChildItem.picked = true;
            grandChildItem.score = score;
          }
          newGrandChildItems.push(grandChildItem);
        });
        newGrandChildItems.length > 0
          ? (childItem.children = newGrandChildItems)
          : (childItem.children = []);
        newChildItems.push(childItem);
      });
      newChildItems.length > 0
        ? (newItem.children = newChildItems)
        : (newItem.children = []);
      newResumeData.push(newItem);
    });
    setResumeData(newResumeData);
  };

  useEffect(() => {
    if (filterExperience) {
      unPickAllExperience();
      scoredExperience.map(
        (scoredExperience: ScoredExperience, index: number) => {
          if (index < filterAmount) {
            pickItem(scoredExperience.itemId, scoredExperience.score);
          }
        },
      );
    }
    setFilterExperience(false);
  }, [filterExperience]);

  useEffect(() => {
    let experienceActive = 0;
    resumeData.map((resumeItem: ResumeDataItem) => {
      if (resumeItem.type === "experience" && resumeItem.picked === true) {
        experienceActive = experienceActive + 1;
      }
      resumeItem.children?.map((childResumeItem: ResumeDataItem) => {
        if (
          childResumeItem.type === "experience" &&
          childResumeItem.picked === true
        ) {
          experienceActive = experienceActive + 1;
        }
        childResumeItem.children?.map(
          (grandChildResumeItem: ResumeDataItem) => {
            if (
              grandChildResumeItem.type === "experience" &&
              grandChildResumeItem.picked === true
            ) {
              experienceActive = experienceActive + 1;
            }
          },
        );
      });
    });
    setItemCount(experienceActive);
  }, [resumeData]);

  const ResumeContent: React.FC<ResumeContentProps> = ({ rows }) => {
    return (
      <>
        {rows &&
          rows.map((row: ResumeDataItem) => {
            return (
              <>
                <div
                  className={`resume-item resume-item--indent-level--${row.indent} resume-item--type--${row.type}`}
                  key={row.itemId}
                >
                  <Checkbox
                    label={row.content}
                    value={row.itemId}
                    checked={row.picked}
                    onChange={() => changeItemPick(row.itemId)}
                  />
                  {row.score && row.picked === true && (
                    <div className="type--body--small resume-item__score">
                      {Math.round(row.score * 100) + "% Match"}
                    </div>
                  )}
                </div>
                {row.children && (
                  <ResumeContent rows={row.children && row.children} />
                )}
              </>
            );
          })}
      </>
    );
  };

  return (
    <section className="resume-builder resume-builder--wrapper">
      <BackLink
        button={true}
        label="Cancel &amp; Return to Brag Sheet"
        title="Cancel"
        onClick={() => {
          setIsOpened(false);
        }}
      />
      <ExperienceRecommender
        setScoredExperience={setScoredExperience}
        setFilterExperience={setFilterExperience}
        setFilterAmount={setFilterAmount}
      />
      <div className="resume-content">
        <div className="resume-experience-counter">
          <span className="type--body--standard">
            {itemCount} Experiences Selected
          </span>
        </div>
        <div className="resume-content--picker">
          <ResumeContent rows={resumeData} />
        </div>
      </div>
      <div className="action-bar resume-builder__action-bar">
        <Button
          label="Build Resume"
          buttonTitle="Finsh and Build your Resume"
          disabled={itemCount > 0 ? false : true}
          buttonType="primary"
          onClick={() => {
            buildResume();
            setIsOpened(false);
          }}
        />
      </div>
    </section>
  );
};
