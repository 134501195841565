import React, { useState, useEffect } from "react";
import { Button, Toggle, SegmentedControl } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { generateReminderMessage } from "utils/stringManipulation";
import { sendEmail } from "utils";
import "./reminders.css";

export interface RemindersProps {
	showHeader?: boolean;
}

export const Reminders: React.FC<RemindersProps> = ({ showHeader = true }) => {
	const {
		preferredName,
		reminderEnabled,
		reminderDay,
		reminderFrequency,
		setReminderEnabled,
		setReminderDay,
		setReminderFrequency,
		setSubmitProfile,
		subscriptionActive,
		userDataLoaded,
		userEmail,
	} = DataContainer.useContainer();

	const [confirmationMessage, setConfirmationMessage] = useState(
		"You will be reminded via email on Monday every week."
	);

	useEffect(() => {
		setConfirmationMessage(
			generateReminderMessage(reminderFrequency, reminderDay)
		);
	}, [reminderFrequency, reminderDay]);

	const [exampleEmailSent, setExampleEmailSent] = useState<boolean>(false);

	return (
		<section>
			{showHeader && (
				<h1 className="type--heading-1 pattern--container--flow__heading">
					How would you like to be reminded?
				</h1>
			)}
			<div className="reminders-form">
				<Toggle
					label="Reminders Enabled"
					value="reminderEnabled"
					name="enabled"
					checked={reminderEnabled}
					onClick={() => setReminderEnabled(!reminderEnabled)}
				/>
				<SegmentedControl
					selected={reminderFrequency}
					label="How often would you like to be reminded to add work experience?"
					name="reminderFrequency"
					onChange={(value) => setReminderFrequency(value)}
					options={frequencyOptions}
					disabled={reminderEnabled ? false : true}
				/>
				<SegmentedControl
					selected={reminderDay}
					label="What day would you like to be reminded?"
					name="reminderDay"
					onChange={(value) => setReminderDay(value)}
					options={dayOptions}
					disabled={
						reminderFrequency !== "daily" && reminderEnabled ? false : true
					}
				/>
				{reminderEnabled && (
					<div className="pane--affirmative">
						<p className="type--body--standard">{confirmationMessage}</p>
						<Button
							label={
								exampleEmailSent
									? "Your example email should arrive shortly"
									: "Send Me an Example"
							}
							buttonTitle={
								exampleEmailSent
									? "Your example email should arrive within a few minutes"
									: "Send Me an Example"
							}
							onClick={() => {
								sendEmail(
									confirmationMessage,
									"",
									preferredName,
									"testReminder",
									userEmail
								);
								setExampleEmailSent(true);
							}}
							buttonType="utility"
							size="small"
							disabled={exampleEmailSent ? true : false}
						/>
					</div>
				)}
				<div className="pattern--button-row">
					<Button
						label="Save"
						disabled={!subscriptionActive || !userDataLoaded ? true : false}
						onClick={() => {
							if (reminderEnabled) {
								if (reminderFrequency === "") {
									setReminderFrequency(frequencyOptions[0].value);
								}
								if (reminderDay === "") {
									setReminderDay(dayOptions[0].value);
								}
							}
							setSubmitProfile(true);
						}}
						buttonType="primary"
					/>
				</div>
			</div>
		</section>
	);
};

interface IOption {
	label: string;
	value: string;
	default: boolean;
}

const frequencyOptions: IOption[] = [
	{
		label: "Weekly",
		value: "weekly",
		default: true,
	},
	{
		label: "Monthly",
		value: "monthly",
		default: false,
	},
	{
		label: "Daily",
		value: "daily",
		default: false,
	},
];

const dayOptions: IOption[] = [
	{
		label: "Monday",
		value: "monday",
		default: true,
	},
	{
		label: "Tuesday",
		value: "tuesday",
		default: false,
	},
	{
		label: "Wednesday",
		value: "wednesday",
		default: false,
	},
	{
		label: "Thursday",
		value: "thursday",
		default: false,
	},
	{
		label: "Friday",
		value: "friday",
		default: false,
	},
];
