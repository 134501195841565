export const generateReminderMessage = (reminderFrequency: string, reminderDay: string): string => {
	let day = "";
	
	if (reminderDay === "monday") {
		day = "Monday";
	} else if (reminderDay === "tuesday") {
		day = "Tuesday";
	} else if (reminderDay === "wednesday") {
		day = "Wednesday";
	} else if (reminderDay === "thursday") {
		day = "Thursday";
	} else {
		day = "Friday";
	}
	
	let message = ""
	
	if (reminderFrequency === "weekly") {
		message = "We will remind you via email on " + day + " every week.";
	} else if (reminderFrequency === "monthly") {
		message = "We will remind you via email on the first " + day + " of every month.";
	} else {
		message = "We will remind you via email every day.";
	}
	return message;
};

export const convertToHtml = (input: string): string => {
	let html = '';
	const lines = input.split('\n');
	let inList = false;
	
	lines.forEach((line, index) => {
		if (line.startsWith('- ')) {
			if (!inList) {
				html += '<ul>';
				inList = true;
			}
			html += `<li>${line.substring(2)}</li>`;
		} else {
			if (inList) {
				html += '</ul>';
				inList = false;
			}
			if (line.trim() !== '') {
				const isHeading = line.endsWith(':');
				// Count the words in the line
				const wordCount = line.split(' ').length;
				// Apply <strong> only if it's a heading of 5 words or less
				const content = isHeading && wordCount <= 5 ? `<strong>${line}</strong>` : line;
	
				if (index === 0 || lines[index - 1].trim() === '' || lines[index - 1].startsWith('- ')) {
					html += `<p>${content}`;
				} else {
					html += `<br />${content}`;
				}
			}
		}
	});
	
	// Close any unclosed tags appropriately
	if (inList) {
		html += '</ul>';
	} else if (html.endsWith('<br />')) {
		// Ensure the paragraph is properly closed if it ends with a line break
		html += '</p>';
	} else if (!html.endsWith('</ul>') && !html.endsWith('<p>')) {
		// Ensure paragraphs are closed if they don't end with a list or aren't empty
		html += '</p>';
	}
	
	return html;
};