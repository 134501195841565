import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/core";
import { ReactComponent as IconRefresh } from "../assets/img/refresh.svg";
import { ReactComponent as IconClose } from "../assets/img/close.svg";
import { getTodaysPrompts } from "data";
import "./experiencePrompt.css";

export interface ExperiencePromptProps {
  addExperienceButton?: boolean;
  borderRadius?: "atomic" | "grouping";
  visible?: boolean;
  setVisible?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const ExperiencePrompt: React.FC<ExperiencePromptProps> = ({
  addExperienceButton = false,
  borderRadius = "atomic",
  visible = true,
  setVisible,
}) => {
  const navigate = useNavigate();

  const [currentPrompt, setCurrentPrompt] = useState<number>(0);

  const prompts = getTodaysPrompts();

  const IconRefreshSVG = <IconRefresh />;
  const IconCloseSVG = <IconClose />;

  return (
    <div
      className={`experience-prompt-wrapper ${!visible && "experience-prompt-wrapper--hidden"} ${
        addExperienceButton && "experience-prompt-wrapper--add-button"
      } experience-prompt-wrapper--radius-${borderRadius}`}
    >
      <div className="experience-prompt-header">
        <div className="experience-prompt-header__meta">
          <h3 className="type--heading-6">Experience Prompt</h3>
          <Button
            label="Refresh"
            buttonTitle="Get another prompt"
            buttonType="icon-only"
            size="small"
            theme="prompt"
            leadingIcon={IconRefreshSVG}
            onClick={() => setCurrentPrompt(currentPrompt === 4 ? 0 : currentPrompt + 1)}
            analyticsTag="plausible-event-name=Prompt+Engagement"
          />
        </div>
        {setVisible && (
          <Button
            label="Close"
            buttonTitle="Close Experience Prompt"
            buttonType="icon-only"
            size="small"
            theme="prompt"
            leadingIcon={IconCloseSVG}
            onClick={setVisible}
            analyticsTag="plausible-event-name=Prompt+Dismissal"
          />
        )}
      </div>
      <p className="type--body--large prompt-text">{prompts[currentPrompt]}</p>
      {addExperienceButton && (
        <Button
          label="Add Experience"
          buttonTitle="Add new experience with these prompts"
          buttonType="utility"
          size="small"
          theme="prompt"
          onClick={() => navigate("experience/new")}
          analyticsTag="plausible-event-name=Prompt+Engagement"
        />
      )}
    </div>
  );
};
