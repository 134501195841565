import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, DatePicker, Dialog, HelperPrompt, Textarea, ExperiencePrompt } from "components/core";
import { ReactComponent as IconClose } from "../assets/img/close.svg";
import { ReactComponent as IconCopy } from "../assets/img/copy.svg";
import { ReactComponent as IconEdit } from "../assets/img/edit.svg";
import { ReactComponent as IconMinus } from "../assets/img/minus.svg";
import { ReactComponent as IconWhistle } from "../assets/img/whistle.svg";
import { ReactComponent as IconDonutSpinner } from "../assets/img/donut-spinner.svg";
import { IGoal, ICompany, ISelectDropDown, IJob, IProject, IWorkExperience } from "types";
import { DataContainer } from "containers/DataContainer";
import "./experienceItem.css";
import { formatDate, workExperiencesValidationSchema, getEmbedding } from "utils";

interface Values {
  workExperience: IWorkExperience;
}
interface WorkExperienceProps {
  autoFocus?: boolean;
  index: number;
  newExperienceItem: boolean;
  remove?: (index: number) => void;
  setNewExperienceItem: (index: boolean) => void;
  workExperience: IWorkExperience;
  hidden?: boolean;
  searchDeletion?: boolean;
  setSearchDeletion?: (value: boolean) => void;
}

export const ExperienceItem: React.FC<WorkExperienceProps> = (props) => {
  const {
    index,
    newExperienceItem,
    setNewExperienceItem,
    workExperience,
    searchDeletion,
    setSearchDeletion,
  } = props;

  const {
    EmptyJob,
    EmptyProject,
    goals,
    jobs,
    companies,
    projects,
    user,
    setJobs,
    setProjects,
    setWorkExperiences,
    subscriptionActive,
    workExperiences,
  } = DataContainer.useContainer();
  const [editing, setEditing] = useState<boolean>(newExperienceItem && index === -1 ? true : false);
  const [isExperienceDeleteConfirmationOpened, setIsExperienceDeleteConfirmationOpened] = useState(false);

  const initialValues = {
    workExperience,
  };

  // useEffect keeps the setPromptsVisible from happening more than once as things re-render

  const experienceDescriptionOriginal = useRef<string>(workExperience.description);

  const IconCloseSVG = <IconClose />;
  const IconCopySVG = <IconCopy />;
  const IconEditSVG = <IconEdit />;
  const IconMinusSVG = <IconMinus />;
  const IconDonutSpinnerSVG = <IconDonutSpinner />;

  const goalItems: ISelectDropDown[] = goals.map((goal: IGoal) => {
    return { value: goal.itemId, label: goal.goal };
  });

  const buildJobText = (): string[] => {
    const texts: string[] = jobs
      .sort((a: IJob, b: IJob) => a.sortOrder - b.sortOrder)
      .map((job: IJob) => {
        const company: ICompany | undefined = companies.find(
          (company: ICompany) => company.itemId === job.companyId,
        );
        if (company === undefined || company.companyName === "") {
          return job.jobTitle;
        } else {
          return job.jobTitle + " at " + company.companyName;
        }
      });
    return texts;
  };

  const [jobText, setJobText] = useState<string[]>(buildJobText());

  const buildJobItems = (): ISelectDropDown[] => {
    const dropDownItems: ISelectDropDown[] = jobs
      .sort((a: IJob, b: IJob) => a.sortOrder - b.sortOrder)
      .map((job: IJob, index: number) => {
        const startDate = job.startDate ? formatDate(job.startDate) : "Unknown Date";
        const endDate = job.endDate ? formatDate(job.endDate) : "Present";
        return {
          value: job.itemId,
          label: jobText[index] + ": " + startDate + " - " + endDate,
        };
      });
    return dropDownItems;
  };

  const [jobItems, setJobItems] = useState<ISelectDropDown[]>(buildJobItems());

  const [projectItems, setProjectItems] = useState<ISelectDropDown[]>(
    projects.map((project: IProject) => {
      return { value: project.itemId, label: project.projectName };
    }),
  );

  // TODO: Double check that Formik works with the new select fields
  // TODO: Filter Project List based on Job
  // TODO: Allow for deletion of Projects within Select field
  // TODO: Add popovers for Copy confirmation and Goal Info
  // TODO: Change text inputs to dropdowns for Job, Projects, and Goal

  function addtoClipboard(content: string) {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(content);
  }

  const createJob = (jobTitle: string): string => {
    const newJob: IJob = new EmptyJob(user);
    newJob.jobTitle = jobTitle;
    const updatedJobs = [newJob, ...jobs];
    updatedJobs.forEach(function (part: IJob, index, updatedJobs) {
      updatedJobs[index].sortOrder = index;
    });
    setJobs(updatedJobs);
    setJobText(buildJobText());
    setJobItems(buildJobItems());
    return newJob.itemId;
  };

  const createProject = (projectName: string, job: string): string => {
    const newProject: IProject = new EmptyProject(user);
    newProject.projectName = projectName;
    newProject.jobId = job;
    const projectDateCreated = new Date();
    newProject.dateCreated = projectDateCreated.toISOString();
    const updatedProjects = [...projects, newProject];
    setProjects(updatedProjects);
    setProjectItems(
      projects.map((project: IProject) => {
        return { value: project.itemId, label: project.projectName };
      }),
    );
    return newProject.itemId;
  };

  const DisplayItem = () => {
    const [metaString, setMetaString] = useState<(string | null | undefined)[]>(
      [
        jobItems.length > 0 && workExperience.jobId
          ? jobText[jobItems.findIndex((jobItem: ISelectDropDown) => jobItem.value === workExperience.jobId)]
          : null,
        workExperience.projectId &&
        projects.find((project: IProject) => project.itemId === workExperience.projectId) !== undefined
          ? projects.find((project: IProject) => project.itemId === workExperience.projectId)?.projectName
          : null,
        workExperience.goalId
          ? goals.filter((goal: IGoal) => goal.itemId === workExperience.goalId)[0].goal
          : null,
      ].filter(Boolean),
    );

    return (
      <div className="experience-item-inner-wrapper">
        <div className="experience-item__content experience-content-wrapper">
          <div className="experience-content-wrapper__content experience-content">
            <p className="experience-content__text">{workExperience.description}</p>
            <p className="experience-content__taxonomy">
              {metaString.map((textString, index) => {
                return (
                  <span key={(textString && textString + index) as string}>
                    {textString} {index != metaString.length - 1 ? " • " : ""}
                  </span>
                );
              })}
            </p>
          </div>
        </div>
        <div className="experience-log__meta experience-meta">
          <p className="experience-date">
            {workExperience.completionDate !== undefined && workExperience.completionDate.length
              ? formatDate(workExperience.completionDate)
              : "\u00A0"}
          </p>
          <div className="experience-date-actions__actions experience-actions">
            <Button
              label="Edit"
              buttonTitle="Edit Experience"
              buttonType="icon-only"
              size="small"
              leadingIcon={IconEditSVG}
              disabled={!subscriptionActive}
              onClick={() => setEditing(true)}
            />
            <Button
              label="Copy"
              buttonTitle="Copy Experience to Clipboard"
              buttonType="icon-only"
              size="small"
              leadingIcon={IconCopySVG}
              disabled={!subscriptionActive}
              onClick={() => addtoClipboard(workExperience.description)}
            />
          </div>
        </div>
      </div>
    );
  };

  const EditItem = () => {
    const GoalsHelperMessage = () => {
      return (
        <p>
          Associating your work experience with goals will help you align your day to day with your long term
          aspirations.
        </p>
      );
    };

    const filterProjects = (jobId: string): ISelectDropDown[] => {
      const filteredProjectItems = projectItems.filter(
        (projectItem: ISelectDropDown) =>
          projects.find((project: IProject) => projectItem.value === project.itemId)?.jobId === jobId,
      );
      return filteredProjectItems;
    };

    const [submittingForm, setSubmittingForm] = useState<boolean>(false);

    const [selectedJob, setSelectedJob] = useState<string>(workExperience.jobId ? workExperience.jobId : "");
    const [selectedProject, setSelectedProject] = useState<string>(
      workExperience.projectId ? workExperience.projectId : "",
    );

    const [projectsToPickFrom, setProjectsToPickFrom] = useState<ISelectDropDown[]>(
      selectedJob !== undefined ? filterProjects(selectedJob) : [],
    );

    const [creatingNewJob, setCreatingNewJob] = useState<boolean>(false);
    const [newJob, setNewJob] = useState<string>("");

    const [creatingNewProject, setCreatingNewProject] = useState<boolean>(false);
    const [newProject, setNewProject] = useState<string>("");

    const projectIsWithJob = (jobId: string, projectId: string): boolean => {
      if (projects.find((project: IProject) => projectId === project.itemId)?.jobId === jobId) {
        return true;
      } else {
        return false;
      }
    };

    useEffect(() => {
      if (selectedJob === "new") {
        setCreatingNewJob(true);
        setSelectedJob("");
        setSelectedProject("");
        setProjectsToPickFrom([]);
      } else {
        if (!projectIsWithJob(selectedJob, selectedProject)) {
          setSelectedProject("");
          setProjectsToPickFrom(filterProjects(selectedJob));
        }
      }
    }, [selectedJob]);

    useEffect(() => {
      if (selectedProject === "new") {
        setCreatingNewProject(true);
        setSelectedProject("");
      }
    }, [selectedProject]);

    const [selectedGoal, setSelectedGoal] = useState<string>(
      workExperience.goalId ? workExperience.goalId : "",
    );

    const wrapper = useRef<HTMLDivElement | null>(null);

    const triggerButton = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      if (newExperienceItem) {
        if (wrapper.current) {
          wrapper.current.classList.add("active");
        }
        if (triggerButton.current) {
          triggerButton.current.classList.add("active");
        }
      }
    }, []);

    return (
      <div className="experience-item__editor">
        <div className="experient-item-prompt-outer-wrapper">
          <div className="experience-item-prompt-wrapper" ref={wrapper}>
            <ExperiencePrompt />
          </div>
          <button
            className="experience-prompt-toggle plausible-event-name=Prompt+Engagement"
            onClick={() => {
              if (wrapper.current) {
                if (wrapper.current.classList.contains("active")) {
                  wrapper.current.classList.remove("active");
                } else {
                  wrapper.current.classList.add("active");
                }
              }
              if (triggerButton.current) {
                if (triggerButton.current.classList.contains("active")) {
                  triggerButton.current.classList.remove("active");
                } else {
                  triggerButton.current.classList.add("active");
                }
              }
            }}
            ref={triggerButton}
          >
            <div className="experience-prompt-toggle__icon-wrapper">
              <div className="experience-prompt-toggle__icon experience-prompt-toggle__icon--close">
                <IconClose />
              </div>
              <div className="experience-prompt-toggle__icon experience-prompt-toggle__icon--open">
                <IconWhistle />
              </div>
            </div>
            <span>Open</span>
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            setSubmittingForm(true);
            // Check if there’s a job to set and then set it in the Formik Values
            if (selectedJob !== "") {
              values.workExperience.jobId = selectedJob;
              // Check if there’s a project to set and then set it in the Formik Values
              if (selectedProject !== "") {
                values.workExperience.projectId = selectedProject;
                // Check if there’s a project to set and then create it and set it in the Formik Values
              } else if (newProject !== "") {
                values.workExperience.projectId = createProject(newProject, selectedJob);
              } else {
                values.workExperience.projectId = "";
              }
            } else if (newJob !== "") {
              values.workExperience.jobId = createJob(newJob);
              if (newProject !== "") {
                values.workExperience.projectId = createProject(newProject, values.workExperience.jobId);
              } else {
                values.workExperience.projectId = "";
              }
            } else {
              values.workExperience.jobId = "";
              values.workExperience.projectId = "";
            }
            // Check if there’s a goal to set and then set it in the Formik Values
            if (selectedGoal !== "") {
              values.workExperience.goalId = selectedGoal;
            } else {
              values.workExperience.goalId = "";
            }
            if (values.workExperience.description !== experienceDescriptionOriginal.current) {
              getEmbedding(values.workExperience.description).then((value) => {
                const vector: string = value;
                if (Array.isArray(JSON.parse(vector))) {
                  values.workExperience.vector = vector;
                } else {
                  values.workExperience.vector = "";
                }
                if (newExperienceItem) {
                  setWorkExperiences([...[values.workExperience], ...workExperiences]);
                } else {
                  const updatedWorkExperiences = [...workExperiences];
                  updatedWorkExperiences.splice(index, 1, values.workExperience);
                  setWorkExperiences(updatedWorkExperiences);
                }
                setSubmitting(false);
                setSubmittingForm(false);
                setEditing(false);
                setNewExperienceItem(false);
              });
            } else {
              if (newExperienceItem) {
                setWorkExperiences([...[values.workExperience], ...workExperiences]);
              } else {
                const updatedWorkExperiences = [...workExperiences];
                updatedWorkExperiences.splice(index, 1, values.workExperience);
                setWorkExperiences(updatedWorkExperiences);
              }
              setSubmitting(false);
              setSubmittingForm(false);
              setEditing(false);
              setNewExperienceItem(false);
            }
          }}
          validationSchema={workExperiencesValidationSchema}
        >
          <Form className="experient-item-editor-form">
            <Textarea
              label="Experience Description"
              name={`workExperience.description`}
              placeholder="Enter an experience or achievement. Keep it short, specific, and describe the outcome if possible."
              rows={2}
            />
            <div className="experience_item_field-row">
              {!creatingNewJob ? (
                <div className="field-wrapper pattern--field-wrapper">
                  <label className="pattern--input-container pattern--input-container--trailing-icon">
                    <span className="pattern--label type--heading-4">Job</span>
                    <select
                      className="pattern-select"
                      name="jobs"
                      value={selectedJob}
                      onChange={(e) => setSelectedJob(e.target.value)}
                    >
                      <option value="">Pick a job</option>
                      <option value="new">Create a job</option>
                      {jobItems.map((job: ISelectDropDown) => {
                        return (
                          <option key={job.value} value={job.value}>
                            {job.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              ) : (
                <div className="new-job-wrapper">
                  <div className="new-job-field field-wrapper pattern--field-wrapper">
                    <label className="pattern--input-container">
                      <span className="pattern--label type--heading-4">New Job Title</span>
                      <input
                        className="pattern--input-field"
                        type="text"
                        value={newJob}
                        placeholder="What’s your new new job title?"
                        onChange={(e) => setNewJob(e.target.value)}
                      />
                    </label>
                  </div>
                  <Button
                    label="Cancel New Job"
                    buttonType="icon-only-utility"
                    leadingIcon={IconCloseSVG}
                    onClick={() => {
                      setNewJob("");
                      setCreatingNewJob(false);
                    }}
                  />
                </div>
              )}
              {!creatingNewProject ? (
                <div className="field-wrapper pattern--field-wrapper">
                  <label className="pattern--input-container pattern--input-container--trailing-icon">
                    <span className="pattern--label type--heading-4">Project</span>
                    <select
                      name="projects"
                      className="pattern-select"
                      value={selectedProject}
                      disabled={selectedJob === "" && newJob === "" ? true : false}
                      onChange={(e) => setSelectedProject(e.target.value)}
                    >
                      <option value="">Pick a project</option>
                      <option value="new">Create new Project</option>
                      {projectsToPickFrom.map((project: ISelectDropDown) => {
                        return (
                          <option key={project.value} value={project.value}>
                            {project.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              ) : (
                <div className="new-project-wrapper">
                  <div className="new-project-field field-wrapper pattern--field-wrapper">
                    <label className="pattern--input-container">
                      <span className="pattern--label type--heading-4">New Project</span>
                      <input
                        className="pattern--input-field"
                        type="text"
                        value={newProject}
                        placeholder="What’s your new project called?"
                        onChange={(e) => setNewProject(e.target.value)}
                      />
                    </label>
                  </div>
                  <Button
                    label="Cancel New Project"
                    buttonType="icon-only-utility"
                    leadingIcon={IconCloseSVG}
                    onClick={() => {
                      setNewProject("");
                      setCreatingNewProject(false);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="experience_item_field-row">
              <div className="pattern--goal-plus-info">
                <div className="field-wrapper pattern--field-wrapper">
                  <label className="pattern--input-container pattern--input-container--trailing-icon">
                    <span className="pattern--label type--heading-4">Goal</span>
                    <select
                      className="pattern-select"
                      name="goals"
                      value={selectedGoal}
                      onChange={(e) => setSelectedGoal(e.target.value)}
                    >
                      <option value="">Pick a goal</option>
                      {goalItems.map((goal: ISelectDropDown) => {
                        return (
                          <option key={goal.value} value={goal.value}>
                            {goal.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <HelperPrompt
                  messageType="small"
                  promptType="unlabeled"
                  label="Why choose a goal?"
                  position="above"
                >
                  <GoalsHelperMessage />
                </HelperPrompt>
              </div>
              <DatePicker
                label="Date"
                key={`we-completionDate${index}`}
                name={`workExperience.completionDate`}
              />
            </div>
            <div className="pattern--button-row pattern--button-row--space-between">
              <Button
                label="Delete"
                buttonTitle="Delete Experience"
                onClick={() => setIsExperienceDeleteConfirmationOpened(true)}
                buttonType="utility"
                theme="destructive"
                disabled={newExperienceItem}
                leadingIcon={IconMinusSVG}
              />
              <div className="pattern--button-row">
                <Button
                  label="Cancel"
                  buttonTitle="Cancel Edits"
                  buttonType="utility"
                  onClick={() => {
                    setNewExperienceItem(false);
                    setEditing(false);
                  }}
                />
                <Button
                  buttonTitle="Save Edits"
                  buttonType="primary"
                  label="Save"
                  leadingIcon={submittingForm && IconDonutSpinnerSVG}
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  };

  return (
    <>
      {workExperience.description.length ? (
        <div
          className={`experience-log__item experience-item ${props.hidden && "experience-item--hidden"}`}
          data-item={index}
        >
          {editing ? <EditItem /> : <DisplayItem />}
        </div>
      ) : editing ? (
        <>
          <div
            className={`experience-log__item experience-item ${props.hidden && "experience-item--hidden"}`}
            data-item={index}
          >
            <EditItem />
          </div>
        </>
      ) : null}
      <Dialog
        isOpened={isExperienceDeleteConfirmationOpened}
        setIsOpened={setIsExperienceDeleteConfirmationOpened}
        primaryButtonType="primary"
        primaryButtonTitle="Confirm Experience Deletion"
        primaryButtonLabel="Delete"
        primaryButtonFunction={() => {
          searchDeletion !== undefined &&
            setSearchDeletion !== undefined &&
            setSearchDeletion(!searchDeletion);
          const updatedWorkExperiences: IWorkExperience[] = [...workExperiences];
          updatedWorkExperiences.splice(index, 1);
          setEditing(false);
          setIsExperienceDeleteConfirmationOpened(false);
          setWorkExperiences(updatedWorkExperiences);
        }}
        primaryButtonClosesDialog={true}
        secondaryButtonType="utility"
        secondaryButtonTitle="Cancel Experience Deletion"
        secondaryButtonLabel="Cancel"
      >
        <p className="type--body--large">Are you sure you want to delete this experience?</p>
      </Dialog>
    </>
  );
};
