import React from "react";
import "./menuItem.css";

export interface MenuItemProps {
	type?: "header" | "action";
	label?: string;
	title?: string;
	itemValue?: string;
	primaryAction?: () => void;
	primaryAnalyticsTag?: string;
	secondaryActions?: [{
		action?: () =>  void,
		label?: string,
		alt?: string,
		icon?: unknown,
		analyticsTag?: string
	}];
	secondaryAction1?: () => void;
	secondaryAction1Label?: string;
	secondaryAction1Alt?: string;
	secondaryAction1Icon?: unknown;
	secondaryAction2?: () => void;
	secondaryAction2Label?: string;
	secondaryAction2Alt?: string;
	secondaryAction2Icon?: unknown;
	nested?: boolean;
	theme?: "default" | "dark";
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
	return (
		<div
			className={`menu-item ${
				props.nested && "menu-item--nested"
			} ${props.type === "header" && "menu-item--header"} ${props.theme === "dark" && "menu-item--dark"}`}
		>
			{props.type === "header" ?
				<h6 className="type--heading-6">{props.label}</h6>
			:
				<>
					<button
						className={`menu-item-primary-action type--body--standard ${props.primaryAnalyticsTag && props.primaryAnalyticsTag}`}
						title={props.title}
						onClick={props.primaryAction}
					>
						{props.label}
					</button>
					{props.secondaryActions !== undefined && props.secondaryActions.length && props.secondaryActions.map((item, index: number) => {
						<button
							className={`menu-item-secondary-action ${item.analyticsTag && item.analyticsTag}`}
							title={item.alt}
							onClick={item.action}
							key={index}
						>
							{item.icon}
							<span className="menu-item-secondary-action__label">
								{item.label}
							</span>
						</button>
					})}
				</>
		}
		</div>
	);
};
