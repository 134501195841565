import React, { useEffect, useState } from "react";
import { Button } from "components/core";
import { ReactComponent as IconInfo } from "../assets/img/info.svg";
import { ReactComponent as IconClose } from "../assets/img/close.svg";
import "./helperPrompt.css";

export interface HelperPromptProps {
	label: string;
	positioningClass?: string;
	promptType?: "labeled" | "unlabeled";
	messageType?: "large" | "small";
	position?: "above" | "below" | "left" | "right";
	children: unknown;
}

export const HelperPrompt: React.FC<HelperPromptProps> = ({
	label,
	positioningClass,
	promptType = "labeled",
	messageType = "large",
	position = "above",
	children,
}) => {
	const [isOpen, setOpen] = useState(false);
	const [isDismissing, setDismissing] = useState(false);

	const openToggle = () => {
		setOpen(true);
	};

	const dismissHelper = () => {
		setDismissing(true);
	};

	useEffect(() => {
		setTimeout(() => {
			if (isDismissing === true) {
				setOpen(false);
				setDismissing(false);
			}
		}, 500);
	}, [isDismissing]);

	useEffect(() => {
		const dismissPopover = () => {
			if (messageType == "small") {
				setOpen(false);
			} else {
				return () => window.removeEventListener("mousedown", dismissPopover);
			}
		};

		window.addEventListener("mousedown", dismissPopover);
		return () => window.removeEventListener("mousedown", dismissPopover);
	}, []);

	const IconInfoSVG = <IconInfo />;
	const IconCloseSVG = <IconClose />;

	return (
		<>
			<div
				className={`helper-prompt-wrapper ${
					positioningClass && positioningClass
				}`}
			>
				<button
					className={`helper-prompt helper-prompt${
						promptType === "labeled" ? "--labeled" : "--unlabeled"
					}`}
					title={label}
					onClick={() => openToggle()}
					type="button"
				>
					<span className="helper-prompt-label">{label}</span>
					{IconInfoSVG}
				</button>
				{isOpen && (
					<div
						className={`helper-frame${
							messageType === "large" ? "--large" : "--small"
						} ${isDismissing && "helper-frame--dismissing"} helper-frame--${position && position}`}
					>
						{messageType === "large" ? (
							<div className="helper-content-wrapper">
								<div className="helper-content relative-container">
									<Button
										buttonType="icon-only"
										label="Close"
										buttonTitle="Close Helper"
										size="medium"
										type="button"
										onClick={dismissHelper}
										leadingIcon={IconCloseSVG}
									/>
									{children}
								</div>
							</div>
						) : (
							<div>{children}</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};
