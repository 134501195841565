import React, { useState, useRef, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Button, HelperPrompt, GoalItem, SearchPrimary } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { IGoal } from "types";
import { ReactComponent as IconSearch } from "../core/assets/img/search.svg";
import "./goals.css";

const Goals: React.FC = () => {
	const { 
		user, 
		userDataLoaded,
		EmptyGoal,
		goals,
		subscriptionActive,
	} = DataContainer.useContainer();
	
	const [newGoal, setNewGoal] = useState<boolean>(false);
		
	const [searching, setSearching] = useState<boolean>(false);
		
	const [searchTerm, setSearchTerm] = useState<string>("");
		
	const [searchResults, setSearchResults] = useState<IGoal[]>([]);
	
	const [searchDeletion, setSearchDeletion] = useState<boolean>(false);
	
	const onChangeSearch = (value: string) => {
		setSearchTerm(value);
	};
	
	useEffect(() => {
		if (searchTerm !== "") {
			setSearchResults(goals.filter((goal: IGoal) => {
				const searchString = goal.goal.toLowerCase() + " " + goal.details.toLowerCase();
				return searchString.includes(searchTerm.toLowerCase());
			}));
		} else {
			setSearchResults([]);
		}
	}, [searchTerm]);
	
	const clearSearch = () => {
		setSearchTerm("");
	};
	
	useEffect(() => {		
		if (searching && searchTerm !== "") {
			setSearchResults(goals.filter((goal: IGoal) => {
				const searchString = goal.goal.toLowerCase() + " " + goal.details.toLowerCase();
				return searchString.includes(searchTerm.toLowerCase());
			}));
		}
	}, [searchDeletion]);
	
	const location = useLocation();
	
	const { pathname } = useLocation();
	const isNewPath = matchPath("/goals/new", pathname);
	
	// useEffect & useRef ensures that createNewExperience happens once if the URL is experience/new as components re-render
	
	const goalsLoaded = useRef<boolean>(false);
	
	useEffect(() => {
		if (isNewPath && userDataLoaded && !goalsLoaded.current) {
			createNewGoal();
			goalsLoaded.current = true;
		}
	}, [goals]);
	
	useEffect(()=> {
		if (location.hash) {
			const elem = document.getElementById(location.hash.slice(1))
			if(elem) {
				elem.scrollIntoView({behavior: "smooth"})
			}          
		} else {
			window.scrollTo({top:0,left:0, behavior: "smooth"})
		}
	}, [location]);
	
	const createNewGoal = () => {
		setSearchTerm("");
		setSearchResults([]);
		setSearching(false);
		setNewGoal(true);
	};
	
	const IconSearchSVG = <IconSearch />;
	
	const WhyGoalsMessage = () => {
		return (
			<>
				<h2 className="type--heading-1">Why Goals?</h2>
				<p className="type--body--large">Goals are meant to help you visualize what you want to become, achieve, or acquire in the future.</p>
				<p className="type--body--large">For goals to be successful, they need to be supported by systems of behavior. This is why, within Career Minder, you can associate your experience with goals in order to see whether or not you are actually working towards them on a regular basis.</p>
				<hr/>
				<p className="type--body--large">If little of your experience is actually connected to the goals you have set, then it may be time to revisit them.</p>
				<ul className="type--unordered-list">
					<li className="type--body--large">If you have this disconnect, but are happy with the state of your career, then it may be time to make new goals that more closely align with what you actually want.</li>
					<li className="type--body--large">If your goal is still something you want to shape your work around, but your experience is disconnected then you may need to start thinking about how to better align your career with your goals.</li>
				</ul>
				<hr/>
				<p className="type--body--large">There are lots of different philosophies about how to set goals, how often you should revisit them, how specific they should be, etc. If you have not had success in setting and achieving goals in the past, there are many resources online to help, including frameworks, workshops, and even journals.</p>
				<blockquote className="type--blockquote">
					<p className="type--body--large">“I am so thoroughly convinced that if we don’t set goals in our life and learn how to master the techniques of living to reach our goals, we can reach a ripe old age and look back on our life only to see that we reached but a small part of our full potential. When one learns to master the principles of setting a goal, he will then be able to make a great difference in the results he attains in this life.”</p>
					<p className="type--body--large">Melvin Russell Ballard</p>
				</blockquote>
			</>
		);
	};

	return (
		<div className="flex-page-outer-wrapper">
			<section className="flex-page-wrapper goals-page-wrapper">
				<div className="header--withaction goals-masthead">
					<div className="header--withaction__title-grouping">
						<h1 className="type--heading-1">
							Goals
						</h1>
						<HelperPrompt
							label="Why Goals?"
						>
							<WhyGoalsMessage />
						</HelperPrompt>
					</div>
					<div className="header--withaction__action-grouping">
						<Button
							buttonType="icon-only"
							label="Search Experience"
							buttonTitle="Start a search of your experience"
							disabled={searching ? true : false}
							onClick={() => setSearching(true)}
							leadingIcon={IconSearchSVG}
							type="button"
						/>
						<Button
							buttonType="primary"
							key="`addGoal"
							label="Add Goal"
							disabled={!subscriptionActive || !userDataLoaded ? true : false}
							onClick={() => createNewGoal()}
							type="button"
						/>
					</div>
				</div>
				<div className="main-content-card pattern--container--flow__body goals-container">
					{searching ? (
						<>
							<SearchPrimary
								clearSearch={clearSearch}
								closeSearch={() => setSearching(false)}
								fieldOnChange={onChangeSearch}
								searchResultsLength={searchResults.length}
								searchValue={searchTerm}
								shouldAutoFocus={true}
							/>
							{searchResults.length > 0 &&
								<>
									{goals.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map(
										(goal: IGoal, index: number) => {
											return (
												<GoalItem
													index={index}
													key={uuid()}
													newGoal={newGoal}
													setNewGoal={setNewGoal}
													goal={goal}
													hidden={searchResults.findIndex(i => i.itemId === goal.itemId) !== -1 ? false : true}
													searchDeletion={searchDeletion}
													setSearchDeletion={setSearchDeletion}
												/>
											);
										}
									)}
								</>
							}
						</>
					) : (
						<>
						{newGoal &&
							<GoalItem
								index={-1}
								key={uuid()}
								newGoal={newGoal}
								setNewGoal={setNewGoal}
								goal={new EmptyGoal(user)}
							/>
						}
						{goals.length ? (
							<>
							{goals.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map(
								(goal: IGoal, index: number) => {
									return (
										<GoalItem
											index={index}
											key={uuid()}
											newGoal={newGoal}
											setNewGoal={setNewGoal}
											goal={goal}
										/>
									);
								}
							)}
							</>
						) : (
							<div className="experience-log-empty-state pattern--empty-state--fill-area">
								<h3 className="type--heading-3">No Goals</h3>
								<p className="type--body--large">
									Start adding some goals with the button above.
								</p>
							</div>
						)}
						</>
					)
					}
				</div>
			</section>
		</div>
	);
};

export default Goals;
