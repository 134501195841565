import React, { useState, useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import { ReactComponent as IconCheck } from "../assets/img/check.svg";
import { ReactComponent as IconDonutSpinner } from "../assets/img/donut-spinner.svg";
import { debounce } from "lodash";
import "./autoSave.css";

interface AutoSaveProps {
	debounceMs: number;
}

export const AutoSave: React.FC<AutoSaveProps> = ({ debounceMs }) => {
	const formik = useFormikContext();
	const [lastSaved, setLastSaved] = useState<string>("");
	const [saving, setSaving] = useState<number>(0);
	const debouncedSubmit = useCallback(
		debounce(
			() =>
				formik.submitForm().then(() => {
					setLastSaved(new Date().toISOString());
					setSaving(1);
				}),
			debounceMs
		),
		[debounceMs, formik.submitForm]
	);

	useEffect(() => {
		debouncedSubmit();
	}, [debouncedSubmit, formik.values]);
	
	useEffect(() => {
		if (saving === 1) {
			setTimeout(() => {
				setSaving(0);
			}, 2000);
		}
	}, [saving]);
	
	const IconDonutSpinnerSVG = <IconDonutSpinner />;
	const IconCheckSVG = <IconCheck />;

	return (
		<div className={`autosave ${lastSaved !== null && "autosave--saved"}`}>
			<span className="type--body--small autosave-label">{saving === 0 ? "Saved" : saving === 1 ? "Saving" : "Error"}</span>
			{saving === 1 ? 
				<>
				{IconDonutSpinnerSVG}
				</> 
				: 
				<>
				{IconCheckSVG}
				</>
			}
		</div>
	);
};
