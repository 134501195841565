import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import WebFont from "webfontloader";
import UnstatedNextProviders from "containers/UnstatedNextProviders";
import AppRoutes from "./Routes";

export const App: React.FC = () => {
	useEffect(() => {
		WebFont.load({
			google: {
				families: [
					"Fira Sans:200,300,400,500,600,800",
					"Fira Sans Extra Condensed:800",
					"IBM Plex Mono:400,700"
				],
			},
			typekit: {
				id: 'ifx1yxx',
			},
		});
	}, []);

	return (
		<BrowserRouter>
			<UnstatedNextProviders>
				<AppRoutes />
			</UnstatedNextProviders>
		</BrowserRouter>
	);
};

export default App;
