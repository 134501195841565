import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Button, Input, SequenceStepper } from "components/core";
import { DataContainer } from "containers";
import { profileValidationSchema } from "utils/validations";
import "./personalDetails.css";

interface Values {
  fullName: string;
  jobTitle: string;
  preferredName: string;
}

const PersonalDetails: React.FC = () => {
  const {
    fullName,
    jobTitle,
    preferredName,
    submitProfile,
    setFullName,
    setJobTitle,
    setPreferredName,
    setSubmitProfile,
    userDataLoaded,
  } = DataContainer.useContainer();
  const navigate = useNavigate();

  const initialValues = {
    fullName,
    jobTitle,
    preferredName,
  };

  const profileIsUpdated = (): boolean => {
    if (submitProfile === true) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="body-wrapper">
      <section className="pattern--container--flow pattern--container--flow-narrow">
        <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
          <h1 className="type--heading-1">Profile</h1>
          <div className="accessory--resizeable">
            <SequenceStepper length={6} currentIndex={1} />
          </div>
        </div>
        <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values: Values): Promise<void> => {
              setFullName(values.fullName);
              setJobTitle(values.jobTitle);
              setPreferredName(values.preferredName);
              setSubmitProfile(true);
              while (!profileIsUpdated()) {
                await new Promise((resolve) => setTimeout(resolve, 500)); // wait for half second before checking again
              }
              navigate("/payment", { replace: true });
            }}
            validationSchema={profileValidationSchema}
          >
            <Form className="pattern--login-signup-form">
              <Input label="Full Name" name="fullName" placeholder="What's your full name?" />
              <Input
                label="Preferred Name"
                name="preferredName"
                placeholder="What would you like us to call you?"
              />
              <Input
                label="Job Title"
                name="jobTitle"
                placeholder="How do you describe what you do?"
                value={jobTitle}
              />
              <Button
                buttonType="secondary"
                disabled={!userDataLoaded ? true : false}
                label="Next"
                type="submit"
              />
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default PersonalDetails;
