import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, SearchPrimary, StatusPill } from "components/core";
import { ReactComponent as IconSearch } from "../core/assets/img/search.svg";
import { DataContainer } from "containers/DataContainer";
import { ICatchUp } from "types";
import { CatchUpItem } from "./CatchUpItem";
import "./catchUps.css";

/**
 * Primary UI component for user interaction
 */
export const CatchUps: React.FC = () => {
	const {
		catchups,
		subscriptionActive,
		userDataLoaded,
		reminderEnabled
	} = DataContainer.useContainer();
	
	const navigate = useNavigate();
	
	const [searching, setSearching] = useState<boolean>(false);
		
	const [searchTerm, setSearchTerm] = useState<string>("");
		
	const [searchResults, setSearchResults] = useState<ICatchUp[]>([]);
	
	const onChangeSearch = (value: string) => {
		setSearchTerm(value);
	};
	
	useEffect(() => {
		if (searchTerm !== "") {
			setSearchResults(catchups.filter((catchup: ICatchUp) => {
				const searchString = catchup.description.toLowerCase() + " " + catchup.summary.toLowerCase() + " " + catchup.name.toLowerCase();
				return searchString.includes(searchTerm.toLowerCase());
			}));
		} else {
			setSearchResults([]);
		}
	}, [searchTerm]);
	
	const clearSearch = () => {
		setSearchTerm("");
	};
	
	const IconSearchSVG = <IconSearch />;
	
	return (
		<div className="catchups-outer-wrapper">
			<section className="catchups-container">
				<div className="header--withaction catchups-masthead">
					<div className="header--withaction__title-grouping">
						<h1 className="type--heading-1">Catch Ups</h1>
						<Link
							to="/profile/reminders"
							title="See Reminders settings"
							className="statusPill-wrapper"
						>
							<StatusPill
								label="Reminders"
								size="small"
								status={reminderEnabled ? "affirmative" : "indeterminate"}
								theme="secondary"
							/>
						</Link>
					</div>
					<div className="header--withaction__action-grouping">
						<Button
							buttonType="icon-only"
							label="Search Catch Ups"
							buttonTitle="Start a search of your Catch Ups"
							disabled={searching ? true : false}
							onClick={() => setSearching(true)}
							leadingIcon={IconSearchSVG}
							type="button"
						/>
						<Button
							label="Create Catch Up"
							buttonTitle="Create a new Catch Up"
							disabled={!subscriptionActive || !userDataLoaded ? true : false}
							buttonType="primary"
							onClick={() => navigate("new")}
						/>
					</div>
				</div>
				<div className={`main-content-card catchups-log ${!catchups.length && "catchups-log--empty"}`}>
					{searching ? (
						<>
							<SearchPrimary
								clearSearch={clearSearch}
								closeSearch={() => setSearching(false)}
								fieldOnChange={onChangeSearch}
								searchResultsLength={searchResults.length}
								searchValue={searchTerm}
								shouldAutoFocus={true}
							/>
							{searchResults.length > 0 &&
								<>
									{catchups.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map((catchup: ICatchUp, index: number) => {
										return (
											<CatchUpItem
												index={index}
												catchUp={catchup}
												hidden={searchResults.findIndex(i => i.itemId === catchup.itemId) !== -1 ? false : true}
												key={catchup.itemId}
											/>
										);
										// eslint-disable-next-line no-mixed-spaces-and-tabs
									})}
								</>
							}
						</>
					) : (
						<>
							{catchups.length
								? catchups.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map((catchup: ICatchUp, index: number) => {
										return (
											<CatchUpItem
												index={index}
												catchUp={catchup}
												key={catchup.itemId}
											/>
										);
										// eslint-disable-next-line no-mixed-spaces-and-tabs
							  	})
								: null
							}
						</>
					)}
					{!catchups.length && (
						<div className="bragsheets-empty-state pattern--empty-state--fill-area">
							<h3 className="type--heading-3">No Catch Ups</h3>
							<p className="type--body--large">
								Start adding some Catch Ups with the button above.
							</p>
						</div>
					)}
				</div>
			</section>
		</div>
	);
};