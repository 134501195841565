import React from "react";
import { ReactComponent as IconAffirmativeSmall } from "../assets/img/statusAffirmativeSmall.svg";
import { ReactComponent as IconIndeterminateSmall } from "../assets/img/statusIndeterminateSmall.svg";
import { ReactComponent as IconNegativeSmall } from "../assets/img/statusNegativeSmall.svg";
import { ReactComponent as IconNeutralSmall } from "../assets/img/statusNeutralSmall.svg";
import { ReactComponent as IconAffirmativeStandard } from "../assets/img/statusAffirmativeStandard.svg";
import { ReactComponent as IconIndeterminateStandard } from "../assets/img/statusIndeterminateStandard.svg";
import { ReactComponent as IconNegativeStandard } from "../assets/img/statusNegativeStandard.svg";
import { ReactComponent as IconNeutralStandard } from "../assets/img/statusNeutralStandard.svg";
import "./statusPill.css";

export interface StatusPillProps {
	label: string;
	status: "affirmative" | "indeterminate" | "negative" | "neutral";
	size?: "standard" | "small";
	theme?: "standard" | "secondary";
}

export const StatusPill: React.FC<StatusPillProps> = ({
	label,
	status = "indeterminate",
	size = "standard",
	theme = "standard",
}) => {
	return (
		<div className={`status-wrapper status-wrapper--${size} status-wrapper--theme-${theme}`}>
			{status === "indeterminate" 
				? size === "standard" 
					? <IconIndeterminateStandard />
					: <IconIndeterminateSmall />
				: status === "affirmative"
					? size === "standard"
						? <IconAffirmativeStandard />
						: <IconAffirmativeSmall />
					: status === "negative"
						? size === "standard"
							? <IconNegativeStandard />
							: <IconNegativeSmall />
						: size === "standard"
							? <IconNeutralStandard />
							: <IconNeutralSmall />
			}
			<p className="type--body--standard">{label}</p>
		</div>
	);
};