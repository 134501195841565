import React, { ForwardedRef } from "react";
import { ErrorMessage, Field } from "formik";

export interface InputProps {
	autoFocus?: boolean;
	helperPrompt?: string;
	label?: string;
	leadingIcon?: unknown;
	name: string;
	placeholder?: string;
	presentation?: "default" | "featured" | "empty-headline";
	trailingIcon?: unknown;
	type?: string;
	value?: string;
	readonly?: boolean;
	inputRef?: ForwardedRef<HTMLInputElement>;
	onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<InputProps> = (props) => {
	const {
		autoFocus = false,
		label,
		leadingIcon,
		name,
		placeholder,
		presentation = "default",
		trailingIcon,
		type,
		helperPrompt,
		inputRef,
		readonly = false,
	} = props;

	return (
		<div className="field-wrapper pattern--field-wrapper">
			<label
				className={`pattern--input-container ${
					leadingIcon ? "pattern--input-container--leading-icon" : ""
				} ${trailingIcon ? "pattern--input-container--trailing-icon" : ""}`}
			>
				{label && (
					<span className="pattern--label type--heading-4">
						{label}
						{helperPrompt && helperPrompt}
					</span>
				)}
				{readonly ? (
					<input
						className={
							presentation === "default"
								? "pattern--input-field pattern--input-field--readonly"
								: presentation === "empty-headline"
								? "pattern-empty-headline"
								: "featured-field pattern--featured-field"
						}
						placeholder={placeholder ? placeholder : ""}
						value={props.value}
					/>
				) : (
					<Field
						name={name}
						autoFocus={autoFocus}
						className={
							presentation === "default"
								? "pattern--input-field"
								: presentation === "empty-headline"
								? "pattern-empty-headline"
								: "featured-field pattern--featured-field"
						}
						placeholder={placeholder ? placeholder : ""}
						ref={inputRef}
						type={type}
						onKeyPress={props.onKeyPress && props.onKeyPress}
					/>
				)}

				{leadingIcon && leadingIcon}
				{trailingIcon && trailingIcon}
				<ErrorMessage
					component="div"
					className="pattern--field-error-message"
					name={name as string}
				/>
			</label>
		</div>
	);
};
