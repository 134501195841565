import React from "react";
import "./checkbox.css";

export interface CheckboxProps {
	label: string;
	value: string;
	checked?: boolean;
	onChange: (value: any) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
	
	return (
		<label className="checkbox-wrapper">
			<input 
				type="checkbox" 
				name={props.value} 
				value={props.value} 
				checked={props.checked} 
				onChange={props.onChange} 
			/>
			<span className="checkbox-label type--body--large">{props.label}</span>
		</label>	
	);
};