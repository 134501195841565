import React from "react";
import { Button } from "components/core";
import "./toast.css";

export interface ToastProps {
	isOpen: boolean;
	message: string;
	lightTheme?: boolean;
	icon?: unknown;
	iconPlacement?: "leading" | "trailing";
	buttonAction?: () => void;
	buttonLabel?: string;
}

export const Toast: React.FC<ToastProps> = (props) => {

	return (
		<div className={`toast ${props.isOpen && "toast--open"} ${props.lightTheme && "toast--light"} ${props.icon !== undefined || props.buttonLabel ? "toast--container" : ""} ${props.icon !== undefined ? "toast--contains-button" : ""} ${props.iconPlacement === "trailing" && "toast--icon-trailing"}`}>
			{props.icon && props.icon}
			<p className="type--body--standard">{props.message}</p>
			{props.buttonAction && props.buttonLabel && 
				<Button
					buttonType="utility"
					label={props.buttonLabel}
					size="small"
					buttonTitle={props.buttonLabel}
					onClick={props.buttonAction}
				/>
			}
		</div>
	);
};
