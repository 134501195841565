import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SearchPrimary } from "components/core";
import { ReactComponent as IconSearch } from "../core/assets/img/search.svg";
import { DataContainer } from "containers/DataContainer";
import { IBragSheet } from "types";
import { BragSheetItem } from "./BragSheetItem";
import "./bragsheets.css";

interface BragSheetsProps {
	email?: string;
	jobDescription?: string;
	name?: string;
	bragSheetIndex?: number;
}

const BragSheets: React.FC<BragSheetsProps> = () => {
	const {
		bragSheets,
		subscriptionActive,
		userDataLoaded,
	} = DataContainer.useContainer();

	const navigate = useNavigate();
	
	const [searching, setSearching] = useState<boolean>(false);
		
	const [searchTerm, setSearchTerm] = useState<string>("");
		
	const [searchResults, setSearchResults] = useState<IBragSheet[]>([]);
	
	const onChangeSearch = (value: string) => {
		setSearchTerm(value);
	};
	
	useEffect(() => {
		if (searchTerm !== "") {
			setSearchResults(bragSheets.filter((bragSheet: IBragSheet) => {
				const searchString = bragSheet.content.toLowerCase() + " " + bragSheet.bragSheetName.toLowerCase();
				return searchString.includes(searchTerm.toLowerCase());
			}));
		} else {
			setSearchResults([]);
		}
	}, [searchTerm]);
	
	const clearSearch = () => {
		setSearchTerm("");
	};
	
	const IconSearchSVG = <IconSearch />;

	return (
		<div className="bragsheets-outer-wrapper">
			<section className="bragsheets-container">
				<div className="header--withaction bragsheets-masthead">
					<h1 className="type--heading-1">Brag Sheets</h1>
					<div className="header--withaction__action-grouping">
						<Button
							buttonType="icon-only"
							label="Search Brag Sheets"
							buttonTitle="Start a search of your brag sheets"
							disabled={searching ? true : false}
							onClick={() => setSearching(true)}
							leadingIcon={IconSearchSVG}
							type="button"
						/>
						<Button
							label="Write Brag Sheet"
							buttonTitle="Write a new Brag Sheet"
							disabled={!subscriptionActive || !userDataLoaded ? true : false}
							buttonType="primary"
							onClick={() => navigate("new")}
						/>
					</div>
				</div>
				<div className={`main-content-card bragsheets-log ${!bragSheets.length && "bragsheets-log--empty"}`}>
					{searching ? (
						<>
							<SearchPrimary
								clearSearch={clearSearch}
								closeSearch={() => setSearching(false)}
								fieldOnChange={onChangeSearch}
								searchResultsLength={searchResults.length}
								searchValue={searchTerm}
								shouldAutoFocus={true}
							/>
							{searchResults.length > 0 &&
								<>
									{bragSheets.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map((bragSheet: IBragSheet, index: number) => {
										return (
											<BragSheetItem
												index={index}
												bragSheet={bragSheet}
												hidden={searchResults.findIndex(i => i.itemId === bragSheet.itemId) !== -1 ? false : true}
												key={bragSheet.itemId}
											/>
										);
										// eslint-disable-next-line no-mixed-spaces-and-tabs
									})}
								</>
							}
						</>
					) : (
						<>
							{bragSheets.length
								? bragSheets.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map((bragSheet: IBragSheet, index: number) => {
										return (
											<BragSheetItem
												index={index}
												bragSheet={bragSheet}
												key={bragSheet.itemId}
											/>
										);
										// eslint-disable-next-line no-mixed-spaces-and-tabs
					  			})
								: null
							}
						</>
					)}
					{!bragSheets.length && (
						<div className="bragsheets-empty-state pattern--empty-state--fill-area">
							<h3 className="type--heading-3">No Brag Sheets</h3>
							<p className="type--body--large">
								Start adding some Brag Sheets with the button above.
							</p>
						</div>
					)}
				</div>
			</section>
		</div>
	);
};

export default BragSheets;
