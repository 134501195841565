import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SequenceStepper, GoalItem } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { IGoal } from "types";
import { v4 as uuid } from "uuid";
import "./onboardingGoals.css";

export const OnboardingGoals: React.FC = () => {
    const { EmptyGoal, goals, user } = DataContainer.useContainer();
    const navigate = useNavigate();

    const [newGoal, setNewGoal] = useState<boolean>(false);
    const [creatingGoal, setCreatingGoal] = useState<boolean>(goals.length > 0 ? false : true);

    return (
        <section className="pattern--container--flow pattern--container--flow-narrow">
            <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
                <h1 className="type--heading-1">Goals</h1>
                <div className="accessory--resizeable">
                    <SequenceStepper length={6} currentIndex={3} />
                </div>
            </div>
            <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
                <p className="type--body--large">
                    Add your career goals. Make sure they are concrete and something you can take a step
                    towards today, even if it&rsquo;s a small step. As you use Career Minder your work
                    experience will be associated with your goals.
                </p>
                <div className="resume-category-wrapper">
                    <button
                        className="pattern--area-fill-button type--body--large"
                        disabled={creatingGoal}
                        onClick={() => {
                            setCreatingGoal(true);
                        }}
                    >
                        Add {goals.length > 1 ? "another" : "a"} Goal
                    </button>
                    {creatingGoal && (
                        <GoalItem
                            index={-1}
                            key={uuid()}
                            newGoal={creatingGoal}
                            setNewGoal={setCreatingGoal}
                            goal={new EmptyGoal(user)}
                            relatedExperienceVisible={false}
                        />
                    )}
                    {goals.map((goal: IGoal, index: number) => {
                        return (
                            <GoalItem
                                index={index}
                                key={uuid()}
                                newGoal={newGoal}
                                setNewGoal={setNewGoal}
                                goal={goal}
                                relatedExperienceVisible={false}
                            />
                        );
                    })}
                </div>
                <div className="pattern--button-row">
                    <Button
                        label="Back"
                        buttonTitle="Back to Resume Upload"
                        buttonType="utility"
                        onClick={() => navigate("/payment/success")}
                    />
                    <Button
                        label="Next"
                        buttonTitle="Continue to Resume"
                        buttonType="secondary"
                        onClick={() => navigate("/onboarding/resume")}
                    />
                </div>
            </div>
        </section>
    );
};
