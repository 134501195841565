import React from "react";
import "./menu.css";

export interface MenuProps {
	children: unknown;
	isOpened: boolean;
	position?: "full" | "left" | "right";
	theme?: "default" | "dark";
}

export const Menu: React.FC<MenuProps> = ({
	children,
	isOpened,
	theme = "default",
	position = "left",
}) => {
	return (
		<div
			className={`menu menu--${position} ${
				isOpened ? "menu--open" : "menu--closed"
			} ${theme === "dark" && "menu--dark"}`}
		>
			{children}
		</div>
	);
};
