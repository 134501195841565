import * as Yup from "yup";

export const bragSheetValidationSchema = Yup.object().shape({
    bragSheetName: Yup.string().required("A name for the Brag Sheet is required."),
    bragSheetEditorState: Yup.string().required("You need to enter something to brag about."),
});

export const certificationSummaryValidationSchema = Yup.object().shape({
    certification: Yup.object({
        certificationName: Yup.string().required("Please enter a name for this certification."),
    }),
})

export const confirmationCodeValidationSchema = Yup.object().shape({
    confirmationNumber: Yup.string().required("Please enter the confirmation number. Numbers only please.")
                            .matches(/([0-9])*\d/, "Numbers only please."),
})

// needs to be out of alphabetical order to support the validations that follow.
const passwordYup = Yup.string()
                    .required("Enter a password for this account.")
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "The password must be at least 8 characters long with at least one number, one capital letter and one special character !, @, #, $, %, ^, &, or *.");

const confirmPasswordYup = Yup.string()
                            .required("Please enter a confirmation password for this account. Passwords must match.")
                            .oneOf([Yup.ref('password'), null], 'Passwords must match.')

export const confirmPasswordResetValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter the email address associated with this account."),
    confirmationCode: Yup.string()
        .required("A confirmation code is required."),
});

export const educationSummaryValidationSchema = Yup.object().shape({
    education: Yup.object({
        degree: Yup.string().required("Please enter an education degree."),
    }),
})

export const goalsValidationSchema = Yup.object().shape({
    goals: Yup.array(
        Yup.object({
            goal: Yup.string().required("Please enter a name for the goal."),
        })
    ),
});

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter the email address associated with this account."),
    password: Yup.string().required("Enter a password for this account."),
});

// TODO: The next two validations are duplicates. Can we consolidate?

export const profileValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Your full name is required."),
    preferredName: Yup.string().required("Please enter the name by which you would like to be called."),
    jobTitle: Yup.string(),
});

export const whoAreYouValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter your full name."),
    preferredName: Yup.string().required("Please enter the name by which you would like to be called."),
    jobTitle: Yup.string(),
});

export const resetValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter the email address associated with this account.")
});

export const changePasswordValidationSchema = Yup.object().shape({
    oldPassword: passwordYup,
    password: passwordYup,
    confirmNewPassword: confirmPasswordYup,
});

export const signUpValidationSchema = Yup.object().shape({
    emailAddress: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter the email address associated with this account."),
    password: passwordYup,
    confirmPassword: confirmPasswordYup,
});

export const changeEmailValidationSchema = Yup.object().shape({
    emailAddress: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter the email address associated with this account."),
});

export const workExperiencesValidationSchema = Yup.object().shape({
    workExperiences: Yup.array(
        Yup.object({
            description: Yup.string().required("Please enter a description."),
        })
    ),
});

export const workSummaryValidationSchema = Yup.object().shape({
    job: Yup.object({
        jobTitle: Yup.string().required("Please enter a job title."),
    }),
});

export const catchUpValidationSchema = Yup.object().shape({
    description: Yup.string().required("A description for the Catch Up is required."),
});

export const reviewsValidationSchema = Yup.object().shape({
    reviewName: Yup.string().required("A name for the Review is required."),
});
