import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SequenceStepper } from "components/core";
import { Job } from "components/Dashboard/Job";
import { Education } from "components/Dashboard/Education";
import { Certification } from "components/Dashboard/Certification";
import { DataContainer } from "containers/DataContainer";
import { ICertification, IEducation, IJob } from "types";
import { v4 as uuid } from "uuid";
import "./resumeReview.css";

export const ResumeReview: React.FC = () => {
  const {
    EmptyCertification,
    certifications,
    setCertifications,
    EmptyEducation,
    educations,
    setEducations,
    EmptyJob,
    jobs,
    setJobs,
    user,
    inactivateItem,
  } = DataContainer.useContainer();
  const navigate = useNavigate();

  const [newJob, setNewJob] = useState<number>(-1);
  const [newEducation, setNewEducation] = useState<number>(-1);
  const [newCertification, setNewCertification] = useState<number>(-1);

  return (
    <section className="pattern--container--flow pattern--container--flow-narrow">
      <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
        <h1 className="type--heading-1">Resume</h1>
        <div className="accessory--resizeable">
          <SequenceStepper length={6} currentIndex={4} />
        </div>
      </div>
      <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
        <p className="type--body--large">
          Here is what we’ve found in your resume. Make any corrections before moving on to your first Catch
          Up.
        </p>
        <div className="resume-category-wrapper">
          <h2 className="type--heading-3">Jobs</h2>
          <button
            className="pattern--area-fill-button type--body--large"
            onClick={() => {
              setNewJob(0);
              const updatedJobs = [...[new EmptyJob(user)], ...jobs];
              setJobs(updatedJobs);
            }}
          >
            Add {jobs.length > 1 ? "another" : "a"} Job
          </button>
          {jobs
            .sort((a: IJob, b: IJob) => a.sortOrder - b.sortOrder)
            .map((job: IJob, index: number) => {
              return (
                <Job
                  inactivateItem={inactivateItem}
                  index={index}
                  key={uuid()}
                  newJob={newJob}
                  setNewJob={setNewJob}
                  job={job}
                  onDashboard={false}
                />
              );
            })}
        </div>
        <div className="resume-category-wrapper">
          <h2 className="type--heading-3">Education</h2>
          <button
            className="pattern--area-fill-button type--body--large"
            onClick={() => {
              setNewEducation(0);
              const updatedEducation = [...educations, ...[new EmptyEducation(user)]];
              setEducations(updatedEducation);
            }}
          >
            Add {educations.length > 1 ? "another" : "a"} Education
          </button>
          {educations
            .sort((a: IEducation, b: IEducation) => {
              // Treat undefined sortOrder as a large number to sort it to the end
              const sortOrderA = a.sortOrder !== undefined ? a.sortOrder : Number.MAX_SAFE_INTEGER;
              const sortOrderB = b.sortOrder !== undefined ? b.sortOrder : Number.MAX_SAFE_INTEGER;

              return sortOrderA - sortOrderB;
            })
            .map((education: IEducation, index: number) => {
              return (
                <Education
                  inactivateItem={inactivateItem}
                  index={index}
                  key={uuid()}
                  newEducation={newEducation}
                  setNewEducation={setNewEducation}
                  education={education}
                  onDashboard={false}
                />
              );
            })}
        </div>
        <div className="resume-category-wrapper">
          <h2 className="type--heading-3">Certification</h2>
          <button
            className="pattern--area-fill-button type--body--large"
            onClick={() => {
              setNewCertification(0);
              const updatedCertifications = [...certifications, ...[new EmptyCertification(user)]];
              setCertifications(updatedCertifications);
            }}
          >
            Add {certifications.length > 1 ? "another" : "a"} Certification
          </button>
          {certifications
            .sort((a: ICertification, b: ICertification) => {
              // Treat undefined sortOrder as a large number to sort it to the end
              const sortOrderA = a.sortOrder !== undefined ? a.sortOrder : Number.MAX_SAFE_INTEGER;
              const sortOrderB = b.sortOrder !== undefined ? b.sortOrder : Number.MAX_SAFE_INTEGER;

              return sortOrderA - sortOrderB;
            })
            .map((certification: ICertification, index: number) => {
              return (
                <Certification
                  inactivateItem={inactivateItem}
                  index={index}
                  key={uuid()}
                  newCertification={newCertification}
                  setNewCertification={setNewCertification}
                  certification={certification}
                  onDashboard={false}
                />
              );
            })}
        </div>
        <div className="pattern--button-row">
          <Button
            label="Back"
            buttonTitle="Back to Resume Upload"
            buttonType="utility"
            onClick={() => navigate("/onboarding/resume")}
          />
          <Button
            label="Next"
            buttonTitle="Analyze resume"
            buttonType="secondary"
            onClick={() => navigate("/onboarding/catchup")}
          />
        </div>
      </div>
    </section>
  );
};
