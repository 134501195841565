import React from "react";
import { Button, SearchField } from "components/core";
import "./searchPrimary.css";

export interface SearchPrimaryProps {
	clearSearch: () => void;
	closeSearch: () => void;
	fieldOnChange: (values: string) => void;
	searchResultsLength: number;
	searchValue: string;
	shouldAutoFocus?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const SearchPrimary: React.FC<SearchPrimaryProps> = ({
	clearSearch,
	closeSearch,
	fieldOnChange,
	searchResultsLength,
	searchValue,
	shouldAutoFocus = false
}) => {
	return (
		<div className="search-primary">
			<div className="search-wrapper">
				<div className="search-wrapper__field">
					<SearchField
						clearSearch={clearSearch}
						fieldOnChange={fieldOnChange}
						searchValue={searchValue}
						shouldAutoFocus={shouldAutoFocus}
					/>
				</div>
				<Button
					buttonType="utility"
					label="End Search"
					buttonTitle="End your search"
					onClick={closeSearch}
					type="button"
				/>
			</div>
			{searchResultsLength > 0 ? (
				<div className="search-results-header">
					<h2 className="type--heading-3">{searchResultsLength} results found with &ldquo;{searchValue.toLowerCase()}&rdquo;</h2>
				</div>
			) : (
				<div className="search-results-header">
					<h2 className="type--heading-3">No Search Results</h2>
				</div>
			)}
		</div>
	);
};