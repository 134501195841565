import React from "react";
import { IReview, IBragSheet } from "types";
import { BragSheetItem } from "../../BragSheets/BragSheetItem";
import { DataContainer } from "containers/DataContainer";
import { getUnixTimestamp, getPastDate } from "utils";
import "./reviewBragSheetsTab.css";

export interface ReviewBragSheetsTabProps {
	review: IReview;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewBragSheetsTab: React.FC<ReviewBragSheetsTabProps> = ({
	review
}) => {
	const {
		bragSheets,
	} = DataContainer.useContainer();
	
	const seedBragSheets = (): IBragSheet[] => {
		const data: IBragSheet[] = [];
		
		bragSheets.map((bragSheet:IBragSheet) => {
			if (getUnixTimestamp(bragSheet.dateCreated) < getUnixTimestamp(review.nextKeyDate) && getUnixTimestamp(bragSheet.dateCreated) > getUnixTimestamp(review.startDate ? review.startDate : getPastDate(review.nextKeyDate, 36500, false))) {
				data.push(bragSheet);
			}
		});
		
		return data;
	}
	
	const applicableBragSheets = seedBragSheets();
	
	return (
		<div className="review-brag-sheet-wrapper">
			<div className="review-brag-sheet-header">
				<p className="type--data review-experience-count">{applicableBragSheets.length} Brag Sheet{applicableBragSheets.length !== 1 && "s"}</p>
			</div>
			<div className={applicableBragSheets.length ? "review-brag-sheet-container" : "review-brag-sheet-content"}>
				{applicableBragSheets.length > 0 ?
					<>
						{applicableBragSheets.sort((a: IBragSheet, b: IBragSheet) => getUnixTimestamp(b.dateCreated) - getUnixTimestamp(a.dateCreated)).map((bragSheet: IBragSheet, index: number) => {
							return (
								<BragSheetItem
									index={index}
									bragSheet={bragSheet}
									hidden={false}
									key={bragSheet.itemId}
								/>
							)
						})}
					</>
				:
					<div className="review-tab--empty-state pattern--empty-state--fill-area">
						<h3 className="type--heading-3">No Brag Sheets</h3>
						<p className="type--body--large">
							Go to Brag Sheets to create one.
						</p>
					</div>
				}
			</div>
		</div>
	);
};