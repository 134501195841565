import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { BackLink, Button, Dialog, MenuItem, Menu } from "components/core";
import { ReviewExperienceTab } from "./ReviewExperienceTab";
import { ReviewBragSheetsTab } from "./ReviewBragSheetsTab";
import { ReviewInterviewPrepTab } from "./ReviewInterviewPrepTab";
import { ReviewTrendsTab } from "./ReviewTrendsTab";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as IconEdit } from "../core/assets/img/edit.svg";
import { ReactComponent as IconSettings } from "../core/assets/img/settings.svg";
import { ReactComponent as IconLoading } from "../core/assets/img/donut-spinner.svg";
import { IReview, IReviewRecurring } from "types";
import { formatDate } from "utils";
import "./reviewDetail.css";

export interface ReviewDetailProps {
  fromAuthoring?: boolean;
  reviewId?: string;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewDetail: React.FC<ReviewDetailProps> = (props) => {
  const { reviewId = "" } = props;
  const {
    reviews,
    userDataLoaded,
    EmptyReview,
    setReviews,
    user,
    setSubmitProfile,
    recurringReviews,
    setRecurringReviews,
    userEmail,
  } = DataContainer.useContainer();

  const navigate = useNavigate();

  const reviewLoaded = useRef<boolean>(false);

  const setUpReview = (): IReview => {
    if (userDataLoaded && !reviewLoaded.current) {
      if (reviewId === "") {
        return new EmptyReview(user);
      } else {
        const review = reviews.filter((item: IReview) => item.itemId === reviewId)[0];
        return review;
      }
    }
    return new EmptyReview(user);
  };

  const review: IReview = setUpReview();
  const [isNewReview] = useState<boolean>(reviewId === "");

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false);

  const IconSettingsSVG = <IconSettings />;
  const IconEditSVG = <IconEdit />;
  const IconLoadingSVG = <IconLoading />;

  const deleteReview = () => {
    // TODO: Update Delete Review function
    const updatedReviews: IReview[] = reviews.filter(
      (filteredReview: IReview) => filteredReview.itemId !== review.itemId,
    );
    setReviews(updatedReviews);
    if (review.recurring === true) {
      const recurringReviewList: IReviewRecurring[] =
        recurringReviews !== "" ? JSON.parse(recurringReviews) : [];
      recurringReviewList.map((reviewRecurring: IReviewRecurring, index: number) => {
        if (reviewRecurring.reviewId === review.itemId) {
          recurringReviewList.splice(index, 1);
          setRecurringReviews(JSON.stringify(recurringReviewList));
          setSubmitProfile(true);
        }
      });
    }
    document.body.classList.remove("dialog-open");
    navigate("/reviews");
  };

  const [isExperienceSummary, setIsExperienceSummary] = useState(true);
  const [isInterviewPreparationSummary, setIsInterviewPreparationSummary] = useState(false);
  const [isBragSheetSummary, setIsBragSheetSummary] = useState(false);
  const [isTrendsSummary, setIsTrendsSummary] = useState(false);

  const { pathname } = useLocation();
  const isExperienceSummaryPath = matchPath("/experience", pathname);
  const isInterviewPreparationSummaryPath = matchPath("/interview-preparation", pathname);
  const isBragSheetSummaryPath = matchPath("/brag-sheets", pathname);
  const isTrendsSummaryPath = matchPath("/trends", pathname);

  useEffect(() => {
    if (isExperienceSummaryPath) {
      setIsExperienceSummary(true);
      setIsInterviewPreparationSummary(false);
      setIsBragSheetSummary(false);
      setIsTrendsSummary(false);
    }
    if (isInterviewPreparationSummaryPath) {
      setIsExperienceSummary(false);
      setIsInterviewPreparationSummary(true);
      setIsBragSheetSummary(false);
      setIsTrendsSummary(false);
    }
    if (isBragSheetSummaryPath) {
      setIsExperienceSummary(false);
      setIsInterviewPreparationSummary(false);
      setIsBragSheetSummary(true);
      setIsTrendsSummary(false);
    }
    if (isTrendsSummaryPath) {
      setIsExperienceSummary(false);
      setIsInterviewPreparationSummary(false);
      setIsBragSheetSummary(false);
      setIsTrendsSummary(true);
    }
  }, []);

  const handleTabNavigation = (tabname: string) => {
    switch (tabname) {
      case "experienceSummary":
        setIsExperienceSummary(true);
        setIsInterviewPreparationSummary(false);
        setIsBragSheetSummary(false);
        setIsTrendsSummary(false);
        break;
      case "interviewPreparationSummary":
        setIsExperienceSummary(false);
        setIsInterviewPreparationSummary(true);
        setIsBragSheetSummary(false);
        setIsTrendsSummary(false);
        break;
      case "bragSheetSummary":
        setIsExperienceSummary(false);
        setIsInterviewPreparationSummary(false);
        setIsBragSheetSummary(true);
        setIsTrendsSummary(false);
        break;
      case "trendsSummary":
        setIsExperienceSummary(false);
        setIsInterviewPreparationSummary(false);
        setIsBragSheetSummary(false);
        setIsTrendsSummary(true);
        break;
      default:
        if (
          process.env.REACT_APP_USER_BRANCH !== "master" ||
          (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
        ) {
          console.log("make sure to put in the value of the tab");
        }
    }
  };

  const Tabs = () => {
    return (
      <div className="pattern--tab-navigation dashboard-navigation">
        <button
          className={
            isExperienceSummary
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("experienceSummary")}
        >
          Experience
        </button>
        {review.jobInterview && (
          <button
            className={
              isInterviewPreparationSummary
                ? "pattern--tab-nav-item pattern--tab-nav-item--active"
                : "pattern--tab-nav-item"
            }
            onClick={() => handleTabNavigation("interviewPreparationSummary")}
          >
            Interview Preparation
          </button>
        )}
        {review.type !== "overall" && (
          <button
            className={
              isBragSheetSummary
                ? "pattern--tab-nav-item pattern--tab-nav-item--active"
                : "pattern--tab-nav-item"
            }
            onClick={() => handleTabNavigation("bragSheetSummary")}
          >
            Brag Sheets
          </button>
        )}
        <button
          className={
            isTrendsSummary
              ? "pattern--tab-nav-item pattern--tab-nav-item--active plausible-event-name=Review+Trends+Tab"
              : "pattern--tab-nav-item plausible-event-name=Review+Trends+Tab"
          }
          onClick={() => handleTabNavigation("trendsSummary")}
        >
          Trends
        </button>
      </div>
    );
  };

  const [editingNextKeyDate, setEditingNextKeyDate] = useState<boolean>(false);
  const [updatingNextKeyDate, setUpdatingNextKeyDate] = useState<boolean>(false);
  const [newNextKeyDate, setNewNextKeyDate] = useState<string>("");

  const [experienceTabKey, setExperienceTabKey] = useState<number>(0);
  const [interviewPrepTabKey, setInterviewPrepTabKey] = useState<number>(0);
  const [bragsheetsTabKey, setBragsheetsTabKey] = useState<number>(0);
  const [trendsTabKey, setTrendsTabKey] = useState<number>(0);

  return (
    <div className="review-outer-wrapper">
      <section className="review-container">
        <div className="header--withbreadcrumb review-masthead">
          <BackLink
            button={false}
            label="Return to Reviews"
            title="Return to Reviews"
            navigateTo="/reviews"
          />
          <div className="pattern--menu-container pattern--menu-container--mobile-left-desktop-right">
            <Button
              label="Review Settings"
              buttonTitle="See Review Settings"
              buttonType="icon-only"
              disabled={isNewReview}
              trailingIcon={IconSettingsSVG}
              size="small"
              onClick={() => {
                setIsSettingsMenuOpen(!isSettingsMenuOpen);
              }}
            />
            <Menu isOpened={isSettingsMenuOpen} position="right">
              <MenuItem
                type="action"
                label="Delete Review"
                title="Delete this Review"
                primaryAction={() => {
                  setIsDeletionDialogOpen(!isDeletionDialogOpen);
                  setIsSettingsMenuOpen(!isSettingsMenuOpen);
                }}
              />
            </Menu>
            <Dialog
              isOpened={isDeletionDialogOpen}
              setIsOpened={setIsDeletionDialogOpen}
              primaryButtonType="primary"
              primaryButtonTitle="Delete Review"
              primaryButtonLabel="Yes, Delete"
              primaryButtonFunction={deleteReview}
              primaryButtonClosesDialog={true}
              secondaryButtonType="utility"
              secondaryButtonTitle="Cancel"
              secondaryButtonLabel="Cancel"
            >
              <p className="type--body--large">
                Are you sure you want to delete <strong>{review.name}</strong>
              </p>
            </Dialog>
          </div>
        </div>
        <div className="review-wrapper">
          <div className="review-header">
            <h1 className="type--heading-1">{review.name}</h1>
            <div className="review-header__meta">
              {review.type !== "overall" ? (
                <p className="type--body--standard">
                  Range:{" "}
                  <strong>
                    {review.startDate ? formatDate(review.startDate) + " – " : "until "}
                    {formatDate(review.nextKeyDate)}
                  </strong>
                </p>
              ) : (
                <p className="type--body--standard">
                  Review: <strong>{formatDate(review.nextKeyDate)}</strong>
                </p>
              )}
              <Button
                label="Edit"
                buttonTitle="Edit Review Date"
                buttonType="icon-only"
                trailingIcon={IconEditSVG}
                size="small"
                disabled={editingNextKeyDate && true}
                onClick={() => {
                  setEditingNextKeyDate(true);
                }}
              />
            </div>
            {editingNextKeyDate && (
              <div className="review-edit-date-container">
                <div className="field-wrapper pattern--field-wrapper">
                  <span className="pattern--label type--heading-4">Review Date</span>
                  <div className="pattern--input-container">
                    <input
                      className="pattern--input-field"
                      type="date"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          const isoDate: Date = new Date(e.target.value);
                          isoDate.setMinutes(isoDate.getMinutes() + isoDate.getTimezoneOffset());
                          if (isoDate.toISOString() !== review.nextKeyDate) {
                            setNewNextKeyDate(isoDate.toISOString());
                          }
                        }
                      }}
                      value={
                        newNextKeyDate !== ""
                          ? newNextKeyDate.substring(0, 10)
                          : review.nextKeyDate.substring(0, 10)
                      }
                    />
                  </div>
                </div>
                <div className="pattern--button-row">
                  <Button
                    label="Cancel"
                    buttonTitle="Cancel new Next Key Date"
                    buttonType="utility"
                    onClick={() => {
                      setEditingNextKeyDate(false);
                    }}
                  />
                  <Button
                    label="Save"
                    buttonTitle="Save new Next Key Date"
                    buttonType="secondary"
                    disabled={newNextKeyDate === "" ? true : false}
                    leadingIcon={updatingNextKeyDate && IconLoadingSVG}
                    onClick={() => {
                      setUpdatingNextKeyDate(true);
                      const updatedReview = review;
                      updatedReview.nextKeyDate = newNextKeyDate;
                      const updatedReviews = [...reviews];
                      let currentIndex = 0;
                      reviews.map((currentReview: IReview, index: number) => {
                        if (currentReview.itemId === review.itemId) {
                          currentIndex = index;
                        }
                      });
                      setEditingNextKeyDate(false);
                      setNewNextKeyDate("");
                      setUpdatingNextKeyDate(false);
                      if (isExperienceSummary) {
                        setExperienceTabKey(experienceTabKey + 1);
                      }
                      if (isInterviewPreparationSummary) {
                        setInterviewPrepTabKey(interviewPrepTabKey + 1);
                      }
                      if (isBragSheetSummary) {
                        setBragsheetsTabKey(bragsheetsTabKey + 1);
                      }
                      if (isTrendsSummary) {
                        setTrendsTabKey(trendsTabKey + 1);
                      }
                      if (review.recurring === true) {
                        const recurringReviewList: IReviewRecurring[] =
                          recurringReviews !== "" ? JSON.parse(recurringReviews) : [];
                        recurringReviewList.map((reviewRecurring: IReviewRecurring, index: number) => {
                          if (reviewRecurring.reviewId === review.itemId) {
                            const recurringReview: IReviewRecurring = {
                              reviewId: reviewRecurring.reviewId,
                              nextKeyDate: review.nextKeyDate,
                              reminderEmailSent: reviewRecurring.reminderEmailSent,
                              recurringReviewCreated: reviewRecurring.recurringReviewCreated,
                            };
                            recurringReviewList.splice(index, 1, recurringReview);
                            setRecurringReviews(JSON.stringify(recurringReviewList));
                            setSubmitProfile(true);
                          }
                        });
                      }
                      updatedReviews.splice(currentIndex, 1, updatedReview);
                      setReviews(updatedReviews);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="review-body">
            <Tabs />
            <div
              className={`review-pane-content ${review.jobInterview && isInterviewPreparationSummary && "review-pane-content--highlight"}`}
            >
              {isExperienceSummary && <ReviewExperienceTab review={review} key={experienceTabKey} />}
              {review.jobInterview && isInterviewPreparationSummary && (
                <ReviewInterviewPrepTab review={review} key={interviewPrepTabKey} />
              )}
              {review.type !== "overall" && isBragSheetSummary && (
                <ReviewBragSheetsTab review={review} key={bragsheetsTabKey} />
              )}
              {isTrendsSummary && <ReviewTrendsTab review={review} key={trendsTabKey} />}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
