import React from "react";
import "./segmentedControl.css";

export interface SegmentedControlProps {
	label?: string;
	helperPrompt?: unknown;
	name: string;
	onChange: (value: string) => void;
	options: {
		label: string;
		value: string;
		default?: true | false;
	}[];
	selected?: string;
	disabled?: boolean;
}

export const SegmentedControl: React.FC<SegmentedControlProps> = (props) => {
	const { label, name, onChange, options, selected, disabled } = props;
	return (
		<div className="segmented-control-container field-wrapper">
			{label && (
				<span className="pattern--label type--heading-4">
					{props.label}
					{props.helperPrompt && props.helperPrompt}
				</span>
			)}
			<div className={`segmented-control sc-zoom ${disabled && "segmented-control--disabled"}`}>
				{options.map((option, index) => {
					return (
						<div
							className="segmented-control-btn"
							key={(option.value + index) as string}
						>
							<input
								checked={selected ? option.value === selected : option.default}
								id={option.value}
								name={name}
								onChange={() => onChange(option.value)}
								type="radio"
								value={option.value}
								disabled={disabled}
							/>
							<label htmlFor={option.value}>{option.label}</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};
