import React, { useEffect, useState } from "react";
import { Button } from "components/core";
import { ReactComponent as IconArrowPointingLeft } from "../assets/img/arrow-pointing-left.svg";
import { ReactComponent as IconArrowPointingRight } from "../assets/img/arrow-pointing-right.svg";
import { ReactComponent as IconClose } from "../assets/img/close.svg";
import { IGoal } from "types";
import "./goalDisplay.css";

interface GoalDisplayProps {
	displayType?: "small" | "large";
	dismissable?: boolean;
	dismissFunction?: () => void;
	selectedGoal?: string;
	goals: IGoal[];
}

export const GoalDisplay: React.FC<GoalDisplayProps> = ({
	displayType,
	dismissable,
	dismissFunction,
	goals,
}) => {
	const [goal, setGoal] = useState<IGoal>(goals[0]);

	const [goalIndex, setGoalIndex] = useState(0);

	// TODO: Large Display Type

	const handleButtonClick = (source: string) => {
		if (source === "decrement") {
			if (goalIndex === 0) {
				setGoalIndex(goals.length - 1);
			} else {
				setGoalIndex(goalIndex - 1);
			}
		} else {
			if (goalIndex === goals.length - 1) {
				setGoalIndex(0);
			} else {
				setGoalIndex(goalIndex + 1);
			}
		}
	};

	useEffect(() => {
		setGoal(goals[goalIndex]);
	}, [goalIndex]);

	const IconArrowPointingLeftSVG = <IconArrowPointingLeft />;
	const IconArrowPointingRightSVG = <IconArrowPointingRight />;
	const IconCloseSVG = <IconClose />;

	return (
		<div className={`goal-display goal-display--${displayType}`}>
			<>
				<div className="goal-display__header-row">
					<h2 className="type--heading-3">{goal.goal}</h2>
					{dismissable && (
						<Button
							buttonType="icon-only"
							size="small"
							leadingIcon={IconCloseSVG}
							label="Close"
							buttonTitle="Close Goals"
							onClick={dismissFunction}
						/>
					)}
				</div>
				<p
					className={
						displayType === "large"
							? "type--body--large"
							: "type--body--standard"
					}
				>
					{goal.details}
				</p>
			</>
			{goals.length > 1 && displayType !== "large" ? (
				<div className="pagination pattern--pagination--small">
					<Button
						label="Previous"
						buttonTitle="Previous Goal"
						buttonType="icon-only-utility"
						leadingIcon={IconArrowPointingLeftSVG}
						onClick={() => handleButtonClick("decrement")}
					/>
					<span className="type--body--standard">
						{goalIndex + 1} of {goals.length}
					</span>
					<Button
						label="Next"
						buttonTitle="Next Goal"
						buttonType="icon-only-utility"
						leadingIcon={IconArrowPointingRightSVG}
						onClick={() => handleButtonClick("increment")}
					/>
				</div>
			) : null}
		</div>
	);
};
