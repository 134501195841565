import {formatDate} from "utils";

export const signUpEmailExplanation = "We encourage you to use your personal email address rather than a work or school email. Career Minder should help you in your current role, but also as you move from job to job."

const insertDecimal = (num: number) => {
	return (num / 100).toFixed(2);
};

const createActiveMessage = (
	price: number | undefined, 
	interval: "month" | "year" | undefined,
	trial: boolean | undefined,
	discount?: {
		discountedPrice: number,
		discountDate: number | undefined,
	},
	daysFromNowForTrialEnd?: number
):string => {
	let daysFromNow = "";
	if (daysFromNowForTrialEnd !== undefined) {
		if (daysFromNowForTrialEnd > 0) {
			daysFromNow = daysFromNowForTrialEnd + " days from now";
		} else {
			daysFromNow = "today";
		}
	}
	let messageString = "Your subscription is active."
	if (trial !== undefined && trial === true) {
		messageString = "You trial is active and will end " + daysFromNow + "."
	}
	if ((discount && discount.discountDate === 0) || (discount && discount.discountedPrice === 0 && trial === true)) {
		return messageString;
	}
	if (trial !== undefined && trial === true) {
		messageString += " Manage your subscription to add payment info."
		return messageString;
	}
	messageString += " You will be billed $";
	const now = new Date();
	const nowTimestamp = now.getTime()/1000;
	if (discount && discount.discountDate && discount.discountDate > nowTimestamp) {
		messageString += insertDecimal(discount.discountedPrice);
	} else if (price) {
		messageString += insertDecimal(price);
	}
	messageString += " every " + (interval && interval);
	if (discount && discount.discountDate && discount.discountDate > 0 && discount.discountDate > nowTimestamp) {
		const discountExpiration = new Date(discount.discountDate*1000);
		const convertedDate = new Date(discountExpiration).toLocaleDateString("en-us", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
		messageString += " until " + convertedDate +  ".";
	} else {
		messageString += ".";
		return messageString;
	}
	messageString += " After which, you will be billed $" + (price && insertDecimal(price)) + " every " + (interval && interval) + ".";
	return messageString;
};

export const getSubscriptionStatusString = (
	length:"short" | "long", 
	status: "active" | "past_due" | "unpaid"| "canceled" | "incomplete" | "incomplete_expired" | "trialing" | "paused", 
	price?: number, 
	interval?: "month" | "year",
	discount?: {
		discountedPrice: number,
		discountDate: number | undefined,
	},
	daysFromNowForTrialEnd?: number,
): string => {
	let daysFromNow = "";
	if (daysFromNowForTrialEnd !== undefined) {
		if (daysFromNowForTrialEnd > 0) {
			daysFromNow = daysFromNowForTrialEnd + " days from now";
		} else {
			daysFromNow = "today";
		}
	}
	const statuses = [
		{
			status: "active",
			short: "Your subscription is active",
			long: createActiveMessage(price && price, interval, false, discount),
		},
		{
			status: "past_due",
			short: "Your subscription is past due",
			long: "Your subscription is past due. Manage your subscription to fix it.",
		},
		{
			status: "unpaid",
			short: "Your subscription is past unpaid",
			long: "Your subscription is unpaid. Manage your subscription to fix it.",
		},
		{
			status: "canceled",
			short: "Your subscription has been cancelled",
			long: "Your subscription has been cancelled. If you’d like to change that, manage your subscription.",
		},
		{
			status: "incomplete",
			short: "Your subscription is not active",
			long: "Your subscription is not active. Manage your subscription to fix it.",
		},
		{
			status: "incomplete_expired",
			short: "Your subscription is not active",
			long: "Your subscription is not active. Manage your subscription to fix it.",
		},
		{
			status: "trialing",
			short: "Your trial will end " + daysFromNow,
			long: createActiveMessage(price && price, interval, true, discount, daysFromNowForTrialEnd),
		},
		{
			status: "paused",
			short: "Your trial has ended",
			long: "Your trial has ended. Manage your subscription to fix it.",
		},
	];
	
	const findStatus = (): string => {
		const selectedStatus = statuses.find(statusObject => statusObject.status === status);
		if (selectedStatus !== undefined) {
			return length === "short" ? selectedStatus.short : selectedStatus.long;
		} else {
			return length === "short" ? "Error retrieving your subscription status" : "There seems to be an error retrieving your subscription status";
		}
	}
	
	return findStatus();
};

export const subscriptionCancelationExplanation = "Canceling your subscription allows you to use Career Minder through the end of your current billing cycle. After that, you will not be charged again, but you also will not be able to use Career Minder. Your Career Minder account and data will be deleted.";

export const subscriptionCancelledBillingMessage = "Your subscription has been canceled and your account will be deleted at the end of your current billing period. You can use Career Minder through the end of your current billing cycle. After that, you will not be charged again, but you also will not be able to use Career Minder. Your Career Minder account and data will be deleted. If you’ve changed your mind you can reverse the cancelation below."

