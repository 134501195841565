import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, DatePicker, Dialog, Input, Textarea } from "components/core";
import { ReactComponent as IconArrowPointingUp } from "../assets/img/arrow-pointing-up.svg";
import { ReactComponent as IconCalendar } from "../assets/img/calendar.svg";
import { ReactComponent as IconEdit } from "../assets/img/edit.svg";
import { ReactComponent as IconMinus } from "../assets/img/minus.svg";
import { ReactComponent as IconDonutSpinner } from "../assets/img/donut-spinner.svg";
import { IGoal, IWorkExperience, IJob, IProject, ICompany } from "types";
import { DataContainer } from "containers/DataContainer";
import { formatDate, getDaysAgo, goalsValidationSchema, getEmbedding } from "utils";
import "./goalItem.css";

interface Values {
  goal: IGoal;
}

export interface GoalItemProps {
  autoFocus?: boolean;
  index: number;
  newGoal: boolean;
  remove?: (index: number) => void;
  setNewGoal: (index: boolean) => void;
  goal: IGoal;
  hidden?: boolean;
  searchDeletion?: boolean;
  setSearchDeletion?: (value: boolean) => void;
  editable?: boolean;
  useTheseRelatedExperience?: IWorkExperience[];
  relatedExperienceVisible?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const GoalItem: React.FC<GoalItemProps> = (props) => {
  const {
    index,
    newGoal,
    setNewGoal,
    goal,
    searchDeletion,
    setSearchDeletion,
    editable = true,
    useTheseRelatedExperience = undefined,
    relatedExperienceVisible = true,
  } = props;
  const { goals, setGoals, subscriptionActive, workExperiences, jobs, projects, companies } =
    DataContainer.useContainer();

  const [editing, setEditing] = useState<boolean>(newGoal && index === -1 ? true : false);
  const [viewingExperience, setViewingExperience] = useState<boolean>(false);

  const [isGoalDeleteConfirmationOpened, setIsGoalDeleteConfirmationOpened] = useState(false);

  const initialValues = {
    goal,
  };

  const completionDateSentence = (dateToFormat: string): string => {
    const daysAgo = Math.floor(getDaysAgo(dateToFormat));
    if (daysAgo < 0) {
      const daysAgoPositive = daysAgo * -1;
      const message = daysAgoPositive + " days from now";
      return message;
    } else {
      const message = daysAgo + " days ago";
      return message;
    }
  };

  const relatedExperience: IWorkExperience[] = useTheseRelatedExperience
    ? useTheseRelatedExperience
    : workExperiences.filter((experience: IWorkExperience) => {
        if (experience.goalId === goal.itemId) {
          return experience;
        }
      });

  const buildRelatedExperienceMeta = (experience: IWorkExperience): string => {
    let metaInfo = "";
    if (experience.completionDate !== "" && experience.completionDate !== undefined) {
      metaInfo += formatDate(experience.completionDate);
      if (experience.jobId !== "" || experience.projectId !== "") {
        metaInfo += " • ";
      }
    }
    if (experience.jobId !== "" && experience.jobId !== undefined) {
      const relatedJob = jobs.filter((job: IJob) => {
        return job.itemId.includes(experience.jobId);
      })[0];
      const relatedCompany = companies.filter((company: ICompany) => {
        return company.itemId.includes(relatedJob.companyId);
      })[0];
      if (relatedCompany !== undefined) {
        metaInfo +=
          relatedJob.jobTitle + ": " + relatedCompany !== undefined &&
          relatedCompany.companyName !== undefined
            ? relatedCompany.companyName
            : "";
      } else {
        metaInfo += relatedJob.jobTitle;
      }

      if (experience.projectId !== "") {
        metaInfo += " • ";
      }
    }
    if (experience.projectId !== "" && experience.projectId !== undefined) {
      const relatedProject = projects.filter((project: IProject) => {
        return project.itemId.includes(experience.projectId);
      })[0];
      if (relatedProject !== undefined) {
        metaInfo += relatedProject.projectName;
      } else {
        metaInfo = metaInfo.substring(0, metaInfo.length - 3);
      }
    }

    return metaInfo;
  };

  const IconEditSVG = <IconEdit />;
  const IconMinusSVG = <IconMinus />;
  const IconDonutSpinnerSVG = <IconDonutSpinner />;

  const DisplayItem = () => {
    return (
      <div className="goal-item-inner-wrapper">
        <div className="goal-content-wrapper__content goal-content">
          <h2 className="type--heading-3 goal-content__text">{goal.goal}</h2>
          <p className="type--body--large">{goal.details}</p>
        </div>
      </div>
    );
  };

  const [submittingForm, setSubmittingForm] = useState<boolean>(false);

  const EditItem = () => {
    return (
      <div className="goal-item__editor">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            setSubmittingForm(true);
            getEmbedding(values.goal.details).then((value) => {
              const vector: string = value;
              if (Array.isArray(JSON.parse(vector))) {
                values.goal.vector = vector;
              } else {
                values.goal.vector = "";
              }
              if (newGoal) {
                setGoals([...[values.goal], ...goals]);
              } else {
                const updatedGoals = [...goals];
                updatedGoals.splice(index, 1, values.goal);
                setGoals(updatedGoals);
              }
              setSubmitting(false);
              setSubmittingForm(false);
              setEditing(false);
              setNewGoal(false);
            });
          }}
          validationSchema={goalsValidationSchema}
        >
          <Form>
            <Input
              name={`goal.goal`}
              label="What would you like to do next?"
              placeholder="Enter a job title, thing you's like to accomplish, etc."
              autoFocus={true}
            />
            <Textarea
              name={`goal.details`}
              label="Describe your goal in more detail"
              placeholder="Get into the nitty gritty"
            />
            <DatePicker
              label="When would you like to do it"
              name={`goal.completionDate`}
              key={`we-completionDate${index}`}
            />
            <div className="pattern--button-row pattern--button-row--space-between">
              <Button
                label="Delete"
                buttonTitle="Delete Experience"
                onClick={() => setIsGoalDeleteConfirmationOpened(true)}
                buttonType="utility"
                theme="destructive"
                leadingIcon={IconMinusSVG}
              />
              <div className="pattern--button-row">
                <Button
                  label="Cancel"
                  buttonTitle="Cancel Edits"
                  buttonType="utility"
                  onClick={() => {
                    setNewGoal(false);
                    setEditing(false);
                  }}
                />
                <Button
                  buttonTitle="Save Edits"
                  buttonType="primary"
                  label="Save"
                  leadingIcon={submittingForm && IconDonutSpinnerSVG}
                  type="submit"
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  };

  const GoalMeta = () => {
    return (
      <div
        className={`goal-log__meta goal-meta ${!relatedExperienceVisible && "goal-meta--hidden"} ${editing && "goal-meta--editing"}`}
      >
        <div className="goal-meta__content">
          <div className="goal-experience-date">
            {relatedExperienceVisible && (
              <>
                <button
                  className={`related-experience-indicator related-experience-indicator--large ${relatedExperience.length === 0 && "related-experience-indicator--disabled"}`}
                  onClick={() => setViewingExperience(!viewingExperience)}
                  disabled={relatedExperience.length > 0 ? false : true}
                >
                  {viewingExperience && (
                    <div className="icon-wrapper">
                      <IconArrowPointingUp />
                    </div>
                  )}
                  <span className="type--body--small">
                    {viewingExperience ? "Hide Related Experience" : "See Related Experience"}
                  </span>
                  <div className="related-experience--number type--body--standard">
                    {relatedExperience.length}
                  </div>
                </button>
                {!editing && goal.completionDate && goal.completionDate.length && (
                  <div className="goal-date">
                    <IconCalendar />
                    <p className="type--body--standard">
                      {formatDate(goal.completionDate) + " • " + completionDateSentence(goal.completionDate)}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          {!editing && editable && (
            <div className="goal-date-actions__actions goal-actions">
              <Button
                label="Edit"
                buttonTitle="Edit Experience"
                buttonType="icon-only"
                size="small"
                theme="goal"
                leadingIcon={IconEditSVG}
                disabled={!subscriptionActive}
                onClick={() => setEditing(true)}
              />
            </div>
          )}
        </div>
        {viewingExperience && relatedExperience.length > 0 && (
          <div className="goal-related-experience">
            {relatedExperience
              .sort((a, b) => new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf())
              .map((workExperience: IWorkExperience) => {
                return (
                  <div className="related-experience-item" key={workExperience.itemId}>
                    <p className="type--body--large">{workExperience.description}</p>
                    <p className="type--body--standard related-experience-item__meta">
                      {buildRelatedExperienceMeta(workExperience)}
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {goal.goal.length ? (
        <div
          className={`goal-log__item goal-item ${props.hidden && "goal-item--hidden"}`}
          data-item={index}
          id={goal.itemId.slice(5)}
        >
          <div className="goal-item-inner-wrapper">{editing ? <EditItem /> : <DisplayItem />}</div>
          <GoalMeta />
        </div>
      ) : editing ? (
        <>
          <div
            className={`goal-log__item goal-item ${props.hidden && "goal-item--hidden"}`}
            data-item={index}
          >
            <div className="goal-item-inner-wrapper">
              <EditItem />
            </div>
            <GoalMeta />
          </div>
        </>
      ) : null}
      <Dialog
        isOpened={isGoalDeleteConfirmationOpened}
        setIsOpened={setIsGoalDeleteConfirmationOpened}
        primaryButtonType="primary"
        primaryButtonTitle="Confirm Goal Deletion"
        primaryButtonLabel="Delete"
        primaryButtonFunction={() => {
          searchDeletion !== undefined &&
            setSearchDeletion !== undefined &&
            setSearchDeletion(!searchDeletion);
          const updatedGoals: IGoal[] = [...goals];
          updatedGoals.splice(index, 1);
          setEditing(false);
          setGoals(updatedGoals);
        }}
        primaryButtonClosesDialog={true}
        secondaryButtonType="utility"
        secondaryButtonTitle="Cancel Goal Deletion"
        secondaryButtonLabel="Cancel"
      >
        <p className="type--body--large">Are you sure you want to delete this goal?</p>
      </Dialog>
    </>
  );
};
