import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { CredentialsContainer } from "containers/CredentialsContainer";

const LogoutButton: React.FC = () => {
	const navigate = useNavigate();
	const { setIsAuthenticated, setUser } = CredentialsContainer.useContainer();

	const signOut = async () => {
		try {
			await Auth.signOut();
			setUser(null);
			setIsAuthenticated(false);
			navigate("/login");
		} catch (error) {
			console.log("error signing out", error);
		}
	};

	return <button onClick={signOut} className="type--heading-3 mobile-nav-link mobile-nav-link--button mobile-nav-link--secondary">Log Out</button>;
};

export default LogoutButton;
