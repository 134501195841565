import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { filter, isEqual } from "lodash";
import { CreatableSelect, DatePicker, Input, Button, Dialog } from "components/core";
import { DataContainer } from "containers/DataContainer";
import {
  ISelectDropDown,
  IJob,
  ICompany,
  IProject,
  ISentiment,
  IWorkExperience,
  IJobPlus,
  IJobMinus,
} from "types";
import { JobExperience } from "./JobExperience";
import { PlusesMinuses } from "./PlusesMinuses";
import { Sentiments } from "./Sentiments";
import { formatDate, workSummaryValidationSchema } from "utils";
import { ReactComponent as IconMinus } from "../../core/assets/img/minus.svg";
import { ReactComponent as IconArrowDown } from "../../core/assets/img/arrow-pointing-down.svg";
import { ReactComponent as IconArrowUp } from "../../core/assets/img/arrow-pointing-up.svg";
import { ReactComponent as IconEdit } from "../../core/assets/img/edit.svg";
import "./job.css";

interface Values {
  job: IJob;
}
interface JobProps {
  autoFocus?: boolean;
  inactivateItem: (itemId: string) => void;
  index: number;
  newJob: number;
  setNewJob: (index: number) => void;
  job: IJob;
  onDashboard?: boolean;
}

interface EditJobFormRef {
  submitForm: () => void;
}

export const Job: React.FC<JobProps> = (props) => {
  const { index, newJob, setNewJob, job, onDashboard } = props;

  const {
    companies,
    jobs,
    projects,
    sentiments,
    user,
    EmptyCompany,
    setCompanies,
    setProjects,
    setJobs,
    workExperiences,
    setWorkExperiences,
    setSentiments,
    subscriptionActive,
  } = DataContainer.useContainer();
  const [editing, setEditing] = useState<boolean>(index === newJob);
  const [isJobDeleteConfirmationOpened, setIsJobDeleteConfirmationOpened] = useState(false);

  const initialValues = {
    job,
  };

  const companyItems: ISelectDropDown[] = companies.map((company: ICompany) => {
    return { value: company.itemId, label: company.companyName };
  });

  const createCompany = (company: string): string => {
    const newCompany: ICompany = new EmptyCompany(user);
    newCompany.companyName = company;
    const updatedCompanies = [...companies, newCompany];
    setCompanies(updatedCompanies);
    return newCompany.itemId;
  };

  const moveJobDown = () => {
    const updatedJobs = [...jobs];
    if (index !== -1 && index < updatedJobs.length - 1) {
      const el = updatedJobs[index];
      updatedJobs[index] = updatedJobs[index + 1];
      updatedJobs[index + 1] = el;
      updatedJobs.forEach(function (_part: IJob, index, updatedJobs) {
        updatedJobs[index].sortOrder = index;
      });
      setJobs(updatedJobs);
    }
  };

  const moveJobUp = () => {
    const updatedJobs = [...jobs];
    if (index > 0) {
      const el = updatedJobs[index];
      updatedJobs[index] = updatedJobs[index - 1];
      updatedJobs[index - 1] = el;
      updatedJobs.forEach(function (part: IJob, index, updatedJobs) {
        updatedJobs[index].sortOrder = index;
      });
      setJobs(updatedJobs);
    }
  };

  const remove = (index: number): void => {
    const updatedJobs = [...jobs];
    updatedJobs.splice(index, 1);
    setJobs(updatedJobs);
  };

  const IconMinusSVG = <IconMinus />;
  const IconArrowDownSVG = <IconArrowDown />;
  const IconArrowUpSVG = <IconArrowUp />;
  const IconEditSVG = <IconEdit />;

  const [experienceToUpdate, setExperienceToUpdate] = useState<IWorkExperience[]>([]);
  const [projectsToDelete, setProjectsToDelete] = useState<IProject[]>([]);

  // TODO: write these to the job if they’ve changed and we save, discard changes if we cancel

  const [pluses, setPluses] = useState<IJobPlus[]>(job.pluses ? JSON.parse(job.pluses) : []);
  const [tempJobPluses, setTempJobPluses] = useState<IJobPlus[]>(pluses);

  const [minuses, setMinuses] = useState<IJobMinus[]>(job.minuses ? JSON.parse(job.minuses) : []);
  const [tempJobMinuses, setTempJobMinuses] = useState<IJobMinus[]>(minuses);

  const [sentimentsToUpdate, setSentimentsToUpdate] = useState<ISentiment[]>([]);

  const resetTempValues = () => {
    setTempJobPluses(pluses);
    setTempJobMinuses(minuses);
    setSentimentsToUpdate([]);
    setExperienceToUpdate([]);
    setProjectsToDelete([]);
  };

  const EditJobForm = forwardRef((props, ref) => {
    const formikContext = useFormikContext();

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formikContext.submitForm();
      },
    }));

    const jobValue: ISelectDropDown = filter(companyItems, ["value", job.companyId])[0];

    return (
      <Form className="job-summary-item-form-wrapper">
        <Input label="Job Title" name={`job.jobTitle`} placeholder="How do your coworkers describe you?" />
        <div className="field-wrapper pattern--field-wrapper">
          <label className="pattern--label type--heading-4">Company</label>
          <CreatableSelect
            className="pattern-dropdown"
            classNamePrefix="pattern-dropdown"
            key={`we-company${index}`}
            name={`job.companyId`}
            options={companyItems}
            placeholder="Where do you work?"
            value={jobValue}
          />
        </div>

        <DatePicker key={`we-startDate${index}`} name={`job.startDate`} label="Start Date" />
        <DatePicker
          key={`we-endDate${index}`}
          name={`job.endDate`}
          label="End Date"
          helperPrompt="Leave blank if it is a current job"
        />
      </Form>
    );
  });
  EditJobForm.displayName = "EditJobForm";

  const formRef = useRef<EditJobFormRef>(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const EditJob = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
          // If is a new company, the companyId will be new#company name. So need to
          // parse out the company name and create and push new company item.
          if (values.job.companyId !== undefined && values.job.companyId.startsWith("new#")) {
            values.job.companyId = createCompany(values.job.companyId.substring(4));
          }
          // If the job title is blank, use an obviously placeholder name
          if (values.job.jobTitle === "") {
            values.job.jobTitle = "Job " + jobs.length;
          }
          // Update Pluses & Minuses
          if (!isEqual(pluses, tempJobPluses)) {
            values.job.pluses = JSON.stringify(tempJobPluses);
            setPluses(tempJobPluses);
          }
          if (!isEqual(minuses, tempJobMinuses)) {
            values.job.minuses = JSON.stringify(tempJobMinuses);
            setMinuses(tempJobMinuses);
          }
          // Update Sentiments from the bulk sentiment setting
          if (sentimentsToUpdate.length) {
            const today = new Date().toUTCString();
            const endDate = job.endDate ? new Date(job.endDate) : new Date(today);
            const startDate = new Date(job.startDate);

            const updatedSentiments = sentiments.filter((sentiment: ISentiment) => {
              const sentimentDate = new Date(sentiment.dateCreated);
              if (sentimentDate >= startDate && sentimentDate <= endDate) {
                const matchingSentiment = sentimentsToUpdate.find((s: ISentiment) => {
                  const sDate = new Date(s.dateCreated);
                  return (
                    sDate.getFullYear() === sentimentDate.getFullYear() &&
                    sDate.getMonth() === sentimentDate.getMonth() &&
                    sDate.getDate() === sentimentDate.getDate() &&
                    s.jobId === sentiment.jobId
                  );
                });
                return matchingSentiment === undefined;
              }
              return true;
            });

            const uniqueSentiments = [...updatedSentiments, ...sentimentsToUpdate].reduce(
              (acc: ISentiment[], sentiment: ISentiment) => {
                const sentimentDate = new Date(sentiment.dateCreated);
                const existingSentiment = acc.find((s: ISentiment) => {
                  const sDate = new Date(s.dateCreated);
                  return (
                    sDate.getFullYear() === sentimentDate.getFullYear() &&
                    sDate.getMonth() === sentimentDate.getMonth() &&
                    sDate.getDate() === sentimentDate.getDate() &&
                    s.jobId === sentiment.jobId
                  );
                });
                if (!existingSentiment) {
                  acc.push(sentiment);
                }
                return acc;
              },
              [],
            );

            setSentiments(uniqueSentiments);
            setSentimentsToUpdate([]);
          }

          // Update Experiences that have been edited
          if (experienceToUpdate.length) {
            const updatedWorkExperiences = workExperiences.map((experience: IWorkExperience) => {
              const matchedExperience = experienceToUpdate.find(
                (e: IWorkExperience) => e.itemId === experience.itemId,
              );
              if (matchedExperience) {
                return matchedExperience;
              }
              return experience;
            });
            setWorkExperiences(updatedWorkExperiences);
            setExperienceToUpdate([]);
          }

          // Delete Projects
          if (projectsToDelete.length) {
            const updatedProjects = projects
              .map((project: IProject) => {
                const matchedProject = projectsToDelete.find((p: IProject) => p.itemId === project.itemId);
                if (matchedProject === undefined) {
                  return project;
                }
              })
              .filter((project): project is IProject => project !== undefined);
            setProjects(updatedProjects);
            setProjectsToDelete([]);
          }

          const updatedJobs = [...jobs];
          updatedJobs.splice(index, 1, values.job);
          // Set each job’s sortOrder to match their index
          updatedJobs.forEach(function (part: IJob, index, updatedJobs) {
            updatedJobs[index].sortOrder = index;
          });
          setJobs(updatedJobs);
          setSubmitting(false);
          setEditing(false);
          setNewJob(-1);
        }}
        validationSchema={workSummaryValidationSchema}
      >
        <EditJobForm ref={formRef} />
      </Formik>
    );
  };

  let companyName = "";
  if (job.companyId) {
    companyName = filter(companyItems, ["value", job.companyId])[0].label;
  }

  return (
    <div
      className={`job-summary-item summary-item ${editing && "job-summary-item--edit"} ${onDashboard && "summary-item--on-dashboard"}`}
      data-item={index}
    >
      <div className="job-summary-item__header">
        {!editing ? (
          <div className="job-header-content">
            <h2 className="type--heading-6 job-header-content__company">{companyName}</h2>
            <h3 className="type--body--large">{job.jobTitle}</h3>
            <p className="type--body--standard job-date-range">
              {`${job.startDate ? formatDate(job.startDate) : "Unknown Date"} –
							${job.endDate ? formatDate(job.endDate) : "Present"}`}
            </p>
          </div>
        ) : (
          <EditJob />
        )}
      </div>
      <JobExperience
        experiencesToUpdate={experienceToUpdate}
        setExperiencesToUpdate={setExperienceToUpdate}
        projectsToDelete={projectsToDelete}
        setProjectsToDelete={setProjectsToDelete}
        editing={editing}
        jobId={job.itemId}
        open={true}
      />
      <PlusesMinuses
        plusesToUpdate={tempJobPluses}
        setPlusesToUpdate={setTempJobPluses}
        minusesToUpdate={tempJobMinuses}
        setMinusesToUpdate={setTempJobMinuses}
        editing={editing}
        open={false}
      />
      <Sentiments
        sentimentsToUpdate={sentimentsToUpdate}
        setSentimentsToUpdate={setSentimentsToUpdate}
        editing={editing}
        job={job}
        open={false}
      />
      <div className="job-summary-item__footer">
        {!editing ? (
          <>
            <Button
              label="Edit Job"
              buttonTitle="Edit this job"
              buttonType="icon-only"
              size="small"
              leadingIcon={IconEditSVG}
              disabled={!subscriptionActive}
              onClick={() => setEditing(true)}
            />
            <div className="up-down-commands">
              <Button
                label="Move Down"
                buttonTitle="Move Job Down"
                buttonType="icon-only"
                size="small"
                leadingIcon={IconArrowDownSVG}
                onClick={() => moveJobDown()}
              />
              <Button
                label="Move Up"
                buttonTitle="Move Job Up"
                buttonType="icon-only"
                size="small"
                leadingIcon={IconArrowUpSVG}
                onClick={() => moveJobUp()}
              />
            </div>
          </>
        ) : (
          <>
            <Button
              label="Delete"
              buttonTitle="Delete Experience"
              onClick={() => setIsJobDeleteConfirmationOpened(true)}
              buttonType="utility"
              leadingIcon={IconMinusSVG}
              theme="destructive"
            />
            <Dialog
              isOpened={isJobDeleteConfirmationOpened}
              setIsOpened={setIsJobDeleteConfirmationOpened}
              primaryButtonType="primary"
              primaryButtonTitle="Confirm Job Deletion"
              primaryButtonLabel="Delete"
              primaryButtonFunction={() => {
                remove(index);
                setIsJobDeleteConfirmationOpened(false);
                document.body.classList.remove("dialog-open");
              }}
              primaryButtonClosesDialog={true}
              secondaryButtonType="utility"
              secondaryButtonTitle="Cancel Job Deletion"
              secondaryButtonLabel="Cancel"
            >
              <p className="type--body--large">
                Are you sure you want to delete <strong>{job.jobTitle}</strong>?
              </p>
            </Dialog>
            <div className="pattern--button-row">
              <Button
                buttonTitle="Cancel Edits"
                buttonType="utility"
                label="Cancel"
                onClick={() => {
                  if (newJob >= 0) {
                    remove(index);
                  }
                  setNewJob(-1);
                  resetTempValues();
                  setEditing(false);
                }}
              />
              <Button buttonTitle="Submit" buttonType="primary" label="Submit" onClick={handleSubmit} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
