import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Formik, Form, FormikHelpers } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button, ErrorBanner, Input } from "components/core";
import { CredentialsContainer, DataContainer } from "containers";
import { loginValidationSchema } from "utils/validations";
import "./authenticator.css";

interface Values {
	email: string;
	password: string;
}

export const Login: React.FC = () => {
	const navigate = useNavigate();
	const { setUser } = CredentialsContainer.useContainer();
	const { startAt } = DataContainer.useContainer();

	const initialValues = {
		email: "",
		password: "",
	};

	const location = useLocation();

	const locationHash = location.hash.substring(1);

	useEffect(() => {
		if (startAt !== "") {
			navigate(locationHash !== "" ? locationHash : startAt, { replace: true });
		}
	}, [startAt]);

	const [isErroring, setIsErroring] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	return (
		<div className="body-wrapper">
			<section className="pattern--container--flow pattern--container--flow-narrow">
				<h1 className="type--heading-1 pattern--container--flow__heading">
					Login
				</h1>
				<div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
					<Formik
						initialValues={initialValues}
						onSubmit={async (
							values: Values,
							{ setSubmitting }: FormikHelpers<Values>
						) => {
							try {
								const user = await Auth.signIn(values.email, values.password);
								setIsErroring(false);
								setUser(user);
								setSubmitting(false);
							} catch (error) {
								let message = "Unknown error";
								if (error instanceof Error) message = error.message;
								setErrorMessage(message);
								setIsErroring(true);
							}
						}}
						validationSchema={loginValidationSchema}
					>
						<Form className="pattern--login-signup-form">
							<Input label="Email Address" name="email" />
							<Input label="Password" name="password" type="password" />
							{isErroring && <ErrorBanner message={errorMessage} />}
							<Button label="Login" type="submit" />
							<div className="pattern--login-signup-form__supplements">
								<p className="type--body--standard">
									Forgot password? <Link to="/forgot">Reset Password</Link>
								</p>
								<p className="type--body--standard">
									Don&lsquo;t have an account? <Link to="/signup">Sign Up</Link>
								</p>
							</div>
						</Form>
					</Formik>
				</div>
			</section>
		</div>
	);
};
