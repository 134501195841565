/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from "react";
import { createContainer } from "unstated-next";
import { Amplify, API } from "aws-amplify";
import awsconfig from "aws-exports";
import { v4 as uuid } from "uuid";
import { isEqual, cloneDeep } from "lodash";
import {
	IBragSheet,
	ICatchUp,
	ICertification,
	ICompany,
	IEducation,
	IGoal,
	IKeyWord,
	IJob,
	IProject,
	IReview,
	ISchool,
	ISentiment,
	IWorkExperience,
} from "types";
import { getEmbedding } from "utils";
import { CredentialsContainer } from "containers/CredentialsContainer";

Amplify.configure(awsconfig);

const useDataContainer = () => {
	const apiName = "careerminder";

	const { user } = CredentialsContainer.useContainer();
	const [userDataLoaded, setUserDataLoaded] = useState<boolean>(false);
	const [bragSheets, setBragSheets] = useState<IBragSheet[]>([]);
	const [catchups, setCatchups] = useState<ICatchUp[]>([]);
	const [certifications, setCertifications] = useState<ICertification[]>([]);
	const [companies, setCompanies] = useState<ICompany[]>([]);
	const [schools, setSchools] = useState<ISchool[]>([]);
	const [currentSentiment, setCurrentSentiment] = useState<number>(1);
	const [userEmail, setUserEmail] = useState<string>("");
	const [fullName, setFullName] = useState<string>("");
	const [stripeSubscriptionID, setStripeSubscriptionID] = useState<string>("");
	// subscriptionActive is a simple boolean for catching whether or not the subscription is active or not
	const [subscriptionActive, setSubscriptionActive] = useState<boolean>(false);
	const [subscriptionCanceling, setSubscriptionCanceling] = useState<boolean>(
		false
	);
	// subscriptionStatus reflects stripe's suscription.status
	const [subscriptionStatus, setSubscriptionStatus] = useState<string>("");
	const [stripeCustomerID, setStripeCustomerID] = useState<string>(uuid());
	const [stripePrice, setStripePrice] = useState<number>();
	const [stripePlanInterval, setStripePlanInterval] = useState<string>("");
	const [stripePercentDiscount, setStripePercentDiscount] = useState<number>();
	const [
		stripeDiscountEndDateUnix,
		setStripeDiscountEndDateUnix,
	] = useState<string>("");
	const [goals, setGoals] = useState<IGoal[]>([]);
	const [jobTitle, setJobTitle] = useState<string>("");
	const [jobs, setJobs] = useState<IJob[]>([]);
	const [educations, setEducations] = useState<IEducation[]>([]);
	const [keyWords, setKeyWords] = useState<IKeyWord[]>([]);
	const [preferredName, setPreferredName] = useState<string>("");
	const [profileInactive, setProfileInactive] = useState<boolean>(false);
	const [profileItemId, setProfileItemId] = useState<string>("profile");
	const [projects, setProjects] = useState<IProject[]>([]);
	const [reminderEnabled, setReminderEnabled] = useState<boolean>(false);
	const [reminderDay, setReminderDay] = useState<string>("");
	const [reminderFrequency, setReminderFrequency] = useState<string>("");
	const [reminderViaPhone, setReminderViaPhone] = useState<boolean>(false);
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [reviews, setReviews] = useState<IReview[]>([]);
	const [recurringReviews, setRecurringReviews] = useState<string>("");
	const [sentiments, setSentiments] = useState<ISentiment[]>([]);
	const [startAt, setStartAt] = useState<string>("");
	const [submitKeyWords, setSubmitKeyWords] = useState<boolean>(false);
	const [submitProfile, setSubmitProfile] = useState<boolean>(false);
	const [termsAgreementDate, setTermsAgreementDate] = useState<string>("");
	const [workExperiences, setWorkExperiences] = useState<IWorkExperience[]>([]);

	// Use a ref to store the previous state of bragSheets
	const prevBragSheetsRef = useRef<IBragSheet[]>([]);
	// Use a ref to store the previous state of catchUps
	const prevCatchUpsRef = useRef<ICatchUp[]>([]);
	// Use a ref to store the previous state of certifications
	const prevCertificationsRef = useRef<ICertification[]>([]);
	// Use a ref to store the previous state of companies
	const prevCompaniesRef = useRef<ICompany[]>([]);
	// Use a ref to store the previous state of educations
	const prevEducationsRef = useRef<IEducation[]>([]);
	// Use a ref to store the previous state of goals
	const prevGoalsRef = useRef<IGoal[]>([]);
	// Use a ref to store the previous state of jobs
	const prevJobsRef = useRef<IJob[]>([]);
	// Use a ref to store the previous state of projects
	const prevProjectsRef = useRef<IProject[]>([]);
	// Use a ref to store the previous state of reviews
	const prevReviewsRef = useRef<IReview[]>([]);
	// Use a ref to store the previous state of goals
	const prevSchoolsRef = useRef<ISchool[]>([]);
	// Use a ref to store the previous state of sentiments
	const prevSentimentsRef = useRef<ISentiment[]>([]);
	// Use a ref to store the previous state of WorkExperiences
	const prevWorkExperiencesRef = useRef<IWorkExperience[]>([]);

	class EmptyBragSheet {
		customerId: string;
		itemId: string;
		bragSheetName: string;
		reference: string;
		referenceVector: string;
		content: string;
		inactive: boolean;
		dateCreated: string;
		keyWords: string[];
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "bragsheet#" + uuid();
			this.bragSheetName = "";
			this.reference = "";
			this.referenceVector = "";
			this.content = "";
			this.dateCreated = new Date().toUTCString();
			this.inactive = false;
			this.keyWords = [];
		}
	}
	class EmptyCatchup {
		customerId: string;
		itemId: string;
		dateCreated: string;
		description: string;
		experiences: string;
		inactive: boolean;
		name: string;
		sentiment: string;
		suggestions: string;
		summary: string;
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "catchup#" + uuid();
			this.dateCreated = new Date().toUTCString();
			this.description = "";
			this.experiences = "";
			this.inactive = false;
			this.name = "";
			this.sentiment = "";
			this.suggestions = "";
			this.summary = "";
			this.sortOrder = 0;
		}
	}
	class EmptyCertification {
		customerId: string;
		itemId: string;
		dateCreated: string;
		expirationDate: string;
		certificationIssuer: string;
		certificationName: string;
		verificationLink: string;
		inactive: boolean;
		keyWords: string[];
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "certification#" + uuid();
			this.dateCreated = new Date().toUTCString();
			this.expirationDate = "";
			this.certificationIssuer = "";
			this.certificationName = "";
			this.verificationLink = "";
			this.inactive = false;
			this.keyWords = [];
			this.sortOrder = 0;
		}
	}
	class EmptyCompany {
		customerId: string;
		itemId: string;
		companyName: string;
		dateCreated: string;
		inactive: boolean;
		keyWords: string[];
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "company#" + uuid();
			this.companyName = "";
			this.dateCreated = new Date().toUTCString();
			this.inactive = false;
			this.keyWords = [];
			this.sortOrder = 0;
		}
	}
	class EmptyEducation {
		customerId: string;
		itemId: string;
		schoolId: string;
		dateCreated: string;
		completionDate: string;
		degree: string;
		description: string;
		inactive: boolean;
		keyWords: string[];
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "educations#" + uuid();
			this.schoolId = "";
			this.dateCreated = new Date().toUTCString();
			this.completionDate = "";
			this.degree = "";
			this.description = "";
			this.inactive = false;
			this.keyWords = [];
			this.sortOrder = 0;
		}
	}
	class EmptyGoal {
		customerId: string;
		itemId: string;
		dateCreated: string;
		completionDate: string;
		details: string;
		goal: string;
		inactive: boolean;
		keyWords: string[];
		vector: string;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "goal#" + uuid();
			this.dateCreated = new Date().toUTCString();
			this.completionDate = "";
			this.details = "";
			this.goal = "";
			this.inactive = false;
			this.keyWords = [];
			this.vector = "";
		}
	}
	class EmptyJob {
		customerId: string;
		itemId: string;
		companyId: string;
		dateCreated: string;
		endDate: string;
		jobTitle: string;
		inactive: boolean;
		keyWords: string[];
		minuses?: string;
		pluses?: string;
		startDate: string;
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "job#" + uuid();
			this.companyId = "";
			this.dateCreated = new Date().toUTCString();
			this.endDate = "";
			this.inactive = false;
			this.jobTitle = "";
			this.keyWords = [];
			this.startDate = "";
			this.sortOrder = 0;
		}
	}
	class EmptyProject {
		customerId: string;
		itemId: string;
		dateCreated: string;
		inactive: boolean;
		jobId: string;
		keyWords: never[];
		projectDate: string;
		projectName: string;
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "project#" + uuid();
			this.dateCreated = new Date().toUTCString();
			this.inactive = false;
			this.jobId = "";
			this.keyWords = [];
			this.projectDate = "";
			this.projectName = "";
			this.sortOrder = 0;
		}
	}
	class EmptyReview {
		customerId: string;
		dateCreated: string;
		duration: "quarterly" | "semi-annual" | "annual" | "custom" | "all-time";
		inactive: boolean;
		itemId: string;
		jobInterview?: boolean;
		name: string;
		nextKeyDate: string;
		questions: string;
		recurring?: boolean;
		recurringReviewCreated: boolean;
		relatedContent?: string;
		relatedContentVector?: string;
		relatedJobs: string;
		startDate?: string;
		suggestions: string;
		type: "time-bound" | "overall";
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.dateCreated = new Date().toUTCString();
			this.duration = "quarterly";
			this.inactive = false;
			this.itemId = "review#" + uuid();
			this.name = "";
			this.nextKeyDate = "";
			this.questions = "";
			this.recurringReviewCreated = false;
			this.relatedJobs = "";
			this.suggestions = "";
			this.type = "time-bound";
		}
	}
	class EmptySchool {
		customerId: string;
		itemId: string;
		schoolName: string;
		dateCreated: string;
		inactive: boolean;
		keyWords: never[];
		sortOrder: number;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "school#" + uuid();
			this.schoolName = "";
			this.dateCreated = new Date().toUTCString();
			this.inactive = false;
			this.keyWords = [];
			this.sortOrder = 0;
		}
	}
	class EmptySentiment {
		customerId: string;
		itemId: string;
		dateCreated: string;
		feeling: number;
		jobId?: string;
		inactive: boolean;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "sentiment#" + uuid();
			this.dateCreated = new Date().toUTCString();
			this.feeling = 50;
			this.inactive = false;
		}
	}
	class EmptyWorkExperience {
		customerId: string;
		itemId: string;
		dateCreated: string;
		completionDate: string;
		description: string;
		goalId: string;
		inactive: boolean;
		job: string;
		jobId: string;
		keyWords: string[];
		project: string;
		projectId: string;
		vector: string;
		constructor(user: any) {
			this.customerId = user.attributes.sub;
			this.itemId = "workexperience#" + uuid();
			this.dateCreated = new Date().toUTCString();
			this.completionDate = "";
			this.description = "";
			this.goalId = "";
			this.inactive = false;
			this.job = "";
			this.jobId = "";
			this.keyWords = [];
			this.project = "";
			this.projectId = "";
			this.vector = "";
		}
	}

	const updateVectors = async () => {
		if (
			process.env.REACT_APP_USER_BRANCH !== "master" ||
			(process.env.REACT_APP_USER_BRANCH === "master" &&
				userEmail === "releasetest@careerminder.io")
		) {
			console.log("Checking vectors:", workExperiences, goals);
		}
		try {
			let workExperiencesUpdated = false;
			let workExperienceVectorUpdateCount = 0;
			const updatedWorkExperiences: IWorkExperience[] = [];
			await Promise.all(
				workExperiences.map(async (experience: IWorkExperience) => {
					if (!experience.vector || experience.vector === "") {
						await getEmbedding(experience.description).then((value) => {
							const vector: string = value;
							if (Array.isArray(JSON.parse(vector))) {
								experience.vector = vector;
							} else {
								experience.vector = "";
							}
						});
						updatedWorkExperiences.push(experience);
						workExperiencesUpdated = true;
						workExperienceVectorUpdateCount =
							workExperienceVectorUpdateCount + 1;
					} else {
						updatedWorkExperiences.push(experience);
					}
				})
			);
			if (workExperiencesUpdated) {
				setWorkExperiences(updatedWorkExperiences);
				if (
					process.env.REACT_APP_USER_BRANCH !== "master" ||
					(process.env.REACT_APP_USER_BRANCH === "master" &&
						userEmail === "releasetest@careerminder.io")
				) {
					console.log(
						"Experience vector updates",
						workExperienceVectorUpdateCount,
						workExperiences
					);
				}
			} else {
				if (
					process.env.REACT_APP_USER_BRANCH !== "master" ||
					(process.env.REACT_APP_USER_BRANCH === "master" &&
						userEmail === "releasetest@careerminder.io")
				) {
					console.log("No experience vector updates", workExperiences);
				}
			}
			let goalsUpdated = false;
			let goalsVectorUpdateCount = 0;
			const updatedGoals: IGoal[] = [];
			await Promise.all(
				goals.map(async (goal: IGoal) => {
					if (!goal.vector || goal.vector === "") {
						await getEmbedding(goal.details).then((value) => {
							const vector: string = value;
							if (Array.isArray(JSON.parse(vector))) {
								goal.vector = vector;
							} else {
								goal.vector = "";
							}
						});
						updatedGoals.push(goal);
						goalsUpdated = true;
						goalsVectorUpdateCount = goalsVectorUpdateCount + 1;
					} else {
						updatedGoals.push(goal);
					}
				})
			);
			if (goalsUpdated) {
				setGoals(updatedGoals);
				if (
					process.env.REACT_APP_USER_BRANCH !== "master" ||
					(process.env.REACT_APP_USER_BRANCH === "master" &&
						userEmail === "releasetest@careerminder.io")
				) {
					console.log("Goal vector updates", goalsVectorUpdateCount, goals);
				}
			} else {
				if (
					process.env.REACT_APP_USER_BRANCH !== "master" ||
					(process.env.REACT_APP_USER_BRANCH === "master" &&
						userEmail === "releasetest@careerminder.io")
				) {
					console.log("No goal vector updates", goals);
				}
			}
		} catch (err) {
			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log(`Error updating vectors ${user.attributes.sub} :>> `, err);
			}
		}
	};

	const fetchAllData = async (apiName: string, path: string) => {
		let allData: any = [];
		let paginationToken = null;
		do {
			const queryParams: any = paginationToken
				? { queryStringParameters: { paginationToken } }
				: {};

			const response = await API.get(apiName, path, queryParams);
			allData = allData.concat(response.items);

			paginationToken = response.paginationToken || null;
		} while (paginationToken);
		return allData;
	};

	const getCurrentCustomer = async () => {
		try {
			const response = await fetchAllData(apiName, `/${user.attributes.sub}`);
			if (response.length > 0) {
				if (
					process.env.REACT_APP_USER_BRANCH !== "master" ||
					(process.env.REACT_APP_USER_BRANCH === "master" &&
						userEmail === "releasetest@careerminder.io")
				) {
					console.log("response", response);
				}

				//  set the profile information
				const profile = response.filter((item: any) =>
					item.itemId.includes("profile")
				);
				setUserEmail(profile[0].email || user.attributes.email);
				setFullName(profile[0].fullName || "");
				setJobTitle(profile[0].jobTitle || "");
				setPreferredName(profile[0].preferredName || "");
				setCurrentSentiment(profile[0].currentSentiment || "");
				setRecurringReviews(profile[0].recurringReviews || "");
				setReminderEnabled(profile[0].reminderEnabled || false);
				setReminderDay(profile[0].reminderDay || "");
				setReminderFrequency(profile[0].reminderFrequency || "");
				setReminderViaPhone(profile[0].reminderViaPhone || false);
				setPhoneNumber(profile[0].phoneNumber || "");
				setStripeSubscriptionID(profile[0].stripeSubscriptionID || "");
				setSubscriptionActive(profile[0].subscriptionActive || false);
				setSubscriptionCanceling(profile[0].subscriptionCanceling || false);
				setSubscriptionStatus(profile[0].subscriptionStatus || "");
				setStripeCustomerID(profile[0].stripeCustomerID || "");
				setStripePrice(profile[0].stripePrice || 0);
				setStripePlanInterval(profile[0].stripePlanInterval || "");
				setStripePercentDiscount(profile[0].stripePercentDiscount || 0);
				setStripeDiscountEndDateUnix(
					profile[0].stripeDiscountEndDateUnix || ""
				);
				setTermsAgreementDate(profile[0].termsAgreementDate || "");

				// set everything else
				setBragSheets(
					response.filter((item: any) => item.itemId.includes("bragsheet"))
				);
				setCatchups(
					response.filter((item: any) => item.itemId.includes("catchup"))
				);
				setCertifications(
					response.filter((item: any) => item.itemId.includes("certification"))
				);
				setCompanies(
					response.filter((item: any) => item.itemId.includes("company"))
				);
				setEducations(
					response.filter((item: any) => item.itemId.includes("educations"))
				);
				setGoals(response.filter((item: any) => item.itemId.includes("goal")));
				setKeyWords(
					response.filter((item: any) => item.itemId.includes("keyword"))
				);
				setJobs(response.filter((item: any) => item.itemId.includes("job")));
				setProjects(
					response.filter((item: any) => item.itemId.includes("project"))
				);
				setReviews(
					response.filter((item: any) => item.itemId.includes("review"))
				);
				setSentiments(
					response.filter((item: any) => item.itemId.includes("sentiment"))
				);
				setSchools(
					response.filter((item: any) => item.itemId.includes("school"))
				);
				setWorkExperiences(
					response.filter((item: any) => item.itemId.includes("workexperience"))
				);
				setStartAt("/");
			} else {
				// Removed onboarding flow, could come back later
				// setStartAt("/onboarding");
				setStartAt("/terms-of-service");
			}
			setUserDataLoaded(true);
		} catch (err) {
			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log(
					`Error getting customer data for ${user.attributes.sub} :>> `,
					err
				);
			}
		}
	};

	const waitFor = (conditionFunction: () => boolean): Promise<void> => {
		const poll = (resolve: () => void): void => {
			if (conditionFunction()) {
				resolve();
			} else {
				setTimeout(() => poll(resolve), 1000);
			}
		};
		return new Promise<void>(poll);
	};

	const getSubscriptionInfo = async () => {
		try {
			await waitFor(() => userDataLoaded === true && user.attributes !== null);
			const profile = await API.get(
				apiName,
				`/items/${user.attributes.sub}/profile`,
				{}
			);
			setStripeSubscriptionID(profile[0].stripeSubscriptionID);
			setSubscriptionActive(profile[0].subscriptionActive);
			setSubscriptionCanceling(profile[0].subscriptionCanceling);
			setSubscriptionStatus(profile[0].subscriptionStatus);
			setStripeCustomerID(profile[0].stripeCustomerID);
			setStripePrice(profile[0].stripePrice);
			setStripePlanInterval(profile[0].stripePlanInterval);
			setStripePercentDiscount(profile[0].stripePercentDiscount);
			setStripeDiscountEndDateUnix(profile[0].stripeDiscountEndDateUnix);
		} catch (err) {
			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log(
					`Error getting profile data for ${user.attributes.sub}:>> `,
					err
				);
			}
		}
	};

	const inactivateItem = async (itemId: string) => {
		try {
			return await API.put(
				apiName,
				`/items/${user.attributes.sub}/${encodeURIComponent(itemId)}`,
				{
					body: { inactive: true },
				}
			);
		} catch (err) {
			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log("Error putting customer data :>> ", err);
			}
		}
	};

	const updateItem = async (item: unknown, whereFrom?: string) => {
		try {
			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log("item trying to put", item, whereFrom && whereFrom);
			}
			return await API.put(apiName, "/items", { body: item });
		} catch (err) {
			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log("Error putting customer data :>> ", err);
			}
		}
	};

	useEffect(() => {
		if (user) {
			getCurrentCustomer();
		} else {
			setStartAt("");
		}
	}, [user]);

	// Function to check if an item has changed or is new
	const hasItemChanged = (
		currentItem:
			| IBragSheet
			| ICatchUp
			| ICertification
			| ICompany
			| IEducation
			| IGoal
			| IJob
			| IProject
			| IReview
			| ISchool
			| ISentiment
			| IWorkExperience,
		reference: any
	) => {
		const prevItem = reference.current.find(
			(
				item:
					| IBragSheet
					| ICatchUp
					| ICertification
					| ICompany
					| IEducation
					| IGoal
					| IJob
					| IProject
					| IReview
					| ISchool
					| ISentiment
					| IWorkExperience
			) => item.itemId === currentItem.itemId
		);
		// Consider it changed if it’s new or the description has changed
		return !prevItem || !isEqual(prevItem, currentItem);
	};

	useEffect(() => {
		if (userDataLoaded) {
			bragSheets.forEach((bragSheet) => {
				if (hasItemChanged(bragSheet, prevBragSheetsRef)) {
					updateItem(bragSheet, "bragSheets useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = bragSheets.map((item) => item.itemId);
			prevBragSheetsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevBragSheetsRef.current = cloneDeep(bragSheets);
		}
	}, [bragSheets]);

	useEffect(() => {
		if (userDataLoaded) {
			catchups.forEach((catchUp) => {
				if (hasItemChanged(catchUp, prevCatchUpsRef)) {
					updateItem(catchUp, "catchups useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = catchups.map((item) => item.itemId);
			prevCatchUpsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevCatchUpsRef.current = cloneDeep(catchups);
		}
	}, [catchups]);

	useEffect(() => {
		if (userDataLoaded) {
			certifications.forEach((certification) => {
				if (hasItemChanged(certification, prevCertificationsRef)) {
					updateItem(certification, "certification useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = certifications.map((item) => item.itemId);
			prevCertificationsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevCertificationsRef.current = cloneDeep(certifications);
		}
	}, [certifications]);

	useEffect(() => {
		if (userDataLoaded) {
			companies.forEach((company) => {
				if (hasItemChanged(company, prevCompaniesRef)) {
					updateItem(company, "company useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = companies.map((item) => item.itemId);
			prevCompaniesRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevCompaniesRef.current = cloneDeep(companies);
		}
	}, [companies]);

	useEffect(() => {
		if (userDataLoaded) {
			console.log("educations", educations);
			educations.forEach((education) => {
				if (hasItemChanged(education, prevCompaniesRef)) {
					updateItem(education, "education useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = educations.map((item) => item.itemId);
			prevEducationsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevEducationsRef.current = cloneDeep(educations);
		}
	}, [educations]);

	useEffect(() => {
		if (userDataLoaded) {
			console.log("schools", schools);
			schools.forEach((school) => {
				if (hasItemChanged(school, prevCompaniesRef)) {
					updateItem(school, "school useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = schools.map((item) => item.itemId);
			prevSchoolsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevSchoolsRef.current = cloneDeep(schools);
		}
	}, [schools]);

	useEffect(() => {
		if (userDataLoaded) {
			sentiments.forEach((sentiment) => {
				if (hasItemChanged(sentiment, prevSentimentsRef)) {
					updateItem(sentiment, "sentiments useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = sentiments.map((item) => item.itemId);
			prevSentimentsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevSentimentsRef.current = cloneDeep(sentiments);
		}
	}, [sentiments]);

	useEffect(() => {
		if (userDataLoaded) {
			goals.forEach((goal) => {
				if (goal.goal !== "") {
					if (hasItemChanged(goal, prevGoalsRef)) {
						updateItem(goal, "goals useEffect()");
					}
				} else {
					inactivateItem(goal.itemId);
				}
			});

			// Check for deleted items
			const currentIds = goals.map((item) => item.itemId);
			prevGoalsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);

					// Remove references from workExperiences
					const updatedWorkExperiences: IWorkExperience[] = [];
					workExperiences.forEach(
						(experience: IWorkExperience, index: number) => {
							const updatedExperience: IWorkExperience = experience;
							if (!currentIds.includes(experience.goalId)) {
								updatedExperience.goalId = "";
								updatedWorkExperiences.splice(index, 1, updatedExperience);
							}
						}
					);
					setWorkExperiences(updatedWorkExperiences);
				}
			});

			// Update the ref with the current state for the next render
			prevGoalsRef.current = cloneDeep(goals);
		}
	}, [goals]);

	useEffect(() => {
		if (userDataLoaded) {
			jobs.forEach((job) => {
				if (hasItemChanged(job, prevJobsRef)) {
					updateItem(job, "jobs useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = jobs.map((item) => item.itemId);
			prevJobsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);

					// Remove references from workExperiences
					const updatedWorkExperiences: IWorkExperience[] = [
						...workExperiences,
					];
					workExperiences.forEach(
						(experience: IWorkExperience, index: number) => {
							const updatedExperience: IWorkExperience = experience;
							if (!currentIds.includes(experience.jobId)) {
								updatedExperience.jobId = "";
								updatedWorkExperiences.splice(index, 1, updatedExperience);
							}
						}
					);
					setWorkExperiences(updatedWorkExperiences);
				}
			});

			// Update the ref with the current state for the next render
			prevJobsRef.current = cloneDeep(jobs);
		}
	}, [jobs]);

	useEffect(() => {
		if (submitKeyWords) {
			keyWords.forEach((keyWord) => updateItem(keyWord));
			setSubmitKeyWords(false);
		}
	}, [submitKeyWords]);

	useEffect(() => {
		if (submitProfile) {
			updateItem({
				customerId: user.attributes.sub,
				itemId: profileItemId,
				currentSentiment,
				email: userEmail || user.attributes.email,
				fullName,
				inactive: profileInactive,
				jobTitle,
				preferredName,
				recurringReviews,
				reminderEnabled,
				reminderDay,
				reminderFrequency,
				reminderViaPhone,
				phoneNumber,
				stripeSubscriptionID,
				subscriptionActive,
				subscriptionCanceling,
				subscriptionStatus,
				stripeCustomerID,
				stripePrice,
				stripePlanInterval,
				stripePercentDiscount,
				stripeDiscountEndDateUnix,
				termsAgreementDate,
			});
			setSubmitProfile(false);
		}
	}, [submitProfile]);

	useEffect(() => {
		if (userDataLoaded) {
			console.log("projects", projects);
			projects.forEach((project) => {
				if (hasItemChanged(project, prevProjectsRef)) {
					updateItem(project, "projects useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = projects.map((item) => item.itemId);
			prevProjectsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);

					// Remove references from workExperiences
					const updatedWorkExperiences: IWorkExperience[] = [
						...workExperiences,
					];
					workExperiences.forEach(
						(experience: IWorkExperience, index: number) => {
							const updatedExperience: IWorkExperience = experience;
							if (!currentIds.includes(experience.projectId)) {
								updatedExperience.projectId = "";
								updatedWorkExperiences.splice(index, 1, updatedExperience);
							}
						}
					);
					setWorkExperiences(updatedWorkExperiences);
				}
			});

			// Update the ref with the current state for the next render
			prevProjectsRef.current = cloneDeep(projects);
		}
	}, [projects]);

	useEffect(() => {
		if (userDataLoaded) {
			reviews.forEach((review) => {
				if (hasItemChanged(review, prevReviewsRef)) {
					updateItem(review, "reviews useEffect()");
				}
			});

			// Check for deleted items
			const currentIds = reviews.map((item) => item.itemId);
			prevReviewsRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					inactivateItem(prevItem.itemId);
				}
			});

			// Update the ref with the current state for the next render
			prevReviewsRef.current = cloneDeep(reviews);
		}
	}, [reviews]);

	useEffect(() => {
		if (userDataLoaded) {
			workExperiences.forEach((workExperience) => {
				if (workExperience.description !== "") {
					if (hasItemChanged(workExperience, prevWorkExperiencesRef)) {
						console.log("updating workExperience", workExperience);
						updateItem(workExperience, "workExperiences useEffect()");
					}
				} else {
					inactivateItem(workExperience.itemId);
				}
			});

			// Check for deleted items
			const currentIds = workExperiences.map((item) => item.itemId);
			prevWorkExperiencesRef.current.forEach((prevItem) => {
				if (!currentIds.includes(prevItem.itemId)) {
					// Item has been deleted, so inactivate it in the backend
					const deletedItem = workExperiences.find(
						(item) => item.itemId === prevItem.itemId
					);
					if (!deletedItem || deletedItem.description !== "") {
						console.log("inactivating workExperience", prevItem);
						inactivateItem(prevItem.itemId);
					}
				}
			});

			// Update the ref with the current state for the next render
			prevWorkExperiencesRef.current = cloneDeep(workExperiences);
		}
	}, [workExperiences]);

	let updatedVector = false;
	// Ref to track if the initial synchronization of the ref has been done
	const initialSyncDoneRef = useRef(false);

	useEffect(() => {
		if (userDataLoaded && !initialSyncDoneRef.current) {
			if ((workExperiences.length || goals.length) && !updatedVector) {
				updateVectors();
			}
			updatedVector = true;

			prevBragSheetsRef.current = bragSheets;
			prevCatchUpsRef.current = catchups;
			prevCertificationsRef.current = certifications;
			prevCompaniesRef.current = companies;
			prevEducationsRef.current = educations;
			prevGoalsRef.current = goals;
			prevJobsRef.current = jobs;
			prevProjectsRef.current = projects;
			prevReviewsRef.current = reviews;
			prevSchoolsRef.current = schools;
			prevSentimentsRef.current = sentiments;
			prevWorkExperiencesRef.current = workExperiences;

			if (
				process.env.REACT_APP_USER_BRANCH !== "master" ||
				(process.env.REACT_APP_USER_BRANCH === "master" &&
					userEmail === "releasetest@careerminder.io")
			) {
				console.log(
					`Customer data for ${user.attributes.sub}:>> `,
					process.env.REACT_APP_USER_BRANCH,
					user.attributes.sub,
					userEmail,
					stripeSubscriptionID,
					subscriptionActive,
					subscriptionCanceling,
					subscriptionStatus,
					workExperiences,
					goals
				);
			}

			initialSyncDoneRef.current = true;
		}
	}, [
		userDataLoaded,
		bragSheets,
		catchups,
		certifications,
		companies,
		educations,
		goals,
		projects,
		reviews,
		schools,
		sentiments,
		workExperiences,
	]);

	return {
		bragSheets,
		catchups,
		certifications,
		companies,
		currentSentiment,
		educations,
		fullName,
		userEmail,
		goals,
		jobTitle,
		jobs,
		preferredName,
		profileInactive,
		profileItemId,
		projects,
		recurringReviews,
		reviews,
		reminderEnabled,
		reminderDay,
		reminderFrequency,
		reminderViaPhone,
		phoneNumber,
		schools,
		stripeSubscriptionID,
		subscriptionActive,
		subscriptionCanceling,
		subscriptionStatus,
		stripeCustomerID,
		stripePrice,
		stripePlanInterval,
		stripePercentDiscount,
		stripeDiscountEndDateUnix,
		termsAgreementDate,
		user,
		userDataLoaded,
		sentiments,
		startAt,
		workExperiences,
		EmptyBragSheet,
		EmptyCatchup,
		EmptyCertification,
		EmptyCompany,
		EmptyEducation,
		EmptyGoal,
		EmptyJob,
		EmptyProject,
		EmptyReview,
		EmptySchool,
		EmptySentiment,
		EmptyWorkExperience,
		getSubscriptionInfo,
		inactivateItem,
		setBragSheets,
		setCatchups,
		setCertifications,
		setCompanies,
		setCurrentSentiment,
		setEducations,
		setFullName,
		setUserEmail,
		setGoals,
		setJobTitle,
		setJobs,
		setKeyWords,
		setPreferredName,
		setProfileInactive,
		setProfileItemId,
		setProjects,
		setRecurringReviews,
		setReminderEnabled,
		setReminderDay,
		setReminderFrequency,
		setReminderViaPhone,
		setReviews,
		setPhoneNumber,
		setSchools,
		setStripeSubscriptionID,
		setSubscriptionActive,
		setSubscriptionCanceling,
		setSubscriptionStatus,
		setStripeCustomerID,
		setStripePrice,
		setStripePlanInterval,
		setStripePercentDiscount,
		setStripeDiscountEndDateUnix,
		setSentiments,
		setTermsAgreementDate,
		setSubmitKeyWords,
		submitProfile,
		setSubmitProfile,
		setWorkExperiences,
		setUserDataLoaded,
		updateItem,
	};
};

export const DataContainer = createContainer(useDataContainer);
