import React from "react";
import "./toggle.css";

export interface ToggleProps {
	label: string;
	value: string;
	name: string;
	checked?: boolean;
	onClick: () => void;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
	
	return (
		<label className="toggle-wrapper">
			<div className={`toggle-positioning-wrapper ${props.checked && "toggle-positioning-wrapper--checked"}`}>
				<input 
					type="radio" 
					name={props.name} 
					value={props.value} 
					checked={props.checked} 
					onClick={props.onClick} 
				/>
			</div>
			<span className="toggle-label type--body--standard">{props.label}</span>
		</label>	
	);
};