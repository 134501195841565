import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Formik, Form, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, ErrorBanner, Input } from "components/core";
import { confirmPasswordResetValidationSchema } from "utils/validations";
import "./authenticator.css";

interface Values {
	confirmPassword: string;
	confirmationCode: string;
	email: string;
	password: string;
}

export const ForgotConfirmation: React.FC = () => {
	const navigate = useNavigate();

	const initialValues = {
		confirmPassword: "",
		confirmationCode: "",
		email: "",
		password: "",
	};

	const [isErroring, setIsErroring] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const resetPassword = async (
		email: string,
		confirmationCode: string,
		password: string
	) => {
		try {
			await Auth.forgotPasswordSubmit(email, confirmationCode, password);
			setIsErroring(false);
			navigate("/passwordReset", { replace: true });
		} catch (error) {
			let message = "Unknown error";
			if (error instanceof Error) message = error.message;
			setErrorMessage(message);
			setIsErroring(true);
			console.log("error resetting password", error);
		}
	};

	return (
		<div className="body-wrapper">
			<section className="pattern--container--flow pattern--container--flow-narrow">
				<h1 className="type--heading-1 pattern--container--flow__heading">
					Confirm Password Reset
				</h1>
				<div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
					<Formik
						initialValues={initialValues}
						isValid
						onSubmit={async (
							values: Values,
							{ setSubmitting }: FormikHelpers<Values>
						) => {
							try {
								resetPassword(
									values.email,
									values.confirmationCode,
									values.password
								);
								setSubmitting(false);
							} catch (error) {
								console.log("error signing in", error);
							}
						}}
						validationSchema={confirmPasswordResetValidationSchema}
					>
						{({ dirty, isValid }) => (
							<Form className="pattern--login-signup-form">
								<Input label="Email Address" name="email" />
								<Input label="Confimation Code" name="confirmationCode" />
								<Input label="Password" name="password" type="password" />
								<Input
									label="Confirm Password"
									name="confirmPassword"
									type="password"
								/>
								{isErroring && <ErrorBanner message={errorMessage} />}
								<Button
									disabled={!(isValid && dirty)}
									label="Reset Password"
									type="submit"
								/>
								<div className="pattern--login-signup-form__supplements">
									<p className="type--body--standard">
										Forgot password? Reset Password
									</p>
									<p className="type--body--standard">
										Have an account already? Login
									</p>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</section>
		</div>
	);
};
