import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { filter } from "lodash";
import { CreatableSelect, DatePicker, Input, Button, Dialog } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { ISelectDropDown, IEducation, ISchool } from "types";
import { getYear, educationSummaryValidationSchema } from "utils";
import { ReactComponent as IconMinus } from "../../core/assets/img/minus.svg";
import { ReactComponent as IconArrowDown } from "../../core/assets/img/arrow-pointing-down.svg";
import { ReactComponent as IconArrowUp } from "../../core/assets/img/arrow-pointing-up.svg";
import "./education.css";

interface Values {
  education: IEducation;
}
interface EducationProps {
  autoFocus?: boolean;
  inactivateItem: (itemId: string) => void;
  index: number;
  newEducation: number;
  setNewEducation: (index: number) => void;
  education: IEducation;
  onDashboard?: boolean;
}

export const Education: React.FC<EducationProps> = (props) => {
  const { inactivateItem, index, newEducation, setNewEducation, education, onDashboard } = props;

  const { educations, schools, user, EmptySchool, setSchools, setEducations, subscriptionActive } =
    DataContainer.useContainer();
  const [editing, setEditing] = useState<boolean>(index === newEducation);
  const [isEducationDeleteConfirmationOpened, setIsEducationDeleteConfirmationOpened] = useState(false);

  const initialValues = {
    education,
  };

  const schoolItems: ISelectDropDown[] = schools.map((school: ISchool) => {
    return { value: school.itemId, label: school.schoolName };
  });

  const createSchool = (school: string): string => {
    const newSchool: ISchool = new EmptySchool(user);
    newSchool.schoolName = school;
    const updatedSchools = [...schools, newSchool];
    setSchools(updatedSchools);
    return newSchool.itemId;
  };

  const moveEducationDown = () => {
    const updatedEducations = [...educations];
    if (index !== -1 && index < updatedEducations.length - 1) {
      const el = updatedEducations[index];
      updatedEducations[index] = updatedEducations[index + 1];
      updatedEducations[index + 1] = el;
      updatedEducations.forEach(function (_part: IEducation, index, updatedEducations) {
        updatedEducations[index].sortOrder = index;
      });
      setEducations(updatedEducations);
    }
  };

  const moveEducationUp = () => {
    const updatedEducations = [...educations];
    if (index > 0) {
      const el = updatedEducations[index];
      updatedEducations[index] = updatedEducations[index - 1];
      updatedEducations[index - 1] = el;
      updatedEducations.forEach(function (part: IEducation, index, updatedEducations) {
        updatedEducations[index].sortOrder = index;
      });
      setEducations(updatedEducations);
    }
  };

  const remove = (index: number): void => {
    const updatedEducations = [...educations];
    updatedEducations.splice(index, 1);
    setEducations(updatedEducations);
  };

  const IconMinusSVG = <IconMinus />;
  const IconArrowDownSVG = <IconArrowDown />;
  const IconArrowUpSVG = <IconArrowUp />;

  const DisplayEducation = () => {
    let schoolName = "";
    if (education.schoolId) {
      schoolName = filter(schoolItems, ["value", education.schoolId])[0].label;
    }
    return (
      <>
        <div className="education-summary-item__header">
          <div className="education-header-content">
            <h2 className="type--heading-6 education-header-content__school">{schoolName}</h2>
            <h3 className="type--body--large">{education.degree}</h3>
            <p className="type--body--standard education-header-content__date">
              {`${getYear(education.completionDate)}`}
            </p>
          </div>
        </div>
        {education.description && (
          <div className="education-summary-item__content">
            <p className="type--body--standard">{education.description}</p>
          </div>
        )}
        <div className="education-summary-item__footer">
          <Button
            label="Edit"
            buttonTitle="Edit this education"
            buttonType="utility"
            size="small"
            disabled={!subscriptionActive}
            onClick={() => setEditing(true)}
          />
          <div className="up-down-commands">
            <Button
              label="Move Down"
              buttonTitle="Move Education Down"
              buttonType="icon-only"
              size="small"
              disabled={!subscriptionActive}
              leadingIcon={IconArrowDownSVG}
              onClick={() => moveEducationDown()}
            />
            <Button
              label="Move Up"
              buttonTitle="Move Education Up"
              buttonType="icon-only"
              size="small"
              disabled={!subscriptionActive}
              leadingIcon={IconArrowUpSVG}
              onClick={() => moveEducationUp()}
            />
          </div>
        </div>
      </>
    );
  };

  const EditEducation = () => {
    const educationValue: ISelectDropDown = filter(schoolItems, ["value", education.schoolId])[0];
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
          // If is a new company, the companyId will be new#company name. So need to
          // parse out the company name and create and push new company item.
          if (values.education.schoolId.startsWith("new#")) {
            values.education.schoolId = createSchool(values.education.schoolId.substring(4));
          }
          const updatedEducation = [...educations];
          updatedEducation.splice(index, 1, values.education);
          setEducations(updatedEducation);
          setSubmitting(false);
          setEditing(false);
          setNewEducation(-1);
        }}
        validationSchema={educationSummaryValidationSchema}
      >
        <Form className="education-summary-item-form-wrapper">
          <Input label="Degree" name={`education.degree`} placeholder="What is the degree?" />
          <div className="field-wrapper pattern--field-wrapper">
            <label className="pattern--label type--heading-4">School</label>
            <CreatableSelect
              className="pattern-dropdown"
              classNamePrefix="pattern-dropdown"
              key={`we-school${index}`}
              name={`education.schoolId`}
              options={schoolItems}
              placeholder="Where did you study?"
              value={educationValue}
            />
          </div>
          <DatePicker
            key={`we-completionDate${index}`}
            name={`education.completionDate`}
            label="Completion Date"
          />
          <Input
            label="Description"
            name={`education.description`}
            placeholder="What would you like to say about your degree?"
          />
          <div className="pattern--button-row pattern--button-row--space-between">
            <Button
              label="Delete"
              buttonTitle="Delete Experience"
              onClick={() => setIsEducationDeleteConfirmationOpened(true)}
              buttonType="utility"
              leadingIcon={IconMinusSVG}
            />
            <Dialog
              isOpened={isEducationDeleteConfirmationOpened}
              setIsOpened={setIsEducationDeleteConfirmationOpened}
              primaryButtonType="primary"
              primaryButtonTitle="Confirm Education Deletion"
              primaryButtonLabel="Delete"
              primaryButtonFunction={() => {
                remove(index);
                setIsEducationDeleteConfirmationOpened(false);
                document.body.classList.remove("dialog-open");
              }}
              primaryButtonClosesDialog={true}
              secondaryButtonType="utility"
              secondaryButtonTitle="Cancel Education Deletion"
              secondaryButtonLabel="Cancel"
            >
              <p className="type--body--large">
                Are you sure you want to delete <strong>{education.degree}</strong>?
              </p>
            </Dialog>
            <div className="pattern--button-row">
              <Button
                buttonTitle="Cancel Edits"
                buttonType="utility"
                label="Cancel"
                onClick={() => {
                  if (newEducation >= 0) {
                    remove(index);
                  }
                  setNewEducation(-1);
                  setEditing(false);
                }}
              />
              <Button buttonTitle="Save Edits" buttonType="primary" label="Save" type="submit" />
            </div>
          </div>
        </Form>
      </Formik>
    );
  };

  return (
    <div
      className={`education-summary-item summary-item ${editing && "education-summary-item--edit"} ${onDashboard && "summary-item--on-dashboard"}`}
      data-item={index}
    >
      {editing ? <EditEducation /> : <DisplayEducation />}
    </div>
  );
};
