import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FormikHelpers, useFormikContext, FormikValues } from "formik";
import { Button, BackLink, Input, DatePicker, Checkbox, Textarea, Toggle } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as IllustrationOverallReview } from "../core/assets/img/overall-review-illustration.svg";
import { ReactComponent as IllustrationTimeBoundReview } from "../core/assets/img/time-bound-illustration.svg";
import { ReactComponent as IconDonutSpinner } from "../core/assets/img/donut-spinner.svg";
import { IReview, ISelectDropDown, IJob, ICompany, IWorkExperience, IScoredExperience } from "types";
import { reviewsValidationSchema } from "utils/validations";
import { getPastDate, formatDate } from "utils/dateUtils";
import {
  getCompletion,
  getEmbedding,
  prepInterviewQuestionsPayload,
  scoreExperienceWithinRange,
} from "utils";
import "./reviewAuthoring.css";

interface Values {
  review: IReview;
}

export interface ReviewAuthoringProps {
  something?: string;
  reviewId?: string;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewAuthoring: React.FC<ReviewAuthoringProps> = (props) => {
  const { reviewId = "" } = props;
  const { EmptyReview, companies, goals, jobs, reviews, setReviews, workExperiences, user, userDataLoaded } =
    DataContainer.useContainer();

  const navigate = useNavigate();

  const reviewLoaded = useRef<boolean>(false);

  const setUpReview = (): IReview => {
    if (userDataLoaded && !reviewLoaded.current) {
      if (reviewId === "") {
        return new EmptyReview(user);
      } else {
        return reviews.filter((item: IReview) => item.itemId === reviewId)[0];
      }
    }
    return new EmptyReview(user);
  };

  const review: IReview = setUpReview();

  const [isNewReview] = useState<boolean>(reviewId === "");
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [reviewType, setReviewType] = useState<"time-bound" | "overall">("time-bound");
  const [isJobInterview, setIsJobInterview] = useState<boolean>(false);
  const [jobsReady, setJobsReady] = useState<boolean>(false);

  const updateJobs = (jobId: string) => {
    if (selectedJobs.indexOf(jobId) !== -1) {
      const updatedJobs = selectedJobs;
      let jobIndex = 0;
      selectedJobs.map((e: string, index: number) => {
        if (e === jobId) {
          jobIndex = index;
        }
      });
      updatedJobs.splice(jobIndex, 1);
      setSelectedJobs(updatedJobs);
      selectedJobs.length ? setJobsReady(true) : setJobsReady(false);
    } else {
      const updatedJobs = selectedJobs;
      updatedJobs.push(jobId);
      setSelectedJobs(updatedJobs);
      selectedJobs.length ? setJobsReady(true) : setJobsReady(false);
    }
  };

  useEffect(() => {
    if (reviewType === "time-bound") {
      setIsJobInterview(false);
    }
  }, [reviewType]);

  const initialValues = {
    review,
  };

  const [processStep, setProcessStep] = useState<number>(0);

  const jobItems: ISelectDropDown[] = jobs
    .sort((a: IJob, b: IJob) => a.sortOrder - b.sortOrder)
    .map((job: IJob) => {
      const company: ICompany | undefined = companies.find(
        (company: ICompany) => company.itemId === job.companyId,
      );
      if (company === undefined || company.companyName === "") {
        return { value: job.itemId, label: job.jobTitle };
      } else {
        return {
          value: job.itemId,
          label: job.jobTitle + " • " + company.companyName,
        };
      }
    });

  const [submittingForm, setSubmittingForm] = useState<boolean>(false);
  const IconDonutSpinnerSVG = <IconDonutSpinner />;

  return (
    <section className="review-creation-wrapper">
      <div className="review-creation-content">
        <BackLink
          button={false}
          label="Cancel & Return to Reviews"
          title="Cancel & Return to Reviews"
          navigateTo="/reviews"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            setSubmittingForm(true);
            const updatedReview: IReview = values.review;
            setSubmitting(true);
            if (updatedReview.name === "") {
              updatedReview.name = "Review " + (reviews.length + 1);
            }

            values.review.type = reviewType;

            switch (values.review.duration) {
              case "quarterly":
                values.review.startDate = getPastDate(values.review.nextKeyDate, 90, false);
                break;
              case "semi-annual":
                values.review.startDate = getPastDate(values.review.nextKeyDate, 178, false);
                break;
              case "annual":
                values.review.startDate = getPastDate(values.review.nextKeyDate, 356, false);
                break;
              case "custom":
                break;
              case "all-time":
                values.review.startDate = getPastDate(values.review.nextKeyDate, 36500, false);
                break;
              default:
                break;
            }

            values.review.jobInterview = isJobInterview ? isJobInterview : false;

            if (values.review.type === "overall") {
              values.review.startDate = getPastDate(values.review.nextKeyDate, 36500, false);
            }
            if (values.review.relatedContent && values.review.relatedContent !== "") {
              const handleReviewProcess = async () => {
                try {
                  // Step 1: Get embedding
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const vector: string = await getEmbedding(values.review.relatedContent!);
                  values.review.relatedContentVector = vector;
                  values.review.relatedJobs = JSON.stringify(selectedJobs);

                  // Step 2: Prepare for the next async function
                  const experiencesToScore: IWorkExperience[] = workExperiences.filter(
                    (experience: IWorkExperience) => selectedJobs.includes(experience.jobId),
                  );

                  const scoredExperiences: IScoredExperience[] = scoreExperienceWithinRange(
                    experiencesToScore,
                    values.review.relatedContentVector,
                    values.review.startDate ?? undefined,
                    values.review.nextKeyDate ?? undefined,
                  );

                  const experiencesForPayload: IWorkExperience[] = scoredExperiences.map(
                    (scoredExperience: IScoredExperience) => {
                      return scoredExperience.workExperience;
                    },
                  );

                  const interviewQuestionPayload = prepInterviewQuestionsPayload(
                    jobs,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    values.review.relatedContent!,
                    experiencesForPayload,
                    goals,
                  );

                  // Step 3: Get completion
                  const response: string = await getCompletion(
                    JSON.stringify(interviewQuestionPayload),
                    "jobInterviewQuestionsGeneration",
                  );

                  try {
                    const responseParsed = JSON.parse(response);

                    const isValidResponse = (response: any) => {
                      if (!response || !Array.isArray(response.questions)) {
                        return false;
                      }

                      for (const item of response.questions) {
                        if (!item || typeof item.question !== "string" || typeof item.reason !== "string") {
                          return false;
                        }
                      }

                      return true;
                    };

                    if (isValidResponse(responseParsed)) {
                      values.review.questions = JSON.stringify(responseParsed.questions);
                    }
                  } catch (error) {
                    console.error("Response structure is invalid", error);
                  }

                  // Step 4: Execute final code
                  const updatedReviews = [...[updatedReview], ...reviews];
                  setReviews(updatedReviews);
                  setSubmitting(false);
                  setSubmittingForm(false);
                  navigate("/reviews/" + values.review.itemId, {
                    replace: true,
                  });
                } catch (error) {
                  // Handle errors here if needed
                  console.error(error);
                }
              };

              // Call the async function
              handleReviewProcess();
            } else {
              values.review.relatedJobs = JSON.stringify(selectedJobs);
              if (values.review.recurring === true) {
                values.review.recurringReviewCreated = false;
                updatedReview.recurringReviewCreated = false;
              }
              const updatedReviews = [...[updatedReview], ...reviews];
              setReviews(updatedReviews);
              setSubmitting(false);
              setSubmittingForm(false);
              navigate("/reviews/" + values.review.itemId, {
                replace: true,
              });
            }
          }}
          validations={reviewsValidationSchema}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              <Form className="review-creation-fields">
                <>
                  <div className={`intro-step ${processStep !== 0 && "intro-step--hidden"}`}>
                    <Input
                      name="review.name"
                      placeholder="What should this report be called?"
                      label="Review Name"
                      autoFocus={isNewReview}
                      onKeyPress={(event) => {
                        if (event.code === "Enter") {
                          event.preventDefault();
                          setProcessStep(1);
                        }
                      }}
                    />
                    <div className="review-type-toggles">
                      <label id="time-bound-toggle" className="review-type-toggle">
                        <h3 className="type--heading-3">Time-Bound Review</h3>
                        <p className="type--body--large">
                          Best for things like annual reviews with your manager, where you are reviewing work
                          in a single role over a set time period. Quarterly and Annual reviews can
                          automatically repeat.
                        </p>
                        <input
                          type="radio"
                          name="time-bound-toggle"
                          value="time-bound"
                          defaultChecked={true}
                          checked={reviewType === "time-bound" ? true : false}
                          onClick={() => setReviewType("time-bound")}
                        />
                        <IllustrationTimeBoundReview />
                      </label>
                      <label id="overall-toggle" className="review-type-toggle">
                        <h3 className="type--heading-3">Overall Review</h3>
                        <p className="type--body--large">
                          Best for job interview preparation. You’ll review your whole job history.
                        </p>
                        <input
                          type="radio"
                          name="overall-toggle"
                          value="overall"
                          checked={reviewType === "overall" ? true : false}
                          onClick={() => setReviewType("overall")}
                        />
                        <Field type="radio" name="review.type" value="overall" />
                        <IllustrationOverallReview />
                      </label>
                    </div>
                    {reviewType === "overall" && (
                      <Toggle
                        label="This review is to prepare for a job interview"
                        value="false"
                        checked={isJobInterview}
                        name="isJobInterview"
                        onClick={() => setIsJobInterview(!isJobInterview)}
                      />
                    )}
                  </div>
                  {processStep !== 0 && <h1 className="type--heading-1">{values.review.name}</h1>}
                  <div
                    className={`review-authoring-step ${
                      processStep !== 1 && "review-authoring-step--hidden"
                    }`}
                  >
                    <p className="type--body--large">
                      The date for your {isJobInterview ? "job interview" : "next review"} is useful for
                      selecting the work experience that best applies.
                    </p>
                    <DatePicker label="Next Review Date" name={`review.nextKeyDate`} />
                  </div>
                  <div
                    className={`review-authoring-step ${
                      processStep !== 2 && "review-authoring-step--hidden"
                    }`}
                  >
                    {isJobInterview ? (
                      <p className="type--body--large">
                        When preparing for a job interview, you likely want to select most or all of your
                        jobs.
                      </p>
                    ) : (
                      <p className="type--body--large">
                        For a review at your work you typically want to choose only your current job. For a
                        personal review, including any side projects or other work you do you may want to pick
                        more. If you are preparing for a job interview or networking event, you likely want to
                        select most or all of your jobs.
                      </p>
                    )}
                    <div>
                      <h4 className="type--heading-4">Relevant Jobs</h4>
                      <div role="group" aria-labelledby="my-checkbox-group" className="checkbox-group">
                        {jobItems.map((job) => {
                          return (
                            <Checkbox
                              label={job.label}
                              value={job.value}
                              onChange={(e) => updateJobs(e.target.value)}
                              key={job.value}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`review-authoring-step ${
                      processStep !== 3 && "review-authoring-step--hidden"
                    }`}
                  >
                    {reviewType === "time-bound" ? (
                      <>
                        <p className="type--body--large">
                          For recurring reviews, Career Minder can automatically repeat the same criteria and
                          automatically create new as each new time period begins.
                        </p>
                        <div>
                          <h4 className="type--heading-4">Review Duration</h4>
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="radio-wrapper">
                              <Field
                                type="radio"
                                name="review.duration"
                                defaultChecked={true}
                                value="quarterly"
                                disabled={values.review.nextKeyDate === "" ? true : false}
                              />
                              <span className="radio-label type--body--large">
                                Quarterly
                                {values.review.nextKeyDate !== "" &&
                                  " (" +
                                    getPastDate(values.review.nextKeyDate, 90, true) +
                                    " to " +
                                    formatDate(values.review.nextKeyDate) +
                                    ")"}
                              </span>
                            </label>
                            <label className="radio-wrapper">
                              <Field
                                type="radio"
                                name="review.duration"
                                value="semi-annual"
                                disabled={values.review.nextKeyDate === "" ? true : false}
                              />
                              <span className="radio-label type--body--large">
                                Semi-Annual
                                {values.review.nextKeyDate !== "" &&
                                  " (" +
                                    getPastDate(values.review.nextKeyDate, 178, true) +
                                    " to " +
                                    formatDate(values.review.nextKeyDate) +
                                    ")"}
                              </span>
                            </label>
                            <label className="radio-wrapper">
                              <Field
                                type="radio"
                                name="review.duration"
                                value="annual"
                                disabled={values.review.nextKeyDate === "" ? true : false}
                              />
                              <span className="radio-label type--body--large">
                                Annual
                                {values.review.nextKeyDate !== "" &&
                                  " (" +
                                    getPastDate(values.review.nextKeyDate, 365, true) +
                                    " to " +
                                    formatDate(values.review.nextKeyDate) +
                                    ")"}
                              </span>
                            </label>
                            <label className="radio-wrapper">
                              <Field
                                type="radio"
                                name="review.duration"
                                value="custom"
                                disabled={values.review.nextKeyDate === "" ? true : false}
                              />
                              <span className="radio-label type--body--large">
                                Custom
                                {values.review.startDate !== undefined &&
                                  values.review.startDate !== "" &&
                                  " (" +
                                    formatDate(values.review.startDate) +
                                    " to " +
                                    formatDate(values.review.nextKeyDate) +
                                    ")"}
                              </span>
                            </label>
                          </div>
                        </div>
                        {values.review.duration === "custom" ? (
                          <DatePicker label="Start Date" name={`review.startDate`} />
                        ) : (
                          <>
                            <h4 className="type--heading-4">Recurring Review</h4>
                            <label className="checkbox-wrapper">
                              <Field type="checkbox" name="review.recurring" />
                              <span className="checkbox-label type--body--large">
                                Automatically create recurring reviews
                              </span>
                            </label>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="type--body--large">
                          By entering a job description, the applicable work experience can be sorted by
                          relevance in your review.
                        </p>
                        <Textarea
                          name={`review.relatedContent`}
                          label="Enter job description"
                          placeholder="What is this related to?"
                        />
                      </>
                    )}
                  </div>
                </>
              </Form>
              <div className="action-bar review-authoring__action-bar pattern--button-row">
                <Button
                  label={processStep === 0 ? "Cancel" : "Back"}
                  buttonTitle={processStep === 0 ? "Cancel and return to reviews" : "Back"}
                  buttonType="utility"
                  type="button"
                  onClick={() => {
                    if (processStep === 0) {
                      navigate("/reviews");
                    } else if (processStep === 1) {
                      setProcessStep(0);
                    } else if (processStep === 2) {
                      setProcessStep(1);
                    } else if (processStep === 3) {
                      setProcessStep(2);
                    }
                  }}
                />
                <Button
                  label={
                    processStep === 0
                      ? "Continue to Details"
                      : processStep === 1
                        ? "Continue to Jobs"
                        : processStep === 2
                          ? reviewType === "time-bound"
                            ? "Continue to Duration"
                            : "Continue to Description"
                          : "Finish"
                  }
                  buttonTitle={
                    processStep === 0
                      ? "Continue to Review Details"
                      : processStep === 1
                        ? "Continue to Related Jobs"
                        : processStep === 2
                          ? reviewType === "time-bound"
                            ? "Continue to Review Duration"
                            : "Continue to Related Job Description"
                          : "Finish Review Setup"
                  }
                  buttonType="primary"
                  disabled={
                    processStep === 0
                      ? values.review.name !== ""
                        ? false
                        : true
                      : processStep === 1
                        ? values.review.nextKeyDate !== ""
                          ? false
                          : true
                        : processStep === 2
                          ? jobsReady
                            ? false
                            : true
                          : processStep === 3
                            ? values.review.startDate !== "" && false
                            : true
                  }
                  type="button"
                  leadingIcon={submittingForm && IconDonutSpinnerSVG}
                  onClick={() => {
                    if (reviewType === "overall") {
                      setFieldValue(values.review.duration, "all-time");
                    }
                    if (processStep === 0) {
                      setProcessStep(1);
                    } else if (processStep === 1) {
                      setProcessStep(2);
                    } else if (processStep === 2) {
                      setProcessStep(3);
                    } else if (processStep === 3) {
                      submitForm();
                    }
                  }}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </section>
  );
};
