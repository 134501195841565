/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { Button } from "components/core";
import { ReviewExperienceItem } from "../ReviewExperienceItem";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as IconLightBulb } from "../../core/assets/img/lightbulb.svg";
import { ReactComponent as IconInfo } from "../../core/assets/img/info.svg";
import { ReactComponent as IconLoading } from "../../core/assets/img/donut-spinner.svg";
import { IReview, IWorkExperience } from "types";
import { getUnixTimestamp, rescoreExperiences, scoreExperienceWithinRange } from "utils";
import "./reviewExperienceTab.css";

const API_NAME = "careerminder";
const API_ENDPOINT = "/ai/";

interface ScoredExperience {
  workExperience: IWorkExperience;
  score: number;
}

interface OpenAIEmbeddingObject {
  object: string;
  embedding: number[];
  index: number;
}

export interface ReviewExperienceTabProps {
  review: IReview;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewExperienceTab: React.FC<ReviewExperienceTabProps> = ({ review }) => {
  const { updateItem, workExperiences, userEmail } = DataContainer.useContainer();

  const navigate = useNavigate();

  const seedExperience = (): ScoredExperience[] => {
    const relatedJobs: string[] = JSON.parse(review.relatedJobs);

    const experiencesToScore: IWorkExperience[] = workExperiences.filter((experience: IWorkExperience) =>
      relatedJobs.includes(experience.jobId),
    );

    const data: ScoredExperience[] = scoreExperienceWithinRange(
      experiencesToScore,
      review.relatedContentVector ?? undefined,
      review.startDate ?? undefined,
      review.nextKeyDate ?? undefined,
    );

    return data;
  };

  const [applicableExperience, setApplicableExperience] = useState<ScoredExperience[]>(seedExperience());

  const IconLightBulbSVG = <IconLightBulb />;
  const IconInfoSVG = <IconInfo />;
  const IconLoadingSVG = <IconLoading />;

  const [relatedContentOpen, setRelatedContentOpen] = useState<boolean>(false);
  const [addingRelatedContent, setAddingRelatedContent] = useState<boolean>(false);
  const [recProcessUnderway, setRecProcessUnderway] = useState<boolean>(false);

  const [relatedContentText, setRelatedContentText] = useState<string>(
    review.relatedContent ? review.relatedContent : "",
  );
  const handleRelatedContentChange = (e: any) => {
    const value = e.target.value;
    setRelatedContentText(value);
  };

  const addRelatedContent = () => {
    setRecProcessUnderway(true);
    let embeddings: OpenAIEmbeddingObject[] = [];
    const getRecommendationForRelatedContent = async () => {
      try {
        const response = await API.post(API_NAME, API_ENDPOINT, {
          body: [relatedContentText],
        });
        embeddings = response.data;
        review.relatedContent = relatedContentText;
        review.relatedContentVector = JSON.stringify(embeddings[0].embedding);
        updateItem(review).then(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const data: ScoredExperience[] = rescoreExperiences(
            applicableExperience,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            review.relatedContentVector!,
          );

          setApplicableExperience(data);
        });
      } catch (error: any) {
        if (
          process.env.REACT_APP_USER_BRANCH !== "master" ||
          (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
        ) {
          console.log("Error getting recommendation", error);
        }
        return "Error";
      }
    };
    getRecommendationForRelatedContent();
  };

  useEffect(() => {
    if (recProcessUnderway) {
      setRecProcessUnderway(false);
      setAddingRelatedContent(false);
    }
  }, [applicableExperience]);

  useEffect(() => {
    setApplicableExperience(seedExperience());
  }, [review]);

  return (
    <div className="review-work-experience-wrapper">
      <div className="review-work-experience-header">
        <p className="type--data review-experience-count">
          {applicableExperience.length} Experience
          {applicableExperience.length > 1 && "s"}
        </p>
        {applicableExperience.length ? (
          review.relatedContent ? (
            <Button
              buttonTitle={
                review.type === "overall"
                  ? "Job Description for this Review"
                  : "Related Content for this Review"
              }
              buttonType="utility"
              label={review.type === "overall" ? "Job Description" : "Related Content"}
              size="small"
              type="button"
              leadingIcon={IconInfoSVG}
              onClick={() => setRelatedContentOpen(true)}
            />
          ) : (
            <Button
              buttonTitle={
                !addingRelatedContent
                  ? "Smart Sort this experience"
                  : "Cancel Smart Sorting of this experience"
              }
              buttonType="utility"
              label={!addingRelatedContent ? "Smart Sort" : "Cancel Smart Sort"}
              size="small"
              type="button"
              leadingIcon={IconLightBulbSVG}
              onClick={() => setAddingRelatedContent(!addingRelatedContent)}
            />
          )
        ) : (
          <Button
            buttonTitle="Add some work experience"
            buttonType="primary"
            label="Add Experience"
            size="small"
            type="button"
            onClick={() => {
              navigate("/experience/new");
            }}
          />
        )}
      </div>
      {addingRelatedContent && (
        <div className="adding-related-content">
          <p className="type--body--large">
            Add some text to compare your experience against. This could be a job description, review
            questions, an email from your boss, or a description of what you want your job to be.
          </p>
          <label className="input-container">
            <span className="pattern--label type--heading-4">Enter Related Content</span>
            <textarea
              placeholder="What is this related content?"
              className="pattern--input-field"
              value={relatedContentText}
              onChange={(e) => handleRelatedContentChange(e)}
              rows={6}
              maxLength={32764}
              autoFocus={true}
            />
          </label>
          <div className="pattern--button-row">
            <Button
              buttonTitle="Cancel Adding Related Experience"
              label="Cancel"
              buttonType="utility"
              onClick={() => setAddingRelatedContent(false)}
            />
            <Button
              buttonTitle="Select Related Experience based on Job Description"
              label="Select Related Experience"
              buttonType="secondary"
              disabled={relatedContentText === "" || recProcessUnderway ? true : false}
              leadingIcon={recProcessUnderway && IconLoadingSVG}
              onClick={() => addRelatedContent()}
            />
          </div>
        </div>
      )}
      <div className="review-experience-content">
        {review.relatedContentVector ? (
          <>
            {applicableExperience
              .sort((a: ScoredExperience, b: ScoredExperience) => b.score - a.score)
              .map((workExperience: ScoredExperience, index: number) => {
                return (
                  <ReviewExperienceItem
                    workExperience={workExperience.workExperience}
                    key={workExperience.workExperience.itemId + index}
                    score={workExperience.score && workExperience.score}
                  />
                );
              })}
          </>
        ) : (
          <>
            {applicableExperience.length ? (
              applicableExperience
                .sort(
                  (a: ScoredExperience, b: ScoredExperience) =>
                    getUnixTimestamp(
                      b.workExperience.completionDate
                        ? b.workExperience.completionDate
                        : b.workExperience.dateCreated,
                    ) -
                    getUnixTimestamp(
                      a.workExperience.completionDate
                        ? a.workExperience.completionDate
                        : a.workExperience.dateCreated,
                    ),
                )
                .map((workExperience: ScoredExperience, index: number) => {
                  return (
                    <ReviewExperienceItem
                      workExperience={workExperience.workExperience}
                      key={workExperience.workExperience.itemId + index}
                    />
                  );
                })
            ) : (
              <div className="experience-tab--empty-state pattern--empty-state--fill-area">
                <h3 className="type--heading-3">No Experience</h3>
                <p className="type--body--large">Add experience with the button above.</p>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`review-related-content-pane ${
          relatedContentOpen && "review-related-content-pane--visible"
        }`}
      >
        <div className="review-related-content-pane__controls">
          <button
            className="review-related-content__control-button type--body--large"
            onClick={() => setRelatedContentOpen(false)}
          >
            <strong>{review.type === "overall" ? "Hide Job Description" : "Hide Related Content"}</strong>
          </button>
          <span className="type--body--large">&bull;</span>
          <button
            className="review-related-content__control-button type--body--large"
            onClick={() => {
              setRelatedContentOpen(false);
              setAddingRelatedContent(true);
            }}
          >
            <strong>Edit</strong>
          </button>
        </div>
        <pre className="type--body--large">{review.relatedContent}</pre>
      </div>
    </div>
  );
};
