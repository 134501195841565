import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import CreatableSelect from "react-select/creatable";
import { ISelectDropDown } from "types";
import { v4 as uuid } from "uuid";

export interface CreatableSelectProps {
	name: string;
	placeholder: string;
	className: string;
	classNamePrefix: string;
	options: ISelectDropDown[];
	value: ISelectDropDown | undefined;
	label?: string;
}

export const CreateSelect: React.FC<CreatableSelectProps> = (props) => {
	const {
		name,
		placeholder,
		className,
		classNamePrefix,
		options,
		value,
		label,
	} = props;
	const [_options, set_options] = useState<ISelectDropDown[]>(options);
	const [_value, set_value] = useState<ISelectDropDown | null>(
		value ? value : null
	);

	const creatableSelect = ({
		field,
		form: { errors, touched, setFieldValue },
	}: FieldProps) => (
		<div>
			<label>
				{label && (
					<span className="pattern--label type--heading-4">
						{label}
					</span>
				)}
				<CreatableSelect
					className={className}
					classNamePrefix={classNamePrefix}
					key={uuid()}
					maxMenuHeight={150}
					onChange={(value) => {
						const selectedValue: ISelectDropDown = value as ISelectDropDown;
						setFieldValue(field.name, selectedValue.value);
						set_value({ value: selectedValue.value, label: selectedValue.label });
					}}
					onCreateOption={(option: string) => {
						const newOption: ISelectDropDown = { label: option, value: "" };
						set_options([..._options, newOption]);
						set_value(newOption);
						setFieldValue(field.name, `new#${option}`);
					}}
					options={_options}
					placeholder={placeholder}
					value={_value}
				/>
				{touched[field.name] && errors[field.name] && (
					<div className="error">{errors[field.name]}</div>
				)}
			</label>
		</div>
	);

	return <Field name={name} component={creatableSelect} />;
};
