import React, { useState, useEffect } from "react";
import { DataContainer } from "containers/DataContainer";
import { IJob, ISentiment } from "types";
import { addSentiments } from "utils";
import { SentimentOption } from "./SentimentOption";
import { ReactComponent as SentimentNegative } from "components/core/assets/img/sentiment-negative.svg";
import { ReactComponent as SentimentNeutral } from "components/core/assets/img/sentiment-neutral.svg";
import { ReactComponent as SentimentPositive } from "components/core/assets/img/sentiment-positive.svg";
import { ReactComponent as IllustrationHighBlankLow } from "components/core/assets/img/illustration-high-blank-low.svg";
import { ReactComponent as IllustrationHighBlankMid } from "components/core/assets/img/illustration-high-blank-mid.svg";
import { ReactComponent as IllustrationHighHighMid } from "components/core/assets/img/illustration-high-high-mid.svg";
import { ReactComponent as IllustrationHighHighLow } from "components/core/assets/img/illustration-high-high-low.svg";
import { ReactComponent as IllustrationHighMidHigh } from "components/core/assets/img/illustration-high-mid-high.svg";
import { ReactComponent as IllustrationHighMidMid } from "components/core/assets/img/illustration-high-mid-mid.svg";
import { ReactComponent as IllustrationHighLowHigh } from "components/core/assets/img/illustration-high-low-high.svg";
import { ReactComponent as IllustrationHighLowMid } from "components/core/assets/img/illustration-high-low-mid.svg";
import { ReactComponent as IllustrationHighLowLow } from "components/core/assets/img/illustration-high-low-low.svg";
import { ReactComponent as IllustrationMidBlankHigh } from "components/core/assets/img/illustration-mid-blank-high.svg";
import { ReactComponent as IllustrationMidBlankLow } from "components/core/assets/img/illustration-mid-blank-low.svg";
import { ReactComponent as IllustrationMidHighHigh } from "components/core/assets/img/illustration-mid-high-high.svg";
import { ReactComponent as IllustrationMidHighMid } from "components/core/assets/img/illustration-mid-high-mid.svg";
import { ReactComponent as IllustrationMidHighLow } from "components/core/assets/img/illustration-mid-high-low.svg";
import { ReactComponent as IllustrationMidMidHigh } from "components/core/assets/img/illustration-mid-mid-high.svg";
import { ReactComponent as IllustrationMidMidLow } from "components/core/assets/img/illustration-mid-mid-low.svg";
import { ReactComponent as IllustrationMidLowHigh } from "components/core/assets/img/illustration-mid-low-high.svg";
import { ReactComponent as IllustrationMidLowMid } from "components/core/assets/img/illustration-mid-low-mid.svg";
import { ReactComponent as IllustrationMidLowLow } from "components/core/assets/img/illustration-mid-low-low.svg";
import { ReactComponent as IllustrationLowBlankHigh } from "components/core/assets/img/illustration-low-blank-high.svg";
import { ReactComponent as IllustrationLowBlankMid } from "components/core/assets/img/illustration-low-blank-mid.svg";
import { ReactComponent as IllustrationLowHighHigh } from "components/core/assets/img/illustration-low-high-high.svg";
import { ReactComponent as IllustrationLowHighMid } from "components/core/assets/img/illustration-low-high-mid.svg";
import { ReactComponent as IllustrationLowHighLow } from "components/core/assets/img/illustration-low-high-low.svg";
import { ReactComponent as IllustrationLowMidMid } from "components/core/assets/img/illustration-low-mid-mid.svg";
import { ReactComponent as IllustrationLowMidLow } from "components/core/assets/img/illustration-low-mid-low.svg";
import { ReactComponent as IllustrationLowLowHigh } from "components/core/assets/img/illustration-low-low-high.svg";
import { ReactComponent as IllustrationLowLowMid } from "components/core/assets/img/illustration-low-low-mid.svg";
import "./bulkSentimentAssignment.css";

interface BulkSentimentAssignmentProps {
  job: IJob;
  sentimentsToUpdate: ISentiment[];
  setSentimentsToUpdate: (value: ISentiment[]) => void;
}

export const BulkSentimentAssignment: React.FC<BulkSentimentAssignmentProps> = (
  props,
) => {
  const { job, sentimentsToUpdate, setSentimentsToUpdate } = props;

  const { userEmail, user, EmptySentiment } = DataContainer.useContainer();

  const sentimentOptions = [
    { type: "negative", component: SentimentNegative },
    { type: "neutral", component: SentimentNeutral },
    { type: "positive", component: SentimentPositive },
  ];

  const sentimentTrendOptions = [
    { type: "high-blank-low", component: IllustrationHighBlankLow },
    { type: "high-blank-mid", component: IllustrationHighBlankMid },
    { type: "high-high-mid", component: IllustrationHighHighMid },
    { type: "high-high-low", component: IllustrationHighHighLow },
    { type: "high-mid-high", component: IllustrationHighMidHigh },
    { type: "high-mid-mid", component: IllustrationHighMidMid },
    { type: "high-low-high", component: IllustrationHighLowHigh },
    { type: "high-low-mid", component: IllustrationHighLowMid },
    { type: "high-low-low", component: IllustrationHighLowLow },
    { type: "mid-blank-high", component: IllustrationMidBlankHigh },
    { type: "mid-blank-low", component: IllustrationMidBlankLow },
    { type: "mid-high-high", component: IllustrationMidHighHigh },
    { type: "mid-high-mid", component: IllustrationMidHighMid },
    { type: "mid-high-low", component: IllustrationMidHighLow },
    { type: "mid-mid-high", component: IllustrationMidMidHigh },
    { type: "mid-mid-low", component: IllustrationMidMidLow },
    { type: "mid-low-high", component: IllustrationMidLowHigh },
    { type: "mid-low-mid", component: IllustrationMidLowMid },
    { type: "mid-low-low", component: IllustrationMidLowLow },
    { type: "low-blank-high", component: IllustrationLowBlankHigh },
    { type: "low-blank-mid", component: IllustrationLowBlankMid },
    { type: "low-high-high", component: IllustrationLowHighHigh },
    { type: "low-high-mid", component: IllustrationLowHighMid },
    { type: "low-high-low", component: IllustrationLowHighLow },
    { type: "low-mid-mid", component: IllustrationLowMidMid },
    { type: "low-mid-low", component: IllustrationLowMidLow },
    { type: "low-low-high", component: IllustrationLowLowHigh },
    { type: "low-low-mid", component: IllustrationLowLowMid },
  ];

  const [selectedSentiment, setSelectedSentiment] = useState<string>("");

  const handleSentimentOptionClick = (sentimentType: string) => {
    setSelectedSentiment(sentimentType);
    const newSentiments = addSentiments(
      job,
      sentimentType,
      user,
      EmptySentiment,
      userEmail,
    );
    setSentimentsToUpdate(newSentiments);
  };

  return (
    <div className={`sentiment-bulk-assignment-wrapper`}>
      <div className="sentiment-bulk-assignment-header">
        <p className="type--body--large">How did you like this job?</p>
      </div>
      <div className="sentiment-bulk-assignment-content">
        <div className="sentiment-option-row">
          {sentimentOptions.map((option) => (
            <SentimentOption
              key={option.type}
              type={option.type}
              component={option.component}
              selected={selectedSentiment === option.type}
              small={true}
              onClick={handleSentimentOptionClick}
            />
          ))}
        </div>
        <div className="sentiment-option-section-divider">
          <p className="type--body--large">
            Or did it change over time, like one of the charts below?
          </p>
        </div>
        <div className="sentiment-options-trends">
          {sentimentTrendOptions.map((option) => (
            <SentimentOption
              key={option.type}
              type={option.type}
              component={option.component}
              selected={selectedSentiment === option.type}
              small={false}
              onClick={handleSentimentOptionClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
