import { IJob, ISentiment } from "types";
import { getDatesBetween } from "./dateUtils";

export const addSentiments = (
  job: IJob,
  sentimentType: string,
  user: any,
  EmptySentiment: any,
  userEmail: any,
): ISentiment[] => {
  const today = new Date().toUTCString();
  const startDate = new Date(job.startDate);
  const endDate = new Date(job.endDate ? job.endDate : today);
  const weeksArray = getDatesBetween(startDate, endDate);

  const newSentiments: ISentiment[] = weeksArray.map(
    (date: string, index: number) => {
      const sentiment: ISentiment = new EmptySentiment(user);
      sentiment.jobId = job.itemId;
      sentiment.dateCreated = date;
      switch (sentimentType) {
        case "negative":
          sentiment.feeling = 0;
          break;
        case "neutral":
          sentiment.feeling = 50;
          break;
        case "positive":
          sentiment.feeling = 100;
          break;
        case "high-blank-low":
          if (index < Math.round(weeksArray.length / 2)) {
            sentiment.feeling = 100;
          } else {
            sentiment.feeling = 0;
          }
          break;
        case "high-blank-mid":
          if (index < Math.round(weeksArray.length / 2)) {
            sentiment.feeling = 100;
          } else {
            sentiment.feeling = 50;
          }
          break;
        case "high-high-mid":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            sentiment.feeling = 100;
          } else {
            sentiment.feeling = 50;
          }
          break;
        case "high-high-low":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            sentiment.feeling = 100;
          } else {
            sentiment.feeling = 0;
          }
          break;
        case "high-mid-high":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            if (index > Math.round(weeksArray.length / 3)) {
              sentiment.feeling = 100;
            } else {
              sentiment.feeling = 50;
            }
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "high-mid-mid":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 100;
          } else {
            sentiment.feeling = 50;
          }
          break;
        case "high-low-high":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 100;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 0;
            } else {
              sentiment.feeling = 100;
            }
          }
          break;
        case "high-low-mid":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 100;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 0;
            } else {
              sentiment.feeling = 50;
            }
          }
          break;
        case "high-low-low":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 100;
          } else {
            sentiment.feeling = 0;
          }
          break;
        case "mid-blank-high":
          if (index < Math.round(weeksArray.length / 2)) {
            sentiment.feeling = 50;
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "mid-blank-low":
          if (index < Math.round(weeksArray.length / 2)) {
            sentiment.feeling = 50;
          } else {
            sentiment.feeling = 0;
          }
          break;
        case "mid-high-high":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "mid-high-mid":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 100;
            } else {
              sentiment.feeling = 50;
            }
          }
          break;
        case "mid-high-low":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 100;
            } else {
              sentiment.feeling = 0;
            }
          }
          break;
        case "mid-mid-high":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "mid-mid-low":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            sentiment.feeling = 0;
          }
          break;
        case "mid-low-high":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 0;
            } else {
              sentiment.feeling = 100;
            }
          }
          break;
        case "mid-low-mid":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 0;
            } else {
              sentiment.feeling = 50;
            }
          }
          break;
        case "mid-low-low":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 50;
          } else {
            sentiment.feeling = 0;
          }
          break;
        case "low-blank-high":
          if (index < Math.round(weeksArray.length / 2)) {
            sentiment.feeling = 0;
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "low-blank-mid":
          if (index < Math.round(weeksArray.length / 2)) {
            sentiment.feeling = 0;
          } else {
            sentiment.feeling = 50;
          }
          break;
        case "low-high-high":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "low-high-mid":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 100;
            } else {
              sentiment.feeling = 50;
            }
          }
          break;
        case "low-high-low":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 100;
            } else {
              sentiment.feeling = 0;
            }
          }
          break;
        case "low-mid-mid":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            sentiment.feeling = 50;
          }
          break;
        case "low-mid-low":
          if (index < Math.round(weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
              sentiment.feeling = 50;
            } else {
              sentiment.feeling = 0;
            }
          }
          break;
        case "low-low-high":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            sentiment.feeling = 100;
          }
          break;
        case "low-low-mid":
          if (index < Math.round(weeksArray.length - weeksArray.length / 3)) {
            sentiment.feeling = 0;
          } else {
            sentiment.feeling = 50;
          }
          break;
        default:
          if (
            process.env.REACT_APP_USER_BRANCH !== "master" ||
            (process.env.REACT_APP_USER_BRANCH === "master" &&
              userEmail === "releasetest@careerminder.io")
          ) {
            console.log("No switch case");
          }
      }
      return sentiment;
    },
  );
  console.log(
    "Counts Negative, Neutral, High",
    newSentiments.filter((sentiment: ISentiment) => sentiment.feeling === 0)
      .length,
    newSentiments.filter((sentiment: ISentiment) => sentiment.feeling === 50)
      .length,
    newSentiments.filter((sentiment: ISentiment) => sentiment.feeling === 100)
      .length,
  );
  return newSentiments;
};
