import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SentimentNegative } from "../../core/assets/img/sentiment-negative.svg";
import { ReactComponent as SentimentNeutral } from "../../core/assets/img/sentiment-neutral.svg";
import { ReactComponent as SentimentPositive } from "../../core/assets/img/sentiment-positive.svg";
import { ICatchUp, ISentiment, IWorkExperience } from "types";
import { DataContainer } from "containers/DataContainer";
import { convertToHtml } from "utils";
import "./catchUpItem.css";

export interface CatchUpItemProps {
    index: number;
    catchUp: ICatchUp;
    hidden?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const CatchUpItem: React.FC<CatchUpItemProps> = (props) => {
    const { catchUp } = props;
    const { sentiments, workExperiences } = DataContainer.useContainer();
    const currentSentiment = sentiments.filter(
        (sentiment: ISentiment) => sentiment.itemId === catchUp.sentiment,
    );
    const relatedExperienceIDs: string[] = catchUp.experiences ? JSON.parse(catchUp.experiences) : [];
    const buildRelatedExperiences = (): IWorkExperience[] => {
        const relatedExperienceSet: IWorkExperience[] = [];
        relatedExperienceIDs.map((experienceId: string) => {
            workExperiences
                .filter((experience: IWorkExperience) => experience.itemId === experienceId)
                .map((experience: IWorkExperience) => {
                    relatedExperienceSet.push(experience);
                });
        });
        return relatedExperienceSet;
    };
    const relatedExperiences = buildRelatedExperiences();
    return (
        <div
            className={`catchup-picker-item  ${props.hidden && "catchup-picker-item--hidden"}`}
            key={catchUp.itemId}
        >
            <Link className="catchup-picker-link" to={catchUp.itemId} title={catchUp.name}>
                <div className="catchup-content">
                    <h2 className="type--heading-3">{catchUp.name}</h2>
                    <div
                        className="catchup-text"
                        dangerouslySetInnerHTML={{
                            __html: catchUp.summary && convertToHtml(catchUp.summary),
                        }}
                    ></div>
                </div>
            </Link>
            <div className="catchup-picker-meta">
                {currentSentiment[0] && (
                    <div className="catchup-picker-meta__sentiment">
                        {currentSentiment[0].feeling !== 0 ? (
                            currentSentiment[0].feeling === 100 ? (
                                <SentimentPositive />
                            ) : (
                                <SentimentNeutral />
                            )
                        ) : (
                            <SentimentNegative />
                        )}
                    </div>
                )}
                <p className="type--body--standard">
                    {relatedExperiences.length} Experience{relatedExperiences.length > 0 && "s"}
                </p>
            </div>
        </div>
    );
};
