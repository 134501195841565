import React from "react";
import { IJob, ICompany, IProject, IGoal, IWorkExperience } from "types";
import { DataContainer } from "containers/DataContainer";
import { formatDate } from "utils";
import "./reviewExperienceItem.css";

export interface ReviewExperienceItemProps {
	workExperience: IWorkExperience;
	key: string;
	score?: number;
}

/**
 * Primary UI component for user interaction
 */
export const ReviewExperienceItem: React.FC<ReviewExperienceItemProps> = ({
	workExperience,
	key,
	score,
}) => {
	const {
		companies,
		jobs,
		projects,
		goals,
	} = DataContainer.useContainer();
	
	const job = jobs.find((job: IJob) => job.itemId === workExperience.jobId);
	
	const company = companies.find((company: ICompany) => company.itemId === job?.companyId);
	
	const project = projects.find((project: IProject) => project.itemId === workExperience.projectId);
	
	const goal = goals.find((goal: IGoal) => goal.itemId === workExperience.goalId);
	
	// const score = workExperience.vector ? 
	
	return (
		<div className="review-work-experience-container">
			<div className="review-work-experience-summary-item" key={key}>
				<div className="review-work-experience-content">
					<p className="type--body--large review-work-experience-description">{workExperience.description}</p>
					<p className="type--body--standard review-work-experience-meta">{formatDate(workExperience.completionDate ? workExperience.completionDate : workExperience.dateCreated)}{job && " • " + job.jobTitle}{company && ": " + company.companyName}{project && " • " + project.projectName}{goal && " • " + goal.goal}</p>
				</div>
				{score &&
					<div className={`review-work-experience-score type--data ${score > .75 ? "review-work-experience-score--positive" : score > .33 ? "review-work-experience-score--neutral" : "review-work-experience-score--negative"}`}>
						{Math.round(score * 100)}% Match
					</div>
				}
			</div>
		</div>
	);
};