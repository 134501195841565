import React, { Fragment } from "react";
import { Field, FieldProps } from "formik";
import "./editor.css";
import { Button } from "../Button";
import { EditorState } from "draft-js";
import { Editor as ReactDraftWysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export interface EditorProps {
  borderType?: "bordered" | "floating";
  button1Function?: () => void;
  button1Label?: string;
  button2Function?: () => void;
  button2Label?: string;
  label?: string;
  name: string;
}

export const Editor: React.FC<EditorProps> = (props: EditorProps) => {
  const { label, name, button1Label, button1Function, button2Label, button2Function } = props;
  const borderType = props.borderType ? props.borderType : "bordered";

  const ReactDraftWysiwygEditorComponent = ({
    field,
    form: { errors, touched, setFieldValue },
  }: FieldProps) => (
    <Fragment>
      <ReactDraftWysiwygEditor
        editorState={field.value}
        toolbarClassName="toolbar"
        wrapperClassName={`editorWrapper editorWrapper--${borderType}`}
        editorClassName="editor"
        placeholder="What do you want to brag about?"
        onEditorStateChange={(es: EditorState) => setFieldValue(field.name, es)}
        toolbar={{
          options: ["inline", "list"],
          inline: {
            options: ["bold", "italic"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
        toolbarCustomButtons={[
          <Button
            buttonType="utility"
            label={button1Label ? button1Label : "Goals"}
            key={"ReactDraftWysiwygEditorExperienceButton"}
            onClick={button1Function}
          />,
          <Button
            buttonType="utility"
            label={button2Label ? button2Label : "Goals"}
            key={"ReactDraftWysiwygEditorReferenceButton"}
            onClick={button2Function}
          />,
        ]}
      />
      {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </Fragment>
  );

  return (
    <div className="editor-container">
      {label && <span className="pattern--label">{label}</span>}
      <Field name={name} component={ReactDraftWysiwygEditorComponent} />
    </div>
  );
};
