import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components/core";
import { DataContainer } from "containers/DataContainer";
import {
	IGoal,
	IWorkExperience,
} from "types";
import { v4 as uuid } from "uuid";
import { formatDate } from "utils";
import { ReactComponent as IconPointingLeft } from "../../core/assets/img/arrow-pointing-left.svg";
import { ReactComponent as IconPointingRight } from "../../core/assets/img/arrow-pointing-right.svg";
import { ReactComponent as IconCalendar } from "../../core/assets/img/calendar.svg";
import "./goalCard.css";

interface GoalCardProps {
	updated?: boolean;
}

export const GoalCard: React.FC<GoalCardProps> = () => {
	const {
		goals,
		EmptyGoal,
		subscriptionActive,
		user,
		userDataLoaded,
		setGoals,
		workExperiences,
	} = DataContainer.useContainer();
	
	const navigate = useNavigate();
	
	const IconPointingLeftSVG = <IconPointingLeft />;
	const IconPointingRightSVG = <IconPointingRight />;

	const finalGoals = goals.filter(
		(goal: IGoal) => goal.goal !== ""
	)

	// Goals
	const [currentGoal, setCurrentGoal] = useState<number>(0);
	const handleButtonClick = (source: string) => {
		if (source === "decrement") {
			if (currentGoal === 0) {
				setCurrentGoal(finalGoals.length - 1);
			} else {
				setCurrentGoal(currentGoal - 1);
			}
		} else {
			if (currentGoal === finalGoals.length - 1) {
				setCurrentGoal(0);
			} else {
				setCurrentGoal(currentGoal + 1);
			}
		}
	};
	
	return (
		<>
			{goals.length ? (
				<div className="goals-card">
					<div className="goals-card__header">
						<div className="goals-card__header__main">
							<h3 className="type--heading-4">Goals</h3>
							<Button
								label="Add Goal"
								buttonTitle="Add a new goal"
								size="small"
								disabled={!subscriptionActive || !userDataLoaded ? true : false}
								onClick={() => navigate("goals/new")}
							/>
						</div>
						<div className="goals-card-pagination">
							<p className="type--data">
								{currentGoal + 1} of {finalGoals.length}
							</p>
							<div className="goals-card-pagination__buttons">
								<Button
									buttonTitle="Previous goal"
									buttonType="icon-only"
									key={uuid()}
									label="Previous"
									leadingIcon={IconPointingLeftSVG}
									onClick={() => handleButtonClick("decrement")}
									size="small"
								/>
								<Button
									buttonTitle="Next goal"
									buttonType="icon-only"
									key={uuid()}
									label="Next"
									leadingIcon={IconPointingRightSVG}
									onClick={() => handleButtonClick("increment")}
									size="small"
								/>
							</div>
						</div>
					</div>
					<div className="goals-card__body">
						{finalGoals.map((goal, index: number) => {
							return (
								<Fragment key={uuid()}>
									{currentGoal === index && (
										<Link 
											to={`/goals#${goal.itemId.slice(5)}`}
											className="goals-card__goal-link"
										>
											<div
												className={goal.details.length ? "goalContent goalContent--has-details" : "goalContent"}
												key={`goal${index.toString()}`}
											>
												<h3 className="type--heading-5">{goal.goal}</h3>
												<p className="type--body--standard">{goal.details}</p>
												<div className="goalContent__meta">
													<div className="goalContent__meta__experience type--caption">
														{workExperiences.filter((experience: IWorkExperience) => experience.goalId === goal.itemId).length}
													</div>
													<div className="goalContent__meta__date">
														<IconCalendar />
														<p className="type--caption">
															{formatDate(goal.completionDate)}
														</p>
													</div>
												</div>
											</div>
										</Link>
									)}
								</Fragment>
							);
						})}
					</div>
				</div>
			) : (
				<div className="goals-card goals-card--empty">
					<Link to="goals/new" className="empty-goal-link">
						<button
							className="goal-empty-state-button"
							onClick={() => console.log("new goal")}
						>
							<p className="type--body--large">
								<strong>You don’t have a goal yet</strong>
							</p>
							<p className="type--body--large">Click to add one</p>
						</button>
					</Link>
				</div>
			)}	
		</>
	);
};