import React, { useEffect, useRef } from "react";
import { Button } from "components/core";
import "./dialog.css";

export interface DialogProps {
	children: unknown;
	isOpened: boolean;
	setIsOpened: (value: boolean) => void;
	primaryButtonType:
		| "icon-only"
		| "icon-only-key"
		| "icon-only-utility"
		| "primary"
		| "secondary"
		| "utility"
		| "goal";
	primaryButtonTitle: string;
	primaryButtonLabel: string;
	primaryButtonFunction: () => void;
	primaryButtonClosesDialog: boolean;
	primaryButtonAnalyticsTag?: string;
	secondaryButtonType?:
		| "icon-only"
		| "icon-only-key"
		| "icon-only-utility"
		| "primary"
		| "secondary"
		| "utility"
		| "goal";
	secondaryButtonTitle?: string;
	secondaryButtonLabel?: string;
	secondaryButtonFunction?: () => void;
	secondaryButtonClosesDialog?: boolean;
	secondaryButtonAnalyticsTag?: string;
}

export const Dialog: React.FC<DialogProps> = ({
	children,
	isOpened,
	setIsOpened,
	primaryButtonType,
	primaryButtonTitle,
	primaryButtonLabel,
	primaryButtonFunction,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const ref: any = useRef(null);

	useEffect(() => {
		if (isOpened) {
			ref.current?.showModal();
			document.body.classList.add("dialog-open"); // prevent bg scroll
		} else {
			document.body.classList.remove("dialog-open");
			ref.current?.close();
		}
	}, [isOpened]);

	{
		/* const proceedAndClose = () => {
		onProceed();
		onClose();
	}; */
	}

	return (
		<dialog className="dialog-wrapper" ref={ref}>
			<div className="dialog-content">{children}</div>
			<div className="dialog-actions">
				<Button
					buttonType={primaryButtonType}
					label={primaryButtonLabel}
					buttonTitle={primaryButtonTitle}
					onClick={primaryButtonFunction}
					analyticsTag={props.primaryButtonAnalyticsTag}
				/>
				{props.secondaryButtonType && (
					<Button
						buttonType={props.secondaryButtonType}
						label="Cancel"
						buttonTitle={props.secondaryButtonTitle}
						onClick={() => {
							setIsOpened(false);
						}}
						analyticsTag={props.secondaryButtonAnalyticsTag}
					/>
				)}
			</div>
		</dialog>
	);
};
