import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Formik, Form, FormikHelpers } from "formik";
import {
	BackLink,
	Button,
	ErrorBanner,
	Input,
	FileDownload,
	Toast,
} from "components/core";
import { DataContainer } from "containers/DataContainer";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { saveAs } from "file-saver";
import {
	confirmationCodeValidationSchema,
	changePasswordValidationSchema,
	changeEmailValidationSchema,
} from "utils/validations";
import { generateDataCSV } from "data";
import { IAuthErrorInterface } from "types";
import { API } from "aws-amplify";
import "./accountDetails.css";

interface AccountDetailsProps {
	message?: string;
}

interface ChangePasswordValues {
	confirmNewPassword: string;
	password: string;
	oldPassword: string;
}

interface EmailValues {
	emailAddress: string;
}

interface EmailConfirmationValues {
	confirmationNumber: string;
}

export const AccountDetails: React.FC<AccountDetailsProps> = () => {
	const {
		userEmail,
		bragSheets,
		workExperiences,
		jobs,
		companies,
		projects,
		goals,
		educations,
		schools,
		certifications,
		stripeCustomerID,
		setUserEmail,
		setSubmitProfile,
	} = DataContainer.useContainer();
	const [confirmingEmailUpdate, setConfirmingEmailUpdate] = useState<boolean>(
		false
	);
	const [email, setEmail] = useState<string>("");
	const [errorMessageEmail, setErrorMessageEmail] = useState<string>("");
	const [
		errorMessageEmailConfirmation,
		setErrorMessageEmailConfirmation,
	] = useState<string>("");
	const [
		errorMessagePasswordReset,
		setErrorMessagePasswordReset,
	] = useState<string>("");
	const [
		isEmailConfirmationErroring,
		setIsEmailConfirmationErroring,
	] = useState<boolean>(false);
	const [isEmailErroring, setIsEmailErroring] = useState<boolean>(false);
	const [isEmailUpdated, setIsEmailUpdated] = useState<boolean>(false);
	const [isPasswordErroring, setIsPasswordErroring] = useState<boolean>(false);
	const [isPasswordUpdated, setIsPasswordUpdated] = useState<boolean>(false);
	const [updatingEmail, setUpdatingEmail] = useState<boolean>(false);
	const [updatingPassword, setUpdatingPassword] = useState<boolean>(false);

	const downloadUserData = () => {
		const csv = generateDataCSV(
			bragSheets,
			workExperiences,
			jobs,
			companies,
			projects,
			goals,
			educations,
			schools,
			certifications,
		);

		const blob = new Blob([csv], { type: "text/plain" });
		const fileName = "cm_data" + ".csv";
		saveAs(blob, fileName);
	};

	const changePassword = async (oldPassword: string, newPassword: string) => {
		const user = await Auth.currentAuthenticatedUser();
		try {
			Auth.changePassword(user, oldPassword, newPassword);
			setIsPasswordUpdated(true);
			setUpdatingPassword(false);
		} catch (error) {
			let message = "Unknown error";
			if (error instanceof Error) message = error.message;
			setErrorMessagePasswordReset(message);
			setIsPasswordErroring(true);
			if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
				console.log("failed with error", error);
			}
		}
	};

	const updateEmail = async (newEmail: string) => {
		const user = await Auth.currentAuthenticatedUser();
		try {
			await Auth.updateUserAttributes(user, {
				email: newEmail,
			});
			setIsEmailErroring(false);
			setConfirmingEmailUpdate(true);
		} catch (error) {
			let message = "Unknown error";
			if (error instanceof Error) message = error.message;
			setErrorMessageEmail(message);
			setIsEmailErroring(true);
			if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
				console.log("failed with error:", error);
			}
		}
	};
	
	const updateEmailInStripe = async () => {
		try {
			const fetchSession = async () => {
				const apiName = "careerminder";
				const apiEndpoint = "/stripeEmail/update";
				const body = {
					customerId: stripeCustomerID,
					email: email,
				};
				const session = await API.post(apiName, apiEndpoint, { body });
				return session;
			};
			const response = await fetchSession();
			if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
				console.log("Updating Email Response", response);
			}
		} catch(error:any) {
			if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
				console.log("Updating Email in Stripe", error);
			}
		}
	};

	const verifyEmailValidationCode = async (code: string) => {
		await Auth.verifyCurrentUserAttributeSubmit("email", code)
			.then(() => {
				updateEmailInStripe();
				setConfirmingEmailUpdate(false);
				setIsEmailConfirmationErroring(false);
				setIsEmailUpdated(true);
				setUpdatingEmail(false);
				setUserEmail(email);
				setSubmitProfile(true); // this needs to go last
			})
			.catch((error:IAuthErrorInterface) => {
				setErrorMessageEmailConfirmation(error.message);
				setIsEmailConfirmationErroring(true);
				if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
					console.log("failed with error", error);
				}
			});
	};

	const changePasswordInitialValues: ChangePasswordValues = {
		confirmNewPassword: "",
		password: "",
		oldPassword: "",
	};

	const emailInitialValues: EmailValues = {
		emailAddress: "",
	};

	const emailConfirmationInitialValues: EmailConfirmationValues = {
		confirmationNumber: "",
	};

	return (
		<>
			{updatingEmail || updatingPassword ? (
				updatingEmail === true ? (
					<>
						<BackLink
							button={true}
							label="Return to Login details"
							title="Return to Login details"
							onClick={() => setUpdatingEmail(false)}
						/>
						{!confirmingEmailUpdate ? (
							<Formik
								initialValues={emailInitialValues}
								isValid
								onSubmit={async (values: EmailValues) => {
									try {
										setEmail(values.emailAddress);
										updateEmail(values.emailAddress);
									} catch (error) {
										if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
											console.log("error signing in", error);
										}
									}
								}}
								validationSchema={changeEmailValidationSchema}
							>
								{({ dirty, isValid }) => (
									<Form className="pattern--tab-pane__child-wrapper">
										<Input
											label="Enter a new email address"
											name="emailAddress"
											type="email"
										/>
										{isEmailErroring && (
											<ErrorBanner message={errorMessageEmail} />
										)}
										<Button
											label="Confirm Updated Email Address"
											buttonTitle="Update your current email address"
											buttonType="primary"
											disabled={!(isValid && dirty)}
											type="submit"
										/>
									</Form>
								)}
							</Formik>
						) : (
							<Formik
								initialValues={emailConfirmationInitialValues}
								onSubmit={async (
									values: EmailConfirmationValues,
									{ setSubmitting }: FormikHelpers<EmailConfirmationValues>
								) => {
									try {
										verifyEmailValidationCode(values.confirmationNumber);
										setSubmitting(false);
									} catch (error) {
										if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
											console.log("error signing in", error);
										}
									}
								}}
								validationSchema={confirmationCodeValidationSchema}
							>
								<Form className="pattern--tab-pane__child-wrapper">
									<p className="type--body--large">Confirmation code sent:</p>
									<Input label="Confirmation Code" name="confirmationNumber" />
									{isEmailConfirmationErroring && (
										<ErrorBanner message={errorMessageEmailConfirmation} />
									)}
									<Button label="Confirm" type="submit" />
								</Form>
							</Formik>
						)}
					</>
				) : (
					<>
						<BackLink
							button={true}
							label="Cancel and return to Login details"
							title="Cancel and return to Login details"
							onClick={() => setUpdatingPassword(false)}
						/>
						<Formik
							initialValues={changePasswordInitialValues}
							isValid
							onSubmit={async (values: ChangePasswordValues) => {
								try {
									changePassword(values.oldPassword, values.password);
								} catch (error) {
									console.log("error signing in", error);
								}
							}}
							validationSchema={changePasswordValidationSchema}
						>
							<Form className="pattern--tab-pane__child-wrapper">
								<Input
									label="Current Password"
									name="oldPassword"
									type="password"
									value=""
								/>
								<Input
									label="New Password"
									name="password"
									type="password"
									value=""
								/>
								<Input
									label="Confirm New Password"
									type="password"
									name="confirmNewPassword"
									value=""
								/>
								{isPasswordErroring && (
									<ErrorBanner message={errorMessagePasswordReset} />
								)}
								<Button
									label="Change Password"
									buttonTitle="Update your current password"
									buttonType="primary"
									type="submit"
								/>
							</Form>
						</Formik>
					</>
				)
			) : (
				<>
					<h2 className="type--heading-3 pane-heading">Login</h2>
					<Input
						label="Email Address"
						name="emailAddress"
						readonly={true}
						value={userEmail}
					/>
					<Button
						label="Update Email Address"
						buttonTitle="Update your current email address"
						buttonType="utility"
						onClick={() => setUpdatingEmail(true)}
					/>
					<h2 className="type--heading-3 pane-heading">Password</h2>
					<div className="pattern--button-row">
						<Button
							label="Change Password"
							buttonTitle="Update your current password"
							buttonType="utility"
							onClick={() => setUpdatingPassword(true)}
						/>
					</div>
					<hr />
					<h2 className="type--heading-3 pane-heading">User Data</h2>
					<p className="type--body--large">
						You can download your user data below.
					</p>
					<FileDownload
						fileName="cm_data.csv"
						buttonTitle="Download cm_data.csv"
						downloadFunction={() => downloadUserData()}
						fillContainer={false}
					/>
					<Toast
						isOpen={isEmailUpdated}
						message={`Your email address has been updated to ${userEmail}`}
						lightTheme={true}
					/>
					<Toast
						isOpen={isPasswordUpdated}
						message={`Your password has been updated`}
						lightTheme={true}
					/>
				</>
			)}
		</>
	);
};
