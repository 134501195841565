import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Formik, Form, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, ErrorBanner, Input } from "components/core";
import { resetValidationSchema } from "utils/validations";
import "./authenticator.css";

export const Forgot: React.FC = () => {
	const navigate = useNavigate();

	interface Values {
		email: string;
	}

	const initialValues = {
		email: "",
	};

	const [isErroring, setIsErroring] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const confirm = async (email: string) => {
		try {
			await Auth.forgotPassword(email);
			setIsErroring(false);
			navigate("/forgotconfirmation", { replace: true });
		} catch (error) {
			let message = "Unknown error";
			if (error instanceof Error) message = error.message;
			setErrorMessage(message);
			setIsErroring(true);
			console.log("error resetting password", error);
		}
	};

	return (
		<div className="body-wrapper">
			<section className="pattern--container--flow pattern--container--flow-narrow">
				<h1 className="type--heading-1 pattern--container--flow__heading">
					Forgot
				</h1>
				<div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
					<Formik
						initialValues={initialValues}
						onSubmit={async (
							values: Values,
							{ setSubmitting }: FormikHelpers<Values>
						) => {
							try {
								confirm(values.email);
								setSubmitting(false);
							} catch (error) {
								console.log("error resetting password", error);
							}
						}}
						validationSchema={resetValidationSchema}
					>
						<Form className="pattern--login-signup-form">
							<Input label="Email Address:" name="email" />
							{isErroring && <ErrorBanner message={errorMessage} />}
							<Button label="Reset Password" type="submit" />
						</Form>
					</Formik>
				</div>
			</section>
		</div>
	);
};
