import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SequenceStepper } from "components/core";
import { ReactComponent as IconDocument } from "components/core/assets/img/document.svg";
import { ReactComponent as IconDonutSpinner } from "components/core/assets/img/donut-spinner.svg";
import { DataContainer } from "containers/DataContainer";
import { ICertification, ICompany, IEducation, IJob, ISchool, IWorkExperience } from "types";
import { getCompletion, getEmbedding, uploadResume, pickSuggestedGoalForVector } from "utils";
import "./resumeUpload.css";

interface FileWithPreview extends File {
    preview?: string;
}

interface ResumeCertification {
    certificationName?: string;
    certificationIssuer?: string;
    expirationDate?: string;
    verificationLink?: string;
}

interface ResumeExperience {
    experience: string;
}

interface ResumeDegree {
    degreeName: string;
    school?: string;
    completionDate?: string;
}

interface ResumeJob {
    jobTitle: string;
    company?: string;
    startDate?: string;
    endDate?: string;
    experiences?: ResumeExperience[];
}

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

export const ResumeUpload: React.FC = () => {
    const {
        EmptyCertification,
        certifications,
        setCertifications,
        EmptyCompany,
        companies,
        setCompanies,
        EmptyEducation,
        educations,
        setEducations,
        goals,
        EmptyJob,
        jobs,
        setJobs,
        EmptySchool,
        schools,
        setSchools,
        EmptyWorkExperience,
        workExperiences,
        setWorkExperiences,
        user,
    } = DataContainer.useContainer();
    const navigate = useNavigate();
    const [isUpload, setIsUpload] = useState<boolean>(true);
    const [file, setFile] = useState<FileWithPreview | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [resumeText, setResumeText] = useState<string>("");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const formatFileSize = (bytes: number): string => {
        if (bytes >= 1024 * 1024) {
            return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        } else if (bytes >= 1024) {
            return `${(bytes / 1024).toFixed(2)} KB`;
        } else {
            return `${bytes} bytes`;
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            validateAndSetFile(droppedFile);
        }
    };

    const validateAndSetFile = (selectedFile: File) => {
        setError(null);

        if (!isValidFileType(selectedFile)) {
            setError("Invalid file type. Please upload a PDF, Word, or TXT file.");
            return;
        }

        if (selectedFile.size > MAX_FILE_SIZE) {
            setError("File is too large. Please upload a file 2MB or smaller.");
            return;
        }
        const fileWithPreview = Object.assign(selectedFile, {
            preview: URL.createObjectURL(selectedFile),
        });
        setFile(fileWithPreview);
    };

    const isValidFileType = (file: File): boolean => {
        const validTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/plain",
        ];
        return validTypes.includes(file.type);
    };

    const clearFile = () => {
        setFile(null);
        setError(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const sendResume = async () => {
        setIsSubmitting(true);
        let resumeData: string;
        if (isUpload && file) {
            const resumeDataFromUpload = await uploadResume(file);
            resumeData = resumeDataFromUpload;
        } else if (!isUpload && resumeText !== "") {
            const resumeDataFromText = await getCompletion(resumeText, "resumeParsingGeneration");
            resumeData = resumeDataFromText;
        } else {
            resumeData = "";
        }

        const resume = JSON.parse(resumeData);

        if (resume.jobs) {
            const newJobs: IJob[] = [];
            const originalJobs: IJob[] = [];
            const newCompanies: ICompany[] = [];
            const newWorkExperiences: IWorkExperience[] = [];
            resume.jobs.toReversed().map((job: ResumeJob, index: number) => {
                const newJob: IJob = new EmptyJob(user);
                newJob.jobTitle = job.jobTitle;
                // Check if there is a company specified from the resume
                if (job.company) {
                    // Check if the resume’s company already exists, if it does set the job’s companyId to that company, otherwise create a new company
                    if (companies.filter((company: ICompany) => job.company === company.companyName).length) {
                        const existingCompany = companies.find(
                            (company: ICompany) => job.company === company.companyName,
                        );
                        newJob.companyId = existingCompany ? existingCompany.itemId : "";
                    } else {
                        const newCompany: ICompany = new EmptyCompany(user);
                        newCompany.companyName = job.company;
                        newCompanies.push(newCompany);
                        newJob.companyId = newCompany.itemId;
                    }
                }
                // Check if there is a job startDate in the resume. If so set that date as the new job’s startDate
                if (job.startDate) {
                    newJob.startDate = new Date(job.startDate).toUTCString();
                }
                if (job.endDate) {
                    newJob.endDate = new Date(job.endDate).toUTCString();
                }

                // Set the sort order for the new job, so it is always at top
                newJob.sortOrder = index;

                newJobs.push(newJob);

                // Check if there are experiences from the resume. If so create the new Experiences
                if (job.experiences) {
                    job.experiences.map((experience: ResumeExperience) => {
                        const newExperience: IWorkExperience = new EmptyWorkExperience(user);
                        newExperience.description = experience.experience;
                        newExperience.jobId = newJob.itemId;
                        getEmbedding(newExperience.description).then((value) => {
                            const vector: string = value;
                            newExperience.vector = vector;
                            pickSuggestedGoalForVector(newExperience.vector, goals).then((value) => {
                                const goal: string = value;
                                newExperience.goalId = goal;
                            });
                        });
                        newWorkExperiences.push(newExperience);
                    });
                }
            });

            if (jobs.length) {
                jobs.map((job: IJob, index: number) => {
                    const updatedJob: IJob = job;
                    updatedJob.sortOrder = job.sortOrder
                        ? job.sortOrder + resume.jobs.length
                        : index + resume.jobs.length;
                    originalJobs.push(updatedJob);
                });
            }

            const updatedJobs = [...originalJobs, ...newJobs];
            setJobs(updatedJobs);
            const updatedCompanies = [...companies, ...newCompanies];
            setCompanies(updatedCompanies);
            const updatedExperiences = [...newWorkExperiences, ...workExperiences];
            setWorkExperiences(updatedExperiences);
        }

        // Check if there is any education in the resume. If so create them
        if (resume.degrees) {
            const newEducations: IEducation[] = [];
            const originalEducations: IEducation[] = [];
            const newSchools: ISchool[] = [];
            resume.degrees.toReversed().map((degree: ResumeDegree, index: number) => {
                const newDegree: IEducation = new EmptyEducation(user);
                newDegree.degree = degree.degreeName;
                // Check if there is a school specified from the resume
                if (degree.school) {
                    // Check if the resume’s school already exists, if it does set the job’s schoolId to that company, otherwise create a new school
                    if (schools.filter((school: ISchool) => degree.school === school.schoolName).length) {
                        const existingSchool = schools.find(
                            (school: ISchool) => degree.school === school.schoolName,
                        );
                        newDegree.schoolId = existingSchool ? existingSchool.itemId : "";
                    } else {
                        const newSchool: ISchool = new EmptySchool(user);
                        newSchool.schoolName = degree.school;
                        newSchools.push(newSchool);
                        newDegree.schoolId = newSchool.itemId;
                    }
                }

                // Check if there is a degree completionDate in the resume. If so set that date as the new school’s completionDate
                if (degree.completionDate) {
                    newDegree.completionDate = new Date(degree.completionDate).toUTCString();
                }

                // Set the sort order for the new degree, so it is always at top
                newDegree.sortOrder = index;

                newEducations.push(newDegree);
            });

            if (educations.length) {
                educations.map((education: IEducation, index: number) => {
                    const updatedEducation: IEducation = education;
                    updatedEducation.sortOrder = education.sortOrder
                        ? education.sortOrder + resume.degrees.length
                        : index + resume.degrees.length;
                    originalEducations.push(updatedEducation);
                });
            }

            const updatedEducations = [...originalEducations, ...newEducations];
            setEducations(updatedEducations);
            const updatedSchools = [...schools, ...newSchools];
            setSchools(updatedSchools);
        }

        // Check if there is any certifications in the resume. If so create them
        if (resume.certifications) {
            const newCertifications: ICertification[] = [];
            const originalCertifications: ICertification[] = [];
            resume.certifications.toReversed().map((certification: ResumeCertification, index: number) => {
                const newCertification: ICertification = new EmptyCertification(user);

                // Check if there is a certificationName specified from the resume
                if (certification.certificationName) {
                    newCertification.certificationName = certification.certificationName;
                }

                // Check if there is a certificationIssuer specified from the resume
                if (certification.certificationIssuer) {
                    newCertification.certificationIssuer = certification.certificationIssuer;
                }

                // Check if there is a expirationDate specified from the resume
                if (certification.expirationDate) {
                    newCertification.expirationDate = certification.expirationDate;
                }

                // Check if there is a verificationLink specified from the resume
                if (certification.verificationLink) {
                    newCertification.verificationLink = certification.verificationLink;
                }

                // Set the sort order for the new degree, so it is always at top
                newCertification.sortOrder = index;

                newCertifications.push(newCertification);
            });

            if (certifications.length) {
                certifications.map((certification: ICertification, index: number) => {
                    const updatedCertification: ICertification = certification;
                    updatedCertification.sortOrder = certification.sortOrder
                        ? certification.sortOrder + resume.certifications.length
                        : index + resume.certifications.length;
                    originalCertifications.push(updatedCertification);
                });
            }

            const updatedCertifications = [...originalCertifications, ...newCertifications];
            setCertifications(updatedCertifications);
        }

        setIsSubmitting(false);
        navigate("/onboarding/resume/review");
    };

    const IconDonutSpinnerSVG = <IconDonutSpinner />;

    return (
        <section className="pattern--container--flow pattern--container--flow-narrow">
            <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
                <h1 className="type--heading-1">Resume</h1>
                <div className="accessory--resizeable">
                    <SequenceStepper length={6} currentIndex={4} />
                </div>
            </div>
            <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
                <p className="type--body--large">
                    Upload your resume to add your work history. We suggest exporting your resume from
                    LinkedIn.
                </p>
                <div className="upload-text-pane">
                    <div className="button-switch-container">
                        <button
                            className={`switch-button type--body--standard ${isUpload && "switch-button--active"}`}
                            onClick={() => setIsUpload(true)}
                        >
                            Upload
                        </button>
                        <button
                            className={`switch-button type--body--standard ${!isUpload && "switch-button--active"}`}
                            onClick={() => setIsUpload(false)}
                        >
                            Text
                        </button>
                    </div>
                    {isUpload ? (
                        file ? (
                            <>
                                <div className="document-upload">
                                    <IconDocument />
                                    <p className="type--body--large">
                                        {file.name} - {formatFileSize(file.size)}
                                    </p>
                                </div>
                                <Button
                                    label="Clear File"
                                    buttonTitle="Clear file to upload another"
                                    buttonType="utility"
                                    onClick={() => {
                                        clearFile();
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <div
                                    className={`file-upload ${isDragging ? "file-upload--drag-over" : ""}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                    onClick={() => {
                                        setTimeout(() => {
                                            fileInputRef.current?.click();
                                        }, 0);
                                    }}
                                >
                                    <p className="type--body--large">
                                        <strong>Drag resume here or click to upload</strong>
                                    </p>
                                    <p className="type--body--standard">(PDF or Word)</p>
                                    <input
                                        id="resume-upload"
                                        type="file"
                                        className="file-upload-button-input"
                                        ref={fileInputRef}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the div's click handler from firing
                                        }}
                                        onChange={(e) => {
                                            const selectedFile = e.target.files?.[0];
                                            if (selectedFile) {
                                                validateAndSetFile(selectedFile);
                                            } else {
                                                console.log("No file selected");
                                            }
                                        }}
                                        accept=".pdf,.doc,.docx,.txt"
                                    />
                                </div>
                                {error && (
                                    <div className="pattern--field-error-message">
                                        <p className="type--body--large">{error}</p>
                                    </div>
                                )}
                            </>
                        )
                    ) : (
                        <div className="field-wrapper pattern--field-wrapper">
                            <label className="input-container">
                                <span className="pattern--label type--heading-4">Resume Text</span>
                                <textarea
                                    className="pattern--input-field"
                                    rows={5}
                                    value={resumeText}
                                    maxLength={8000}
                                    placeholder="Enter the text of your resume here..."
                                    onChange={(e) => setResumeText(e.target.value)}
                                >
                                    Enter the text of your resume here
                                </textarea>
                            </label>
                        </div>
                    )}
                </div>
                <div className="pattern--button-row">
                    <Button
                        label="Back"
                        buttonTitle="Back to Goals"
                        buttonType="utility"
                        onClick={() => navigate("/onboarding/goals")}
                    />
                    <Button
                        label="Next"
                        buttonTitle="Analyze resume"
                        buttonType="secondary"
                        disabled={(isUpload && !file) || (!isUpload && resumeText === "")}
                        leadingIcon={isSubmitting && IconDonutSpinnerSVG}
                        onClick={() => sendResume()}
                    />
                </div>
            </div>
        </section>
    );
};
