import React from "react";
import "./emptySummaryButton.css";

export interface EmptySummaryButtonProps {
    headline?: string;
    description?: string;
    onClick: () => void;
}

export const EmptySummaryButton: React.FC<EmptySummaryButtonProps> = ({ headline, description, onClick }) => {
    return (
        <button
            className="work-summary-job work-summary-job--empty summary-item summary-item--on-dashboard summary-item--empty"
            onClick={onClick}
        >
            <div className="work-summary-job__empty-state-wrapper summary-item__empty-state-inner-wrapper">
                {headline && (
                    <p className="type--body--large">
                        <strong>{headline}</strong>
                    </p>
                )}
                {description && <p className="type--body--large">{description}</p>}
            </div>
        </button>
    );
};
