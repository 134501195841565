import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { DatePicker, Input, Button, Dialog } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { ICertification } from "types";
import { formatDate, certificationSummaryValidationSchema } from "utils";
import { ReactComponent as IconMinus } from "../../core/assets/img/minus.svg";
import { ReactComponent as IconArrowDown } from "../../core/assets/img/arrow-pointing-down.svg";
import { ReactComponent as IconArrowUp } from "../../core/assets/img/arrow-pointing-up.svg";
import { ReactComponent as IconExternalLink } from "../../core/assets/img/arrow-with-stem-pointing-up-right.svg";
import "./certification.css";

interface Values {
  certification: ICertification;
}
interface CertificationProps {
  autoFocus?: boolean;
  inactivateItem: (itemId: string) => void;
  index: number;
  newCertification: number;
  setNewCertification: (index: number) => void;
  certification: ICertification;
  onDashboard?: boolean;
}

export const Certification: React.FC<CertificationProps> = (props) => {
  const { index, newCertification, setNewCertification, certification, onDashboard } = props;

  const { certifications, setCertifications, subscriptionActive } = DataContainer.useContainer();
  const [editing, setEditing] = useState<boolean>(index === newCertification);
  const [isCertificationDeleteConfirmationOpened, setIsCertificationDeleteConfirmationOpened] =
    useState(false);

  const initialValues = {
    certification,
  };

  const moveCertificationDown = () => {
    const updatedCertifications = [...certifications];
    if (index !== -1 && index < updatedCertifications.length - 1) {
      const el = updatedCertifications[index];
      updatedCertifications[index] = updatedCertifications[index + 1];
      updatedCertifications[index + 1] = el;
      updatedCertifications.forEach(function (_part: ICertification, index, updatedCertifications) {
        updatedCertifications[index].sortOrder = index;
      });
      setCertifications(updatedCertifications);
    }
  };

  const moveCertificationUp = () => {
    const updatedCertifications = [...certifications];
    if (index > 0) {
      const el = updatedCertifications[index];
      updatedCertifications[index] = updatedCertifications[index - 1];
      updatedCertifications[index - 1] = el;
      updatedCertifications.forEach(function (part: ICertification, index, updatedCertifications) {
        updatedCertifications[index].sortOrder = index;
      });
      setCertifications(updatedCertifications);
    }
  };

  const remove = (index: number): void => {
    const updatedCertifications = [...certifications];
    updatedCertifications.splice(index, 1);
    setCertifications(updatedCertifications);
  };

  const IconMinusSVG = <IconMinus />;
  const IconArrowDownSVG = <IconArrowDown />;
  const IconArrowUpSVG = <IconArrowUp />;
  const IconExternalLinkSVG = <IconExternalLink />;

  const DisplayCertification = () => {
    return (
      <>
        <div className="certification-summary-item__header">
          <div className="certification-header-content">
            <h2 className="type--heading-4">
              {certification.certificationName}
              {certification.certificationIssuer && <> &bull; {certification.certificationIssuer}</>}
            </h2>
            <p className="type--body--standard certification-header-content__date">
              {certification.expirationDate ? (
                <>Expires: {`${formatDate(certification.expirationDate)}`}</>
              ) : (
                <>No Expiration Date</>
              )}
            </p>
          </div>
        </div>
        {certification.verificationLink && (
          <div className="certification-summary-item__content">
            <Button
              label="Verification"
              buttonTitle="Visit Verification Link"
              buttonType="utility"
              size="medium"
              trailingIcon={IconExternalLinkSVG}
              disabled={!subscriptionActive}
              onClick={() => window.open(certification.verificationLink, "_blank")}
            />
          </div>
        )}
        <div className="certification-summary-item__footer">
          <Button
            label="Edit"
            buttonTitle="Edit this certification"
            buttonType="utility"
            size="small"
            disabled={!subscriptionActive}
            onClick={() => setEditing(true)}
          />
          <div className="up-down-commands">
            <Button
              label="Move Down"
              buttonTitle="Move Certification Down"
              buttonType="icon-only"
              size="small"
              disabled={!subscriptionActive}
              leadingIcon={IconArrowDownSVG}
              onClick={() => moveCertificationDown()}
            />
            <Button
              label="Move Up"
              buttonTitle="Move Certification Up"
              buttonType="icon-only"
              size="small"
              disabled={!subscriptionActive}
              leadingIcon={IconArrowUpSVG}
              onClick={() => moveCertificationUp()}
            />
          </div>
        </div>
      </>
    );
  };

  const EditCertification = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
          const updatedCertifications = [...certifications];
          updatedCertifications.splice(index, 1, values.certification);
          setCertifications(updatedCertifications);
          setSubmitting(false);
          setEditing(false);
          setNewCertification(-1);
        }}
        validationSchema={certificationSummaryValidationSchema}
      >
        <Form className="certification-summary-item-form-wrapper">
          <Input
            label="Name"
            name={`certification.certificationName`}
            placeholder="What is the certification?"
          />
          <Input
            label="Issuer"
            name={`certification.certificationIssuer`}
            placeholder="Who issued the certification?"
          />
          <DatePicker
            key={`we-expirationDate${index}`}
            name={`certification.expirationDate`}
            label="Expiration Date"
          />
          <Input
            label="Verification Link"
            name={`certification.verificationLink`}
            placeholder="Where your certification can be verified?"
          />
          <div className="pattern--button-row pattern--button-row--space-between">
            <Button
              label="Delete"
              buttonTitle="Delete Experience"
              onClick={() => setIsCertificationDeleteConfirmationOpened(true)}
              buttonType="utility"
              leadingIcon={IconMinusSVG}
            />
            <Dialog
              isOpened={isCertificationDeleteConfirmationOpened}
              setIsOpened={setIsCertificationDeleteConfirmationOpened}
              primaryButtonType="primary"
              primaryButtonTitle="Confirm Certification Deletion"
              primaryButtonLabel="Delete"
              primaryButtonFunction={() => {
                remove(index);
                setIsCertificationDeleteConfirmationOpened(false);
                document.body.classList.remove("dialog-open");
              }}
              primaryButtonClosesDialog={true}
              secondaryButtonType="utility"
              secondaryButtonTitle="Cancel Certification Deletion"
              secondaryButtonLabel="Cancel"
            >
              <p className="type--body--large">
                Are you sure you want to delete <strong>{certification.certificationName}</strong>?
              </p>
            </Dialog>
            <div className="pattern--button-row">
              <Button
                buttonTitle="Cancel Edits"
                buttonType="utility"
                label="Cancel"
                onClick={() => {
                  if (newCertification >= 0) {
                    remove(index);
                  }
                  setNewCertification(-1);
                  setEditing(false);
                }}
              />
              <Button buttonTitle="Save Edits" buttonType="primary" label="Save" type="submit" />
            </div>
          </div>
        </Form>
      </Formik>
    );
  };

  return (
    <div
      className={`certification-summary-item summary-item ${editing && "certification-summary-item--edit"} ${onDashboard && "summary-item--on-dashboard"}`}
      data-item={index}
    >
      {editing ? <EditCertification /> : <DisplayCertification />}
    </div>
  );
};
