import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { Button, Dialog } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { IProject, IWorkExperience } from "types";
import { ReactComponent as IconTrash } from "components/core/assets/img/trash.svg";
import "./jobExperience.css";

interface JobExperienceProps {
  editing: boolean;
  jobId: string;
  open: boolean;
  experiencesToUpdate: IWorkExperience[];
  setExperiencesToUpdate: (value: IWorkExperience[]) => void;
  projectsToDelete: IProject[];
  setProjectsToDelete: (value: IProject[]) => void;
}

interface IProjectWithExperiences {
  project: IProject;
  experiences: IWorkExperience[];
}

export const JobExperience: React.FC<JobExperienceProps> = (props) => {
  const {
    editing,
    jobId,
    open = true,
    experiencesToUpdate,
    setExperiencesToUpdate,
    projectsToDelete,
    setProjectsToDelete,
  } = props;

  const navigate = useNavigate();

  const { projects, workExperiences } = DataContainer.useContainer();
  const [projectDeleteConfirmation, setProjectDeleteConfirmation] = useState<{
    [key: string]: boolean;
  }>({});

  const buildJobWorkExperiences = (): IWorkExperience[] => {
    return workExperiences
      .filter(
        (experience: IWorkExperience) =>
          experience.jobId !== undefined &&
          experience.jobId.indexOf(jobId) !== -1,
      )
      .sort((a: IWorkExperience, b: IWorkExperience) => {
        const dateA = a.completionDate
          ? new Date(a.completionDate)
          : new Date(a.dateCreated);
        const dateB = b.completionDate
          ? new Date(b.completionDate)
          : new Date(b.dateCreated);
        return dateB.getTime() - dateA.getTime();
      });
  };
  const [jobWorkExperiences, setJobWorkExperiences] = useState<
    IWorkExperience[]
  >(buildJobWorkExperiences());
  const buildUnassignedWorkExperiences = (): IWorkExperience[] => {
    return jobWorkExperiences.filter(
      (experience: IWorkExperience) =>
        !projects.some(
          (project: IProject) => experience.projectId === project.itemId,
        ),
    );
  };
  const [unassignedWorkExperiences, setUnassignedWorkExperiences] = useState<
    IWorkExperience[]
  >(buildUnassignedWorkExperiences());
  const buildJobProjects = (): IProject[] => {
    return projects.filter(
      (project: IProject) => project.jobId.indexOf(jobId) !== -1,
    );
  };
  const [jobProjects, setJobProjects] =
    useState<IProject[]>(buildJobProjects());

  const buildProjectsWithExperience = (): IProjectWithExperiences[] => {
    const projectsWithExperienceList: IProjectWithExperiences[] = [];
    jobProjects.map((project: IProject) => {
      const projectWithExperienceToPush: IProjectWithExperiences = {
        project: project,
        experiences: jobWorkExperiences.filter(
          (experience: IWorkExperience) =>
            experience.projectId !== undefined &&
            experience.projectId.indexOf(project.itemId) !== -1,
        ),
      };
      projectsWithExperienceList.push(projectWithExperienceToPush);
    });
    return projectsWithExperienceList;
  };

  const [projectsWithExperience, setProjectsWithExperience] = useState<
    IProjectWithExperiences[]
  >(buildProjectsWithExperience());

  const [totalWorkExperiences, setTotalWorkExperiences] = useState<number>(
    jobWorkExperiences.length,
  );

  useEffect(() => {
    if (!editing) {
      setJobWorkExperiences(buildJobWorkExperiences());
    }
  }, [editing]);

  useEffect(() => {
    if (!editing) {
      setUnassignedWorkExperiences(buildUnassignedWorkExperiences());
      setJobProjects(buildJobProjects());
      setProjectsWithExperience(buildProjectsWithExperience());
      setTotalWorkExperiences(jobWorkExperiences.length);
    }
  }, [editing, jobWorkExperiences]);

  const updateOrAddExperience = (
    experience: IWorkExperience,
    arr: IWorkExperience[],
  ) => {
    const index = arr.findIndex((item) => item.itemId === experience.itemId);
    if (index !== -1) {
      arr[index] = experience;
    } else {
      arr.push(experience);
    }
  };

  const updateOrAddProject = (project: IProject, arr: IProject[]) => {
    const index = arr.findIndex((item) => item.itemId === project.itemId);
    if (index !== -1) {
      arr[index] = project;
    } else {
      arr.push(project);
    }
  };

  const removeExperienceFromJob = (itemId: string) => {
    setJobWorkExperiences(
      jobWorkExperiences.filter(
        (experience: IWorkExperience) => experience.itemId !== itemId,
      ),
    );
    setTotalWorkExperiences(jobWorkExperiences.length);
    setUnassignedWorkExperiences(
      unassignedWorkExperiences.filter(
        (experience: IWorkExperience) => experience.itemId !== itemId,
      ),
    );

    const experienceToUpdate = unassignedWorkExperiences.find(
      (experience: IWorkExperience) => experience.itemId === itemId,
    );
    if (experienceToUpdate) {
      const updatedExperience: IWorkExperience = {
        ...experienceToUpdate,
        jobId: "",
      };
      const updatedExperiencesToUpdate = [...experiencesToUpdate];
      updateOrAddExperience(updatedExperience, updatedExperiencesToUpdate);
      setExperiencesToUpdate(updatedExperiencesToUpdate);
    }
  };

  const removeExperienceFromProject = (itemId: string) => {
    const newProjectsWithExperience: IProjectWithExperiences[] = [];
    const updatedUnassignedWorkExperiences = unassignedWorkExperiences;
    projectsWithExperience.map(
      (projectWithExperience: IProjectWithExperiences) => {
        const experiences: IWorkExperience[] = [];
        projectWithExperience.experiences.map((experience: IWorkExperience) => {
          if (experience.itemId === itemId) {
            const updatedExperience: IWorkExperience = {
              ...experience,
              projectId: "",
            };
            updatedUnassignedWorkExperiences.push(updatedExperience);
            const updatedExperiencesToUpdate = [...experiencesToUpdate];
            updateOrAddExperience(
              updatedExperience,
              updatedExperiencesToUpdate,
            );
            setExperiencesToUpdate(updatedExperiencesToUpdate);
          } else {
            experiences.push(experience);
          }
        });
        projectWithExperience.experiences = experiences;
        newProjectsWithExperience.push(projectWithExperience);
      },
    );
    if (!isEqual(newProjectsWithExperience, projectsWithExperience)) {
      setProjectsWithExperience(newProjectsWithExperience);
    }
    if (!isEqual(updatedUnassignedWorkExperiences, workExperiences)) {
      setUnassignedWorkExperiences(updatedUnassignedWorkExperiences);
    }
  };

  const deleteProject = (itemId: string) => {
    const updatedProjectsWithExperience = projectsWithExperience.filter(
      (projectWithExperiences: IProjectWithExperiences) =>
        projectWithExperiences.project.itemId !== itemId,
    );
    setProjectsWithExperience(updatedProjectsWithExperience);

    const updatedUnassignedWorkExperiences = [...unassignedWorkExperiences];
    const projectWithExperienceToDelete = projectsWithExperience.find(
      (projectWithExperiences: IProjectWithExperiences) =>
        projectWithExperiences.project.itemId === itemId,
    );
    if (projectWithExperienceToDelete) {
      projectWithExperienceToDelete.experiences.forEach(
        (experience: IWorkExperience) => {
          const updatedExperience: IWorkExperience = {
            ...experience,
            projectId: "",
          };
          const updatedExperiencesToUpdate = [...experiencesToUpdate];
          updateOrAddExperience(updatedExperience, updatedExperiencesToUpdate);
          setExperiencesToUpdate(updatedExperiencesToUpdate);
          updatedUnassignedWorkExperiences.push(updatedExperience);
        },
      );
    }

    const updatedJobProjects = jobProjects.filter(
      (project: IProject) => project.itemId !== itemId,
    );
    setJobProjects(updatedJobProjects);
    setUnassignedWorkExperiences(updatedUnassignedWorkExperiences);

    const projectToDelete = jobProjects.find(
      (project: IProject) => project.itemId === itemId,
    );
    const updatedProjectsToDelete = [...projectsToDelete];
    if (projectToDelete) {
      updateOrAddProject(projectToDelete, updatedProjectsToDelete);
      setProjectsToDelete(updatedProjectsToDelete);
    }
  };

  const IconTrashSVG = <IconTrash />;

  return (
    <div className={`job-work-experiences`}>
      <details open={open}>
        <summary>
          <h3 className="type--heading-6">Experience</h3>
          <span className="type--heading-6">{totalWorkExperiences}</span>
        </summary>
        <div className="job-work-experience-list">
          {totalWorkExperiences > 0 ? (
            <>
              {unassignedWorkExperiences.length === 1 ? (
                <p className="type--body--standard">
                  <div
                    className={`job-experience-summary ${editing && "job-experience-summary--actions"}`}
                  >
                    {unassignedWorkExperiences[0].description}
                    {editing && (
                      <Button
                        buttonType="utility"
                        buttonTitle="Remove Experience from Job"
                        label="Remove from Job"
                        size="small"
                        onClick={() =>
                          removeExperienceFromJob(
                            unassignedWorkExperiences[0].itemId,
                          )
                        }
                      />
                    )}
                  </div>
                </p>
              ) : (
                <ul>
                  {unassignedWorkExperiences.map(
                    (experience: IWorkExperience) => {
                      return (
                        <li
                          className="type--body--standard"
                          key={experience.itemId}
                        >
                          <div
                            className={`job-experience-summary ${editing && "job-experience-summary--actions"}`}
                          >
                            {experience.description}
                            {editing && (
                              <Button
                                buttonType="utility"
                                buttonTitle="Remove Experience from Job"
                                label="Remove from Job"
                                size="small"
                                onClick={() =>
                                  removeExperienceFromJob(experience.itemId)
                                }
                              />
                            )}
                          </div>
                        </li>
                      );
                    },
                  )}
                </ul>
              )}

              {projectsWithExperience.map(
                (projectWithExperiences: IProjectWithExperiences) => {
                  const projectId = projectWithExperiences.project.itemId;
                  return (
                    <div
                      className="project-in-job"
                      key={projectWithExperiences.project.itemId}
                    >
                      <div
                        className={`project__header ${editing && "project__header--actions"} ${projectWithExperiences.experiences.length === 0 && "project__header--no-experience"}`}
                      >
                        <h4 className="type--heading-5">
                          {projectWithExperiences.project.projectName}
                        </h4>
                        {editing && (
                          <>
                            <Button
                              buttonType="icon-only"
                              buttonTitle="Delete this project"
                              label="Delete Project"
                              size="small"
                              leadingIcon={IconTrashSVG}
                              onClick={() =>
                                setProjectDeleteConfirmation((prevState) => ({
                                  ...prevState,
                                  [projectId]: true,
                                }))
                              }
                            />
                            <Dialog
                              isOpened={
                                projectDeleteConfirmation[projectId] || false
                              }
                              setIsOpened={(isOpened) =>
                                setProjectDeleteConfirmation((prevState) => ({
                                  ...prevState,
                                  [projectId]: isOpened,
                                }))
                              }
                              primaryButtonType="primary"
                              primaryButtonTitle="Confirm Project Deletion"
                              primaryButtonLabel="Delete"
                              primaryButtonFunction={() => {
                                deleteProject(
                                  projectWithExperiences.project.itemId,
                                );
                                document.body.classList.remove("dialog-open");
                              }}
                              primaryButtonClosesDialog={true}
                              secondaryButtonType="utility"
                              secondaryButtonTitle="Cancel Project Deletion"
                              secondaryButtonLabel="Cancel"
                            >
                              <p className="type--body--large">
                                Are you sure you want to delete{" "}
                                <strong>
                                  {projectWithExperiences.project.projectName}
                                </strong>
                                ?
                              </p>
                            </Dialog>
                          </>
                        )}
                      </div>
                      {projectWithExperiences.experiences.length > 0 && (
                        <div className="project__content">
                          {projectWithExperiences.experiences.length === 1 ? (
                            <p className="type--body--standard">
                              <div
                                className={`job-experience-summary ${editing && "job-experience-summary--actions"}`}
                              >
                                {
                                  projectWithExperiences.experiences[0]
                                    .description
                                }
                                {editing && (
                                  <Button
                                    buttonType="utility"
                                    buttonTitle="Remove Experience from Project"
                                    label="Remove from Project"
                                    size="small"
                                    onClick={() =>
                                      removeExperienceFromProject(
                                        unassignedWorkExperiences[0].itemId,
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </p>
                          ) : (
                            <ul>
                              {projectWithExperiences.experiences.map(
                                (experience: IWorkExperience) => {
                                  return (
                                    <li
                                      className="type--body--standard"
                                      key={experience.itemId}
                                    >
                                      <div
                                        className={`job-experience-summary ${editing && "job-experience-summary--actions"}`}
                                      >
                                        {experience.description}
                                        {editing && (
                                          <Button
                                            buttonType="utility"
                                            buttonTitle="Remove Experience from Project"
                                            label="Remove from Project"
                                            size="small"
                                            onClick={() =>
                                              removeExperienceFromProject(
                                                experience.itemId,
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </li>
                                  );
                                },
                              )}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  );
                },
              )}
            </>
          ) : (
            <button
              className="empty-experience-button pattern--empty-state--fill-area"
              onClick={() => navigate("experience/new")}
            >
              <h3 className="type--heading-4">No Experience</h3>
              <p className="type--body--standard">
                Click here to add some on the Experience page.
              </p>
            </button>
          )}
        </div>
      </details>
    </div>
  );
};
