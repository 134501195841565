import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SearchPrimary } from "components/core";
import { ReactComponent as IconSearch } from "../core/assets/img/search.svg";
import { ReviewItem } from "./ReviewItem";
import { DataContainer } from "containers/DataContainer";
import { IReview } from "types";
import "./reviews.css";

export interface ReviewsProps {
	something?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Reviews: React.FC<ReviewsProps> = () => {
	const {
		reviews,
		subscriptionActive,
		userDataLoaded
	} = DataContainer.useContainer();
	
	const navigate = useNavigate();
	
	const today = new Date();
	
	const [
		currentlySelectedReviewRange,
		setCurrentlySelectedReviewRange,
	] = useState<number>(0);
	
	const [searching, setSearching] = useState<boolean>(false);
		
	const [searchTerm, setSearchTerm] = useState<string>("");
		
	const [searchResults, setSearchResults] = useState<IReview[]>([]);
	
	const onChangeSearch = (value: string) => {
		setSearchTerm(value);
	};
	
	useEffect(() => {
		if (searchTerm !== "") {
			setSearchResults(reviews.filter((review: IReview) => {
				const searchString = review.name.toLowerCase() + review.relatedContent?.toLowerCase();
				return searchString.includes(searchTerm.toLowerCase());
			}));
		} else {
			setSearchResults([]);
		}
	}, [searchTerm]);
	
	const clearSearch = () => {
		setSearchTerm("");
	};
	
	const IconSearchSVG = <IconSearch />;
	
	return (
		<div className="reviews-outer-wrapper">
			<section className="reviews-container">
				<div className="header--withaction reviews-masthead">
					<div className="header--withaction__title-grouping">
						<h1 className="type--heading-1">Reviews</h1>
						<div className="small-toggle-row">
							<button
								className={`toggle-button toggle-button--small type--data ${
									currentlySelectedReviewRange === 0 &&
									"toggle-button--selected"
								}`}
								onClick={() => setCurrentlySelectedReviewRange(0)}
								title="Reviews with a review date in the last 7 days or the future"
							>
								Current & Upcoming
							</button>
							<button
								className={`toggle-button toggle-button--small type--data ${
									currentlySelectedReviewRange === 1 &&
									"toggle-button--selected"
								}`}
								onClick={() => setCurrentlySelectedReviewRange(1)}
								title="Reviews with a review date 7 days or further in the past"
							>
								Past
							</button>
						</div>
					</div>
					<div className="header--withaction__action-grouping">
						<Button
							buttonType="icon-only"
							label="Search Reviews"
							buttonTitle="Start a search of your reviews"
							disabled={searching ? true : false}
							onClick={() => setSearching(true)}
							leadingIcon={IconSearchSVG}
							type="button"
						/>
						<Button
							label="Create Review"
							buttonTitle="Create a new Review"
							disabled={!subscriptionActive || !userDataLoaded ? true : false}
							buttonType="primary"
							onClick={() => navigate("new")}
						/>
					</div>
				</div>
				<div className="main-content-card reviews-log">
					{searching ? (
						<>
							<SearchPrimary
								clearSearch={clearSearch}
								closeSearch={() => setSearching(false)}
								fieldOnChange={onChangeSearch}
								searchResultsLength={searchResults.length}
								searchValue={searchTerm}
								shouldAutoFocus={true}
							/>
							{searchResults.length > 0 &&
								<>
									{searchResults.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map((review: IReview, index: number) => {
										return (
											<ReviewItem
												index={index}
												key={review.itemId}
												review={review}
											/>
										);
										// eslint-disable-next-line no-mixed-spaces-and-tabs
									})}
								</>
							}
						</>
					) : (
						<>
							{currentlySelectedReviewRange === 0 ?
								<>
									{reviews.filter(
											(review: IReview) => new Date(review.nextKeyDate).valueOf() > new Date(new Date().setDate(today.getDate() - 7)).valueOf()
										).length
										? reviews.filter(
											(review: IReview) => new Date(review.nextKeyDate).valueOf() > new Date(new Date().setDate(today.getDate() - 1)).valueOf()
										).sort(
											(a, b) => new Date(a.nextKeyDate).valueOf() - new Date (b.nextKeyDate).valueOf()
										).map((review: IReview, index: number) => {
												return (
													<ReviewItem
														index={index}
														key={review.itemId}
														review={review}
													/>
												);
												// eslint-disable-next-line no-mixed-spaces-and-tabs
							  			})
										: 
										<div className="bragsheets-empty-state pattern--empty-state--fill-area">
											<h3 className="type--heading-3">No Current or Upcoming Reviews</h3>
											<p className="type--body--large">
												Create a review with the button above.
											</p>
										</div>
									}
								</>
								:
								<>
									{reviews.filter(
											(review: IReview) => new Date(review.nextKeyDate).valueOf() < new Date(new Date().setDate(today.getDate() - 7)).valueOf()
										).length
										? reviews.filter(
											(review: IReview) => new Date(review.nextKeyDate).valueOf() < new Date(new Date().setDate(today.getDate() - 1)).valueOf()
										).sort(
											(a, b) => new Date(b.nextKeyDate).valueOf() - new Date (a.nextKeyDate).valueOf()
										).map((review: IReview, index: number) => {
												return (
													<ReviewItem
														index={index}
														key={review.itemId}
														review={review}
													/>
												);
												// eslint-disable-next-line no-mixed-spaces-and-tabs
								  		})
										:
										<div className="bragsheets-empty-state pattern--empty-state--fill-area">
											<h3 className="type--heading-3">No Past Reviews</h3>
											<p className="type--body--large">
												Reviews will display here once their Review Date has passed
											</p>
										</div>
									}
								</>
							}
						</>
					)}
				</div>
			</section>
		</div>
	);
};