import React, { useState } from "react";
import { ReactComponent as IconArrowPointingLeft } from "components/core/assets/img/arrow-pointing-left.svg";
import { ReactComponent as IconArrowPointingRight } from "components/core/assets/img/arrow-pointing-right.svg";
import { ReactComponent as IconDonutSpinner } from "components/core/assets/img/donut-spinner.svg";
import { Button } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { API } from "aws-amplify";
import "./subscriptionPausedModal.css";

export interface SubscriptionPausedModalProps {
	something?: string;
}

/**
 * Primary UI component for user interaction
 */
export const SubscriptionPausedModal: React.FC<SubscriptionPausedModalProps> = ({
	something,
	...props
}) => {
	const {
		stripeCustomerID,
	} = DataContainer.useContainer();
	
	const [currentSlide, setCurrentSlide] = useState<number>(0);
	const [launchingSubscriptionManagement, setLaunchingSubscriptionManagement] = useState<boolean>(false);
		
	const IconPointingLeftSVG = <IconArrowPointingLeft />;
	const IconPointingRightSVG = <IconArrowPointingRight />;
	const IconDonutSpinnerSVG = <IconDonutSpinner />;
		
	const manageSubscriptions = async () => {
		setLaunchingSubscriptionManagement(true);
		const url = `${window.location.origin.toString()}`;
		const fetchSession = async () => {
			const apiName = "careerminder";
			const apiEndpoint = "/subscription/manage";
			const body = {
				customerId: stripeCustomerID,
				returnUrl: url,
			};
			const session = await API.post(apiName, apiEndpoint, { body });
			return session;
		};
		const session = await fetchSession();
		window.location.href = session.url;
		setLaunchingSubscriptionManagement(false);
	};
	
	const backgroundImageStyle = {
		backgroundImage: "url('/assets/img/illustration_paused_subscription_modal_background.png')",
	};

	return (
		<div className="subscription-paused-modal-wrapper">
			<div className="subscription-paused-modal">
				<div className="subscription-paused-modal__main-content" style={backgroundImageStyle}>
					<div className="subscription-paused-modal__header">
						<h2>Your Trial has Ended</h2>
						<div className="slider-nav">
							<Button
								buttonTitle="Previous slide"
								buttonType="icon-only"
								label="Previous"
								theme="prompt"
								leadingIcon={IconPointingLeftSVG}
								onClick={() => setCurrentSlide(currentSlide === 0 ? 2 : currentSlide - 1)}
							/>
							<Button
								buttonTitle="Next slide"
								buttonType="icon-only"
								label="Next"
								theme="prompt"
								leadingIcon={IconPointingRightSVG}
								onClick={() => setCurrentSlide(currentSlide !== 2 ? currentSlide + 1 : 0)}
							/>
						</div>
					</div>
					<div className="subscription-paused-modal__slide-wrapper">
						<div className={`subscription-paused-modal__rail subscription-paused-modal__rail--${currentSlide}`}>
							<div className="subscription-paused-modal__slide">
								<div className="subscription-paused-modal__illustration-wrapper">
									<img src="/assets/img/illustration_subscription_paused_1.png" width="709" height="259" />
								</div>
								<p>Keep track of your work experience.</p>
							</div>
							<div className="subscription-paused-modal__slide">
								<div className="subscription-paused-modal__illustration-wrapper">
									<img src="/assets/img/illustration_subscription_paused_2.png" width="620" height="386" />
								</div>
								<p>Be prepared for regular reviews, job interviews, and more.</p>
							</div>
							<div className="subscription-paused-modal__slide">
								<div className="subscription-paused-modal__illustration-wrapper">
									<img src="/assets/img/illustration_subscription_paused_3.png" width="314" height="359" />
								</div>
								<p>Generate resumes that are tailored to your next opportunity.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="subscription-paused-modal__footer">
					<p>Add a payment method to continue using Career Minder</p>
					<Button
						buttonTitle="Add payment method to activate account"
						buttonType="primary"
						label="Add Payment Method"
						leadingIcon={launchingSubscriptionManagement && IconDonutSpinnerSVG}
						onClick={() => manageSubscriptions()}
					/>
				</div>
			</div>
		</div>
	);
};