import React, { useState } from "react";
import { API } from "aws-amplify";
import { Button } from "components/core";
import { CredentialsContainer } from "containers/CredentialsContainer";
import { ReactComponent as IconDonutSpinner } from "../../../core/assets/img/donut-spinner.svg";
import { loadStripe } from "@stripe/stripe-js";
import "./checkoutButton.css";

interface CheckoutButtonProps {
	stripePrice: string;
	customer: string;
}

export const CheckoutButton: React.FC<CheckoutButtonProps> = (props) => {
	const { user } = CredentialsContainer.useContainer();
	const [loadingStripe, setLoadingStripe] = useState(false);
	const stripeSecret: string = process.env
		.REACT_APP_STRIPE_PUBLISHABLE_API_KEY as string;

	const stripePromise = loadStripe(stripeSecret);

	const redirectToCheckout = async () => {
		setLoadingStripe(true);
		try {
			const fetchSession = async () => {
				const apiName = "careerminder";
				const apiEndpoint = "/checkout";
				let body = {};
				if (
					props.customer === "" ||
					(props.customer !== "" && !props.customer.startsWith("cus_"))
				) {
					body = {
						client_reference_id: user.attributes.sub,
						priceId: props.stripePrice,
						quantity: 1,
						customer_email: user.attributes.email,
					};
				} else {
					body = {
						client_reference_id: user.attributes.sub,
						priceId: props.stripePrice,
						quantity: 1,
						customer_email: user.attributes.email,
					};
				}
				const session = await API.post(apiName, apiEndpoint, { body });
				return session;
			};
			const session = await fetchSession();
			const sessionId = session.id;
			const stripe = await stripePromise;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			stripe.redirectToCheckout({ sessionId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setLoadingStripe(false);
		}
	};

	const IconDonutSpinnerSVG = <IconDonutSpinner />;

	return (
		<Button
			buttonType="primary"
			label="Enter Payment Info"
			leadingIcon={loadingStripe && IconDonutSpinnerSVG}
			onClick={redirectToCheckout}
		/>
	);
};
