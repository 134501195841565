import React, { useState } from "react";
import { Button } from "components/core";
import { GoalCard } from "../GoalCard";
import { ReactComponent as IconClose } from "../../core/assets/img/close.svg";
import { ReactComponent as IconRefresh } from "../../core/assets/img/refresh.svg";
import { ReactComponent as IconPin } from "../../core/assets/img/pin.svg";
import { Link, useNavigate } from "react-router-dom";
import { DataContainer } from "containers/DataContainer";
import { IReview } from "types";
import { formatDate, getDaysAgo, getFutureDate } from "utils/dateUtils";
import "./upcomingCard.css";

export interface UpcomingCardProps {
	something?: string;
}

interface IEvent {
  name: string;
  type: "review" | "catchup" | "empty";
  date: string;
  itemId: string;
  recurring?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const UpcomingCard: React.FC<UpcomingCardProps> = () => {
	const {
		catchups,
		reviews
	} = DataContainer.useContainer();
	
	const navigate = useNavigate();
	const [goalsShowing, setGoalsShowing] = useState<boolean>(false);
	
	const nextCatchup: IEvent = {
		name: "Next Catch Up",
		type: "catchup",
		date: new Date().toUTCString(),
		itemId: "",
		recurring: false,
	};
	
	if (catchups.length && getDaysAgo(catchups[0].dateCreated) < 7) {
		nextCatchup.date = getFutureDate(catchups[0].dateCreated, 7, false);
	}
	
	const eventsPreliminary: IEvent[] = [nextCatchup];
	
	if (reviews.filter(
			(review: IReview) => new Date(review.nextKeyDate).valueOf() > new Date(new Date()).valueOf()
		).length) {
			reviews.filter(
				(review: IReview) => new Date(review.nextKeyDate).valueOf() > new Date(new Date()).valueOf()
			).map((review: IReview) => {
				eventsPreliminary.push({
					name: review.name,
					type: "review",
					date: review.nextKeyDate,
					itemId: review.itemId,
					recurring: review.recurring,
				});
			});
	} else {
		eventsPreliminary.push({
			name: "No Upcoming Reviews",
			type: "empty",
			itemId: "",
			date: getFutureDate(eventsPreliminary[0].date, 1, false)
		});
	}
	
	const events = eventsPreliminary.sort(
		(a: IEvent, b: IEvent) => new Date(a.date).valueOf() - new Date (b.date).valueOf()
	);
	
	const IconCloseSVG = <IconClose />;
	
	return (
		<div className="upcoming-card">
			<div className="upcoming-card__main">
				<div className="upcoming-card__header">
					<h2 className="type--heading-4">Upcoming</h2>
					<div className="upcoming-card-goals--see-goals-wrapper">
						<Button
							buttonTitle="See your Goals"
							buttonType="utility"
							label="See Goals"
							onClick={() => setGoalsShowing(true)}
							size="small"
						/>
					</div>
					<div className="upcoming-card-goals--visit-goals-wrapper">
						<Button
							buttonTitle="See your Goals"
							buttonType="utility"
							label="See Goals"
							onClick={() => () => navigate("goals")}
							size="small"
						/>
					</div>
				</div>
				<div className="upcoming-card__body-wrapper">
					<div className="upcoming-card__content">
						<div className="upcoming-card-today">
							<h3 className="type--heading-6">Today</h3>
							<IconPin />
						</div>
						{events.map((event: IEvent, index: number) => {
							return (
								<div className="upcoming-card-review--outer-wrapper" key={event.type + index}>
									<Link
										to={`${event.type === "review" ? "reviews/" + event.itemId : event.type === "catchup" ? "catchups/new" : "reviews/new"}`}
									>
										<div className={`upcoming-card-review ${event.type === "catchup" && "upcoming-card-review--catchup"} ${event.type === "empty" && "upcoming-card-review--empty"}`}>
											<h3 className="type--body--large">{event.name}</h3>
											<div className="upcoming-card-review__meta">
												<p className="type--body--standard">{event.type === "empty" ? "Click to create one" : formatDate(event.date)}</p>
												{event.recurring && 
													<IconRefresh />
												}
											</div>
										</div>
									</Link>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<div className={`upcoming-card__goals ${goalsShowing && "upcoming-cards__goals--active"}`}>
				<div className="upcoming-card__goals__close-wrapper">
					<Button
						buttonTitle="Close your Goals"
						buttonType="icon-only"
						label="Close Goals"
						onClick={() => setGoalsShowing(false)}
						leadingIcon={IconCloseSVG}
					/>
				</div>
				<div className="upcoming-card__goals__inner-wrapper">
					<GoalCard />
				</div>
			</div>
		</div>
	);
};