import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, HelperPrompt, Textarea, SequenceStepper } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as IconDonutSpinner } from "../core/assets/img/donut-spinner.svg";
import { ReactComponent as IconInfo } from "../core/assets/img/info.svg";
import { ReactComponent as IconShare } from "../core/assets/img/arrow-with-stem-pointing-up-right.svg";
import { ICatchUp, ISentiment, ISuggestionCatchUp, IWorkExperience, IJob, IProject, IGoal } from "types";
import { catchUpValidationSchema } from "utils/validations";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const similarity = require("compute-cosine-similarity");
import { sendEmail, getEmbedding, getCompletion, pickSuggestedGoalForVector } from "utils";
import "./onboardingCatchup.css";

interface Values {
    catchUp: ICatchUp;
}

interface IGoalSummary {
    title: string;
    description: string;
}

interface IExperienceDescription {
    experience: string;
}

export interface OnboardingCatchupProps {
    embedded?: boolean;
}

export const OnboardingCatchup: React.FC<OnboardingCatchupProps> = (props) => {
    const { embedded = false } = props;
    const {
        EmptyCatchup,
        EmptyWorkExperience,
        EmptySentiment,
        goals,
        jobs,
        catchups,
        workExperiences,
        projects,
        sentiments,
        setCatchups,
        setSentiments,
        setWorkExperiences,
        preferredName,
        user,
        userEmail,
    } = DataContainer.useContainer();

    const navigate = useNavigate();

    const catchUp: ICatchUp = new EmptyCatchup(user);

    const initialValues = {
        catchUp,
    };

    const [submittingForm, setSubmittingForm] = useState<boolean>(false);
    const IconDonutSpinnerSVG = <IconDonutSpinner />;
    const IconShareSVG = <IconShare />;

    const pickSuggestedJob = async (vector: string): Promise<string> => {
        const currentJobs = jobs.filter((job: IJob) => !job.endDate);

        if (currentJobs.length) {
            if (currentJobs.length > 1) {
                const jobScores: number[] = [];
                currentJobs.map((job: IJob) => {
                    const jobExperienceScores: number[] = [];
                    workExperiences
                        .filter((experience: IWorkExperience) => experience.jobId === job.itemId)
                        .map((experience: IWorkExperience) => {
                            const score = similarity(JSON.parse(vector), JSON.parse(experience.vector));
                            jobExperienceScores.push(score);
                        });
                    const jobExperienceScoresAverage =
                        jobExperienceScores.reduce((acc, current) => acc + current, 0) /
                        jobExperienceScores.length;
                    jobScores.push(jobExperienceScoresAverage);
                });
                const indexOfMax = jobScores.reduce(
                    (maxIndex, current, index, array) => (current > array[maxIndex] ? index : maxIndex),
                    0,
                );
                return jobs[indexOfMax].itemId;
            } else {
                return currentJobs[0].itemId;
            }
        } else {
            return "";
        }
    };

    const pickSuggestedProject = async (vector: string, jobId: string): Promise<string> => {
        // Decide on suggested project

        const projectScores: number[] = [];

        const projectSuggestionOptions: IProject[] = projects.filter(
            (project: IProject) => project.jobId === jobId,
        );

        if (projectSuggestionOptions.length) {
            const projectSuggestionOptionIds: string[] = [];

            projectSuggestionOptions.map((project: IProject) => {
                projectSuggestionOptionIds.push(project.itemId);
                const projectExperienceScores: number[] = [];
                const filterWorkedExperiences: IWorkExperience[] = workExperiences.filter(
                    (experience: IWorkExperience) => experience.projectId === project.itemId,
                );

                if (filterWorkedExperiences.length) {
                    filterWorkedExperiences.map((experience: IWorkExperience) => {
                        const score = similarity(JSON.parse(vector), JSON.parse(experience.vector));
                        projectExperienceScores.push(score);
                    });
                    const projectExperienceScoresAverage =
                        projectExperienceScores.reduce((acc, current) => acc + current, 0) /
                        projectExperienceScores.length;
                    projectScores.push(projectExperienceScoresAverage);
                }
            });

            const unfilteredWorkExperiences = workExperiences.filter(
                (experience: IWorkExperience) => experience.jobId === jobId && experience.projectId === "",
            );

            if (unfilteredWorkExperiences.length) {
                const unfilteredWorkExperienceScores: number[] = [];

                unfilteredWorkExperiences.map((experience: IWorkExperience) => {
                    projectSuggestionOptionIds.push("noproject");
                    const score = similarity(JSON.parse(vector), JSON.parse(experience.vector));
                    unfilteredWorkExperienceScores.push(score);
                });

                const unfilteredWorkExperienceScoresAverage =
                    unfilteredWorkExperienceScores.reduce((acc, current) => acc + current, 0) /
                    unfilteredWorkExperienceScores.length;
                projectScores.push(unfilteredWorkExperienceScoresAverage);
            }

            if (projectScores.length > 1) {
                const indexOfMax = projectScores.reduce(
                    (maxIndex, current, index, array) => (current > array[maxIndex] ? index : maxIndex),
                    0,
                );
                if (projectSuggestionOptionIds[indexOfMax] !== "noproject") {
                    return projectSuggestionOptionIds[indexOfMax];
                } else {
                    return "";
                }
            } else {
                return projects[0].itemId;
            }
        } else {
            return "";
        }
    };

    const getGeneratedWorkExperiences = async (description: string): Promise<string> => {
        try {
            const experienceResponse = await getCompletion(description, "catchUpExperienceGeneration");

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const experienceResponsePrepped = JSON.parse(experienceResponse);
            const experienceDescriptions = experienceResponsePrepped.experiences;
            if (experienceDescriptions) {
                const processExperiences = async () => {
                    const newWorkExperiences: IWorkExperience[] = await Promise.all(
                        experienceDescriptions.map(async (experience: IExperienceDescription) => {
                            const newWorkExperience: IWorkExperience = new EmptyWorkExperience(user);
                            newWorkExperience.description = experience.experience;

                            const value = await getEmbedding(newWorkExperience.description);
                            const vector: string = value;
                            newWorkExperience.vector = Array.isArray(JSON.parse(vector)) ? vector : "";

                            // Set suggested job
                            newWorkExperience.jobId = await pickSuggestedJob(newWorkExperience.vector);

                            if (newWorkExperience.jobId !== "") {
                                // Set suggested project
                                newWorkExperience.projectId = await pickSuggestedProject(
                                    newWorkExperience.vector,
                                    newWorkExperience.jobId,
                                );
                            }
                            // Set suggested goal
                            newWorkExperience.goalId = await pickSuggestedGoalForVector(
                                newWorkExperience.vector,
                                goals,
                            );

                            const today = new Date();
                            newWorkExperience.dateCreated = today.toISOString();
                            newWorkExperience.completionDate = today.toISOString();

                            return newWorkExperience;
                        }),
                    );

                    const updatedWorkExperiences = [...newWorkExperiences, ...workExperiences];
                    setWorkExperiences(updatedWorkExperiences);

                    const currentRelatedExperience: string[] = newWorkExperiences.map(
                        (experience: IWorkExperience) => experience.itemId,
                    );
                    return JSON.stringify(currentRelatedExperience);
                };
                const experiencesFromCatchup: string = await processExperiences();
                return experiencesFromCatchup;
            } else {
                return "";
            }
        } catch (error: unknown) {
            try {
                sendEmail("Recommendation Error", error, preferredName, "supportRequest", userEmail);
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error", error);
                    return "Error, sent a report";
                } else {
                    return "Error, couldn’t send a report";
                }
            } catch (err: unknown) {
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error sending error report", err);
                }
                return "Error";
            }
        }
    };

    const getGeneratedSuggestions = async (description: string): Promise<string> => {
        try {
            const goalsToSend: IGoalSummary[] = [];

            goals.map((goal: IGoal) => {
                const goalToSend: IGoalSummary = {
                    title: goal.goal,
                    description: goal.details,
                };
                goalsToSend.push(goalToSend);
            });

            const payload = {
                goals: goalsToSend,
                recentWorkDescription: description,
            };

            const suggestionResponse = await getCompletion(
                JSON.stringify(payload),
                "catchUpSuggestionsGeneration",
            );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const suggestionResponsePrepped = JSON.parse(suggestionResponse);
            const suggestions = suggestionResponsePrepped.suggestions;
            if (suggestions) {
                const suggestionsForCatchUp: ISuggestionCatchUp[] = [];

                suggestions.map((suggestion: ISuggestionCatchUp) => {
                    suggestionsForCatchUp.push(suggestion);
                });

                return JSON.stringify(suggestionsForCatchUp);
            } else {
                return "";
            }
        } catch (error: unknown) {
            try {
                sendEmail("Recommendation Error", error, preferredName, "supportRequest", userEmail);
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error", error);
                    return "Error, sent a report";
                } else {
                    return "Error, couldn’t send a report";
                }
            } catch (err: unknown) {
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error sending error report", err);
                }
                return "Error";
            }
        }
    };

    const getGeneratedSummary = async (description: string): Promise<string> => {
        try {
            const responseSummary = await getCompletion(description, "catchUpSummaryGeneration");
            return JSON.parse(responseSummary);
        } catch (error: unknown) {
            try {
                sendEmail("Recommendation Error", error, preferredName, "supportRequest", userEmail);
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error", error);
                    return "Error, sent a report";
                } else {
                    return "Error, couldn’t send a report";
                }
            } catch (err: unknown) {
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error sending error report", err);
                }
                return "Error";
            }
        }
    };

    const getGeneratedSentiment = async (description: string): Promise<string> => {
        try {
            // TODO: Look into using direct sentiment analysis. Maybe with Mistral 8x7b on Anyscale. This could be a good option for replacing OpenAI entirely. The cost could certainly be lower

            const responseContent = await getCompletion(description, "catchUpSentimentGeneration");
            const responseContentParsed = JSON.parse(responseContent);
            const newSentiment: ISentiment = new EmptySentiment(user);
            if (responseContentParsed?.toLowerCase().includes("positive")) {
                newSentiment.feeling = 100; // Positive sentiment
            } else if (responseContentParsed?.toLowerCase().includes("negative")) {
                newSentiment.feeling = 0; // Negative sentiment
            } else {
                newSentiment.feeling = 50; // Neutral sentiment
            }
            setSentiments([...[newSentiment], ...sentiments]);
            return newSentiment.itemId;
        } catch (error: unknown) {
            try {
                sendEmail("Recommendation Error", error, preferredName, "supportRequest", userEmail);
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error", error);
                    return "Error, sent a report";
                } else {
                    return "Error, didn’t send a report";
                }
            } catch (err: unknown) {
                if (
                    process.env.REACT_APP_USER_BRANCH !== "master" ||
                    (process.env.REACT_APP_USER_BRANCH === "master" &&
                        userEmail === "releasetest@careerminder.io")
                ) {
                    console.log("Error sending error report", err);
                }
                return "Error";
            }
        }
    };

    const DictationMessage = () => {
        return (
            <>
                <h2 className="type--heading-1">How to dictate on your device</h2>
                <p className="type--body--large">
                    All major computing platforms have the ability to dictate into text fields. See how on
                    your device:
                </p>
                <div className="helper-list">
                    <div className="helper-list__item">
                        <h3 className="type--body--large">iPhone &amp; iPad</h3>
                        <a
                            href="https://support.apple.com/guide/iphone/dictate-text-iph2c0651d2/ios"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label="View Apple FAQ"
                                buttonTitle="View Apple FAQ"
                                buttonType="utility"
                                size="small"
                                trailingIcon={IconShareSVG}
                                onClick={() => console.log("open")}
                            />
                        </a>
                    </div>
                    <div className="helper-list__item">
                        <h3 className="type--body--large">Android</h3>
                        <a
                            href="https://support.google.com/gboard/answer/2781851?hl=en&co=GENIE.Platform%3DAndroid"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label="View Android FAQ"
                                buttonTitle="View Android FAQ"
                                buttonType="utility"
                                size="small"
                                trailingIcon={IconShareSVG}
                                onClick={() => console.log("open")}
                            />
                        </a>
                    </div>
                    <div className="helper-list__item">
                        <h3 className="type--body--large">Mac</h3>
                        <a
                            href="https://support.apple.com/guide/mac-help/use-dictation-mh40584/mac"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label="View Apple FAQ"
                                buttonTitle="View Apple FAQ"
                                buttonType="utility"
                                size="small"
                                trailingIcon={IconShareSVG}
                                onClick={() => console.log("open")}
                            />
                        </a>
                    </div>
                    <div className="helper-list__item">
                        <h3 className="type--body--large">Windows</h3>
                        <a
                            href="https://support.microsoft.com/en-us/windows/dictate-text-using-speech-recognition-854ef1de-7041-9482-d755-8fdf2126ef27"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label="View Windows FAQ"
                                buttonTitle="View Windows FAQ"
                                buttonType="utility"
                                size="small"
                                trailingIcon={IconShareSVG}
                                onClick={() => console.log("open")}
                            />
                        </a>
                    </div>
                </div>
            </>
        );
    };

    return (
        <section className="pattern--container--flow pattern--container--flow-narrow">
            <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
                <h1 className="type--heading-1">Catch Up</h1>
                <div className="accessory--resizeable">
                    <SequenceStepper length={6} currentIndex={5} />
                </div>
            </div>
            <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
                <p className="type--body--large">
                    Catch Ups are a way to use a journal entry to quickly keep your Experience up to date on a
                    regular basis.
                </p>
                <p className="type--body--large">
                    What have you worked on recently? How did it go? Were there results? What could improve
                    next time? Do you feel closer to your goals or further away?
                </p>
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
                        setSubmittingForm(true);
                        const newCatchUp: ICatchUp = values.catchUp;
                        setSubmitting(true);
                        newCatchUp.name = new Date().toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        });

                        getGeneratedSummary(values.catchUp.description)
                            .then((value) => {
                                const summary: string = value;
                                if (!summary.includes("Error")) {
                                    newCatchUp.summary = summary;
                                } else {
                                    newCatchUp.summary = "";
                                }
                            })
                            .then(() => getGeneratedWorkExperiences(values.catchUp.description))
                            .then((value) => {
                                const experiences: string = value;
                                if (!experiences.includes("Error")) {
                                    values.catchUp.experiences = experiences;
                                } else {
                                    newCatchUp.experiences = "";
                                }
                            })
                            .then(() => getGeneratedSuggestions(values.catchUp.description))
                            .then((value) => {
                                const suggestions: string = value;
                                if (!suggestions.includes("Error")) {
                                    values.catchUp.suggestions = suggestions;
                                } else {
                                    newCatchUp.suggestions = "";
                                }
                            })
                            .then(() => getGeneratedSentiment(values.catchUp.description))
                            .then((value) => {
                                const sentiment: string = value;
                                if (!sentiment.includes("Error")) {
                                    newCatchUp.sentiment = sentiment;
                                } else {
                                    newCatchUp.sentiment = "";
                                }
                            })
                            .then(() => {
                                setCatchups([...[newCatchUp], ...catchups]);
                                setSubmitting(false);
                                setSubmittingForm(false);
                                navigate("/onboarding/catchup/" + values.catchUp.itemId, {
                                    replace: true,
                                });
                            });
                    }}
                    validations={catchUpValidationSchema}
                >
                    {({ values, submitForm }) => (
                        <>
                            <Form className="catchup-creation-fields">
                                <>
                                    <div className="catchup-creation-fields__description">
                                        <Textarea
                                            label="Catch Up Description"
                                            name={`catchUp.description`}
                                            placeholder="What are the details?"
                                            rows={5}
                                            maxLength={8096}
                                        />
                                    </div>
                                </>
                            </Form>
                            <div className="info-pane-wrapper">
                                <div className="info-pane-wrapper__header">
                                    <h2 className="type--heading-6">Pro Tip</h2>
                                    <IconInfo />
                                </div>
                                <div className="info-pane-wrapper__body">
                                    <p className="type--body--large">
                                        If typing out what you recall about your work seems tiring or
                                        intimidating. Use your device’s dictation abilities to speak out loud,
                                        like you were telling a friend.
                                    </p>
                                    <HelperPrompt label="Show Me How">
                                        <DictationMessage />
                                    </HelperPrompt>
                                </div>
                            </div>
                            {!embedded && (
                                <div className="pattern--button-row">
                                    <Button
                                        label="Back"
                                        buttonTitle="Back to Resume Review"
                                        buttonType="utility"
                                        onClick={() => navigate("/onboarding/resume/review")}
                                    />
                                    <Button
                                        label="Next"
                                        buttonTitle="Finish Catch Up"
                                        buttonType="secondary"
                                        disabled={values.catchUp.description !== "" ? false : true}
                                        type="button"
                                        leadingIcon={submittingForm && IconDonutSpinnerSVG}
                                        onClick={() => submitForm()}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Formik>
            </div>
        </section>
    );
};
