import React, { useState } from "react";
import { Button, Radio, Toast } from "../../core";
import { ReactComponent as IconDonutSpinner } from "../../core/assets/img/donut-spinner.svg";
import { DataContainer } from "../../../containers";
import { sendEmail } from "utils";
import "./help.css";

/**
 * Primary UI component for user interaction
 */
export const Help: React.FC = () => {
	const { preferredName, userEmail } = DataContainer.useContainer();
	const [supportSubject, setSupportSubject] = useState<string>("other");
	const [supportMessage, setSupportMessage] = useState<string>("");
	const [sendingMessage, setSendingMessage] = useState<boolean>(false);
	const [resultToast, setResultToast] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");

	const inputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const message = event.target.value;
		setSupportMessage(message);
	};

	const IconDonutSpinnerSVG = <IconDonutSpinner />;

	const sendMessage = async () => {
		setSendingMessage(true);
		try {
			sendEmail(
				supportSubject,
				supportMessage,
				preferredName,
				"supportRequest",
				userEmail
			);
			setSendingMessage(false);
			setSupportMessage("");
			setToastMessage("Your message has been sent.");
			setResultToast(true);
			setTimeout(() => setResultToast(false), 5000);
		} catch (error: unknown) {
			sendEmail(
				"Sending Help Request Error",
				error,
				preferredName,
				"supportRequest",
				userEmail
			);
			setSendingMessage(false);
			setToastMessage("An error occured.");
			setResultToast(true);
		}
	};

	return (
		<>
			<h2 className="type--heading-3 pane-heading">Contact</h2>
			<p className="type--body--large">
				Having trouble or want to get in touch? Reach out and describe in detail
				what’s on your mind.
			</p>
			<div className="field-wrapper pattern--field-wrapper">
				<label className="input-container">
					<span className="pattern--label type--heading-4">Subject</span>
				</label>
				<Radio
					label="Bug or problem to report"
					name="messageSubject"
					value="bug-report"
					checked={false}
					onClick={() => setSupportSubject("bug-report")}
				/>
				<Radio
					label="Product idea or feature request"
					name="messageSubject"
					value="feature-request"
					checked={false}
					onClick={() => setSupportSubject("feature-request")}
				/>
				<Radio
					label="Other"
					name="messageSubject"
					value="other"
					checked={true}
					onClick={() => setSupportSubject("other")}
				/>
			</div>
			<div className="field-wrapper pattern--field-wrapper">
				<label className="input-container">
					<span className="pattern--label type--heading-4">Message</span>
					<textarea
						name="supportMessage"
						placeholder="What would you like to tell us?"
						className="pattern--input-field"
						rows={5}
						value={supportMessage}
						onChange={inputHandler}
					></textarea>
				</label>
			</div>
			<Button
				buttonTitle="Send message to us"
				buttonType="primary"
				disabled={supportMessage.length ? false : true}
				leadingIcon={sendingMessage && IconDonutSpinnerSVG}
				label="Send Message"
				onClick={() => sendMessage()}
			/>

			<Toast isOpen={resultToast} message={toastMessage} lightTheme={true} />
		</>
	);
};
