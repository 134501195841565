import React from "react";
import "./errorBanner.css";

export interface ErrorBannerProps {
	message: string;
}

/**
 * Primary UI component for user interaction
 */
export const ErrorBanner: React.FC<ErrorBannerProps> = ({
	message
}) => {
	return (
		<div className="error-banner">
			<p className="type--body--standard error-banner__message">{message}</p>
		</div>
	);
};