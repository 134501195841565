import React, { useState, useEffect } from "react";
import { ISentiment } from "types";
import { ReactComponent as SentimentNegative } from "../../core/assets/img/sentiment-negative.svg";
import { ReactComponent as SentimentNeutral } from "../../core/assets/img/sentiment-neutral.svg";
import { ReactComponent as SentimentPositive } from "../../core/assets/img/sentiment-positive.svg";
import { Sentimentometer } from "components/core";
import { getDaysAgo } from "utils";
import { DataContainer } from "containers/DataContainer";
import "./sentimentCard.css";

export interface SentimentCardProps {
  something?: string;
}

/**
 * Primary UI component for user interaction
 */
export const SentimentCard: React.FC<SentimentCardProps> = ({ something }) => {
  const { sentiments, user, EmptySentiment, setSentiments } =
    DataContainer.useContainer();

  const [submissionComplete, setSubmissionComplete] = useState<boolean>(false);

  const [currentlySelectedRange, setCurrentlySelectedRange] =
    useState<number>(0);

  const [sentiment30Days, setSentiment30Days] = useState<number>(
    sentiments
      .filter((sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 31)
      .reduce((acc, val) => {
        const sentimentsLength = sentiments.filter(
          (sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 31,
        ).length;
        return Math.round(acc + val.feeling / sentimentsLength);
      }, 0),
  );
  const [sentiment365Days, setSentiment365Days] = useState<number>(
    sentiments
      .filter(
        (sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 366,
      )
      .reduce((acc, val) => {
        const sentimentsLength = sentiments.filter(
          (sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 366,
        ).length;
        return Math.round(acc + val.feeling / sentimentsLength);
      }, 0),
  );
  const [sentimentAllTime, setSentimentAllTime] = useState<number>(
    sentiments.reduce((acc, val) => {
      return Math.round(acc + val.feeling / sentiments.length);
    }, 0),
  );

  const [sentimentRotation30Days, setSentimentRotation30Days] =
    useState<number>(Math.round((180 * sentiment30Days) / 100));
  const [sentimentRotation365Days, setSentimentRotation365Days] =
    useState<number>(Math.round((180 * sentiment365Days) / 100));
  const [sentimentRotationAllTime, setSentimentRotationAllTime] =
    useState<number>(Math.round((180 * sentimentAllTime) / 100));

  useEffect(() => {
    const days30Average = sentiments
      .filter((sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 31)
      .reduce((acc, val) => {
        const sentimentsLength = sentiments.filter(
          (sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 31,
        ).length;
        return Math.round(acc + val.feeling / sentimentsLength);
      }, 0);
    setSentiment30Days(days30Average);
    setSentimentRotation30Days(Math.round((180 * sentiment30Days) / 100));

    const days365Average = sentiments
      .filter(
        (sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 366,
      )
      .reduce((acc, val) => {
        const sentimentsLength = sentiments.filter(
          (sentiment: ISentiment) => getDaysAgo(sentiment.dateCreated) < 366,
        ).length;
        return Math.round(acc + val.feeling / sentimentsLength);
      }, 0);
    setSentiment365Days(days365Average);
    setSentimentRotation365Days(Math.round((180 * sentiment365Days) / 100));

    const allTimeAverage = sentiments.reduce((acc, val) => {
      return Math.round(acc + val.feeling / sentiments.length);
    }, 0);
    setSentimentAllTime(allTimeAverage);
    setSentimentRotationAllTime(Math.round((180 * sentiment365Days) / 100));
  }, [sentiments]);

  const createSentiment = (sentimentValue: number): string => {
    const mostRecentSentiment = sentiments[sentiments.length - 1];
    const today = new Date();
    const mostRecentSentimentDate = new Date(mostRecentSentiment.dateCreated);
    if (today.toDateString() === mostRecentSentimentDate.toDateString()) {
      const updatedSentiment: ISentiment = {
        customerId: mostRecentSentiment.customerId,
        itemId: mostRecentSentiment.itemId,
        dateCreated: mostRecentSentiment.dateCreated,
        feeling: sentimentValue,
      };
      const updatedSentiments = [...sentiments];
      updatedSentiments.splice(sentiments.length - 1, 1, updatedSentiment);
      setSentiments(updatedSentiments);
      return updatedSentiment.itemId;
    } else {
      const newSentiment: ISentiment = new EmptySentiment(user);
      newSentiment.feeling = sentimentValue;
      const updatedSentiments = [...sentiments, newSentiment];
      setSentiments(updatedSentiments);
      return newSentiment.itemId;
    }
  };

  return (
    <div className="sentiment-wrapper">
      <div
        className={`sentiment-results ${submissionComplete && "sentiment-results--active"}`}
      >
        <h2 className="type--heading-4">Sentimentometer</h2>
        <Sentimentometer
          rotation={
            currentlySelectedRange === 0
              ? sentimentRotation30Days
              : currentlySelectedRange === 1
                ? sentimentRotation365Days
                : sentimentRotationAllTime
          }
        />
        <div className="time-range-wrapper">
          <label className="type--data toggle-label">Average Over Time</label>
          <div className="small-toggle-row">
            <button
              className={`toggle-button toggle-button--small type--data ${
                currentlySelectedRange === 0 && "toggle-button--selected"
              }`}
              onClick={() => setCurrentlySelectedRange(0)}
            >
              30 Days
            </button>
            <button
              className={`toggle-button toggle-button--small type--data ${
                currentlySelectedRange === 1 && "toggle-button--selected"
              }`}
              onClick={() => setCurrentlySelectedRange(1)}
            >
              365 Days
            </button>
            <button
              className={`toggle-button toggle-button--small type--data ${
                currentlySelectedRange === 2 && "toggle-button--selected"
              }`}
              onClick={() => setCurrentlySelectedRange(2)}
            >
              All Time
            </button>
          </div>
        </div>
      </div>
      <div
        className={`sentiment-input ${!submissionComplete && "sentiment-input--active"}`}
      >
        <p className="type--body--large">
          How are you feeling about work right now?
        </p>
        <div className="sentiment-options">
          <button
            className="sentiment-button plausible-event-name=Dashboard+Add+Sentiment plausible-event-sentiment=negative"
            onClick={() => {
              createSentiment(0);
              setSubmissionComplete(true);
            }}
          >
            <SentimentNegative />
          </button>
          <button
            className="sentiment-button plausible-event-name=Dashboard+Add+Sentiment plausible-event-sentiment=neutral"
            onClick={() => {
              createSentiment(50);
              setSubmissionComplete(true);
            }}
          >
            <SentimentNeutral />
          </button>
          <button
            className="sentiment-button plausible-event-name=Dashboard+Add+Sentiment plausible-event-sentiment=positive"
            onClick={() => {
              createSentiment(100);
              setSubmissionComplete(true);
            }}
          >
            <SentimentPositive />
          </button>
        </div>
      </div>
    </div>
  );
};
