import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { Button } from "components/core";
import { IJobMinus, IJobPlus } from "types";
import { ReactComponent as IconPlus } from "components/core/assets/img/plus.svg";
import { ReactComponent as IconMinus } from "components/core/assets/img/minus.svg";
import "./plusesMinuses.css";

interface PlusesMinusesProps {
  editing: boolean;
  open: boolean;
  plusesToUpdate: IJobPlus[];
  setPlusesToUpdate: (value: IJobPlus[]) => void;
  minusesToUpdate: IJobMinus[];
  setMinusesToUpdate: (value: IJobMinus[]) => void;
}

export const PlusesMinuses: React.FC<PlusesMinusesProps> = (props) => {
  const {
    editing,
    open = true,
    plusesToUpdate,
    setPlusesToUpdate,
    minusesToUpdate,
    setMinusesToUpdate,
  } = props;

  const [pluses, setPluses] = useState<IJobPlus[]>(plusesToUpdate);
  const [newPlusValue, setNewPlusValue] = useState<string>("");
  const [minuses, setMinuses] = useState<IJobMinus[]>(minusesToUpdate);
  const [newMinusValue, setNewMinusValue] = useState<string>("");

  useEffect(() => {
    if (!isEqual(pluses, plusesToUpdate)) {
      setPlusesToUpdate(pluses);
    }
  }, [pluses]);

  useEffect(() => {
    if (!isEqual(minuses, minusesToUpdate)) {
      setMinusesToUpdate(minuses);
    }
  }, [minuses]);

  const handlePlusChange = (index: number, value: string) => {
    const updatedPluses = [...pluses];
    updatedPluses[index].plus = value;
    setPluses(updatedPluses);
  };

  const handlePlusDelete = (index: number) => {
    const updatedPluses = [...pluses];
    updatedPluses.splice(index, 1);
    setPluses(updatedPluses);
  };

  const handlePlusAdd = () => {
    if (newPlusValue.trim() !== "") {
      const newPlus: IJobPlus = {
        plus: newPlusValue.trim(),
        // Add any other necessary properties for a new plus
      };
      setPluses([...pluses, newPlus]);
      setNewPlusValue("");
    }
  };

  const handleMinusChange = (index: number, value: string) => {
    const updatedMinuses = [...minuses];
    updatedMinuses[index].minus = value;
    setMinuses(updatedMinuses);
  };

  const handleMinusDelete = (index: number) => {
    const updatedMinuses = [...minuses];
    updatedMinuses.splice(index, 1);
    setMinuses(updatedMinuses);
  };

  const handleMinusAdd = () => {
    if (newMinusValue.trim() !== "") {
      const newMinus: IJobMinus = {
        minus: newMinusValue.trim(),
        // Add any other necessary properties for a new plus
      };
      setMinuses([...minuses, newMinus]);
      setNewMinusValue("");
    }
  };

  const IconMinusSVG = <IconMinus />;
  const IconPlusSVG = <IconPlus />;

  return (
    <div className={`pluses-minuses`}>
      <details open={open}>
        <summary>
          <h3 className="type--heading-6">Pluses & Minuses</h3>
          <span className="plus-minus-tag plus-minus-tag--plus type--heading-6">
            <IconPlus />
            {plusesToUpdate.length}
          </span>
          <span className="plus-minus-tag plus-minus-tag--minus type--heading-6">
            <IconMinus />
            {minuses.length}
          </span>
        </summary>
        <div className="pluses-minuses-list">
          <div className="pluses-minuses-list__section pluses-list">
            <div className="pluses-minuses-list__section__header">
              <IconPlus />
              <h4 className="type--heading-5">Pluses</h4>
            </div>
            <div className="pluses-minuses-list__section__content">
              {pluses.length ? (
                !editing ? (
                  pluses.map((plus: IJobPlus, index: number) => (
                    <p className="type--body--standard" key={index}>
                      {plus.plus}
                    </p>
                  ))
                ) : (
                  <>
                    {pluses.map((plus: IJobPlus, index: number) => (
                      <div className="plus-minus-editing-row" key={index}>
                        <div className="field-wrapper pattern--field-wrapper">
                          <label className={`pattern--input-container`}>
                            <input
                              type="text"
                              value={plus.plus}
                              className="pattern--input-field input--small"
                              onChange={(e) =>
                                handlePlusChange(index, e.target.value)
                              }
                            />
                          </label>
                        </div>
                        <Button
                          label="Delete Plus"
                          buttonType="icon-only-utility"
                          size="small"
                          leadingIcon={IconMinusSVG}
                          onClick={() => handlePlusDelete(index)}
                        />
                      </div>
                    ))}
                    <div className="plus-minus-editing-row">
                      <div className="field-wrapper pattern--field-wrapper">
                        <label className={`pattern--input-container`}>
                          <input
                            type="text"
                            value={newPlusValue}
                            className="pattern--input-field input--small"
                            onChange={(e) => setNewPlusValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handlePlusAdd();
                              }
                            }}
                          />
                        </label>
                      </div>
                      <Button
                        label="Add Plus"
                        buttonType="icon-only-utility"
                        size="small"
                        leadingIcon={IconPlusSVG}
                        onClick={handlePlusAdd}
                      />
                    </div>
                  </>
                )
              ) : !editing ? (
                <p className="type--body--standard">
                  No pluses found. Add them by editing this job.
                </p>
              ) : (
                <div className="plus-minus-editing-row">
                  <div className="field-wrapper pattern--field-wrapper">
                    <label className={`pattern--input-container`}>
                      <input
                        type="text"
                        value={newPlusValue}
                        placeholder="Add a plus"
                        className="pattern--input-field input--small"
                        onChange={(e) => setNewPlusValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handlePlusAdd();
                          }
                        }}
                      />
                    </label>
                  </div>
                  <Button
                    label="Add Plus"
                    buttonType="icon-only-utility"
                    size="small"
                    leadingIcon={IconPlusSVG}
                    onClick={handlePlusAdd}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="pluses-minuses-list__section minuses-list">
            <div className="pluses-minuses-list__section__header pluses-list__header">
              <IconMinus />
              <h4 className="type--heading-5">Minuses</h4>
            </div>
            <div className="pluses-minuses-list__section__content">
              {minuses.length ? (
                !editing ? (
                  minuses.map((minus: IJobMinus, index: number) => (
                    <p className="type--body--standard" key={index}>
                      {minus.minus}
                    </p>
                  ))
                ) : (
                  <>
                    {minuses.map((minus: IJobMinus, index: number) => (
                      <div className="plus-minus-editing-row" key={index}>
                        <div className="field-wrapper pattern--field-wrapper">
                          <label className={`pattern--input-container`}>
                            <input
                              type="text"
                              value={minus.minus}
                              className="pattern--input-field input--small"
                              onChange={(e) =>
                                handleMinusChange(index, e.target.value)
                              }
                            />
                          </label>
                        </div>
                        <Button
                          label="Delete Minus"
                          buttonType="icon-only-utility"
                          size="small"
                          leadingIcon={IconMinusSVG}
                          onClick={() => handleMinusDelete(index)}
                        />
                      </div>
                    ))}
                    <div className="plus-minus-editing-row">
                      <div className="field-wrapper pattern--field-wrapper">
                        <label className={`pattern--input-container`}>
                          <input
                            type="text"
                            value={newMinusValue}
                            className="pattern--input-field input--small"
                            onChange={(e) => setNewMinusValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleMinusAdd();
                              }
                            }}
                          />
                        </label>
                      </div>
                      <Button
                        label="Add Plus"
                        buttonType="icon-only-utility"
                        size="small"
                        leadingIcon={IconPlusSVG}
                        onClick={handleMinusAdd}
                      />
                    </div>
                  </>
                )
              ) : !editing ? (
                <p className="type--body--standard">
                  No minuses found. Add them by editing this job.
                </p>
              ) : (
                <div className="plus-minus-editing-row">
                  <div className="field-wrapper pattern--field-wrapper">
                    <label className={`pattern--input-container`}>
                      <input
                        type="text"
                        value={newMinusValue}
                        placeholder="Add a minus"
                        className="pattern--input-field input--small"
                        onChange={(e) => setNewMinusValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleMinusAdd();
                          }
                        }}
                      />
                    </label>
                  </div>
                  <Button
                    label="Add Plus"
                    buttonType="icon-only-utility"
                    size="small"
                    leadingIcon={IconPlusSVG}
                    onClick={handleMinusAdd}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </details>
    </div>
  );
};
