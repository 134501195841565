import React, { useState, useRef } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import {
  AutoSave,
  BackLink,
  Button,
  Dialog,
  Editor,
  Input,
  GoalDisplay,
  MenuItem,
  Menu,
  Toast,
} from "components/core";
import { ResumeBuilder } from "./ResumeBuilder";
import {
  EditorState,
  ContentState,
  BlockMap,
  Modifier,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
  RawDraftContentBlock,
} from "draft-js";
import { useNavigate } from "react-router-dom";
import { Document, Packer, Paragraph } from "docx";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { saveAs } from "file-saver";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StateToPdfMake from "draft-js-export-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import { ReactComponent as IconPointingDown } from "../core/assets/img/arrow-pointing-down.svg";
import { ReactComponent as IconPointingUp } from "../core/assets/img/arrow-pointing-up.svg";
import { ReactComponent as IconPlus } from "../core/assets/img/plus.svg";
import { ReactComponent as IconSettings } from "../core/assets/img/settings.svg";
import { DataContainer } from "containers/DataContainer";
import { IBragSheet, IWorkExperience } from "types";
import { bragSheetValidationSchema } from "utils/validations";
import "./bragsheetauthoring.css";

interface BragSheetAuthoringProps {
  bragSheetId?: string;
  email?: string;
  jobDescription?: string;
  name?: string;
  newBragSheet?: boolean;
}
interface Values {
  bragSheet: IBragSheet;
  bragSheetEditorState: EditorState;
}

const BragSheetAuthoring: React.FC<BragSheetAuthoringProps> = (props) => {
  const { bragSheetId = "" } = props;
  const {
    EmptyBragSheet,
    bragSheets,
    goals,
    user,
    userEmail,
    userDataLoaded,
    workExperiences,
    fullName,
    setBragSheets,
  } = DataContainer.useContainer();

  const navigate = useNavigate();

  const bragSheetsLoaded = useRef<boolean>(false);

  const setUpBragSheet = (): IBragSheet => {
    if (userDataLoaded && !bragSheetsLoaded.current) {
      if (bragSheetId === "") {
        return new EmptyBragSheet(user);
      } else {
        return bragSheets.filter((item: IBragSheet) => item.itemId === bragSheetId)[0];
      }
    }
    return new EmptyBragSheet(user);
  };

  const bragSheet: IBragSheet = setUpBragSheet();

  const [isNewBragSheet, setIsNewBragSheet] = useState<boolean>(bragSheetId === "");

  const currentDate = new Date();
  const formatDT = (sourceDate: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return new Intl.DateTimeFormat(undefined, options).format(new Date(sourceDate));
  };
  const currentDateString = formatDT(currentDate);

  const [isTemplateMenuOpen, setIsTemplateMenuOpen] = useState(false);

  const coverLetterHtml =
    `<p>` +
    currentDateString +
    `</p>
		<p>To whom it may concern,</p>
		<p>>>Body of Letter<<</p>
		<p>Regards,</p>
		` +
    fullName;

  const [isResumeBuilderOpen, setIsResumeBuilderOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const insertHtmlToContentState = (
    editorState: EditorState,
    textToInsert: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: any,
  ) => {
    const blocks = convertFromHTML(textToInsert);
    const blockMap: BlockMap = ContentState.createFromBlockArray(
      blocks.contentBlocks,
      blocks.entityMap,
    ).getBlockMap();

    const newContent = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap,
    );

    const newEditorState = EditorState.push(editorState, newContent, "insert-fragment");

    const updatedEditorState = EditorState.moveSelectionToEnd(newEditorState);

    setFieldValue("bragSheetEditorState", updatedEditorState);
  };

  const [bragSheetEditorState] = useState<EditorState>(() => {
    if (isNewBragSheet) {
      return EditorState.createEmpty();
    } else {
      if (
        process.env.REACT_APP_USER_BRANCH !== "master" ||
        (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
      ) {
        console.log(EditorState.createWithContent(convertFromRaw(JSON.parse(bragSheet.content))));
      }
      return EditorState.createWithContent(convertFromRaw(JSON.parse(bragSheet.content)));
    }
  });
  const [experienceDisplay, setExperienceDisplay] = useState<boolean>(false);
  const [referenceDisplay, setReferenceDisplay] = useState<boolean>(false);

  const initialValues = {
    bragSheet,
    bragSheetEditorState,
  };

  const IconPointingDownSVG = <IconPointingDown />;
  const IconPointingUpSVG = <IconPointingUp />;
  const IconPlusSVG = <IconPlus />;
  const IconSettingsSVG = <IconSettings />;

  const toggleExperienceDisplay = () => {
    if (experienceDisplay) {
      setExperienceDisplay(false);
    } else {
      setExperienceDisplay(true);
    }
  };

  const toggleReferenceDisplay = () => {
    if (referenceDisplay) {
      setReferenceDisplay(false);
    } else {
      setReferenceDisplay(true);
    }
  };

  //  Bragsheet Editing

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const insertAtCursor = (
    editorState: EditorState,
    textToInsert: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: any,
  ) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(currentContent, currentSelection, textToInsert);

    const newEditorState = EditorState.push(editorState, newContent, "insert-characters");

    const updatedEditorState = EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());

    setFieldValue("bragSheetEditorState", updatedEditorState);
  };

  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);

  const slugify = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, "");

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    const from =
      "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    const to =
      "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    // Remove invalid chars
    str = str
      .replace(/[^a-z0-9 -]/g, "")
      // Collapse whitespace and replace by -
      .replace(/\s+/g, "-")
      // Collapse dashes
      .replace(/-+/g, "-");

    return str;
  };

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("…");

  const popToast = (message: string, timeOnScreen: number) => {
    setToastMessage(message);
    setIsToastOpen(true);
    setTimeout(() => {
      setIsToastOpen(false);
    }, timeOnScreen);
  };

  const copyToClipboard = (clipboardEditorState: EditorState) => {
    const blocks = convertToRaw(clipboardEditorState.getCurrentContent()).blocks;
    const value = blocks.map((block) => (!block.text.trim() && "\n") || block.text).join("\n");
    navigator.clipboard.writeText(value).then(
      () => {
        // setCopied(true);
        setTimeout(() => {
          // setCopied(false);
        }, 1500);
      },
      () => {
        if (
          process.env.REACT_APP_USER_BRANCH !== "master" ||
          (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
        ) {
          console.log(`Failed to set clipboard content.`);
        }
      },
    );
    popToast("Copied to clipboard", 4000);
  };

  const exportTextFile = (editorState: EditorState, title: string) => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks.map((block) => (!block.text.trim() && "\n") || block.text).join("\n");
    const blob = new Blob([value], { type: "text/plain" });
    const fileName = slugify(title) + ".txt";
    saveAs(blob, fileName);
    popToast(fileName + " has been exported", 4000);
  };

  const exportWordDoc = async (editorState: EditorState, title: string) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const paragraphs: Paragraph[] = [];

    rawContentState.blocks.map((block: RawDraftContentBlock) => {
      let paragraph = new Paragraph({});
      if (block.type === "unordered-list-item") {
        paragraph = new Paragraph({
          text: block.text,
          bullet: {
            level: 0,
          },
        });
      } else {
        paragraph = new Paragraph({
          text: block.text,
        });
      }
      paragraphs.push(paragraph);
    });

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: paragraphs,
        },
      ],
    });

    const fileName = slugify(title) + ".docx";

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, fileName);
    });

    popToast(fileName + " has been exported", 4000);
  };

  const exportPdf = async (editorState: EditorState, title: string) => {
    const fonts = (pdfMake.fonts = {
      FiraSans: {
        normal: "https://careerminder.io/assets/fonts/FiraSans-Regular.ttf",
        bold: "https://careerminder.io/assets/fonts/FiraSans-Bold.ttf",
        italics: "https://careerminder.io/assets/fonts/FiraSans-Italic.ttf",
        bolditalics: "https://careerminder.io/assets/fonts/FiraSans-BoldItalic.ttf",
      },
    });
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const stateToPdfMake = new StateToPdfMake(rawContentState);
    const pdfmakeContents = stateToPdfMake.generate();

    pdfmakeContents["defaultStyle"] = {
      font: "FiraSans",
      fontSize: 11,
      lineHeight: 1.3,
    };

    pdfmakeContents["pageMargins"] = [60, 80, 60, 80];

    const fileName = slugify(title);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pdfMake.createPdf(pdfmakeContents, null, fonts).download(fileName);
    popToast(fileName + ".pdf has been exported", 4000);
  };

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState<boolean>(false);

  const deleteBragSheet = () => {
    const currentBragSheetId = bragSheet.itemId;
    // Create a new array excluding the item with the specified itemId
    const updatedBragSheets = bragSheets.filter((bragSheet) => bragSheet.itemId !== currentBragSheetId);
    // Update state with the new array
    setBragSheets(updatedBragSheets);
    navigate("/bragsheets");
  };

  return (
    <div className="bragsheet-authoring-container-outer-wrapper">
      <section className="bragsheet-authoring-container">
        <div className="header--withbreadcrumb bragsheet-masthead">
          <BackLink
            button={false}
            label="Back to Brag Sheets"
            title="Back to Brag Sheets"
            navigateTo="/bragsheets"
          />
          <div className="pattern--menu-container pattern--menu-container--mobile-left-desktop-right">
            <Button
              label="Brag Sheet Settings"
              buttonTitle="See Brag Sheet Settings"
              buttonType="icon-only"
              disabled={isNewBragSheet}
              trailingIcon={IconSettingsSVG}
              size="small"
              onClick={() => {
                setIsExportMenuOpen(false);
                setIsTemplateMenuOpen(false);
                setIsSettingsMenuOpen(!isSettingsMenuOpen);
              }}
            />
            <Menu isOpened={isSettingsMenuOpen} position="right">
              <MenuItem
                type="action"
                label="Delete Brag Sheet"
                title="Delete this Brag Sheet"
                primaryAction={() => {
                  setIsDeletionDialogOpen(!isDeletionDialogOpen);
                  setIsSettingsMenuOpen(!isSettingsMenuOpen);
                }}
              />
            </Menu>
            <Dialog
              isOpened={isDeletionDialogOpen}
              setIsOpened={setIsDeletionDialogOpen}
              primaryButtonType="primary"
              primaryButtonTitle="Delete Brag Sheet"
              primaryButtonLabel="Yes, Delete"
              primaryButtonFunction={deleteBragSheet}
              primaryButtonClosesDialog={true}
              secondaryButtonType="utility"
              secondaryButtonTitle="Cancel"
              secondaryButtonLabel="Cancel"
            >
              <p className="type--body--large">
                Are you sure you want to delete <strong>{bragSheet.bragSheetName}</strong>
              </p>
            </Dialog>
          </div>
        </div>
        {userDataLoaded ? (
          <Formik
            initialValues={initialValues}
            onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
              const updatedBragSheet: IBragSheet = values.bragSheet;
              if (
                updatedBragSheet.bragSheetName !== "" ||
                values.bragSheetEditorState.getCurrentContent().hasText()
              ) {
                setSubmitting(true);
                if (updatedBragSheet.bragSheetName === "") {
                  updatedBragSheet.bragSheetName = "Brag Sheet " + (bragSheets.length + 1);
                }
                updatedBragSheet.content = JSON.stringify(
                  convertToRaw(values.bragSheetEditorState.getCurrentContent()),
                );
                if (isNewBragSheet) {
                  setBragSheets([...[updatedBragSheet], ...bragSheets]);
                  setIsNewBragSheet(false);
                } else {
                  const handleUpdateBragSheet = (updatedBragSheet: IBragSheet) => {
                    setBragSheets((currentBragSheets) => {
                      // Find the index of the item that needs to be updated
                      const bragSheetIndex = currentBragSheets.findIndex(
                        (bragSheet) => bragSheet.itemId === updatedBragSheet.itemId,
                      );

                      if (bragSheetIndex === -1) {
                        return currentBragSheets; // Item not found, return the original array
                      }

                      // Create a new array with the updated item
                      const newBragSheets = [
                        ...currentBragSheets.slice(0, bragSheetIndex),
                        updatedBragSheet,
                        ...currentBragSheets.slice(bragSheetIndex + 1),
                      ];

                      return newBragSheets; // This will be a new array reference
                    });
                  };

                  handleUpdateBragSheet(updatedBragSheet);
                }
                setSubmitting(false);
              } else {
                if (
                  process.env.REACT_APP_USER_BRANCH !== "master" ||
                  (process.env.REACT_APP_USER_BRANCH === "master" &&
                    userEmail === "releasetest@careerminder.io")
                ) {
                  console.log("Brag Sheet Name is blank and text is blank");
                }
              }
            }}
            validations={bragSheetValidationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form className="brag-sheet-wrapper">
                <div className="brag-sheet-header">
                  <div className="brag-sheet-name-wrapper">
                    <Input
                      name="bragSheet.bragSheetName"
                      placeholder="Brag Sheet Name"
                      autoFocus={isNewBragSheet}
                    />
                    <div className="autosave-wrapper">
                      <AutoSave debounceMs={1000} />
                    </div>
                  </div>
                  <div className="brag-sheet-header__action-buttons">
                    <div className="pattern--menu-container pattern--menu-container--mobile-left-desktop-right">
                      <Button
                        label="Export"
                        buttonTitle="Export Brag Sheet"
                        buttonType="primary"
                        trailingIcon={isExportMenuOpen ? IconPointingUpSVG : IconPointingDownSVG}
                        onClick={() => {
                          setIsExportMenuOpen(!isExportMenuOpen);
                          setIsSettingsMenuOpen(false);
                          setIsTemplateMenuOpen(false);
                        }}
                      />
                      <Menu isOpened={isExportMenuOpen} position="right">
                        <MenuItem
                          type="action"
                          label="Copy to clipboard"
                          title="Copy plain text to clipboard"
                          primaryAction={() => {
                            copyToClipboard(values.bragSheetEditorState);
                            setIsExportMenuOpen(!isExportMenuOpen);
                          }}
                          primaryAnalyticsTag="plausible-event-name=Bragsheet+Export+Copy+to+Clipboard"
                        />
                        <MenuItem
                          type="action"
                          label="Export text file (.txt)"
                          title="Export Plain Text File"
                          primaryAction={() => {
                            exportTextFile(values.bragSheetEditorState, values.bragSheet.bragSheetName);
                            setIsExportMenuOpen(!isExportMenuOpen);
                          }}
                          primaryAnalyticsTag="plausible-event-name=Bragsheet+Export+Plain+Text"
                        />
                        <MenuItem
                          type="action"
                          label="Export Word doc (.docx)"
                          title="Export Microsoft Word document"
                          primaryAction={() => {
                            exportWordDoc(values.bragSheetEditorState, values.bragSheet.bragSheetName);
                            setIsExportMenuOpen(!isExportMenuOpen);
                          }}
                          primaryAnalyticsTag="plausible-event-name=Bragsheet+Export+Word+Doc"
                        />
                        <MenuItem
                          type="action"
                          label="Export PDF (.pdf)"
                          title="Export PDF"
                          primaryAction={() => {
                            exportPdf(values.bragSheetEditorState, values.bragSheet.bragSheetName);
                            setIsExportMenuOpen(!isExportMenuOpen);
                          }}
                          primaryAnalyticsTag="plausible-event-name=Bragsheet+Export+PDF"
                        />
                      </Menu>
                    </div>
                    <div className="pattern--menu-container">
                      <Button
                        label="Templates"
                        buttonTitle="Apply template"
                        buttonType="utility"
                        trailingIcon={isTemplateMenuOpen ? IconPointingUpSVG : IconPointingDownSVG}
                        onClick={() => {
                          setIsExportMenuOpen(false);
                          setIsSettingsMenuOpen(false);
                          setIsTemplateMenuOpen(!isTemplateMenuOpen);
                        }}
                      />
                      <Menu isOpened={isTemplateMenuOpen} position="left">
                        <MenuItem
                          type="action"
                          label="Cover Letter"
                          title="Add Cover Letter Template"
                          primaryAction={() => {
                            insertHtmlToContentState(
                              values.bragSheetEditorState,
                              coverLetterHtml,
                              setFieldValue,
                            );
                            setIsTemplateMenuOpen(!isTemplateMenuOpen);
                          }}
                          primaryAnalyticsTag="plausible-event-name=Bragsheet+Template+Cover+Letter"
                        />
                        <MenuItem
                          type="action"
                          label="Resume"
                          title="Add Resume Template"
                          primaryAction={() => {
                            // insertHtmlToContentState(
                            // 	values.bragSheetEditorState,
                            // 	buildResume(),
                            // 	setFieldValue
                            // );
                            // insertResume(
                            // 	values.bragSheetEditorState,
                            // 	setFieldValue
                            // );
                            setIsTemplateMenuOpen(!isTemplateMenuOpen);
                            setIsResumeBuilderOpen(true);
                          }}
                          primaryAnalyticsTag="plausible-event-name=Bragsheet+Template+Resume"
                        />
                      </Menu>
                    </div>
                    {/* TODO: Implement Templates */}
                    {/*                         <Button
										label="Templates"
										buttonType="utility"
									/> */}
                  </div>
                </div>
                <div
                  className={`brag-sheet-body ${
                    experienceDisplay && "brag-sheet-body--with-experience"
                  } ${referenceDisplay && "brag-sheet-body--with-reference"}`}
                >
                  {experienceDisplay && workExperiences.length && (
                    <div className="experience-panel">
                      {workExperiences.map((workExperience: IWorkExperience, index: number) => {
                        return (
                          <>
                            {workExperience.description !== "" && (
                              <div className="experience-panel__item experience-item-compact" key={index}>
                                <p className="type--body--standard experience-item-compact__content">
                                  {workExperience.description}
                                </p>
                                <Button
                                  buttonType="icon-only-utility"
                                  label="Add to Brag Sheet"
                                  leadingIcon={IconPlusSVG}
                                  onClick={() =>
                                    insertAtCursor(
                                      values.bragSheetEditorState,
                                      workExperience.description,
                                      setFieldValue,
                                    )
                                  }
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  )}
                  <Editor
                    borderType="floating"
                    button1Function={() => toggleExperienceDisplay()}
                    button1Label="Experience"
                    button2Function={() => toggleReferenceDisplay()}
                    button2Label="Goals"
                    name="bragSheetEditorState"
                  />
                  {isResumeBuilderOpen && (
                    <ResumeBuilder
                      setIsOpened={setIsResumeBuilderOpen}
                      setResumeText={(data) => {
                        insertHtmlToContentState(values.bragSheetEditorState, data, setFieldValue);
                      }}
                    />
                  )}
                </div>
                {referenceDisplay && (
                  <div className="bragsheet-reference-pane">
                    <GoalDisplay
                      displayType="small"
                      dismissable={true}
                      dismissFunction={() => toggleReferenceDisplay()}
                      goals={goals}
                    />
                  </div>
                )}
                `
              </Form>
            )}
          </Formik>
        ) : (
          <p>Not Loaded</p>
        )}
        <Toast isOpen={isToastOpen} message={toastMessage} />
      </section>
    </div>
  );
};

export default BragSheetAuthoring;
