import React, { useState } from "react";
import { API } from "aws-amplify";
import { Button, ErrorBanner } from "components/core";
import { ReactComponent as IconLightbulb } from "../../core/assets/img/lightbulb.svg";
import { ReactComponent as IconLoading } from "../../core/assets/img/donut-spinner.svg";
import { DataContainer } from "containers/DataContainer";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const similarity = require("compute-cosine-similarity");
import { IWorkExperience } from "types";
import { sendEmail, getEmbedding } from "utils";
import "./experienceRecommender.css";

interface ScoredExperience {
	itemId: string;
	score: number;
}

export interface ExperienceRecommenderProps {
	setScoredExperience: (value: ScoredExperience[]) => void;
	setFilterExperience: (value: boolean) => void;
	setFilterAmount: (value: number) => void;
}

/**
 * Primary UI component for user interaction
 */
export const ExperienceRecommender: React.FC<ExperienceRecommenderProps> = ({
	setScoredExperience,
	setFilterExperience,
	setFilterAmount,
}) => {
	const {
		preferredName,
		userEmail,
		workExperiences,
	} = DataContainer.useContainer();

	const [seekingRecommendation, setSeekingRecommendation] = useState<boolean>(
		false
	);
	const [jobDescription, setJobDescription] = useState<string>("");
	const [sourceString, setSourceString] = useState<string>("");
	const [sourceEmbedding, setSourceEmbedding] = useState<number[]>([]);
	const [recProcessUnderway, setRecProcessUnderway] = useState<boolean>(false);
	const recAvailable = workExperiences.length > 15 ? true : false;
	const rec20Available = workExperiences.length > 25 ? true : false;
	const rec30Available = workExperiences.length > 35 ? true : false;
	const [recommendationError, setRecommendationError] = useState<string>("");

	const IconLoadingSVG = <IconLoading />;

	const workExperienceDescriptions: string[] = [];

	const scoredWorkExperiences: ScoredExperience[] = [];

	const getRecommendations = async () => {
		try {
			setRecProcessUnderway(true);
			workExperienceDescriptions.push(jobDescription);

			let sourceVector: number[] = [];

			if (sourceString !== jobDescription) {
				const sourceVectorString = await getEmbedding(jobDescription);
				sourceVector = JSON.parse(sourceVectorString);
				setSourceEmbedding(sourceVector);
				setSourceString(jobDescription);
			} else {
				sourceVector = sourceEmbedding;
			}

			workExperiences.map((experience: IWorkExperience) => {
				const scoredExperience: ScoredExperience = {
					itemId: experience.itemId,
					score: similarity(JSON.parse(experience.vector), sourceVector),
				};
				scoredWorkExperiences.push(scoredExperience);
			});

			const sortedScoredWorkExperiences = scoredWorkExperiences.sort(
				(a: ScoredExperience, b: ScoredExperience) => b.score - a.score
			);

			setScoredExperience(sortedScoredWorkExperiences);
			setFilterExperience(true);

			setRecProcessUnderway(false);
		} catch (error: unknown) {
			setRecommendationError(
				"Something went wrong. A report has been sent and we’ll look into it."
			);
			try {
				sendEmail(
					"Recommendation Error",
					error,
					preferredName,
					"supportRequest",
					userEmail
				);
				return "Error, sent a report";
			} catch (err: unknown) {
				if (
					process.env.REACT_APP_USER_BRANCH !== "master" ||
					(process.env.REACT_APP_USER_BRANCH === "master" &&
						userEmail === "releasetest@careerminder.io")
				) {
					console.log("Error sending error report", err);
				}
				return "Error";
			}
		}
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const value = e.target.value;
		setJobDescription(value);
	};

	const clearJobDescription = () => {
		setJobDescription("");
	};

	const [
		currentlySelectedFilterAmount,
		setCurrentlySelectedFilterAmount,
	] = useState<number>(10);

	return (
		<div className="experience-recommender">
			<div className="experience-recommender--heading">
				<h2 className="type--heading-6">Smart Resume</h2>
				<IconLightbulb />
			</div>
			<div
				className={`experience-recommender--content ${
					seekingRecommendation && "experience-recommender--content--active"
				}`}
			>
				<p className="type--body--large">
					By adding a job description, Career Minder can use it as a prompt to
					select the most appropriate experience.
				</p>
				{seekingRecommendation ? (
					<>
						<label className="input-container">
							<span className="pattern--label type--heading-4">
								Enter Job Description
							</span>
							<textarea
								placeholder="What is this resume for?"
								className="pattern--input-field"
								value={jobDescription}
								onChange={(
									e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
								) => handleChange(e)}
								rows={6}
								maxLength={32764}
								autoFocus={true}
							/>
						</label>
						<div className="experience-recommender__actions">
							<div className="related-experience--filter--wrapper">
								{rec20Available && (
									<>
										<label className="type--data toggle-label">
											Experiences
										</label>
										<div className="small-toggle-row">
											<button
												className={`toggle-button toggle-button--small type--data ${
													currentlySelectedFilterAmount === 10 &&
													"toggle-button--selected"
												}`}
												onClick={() => {
													setCurrentlySelectedFilterAmount(10);
													setFilterAmount(10);
												}}
											>
												10
											</button>
											<button
												className={`toggle-button toggle-button--small type--data ${
													currentlySelectedFilterAmount === 20 &&
													"toggle-button--selected"
												}`}
												onClick={() => {
													setCurrentlySelectedFilterAmount(20);
													setFilterAmount(20);
												}}
											>
												20
											</button>
											{rec30Available && (
												<button
													className={`toggle-button toggle-button--small type--data ${
														currentlySelectedFilterAmount === 30 &&
														"toggle-button--selected"
													}`}
													onClick={() => {
														setCurrentlySelectedFilterAmount(30);
														setFilterAmount(30);
													}}
												>
													30
												</button>
											)}
										</div>
									</>
								)}
							</div>
							<Button
								buttonTitle="Clear job description"
								label="Clear"
								size="small"
								theme="goal"
								disabled={jobDescription === "" ? true : false}
								onClick={() => clearJobDescription()}
							/>
						</div>
						{recommendationError !== "" && (
							<ErrorBanner message={recommendationError} />
						)}
						<div className="pattern--button-row">
							<Button
								buttonTitle="Cancel Smart Resume"
								buttonType="utility"
								label="Cancel"
								onClick={() => setSeekingRecommendation(false)}
							/>
							<Button
								buttonTitle="Select Related Experience based on Job Description"
								label="Select Related Experience"
								buttonType="secondary"
								disabled={
									jobDescription === "" || recProcessUnderway ? true : false
								}
								leadingIcon={recProcessUnderway && IconLoadingSVG}
								onClick={() => getRecommendations()}
							/>
						</div>
					</>
				) : (
					<>
						{!recAvailable && (
							<p className="type--body--standard">
								Add more work experience in order to be able to get
								recommendations.
							</p>
						)}
						<Button
							buttonTitle="Add Job Description for Smart Resume"
							label="Add Job Description"
							size="small"
							theme="goal"
							disabled={!recAvailable}
							onClick={() => setSeekingRecommendation(true)}
						/>
					</>
				)}
			</div>
		</div>
	);
};
