import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import {
  BackLink,
  Button,
  Dialog,
  ExperienceItem,
  MenuItem,
  Menu,
} from "components/core";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as IconEdit } from "../core/assets/img/edit.svg";
import { ReactComponent as IconSettings } from "../core/assets/img/settings.svg";
import { ReactComponent as SentimentNegative } from "../core/assets/img/sentiment-negative.svg";
import { ReactComponent as SentimentNeutral } from "../core/assets/img/sentiment-neutral.svg";
import { ReactComponent as SentimentPositive } from "../core/assets/img/sentiment-positive.svg";
import {
  ICatchUp,
  ISentiment,
  ISuggestionCatchUp,
  IWorkExperience,
} from "types";
import { convertToHtml } from "utils";
import "./catchUpDetail.css";

export interface CatchUpDetailProps {
  fromAuthoring?: boolean;
  catchUpId?: string;
}

/**
 * Primary UI component for user interaction
 */
export const CatchUpDetail: React.FC<CatchUpDetailProps> = (props) => {
  const { catchUpId = "" } = props;
  const {
    catchups,
    sentiments,
    workExperiences,
    userDataLoaded,
    EmptyCatchup,
    setCatchups,
    setSentiments,
    user,
    userEmail,
  } = DataContainer.useContainer();

  const navigate = useNavigate();

  const catchUpLoaded = useRef<boolean>(false);

  const setUpCatchUp = (): ICatchUp => {
    if (userDataLoaded && !catchUpLoaded.current) {
      if (catchUpId === "") {
        return new EmptyCatchup(user);
      } else {
        const catchUp = catchups.filter(
          (item: ICatchUp) => item.itemId === catchUpId,
        )[0];
        return catchUp;
      }
    }
    return new EmptyCatchup(user);
  };

  const catchUp: ICatchUp = setUpCatchUp();

  const [isNewCatchUp] = useState<boolean>(catchUpId === "");

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] =
    useState<boolean>(false);

  const [currentSentiment, setCurrentSentiment] = useState<ISentiment>(
    sentiments.filter(
      (sentiment: ISentiment) => sentiment.itemId === catchUp.sentiment,
    )[0],
  );

  const relatedExperienceIDs: string[] = catchUp.experiences
    ? JSON.parse(catchUp.experiences)
    : [];

  const buildRelatedExperiences = (): IWorkExperience[] => {
    const relatedExperienceSet: IWorkExperience[] = [];
    relatedExperienceIDs.map((experienceId: string) => {
      workExperiences
        .filter(
          (experience: IWorkExperience) => experience.itemId === experienceId,
        )
        .map((experience: IWorkExperience) => {
          relatedExperienceSet.push(experience);
        });
    });
    return relatedExperienceSet;
  };

  const [relatedExperiences, setRelatedExperiences] = useState<
    IWorkExperience[]
  >(buildRelatedExperiences());

  if (
    process.env.REACT_APP_USER_BRANCH !== "master" ||
    (process.env.REACT_APP_USER_BRANCH === "master" &&
      userEmail === "releasetest@careerminder.io")
  ) {
    console.log("catchUp", catchUp);
    console.log("relatedSentiment", currentSentiment);
    console.log("relatedExperiences", relatedExperiences);
  }

  const buildRelatedExperienceIndexes = (): number[] => {
    const relatedExperienceIndexSet: number[] = [];
    relatedExperiences.map((experience: IWorkExperience) => {
      workExperiences.map((workExperience: IWorkExperience, index: number) => {
        if (experience.itemId === workExperience.itemId) {
          relatedExperienceIndexSet.push(index);
        }
      });
    });
    return relatedExperienceIndexSet;
  };

  const [relatedExperienceIndexes, setRelatedExperienceIndexes] = useState<
    number[]
  >(buildRelatedExperienceIndexes());

  useEffect(() => {
    setRelatedExperiences(buildRelatedExperiences());
    setRelatedExperienceIndexes(buildRelatedExperienceIndexes());
  }, [workExperiences]);

  const IconSettingsSVG = <IconSettings />;
  const IconEditSVG = <IconEdit />;

  const deleteCatchUp = () => {
    // TODO: Update Delete CatchUp function
    const updatedCatchUps: ICatchUp[] = catchups.filter(
      (filteredCatchup: ICatchUp) => filteredCatchup.itemId !== catchUp.itemId,
    );
    setCatchups(updatedCatchUps);
    document.body.classList.remove("dialog-open");
    navigate("/catchups");
  };

  const suggestions: ISuggestionCatchUp[] | null =
    catchUp.suggestions && catchUp.suggestions !== ""
      ? JSON.parse(catchUp.suggestions)
      : null;

  const [isDetails, setIsDetails] = useState(true);
  const [isExperience, setIsExperience] = useState(false);
  const [isSuggestions, setIsSuggestions] = useState(false);
  const [isDescription, setIsDescription] = useState(false);

  const { pathname } = useLocation();
  const isDetailsPath = matchPath("/summary", pathname);
  const isExperiencePath = matchPath("/experience", pathname);
  const isSuggestionsPath = matchPath("/suggestions", pathname);
  const isDescriptionPath = matchPath("/description", pathname);

  useEffect(() => {
    if (isDetailsPath) {
      setIsDetails(true);
      setIsExperience(false);
      setIsSuggestions(false);
      setIsDescription(false);
    }
    if (isExperiencePath) {
      setIsDetails(false);
      setIsExperience(true);
      setIsSuggestions(false);
      setIsDescription(false);
    }
    if (isSuggestionsPath) {
      setIsDetails(false);
      setIsExperience(false);
      setIsSuggestions(true);
      setIsDescription(false);
    }
    if (isDescriptionPath) {
      setIsDetails(false);
      setIsExperience(false);
      setIsSuggestions(false);
      setIsDescription(true);
    }
  }, []);

  const handleTabNavigation = (tabname: string) => {
    switch (tabname) {
      case "Details":
        setIsDetails(true);
        setIsExperience(false);
        setIsSuggestions(false);
        setIsDescription(false);
        break;
      case "Experience":
        setIsDetails(false);
        setIsExperience(true);
        setIsSuggestions(false);
        setIsDescription(false);
        break;
      case "Suggestions":
        setIsDetails(false);
        setIsExperience(false);
        setIsSuggestions(true);
        setIsDescription(false);
        break;
      case "Description":
        setIsDetails(false);
        setIsExperience(false);
        setIsSuggestions(false);
        setIsDescription(true);
        break;
      default:
        if (
          process.env.REACT_APP_USER_BRANCH !== "master" ||
          (process.env.REACT_APP_USER_BRANCH === "master" &&
            userEmail === "releasetest@careerminder.io")
        ) {
          console.log("make sure to put in the value of the tab");
        }
    }
  };

  const Tabs = () => {
    return (
      <div className="pattern--tab-navigation">
        <button
          className={
            isDetails
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("Details")}
        >
          Details
        </button>
        <button
          className={
            isExperience
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("Experience")}
        >
          Experience
        </button>
        <button
          className={
            isSuggestions
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("Suggestions")}
        >
          Suggestions
        </button>
        <button
          className={
            isDescription
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("Description")}
        >
          Description
        </button>
      </div>
    );
  };

  const summaryTextareaRef = useRef<HTMLTextAreaElement>(null);

  const [summary, setSummary] = useState<string>(catchUp.summary);
  const [isEditingSummary, setIsEditingSummary] = useState(false);

  useEffect(() => {
    const adjustHeight = () => {
      const textarea = summaryTextareaRef.current;

      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };
    adjustHeight();

    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight);
  }, [isEditingSummary]);

  const handleSummaryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;

    textarea.style.height = "auto";

    textarea.style.height = `${textarea.scrollHeight}px`;
    const value = e.target.value;
    setSummary(value);
  };

  const descriptionTextareaRef = useRef<HTMLTextAreaElement>(null);

  const [description, setDescription] = useState<string>(catchUp.description);
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  useEffect(() => {
    const adjustHeight = () => {
      const textarea = descriptionTextareaRef.current;

      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };
    adjustHeight();

    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight);
  }, [isEditingDescription]);

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = e.target.value;
    setDescription(value);
  };

  const [newExperienceItem, setNewExperienceItem] = useState<boolean>(false);

  const [isEditingSentiment, setIsEditingSentiment] = useState<boolean>(false);

  const updateSentiment = (sentimentValue: number) => {
    if (currentSentiment.feeling !== sentimentValue) {
      const updatedSentiment = {
        ...currentSentiment,
        feeling: sentimentValue,
      };

      // Create a new array with the updated sentiment
      const updatedSentiments = sentiments.map((sentiment) =>
        sentiment.itemId === currentSentiment.itemId
          ? updatedSentiment
          : sentiment,
      );

      setCurrentSentiment(updatedSentiment);
      setSentiments(updatedSentiments);
    }
    setIsEditingSentiment(false);
  };

  return (
    <div className="outer-wrapper">
      <section className="catchup-container">
        <div className="header--withbreadcrumb catchup-masthead">
          <div className="backlink-wrapper">
            <BackLink
              button={false}
              label="Return to Catch Ups"
              title="Return to Catch Ups"
              navigateTo="/catchups"
            />
          </div>
          <div className="pattern--menu-container pattern--menu-container--mobile-left-desktop-right">
            <Button
              label="Catch Up Settings"
              buttonTitle="See Catch Up Settings"
              buttonType="icon-only"
              disabled={isNewCatchUp}
              trailingIcon={IconSettingsSVG}
              size="small"
              onClick={() => {
                setIsSettingsMenuOpen(!isSettingsMenuOpen);
              }}
            />
            <Menu isOpened={isSettingsMenuOpen} position="right">
              <MenuItem
                type="action"
                label="Delete Catch UP"
                title="Delete this Catch UP"
                primaryAction={() => {
                  setIsDeletionDialogOpen(!isDeletionDialogOpen);
                  setIsSettingsMenuOpen(!isSettingsMenuOpen);
                }}
              />
            </Menu>
            <Dialog
              isOpened={isDeletionDialogOpen}
              setIsOpened={setIsDeletionDialogOpen}
              primaryButtonType="primary"
              primaryButtonTitle="Delete Review"
              primaryButtonLabel="Yes, Delete"
              primaryButtonFunction={deleteCatchUp}
              primaryButtonClosesDialog={true}
              secondaryButtonType="utility"
              secondaryButtonTitle="Cancel"
              secondaryButtonLabel="Cancel"
            >
              <p className="type--body--large">
                Are you sure you want to delete <strong>{catchUp.name}</strong>
              </p>
            </Dialog>
          </div>
        </div>
        <div className="catchup-wrapper">
          <div className="catchup-header">
            <h1 className="type--heading-1">{catchUp.name}</h1>
          </div>
          <div className="catchup-body">
            <Tabs />
            <div
              className={`catchup-pane-content ${isDetails && "catchup-pane-content--details"} ${isExperience && "catchup-pane-content--experience"} ${isSuggestions && "catchup-pane-content--suggestions"} ${isDescription && "catchup-pane-content--description"}`}
            >
              {isDetails && (
                <div className="catchup-details-pane">
                  <div className="catchup-summary-wrapper">
                    <div className="catchup-summary-wrapper__header">
                      <h2 className="type--heading-3">Summary</h2>
                      {!isEditingSummary ? (
                        <Button
                          label="Edit Summary"
                          buttonTitle="Edit Catch Up Summary"
                          buttonType="icon-only"
                          disabled={isNewCatchUp}
                          trailingIcon={IconEditSVG}
                          size="small"
                          onClick={() => {
                            setIsEditingSummary(!isEditingSummary);
                          }}
                        />
                      ) : (
                        <div className="catchup-summary-editing-buttons">
                          <Button
                            label="Cancel"
                            buttonTitle="Cancel Summary Editing"
                            buttonType="utility"
                            size="small"
                            onClick={() => {
                              setSummary(catchUp.summary);
                              setIsEditingSummary(!isEditingSummary);
                            }}
                          />
                          <Button
                            label="Save"
                            buttonTitle="Save Summary"
                            buttonType="utility"
                            size="small"
                            onClick={() => {
                              const updatedCatchUp = catchUp;
                              updatedCatchUp.summary = summary;
                              const updatedCatchups = [...catchups];
                              let currentIndex = 0;
                              catchups.map(
                                (currentCatchUp: ICatchUp, index: number) => {
                                  if (
                                    currentCatchUp.itemId === catchUp.itemId
                                  ) {
                                    currentIndex = index;
                                  }
                                },
                              );
                              updatedCatchups.splice(
                                currentIndex,
                                1,
                                updatedCatchUp,
                              );
                              setCatchups(updatedCatchups);
                              setIsEditingSummary(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {!isEditingSummary ? (
                      <>
                        {!summary ? (
                          <p className="type--body--large">No summary</p>
                        ) : (
                          <div
                            className="catchup-summary-wrapper__content"
                            dangerouslySetInnerHTML={{
                              __html: convertToHtml(summary),
                            }}
                          ></div>
                        )}
                      </>
                    ) : (
                      <label className="input-container">
                        <textarea
                          placeholder="What is the summary of this check in?"
                          className="pattern--input-field"
                          value={summary}
                          onChange={(e) => handleSummaryChange(e)}
                          rows={10}
                          maxLength={32764}
                          autoFocus={true}
                          ref={summaryTextareaRef}
                        />
                      </label>
                    )}
                  </div>
                  <aside className="complementary-elements">
                    {currentSentiment && (
                      <>
                        <h3 className="type--heading-4">Sentiment</h3>
                        <div className="complementary-sentiment">
                          <div className="sentiment-toggle-wrapper">
                            <button
                              className="sentiment-editing-toggle"
                              onClick={() =>
                                setIsEditingSentiment(!isEditingSentiment)
                              }
                            >
                              {currentSentiment.feeling !== 0 ? (
                                currentSentiment.feeling === 100 ? (
                                  <SentimentPositive />
                                ) : (
                                  <SentimentNeutral />
                                )
                              ) : (
                                <SentimentNegative />
                              )}
                            </button>
                            {isEditingSentiment && (
                              <div className="sentiment-editing-wrapper">
                                <button
                                  className="sentiment-update-button"
                                  onClick={() => updateSentiment(0)}
                                >
                                  <SentimentNegative />
                                </button>
                                <button
                                  className="sentiment-update-button"
                                  onClick={() => updateSentiment(50)}
                                >
                                  <SentimentNeutral />
                                </button>
                                <button
                                  className="sentiment-update-button"
                                  onClick={() => updateSentiment(100)}
                                >
                                  <SentimentPositive />
                                </button>
                              </div>
                            )}
                          </div>
                          <p className="type--body--standard">
                            From your description it seems like you are feeling{" "}
                            {currentSentiment.feeling !== 0
                              ? currentSentiment.feeling === 100
                                ? "positive"
                                : "neutral"
                              : "negative"}{" "}
                            about your work right now. Change your sentiment if
                            that’s not accurate.
                          </p>
                        </div>
                      </>
                    )}
                  </aside>
                </div>
              )}
              {isExperience && (
                <div className="catchup-experience">
                  {relatedExperiences.length > 0 && (
                    <div className="complementary-experiences">
                      <p className="type--body--large">
                        Here are some suggested experiences from your
                        description. They may be rephrased, so make sure they
                        are written how you would say them yourself.
                      </p>
                      <div className="experience-list-wrapper">
                        {relatedExperiences.map(
                          (experience: IWorkExperience, index: number) => {
                            return (
                              <ExperienceItem
                                index={relatedExperienceIndexes[index]}
                                key={experience.itemId}
                                newExperienceItem={newExperienceItem}
                                setNewExperienceItem={setNewExperienceItem}
                                workExperience={experience}
                              />
                            );
                          },
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isSuggestions && (
                <div className="catchup-suggestions">
                  {suggestions && suggestions.length > 0 ? (
                    <div className="suggestions-inner">
                      <h2 className="type--heading-3">Suggestions</h2>
                      <p className="type--body--large">
                        Here are some suggestions for how to reach your goals
                        based on what you’ve been working on. They were
                        generated based on your Catch Up description and your
                        Goals.
                      </p>
                      <div className="suggestions-list-wrapper">
                        {suggestions.map(
                          (suggestion: ISuggestionCatchUp, index: number) => {
                            return (
                              <div className="suggestion" key={index}>
                                <div className="suggestion-inner">
                                  <h3 className="type--heading-6">
                                    {suggestion.goal}
                                  </h3>
                                  <p className="type--body--large">
                                    {suggestion.suggestion}
                                  </p>
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="suggestions-inner">
                      <h2 className="type--heading-3">Suggestions</h2>
                      <p className="type--body--large">
                        Here are some suggestions for how to reach your goals
                        based on what you’ve been working on. They were
                        generated based on your Catch Up description and your
                        Goals.
                      </p>
                    </div>
                  )}
                </div>
              )}
              {isDescription && (
                <div>
                  <div className="catchup-description-wrapper__header">
                    <h2 className="type--heading-3">Catch Up Description</h2>
                    {!isEditingDescription ? (
                      <Button
                        label="Edit Description"
                        buttonTitle="Edit Catch Up Description"
                        buttonType="icon-only"
                        disabled={isNewCatchUp}
                        trailingIcon={IconEditSVG}
                        size="small"
                        onClick={() => {
                          setIsEditingDescription(!isEditingDescription);
                        }}
                      />
                    ) : (
                      <div className="catchup-summary-editing-buttons">
                        <Button
                          label="Cancel"
                          buttonTitle="Cancel Description Editing"
                          buttonType="utility"
                          size="small"
                          onClick={() => {
                            setDescription(catchUp.description);
                            setIsEditingDescription(!isEditingDescription);
                          }}
                        />
                        <Button
                          label="Save"
                          buttonTitle="Save Description"
                          buttonType="utility"
                          size="small"
                          onClick={() => {
                            const updatedCatchUp = catchUp;
                            updatedCatchUp.description = description;
                            const updatedCatchups = [...catchups];
                            let currentIndex = 0;
                            catchups.map(
                              (currentCatchUp: ICatchUp, index: number) => {
                                if (currentCatchUp.itemId === catchUp.itemId) {
                                  currentIndex = index;
                                }
                              },
                            );
                            updatedCatchups.splice(
                              currentIndex,
                              1,
                              updatedCatchUp,
                            );
                            setCatchups(updatedCatchups);
                            setIsEditingDescription(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!isEditingDescription ? (
                    <div
                      className="catchup-description"
                      dangerouslySetInnerHTML={{
                        __html: convertToHtml(description),
                      }}
                    ></div>
                  ) : (
                    <label className="input-container">
                      <textarea
                        placeholder="What is the description?"
                        className="pattern--input-field"
                        value={description}
                        onChange={(e) => handleDescriptionChange(e)}
                        rows={10}
                        maxLength={32764}
                        autoFocus={true}
                        ref={descriptionTextareaRef}
                      />
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
