import React from "react";
import { Link, To } from "react-router-dom";
import { ReactComponent as IconPointingLeft } from "../assets/img/arrow-pointing-left.svg";
import { ReactComponent as IconPointingRight } from "../assets/img/arrow-pointing-right.svg";
import "./backLink.css";

export interface BackLinkProps {
	button: boolean;
	label: string;
	title: string;
	onClick?: () => void;
	navigateTo?: To;
	pointingRight?: boolean;
	reversed?: boolean;
}

export const BackLink: React.FC<BackLinkProps> = ({
	button,
	label = "Link back to what?",
	title,
	onClick,
	navigateTo,
	pointingRight = false,
	reversed = false,
}) => {
	return (
		<>
			{button ? (
				<button
					className={`back-link type--body--large ${reversed && "back-link--reversed"}`}
					onClick={onClick}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					title={title!}
				>
					{!pointingRight ? 
						<IconPointingLeft />
						:
						<IconPointingRight />
					}
					<strong>{label}</strong>
				</button>
			) : (
				<Link
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					to={navigateTo!}
					className={`back-link ${reversed && "back-link--reversed"}`}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					title={title!}
				>
					{!pointingRight ? 
						<IconPointingLeft />
						:
						<IconPointingRight />
					}
					<strong>{label}</strong>
				</Link>
			)}
		</>
	);
};
