import React, { Fragment, useState, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, ExperiencePrompt } from "components/core";
import { SentimentCard } from "./SentimentCard";
import { Certification } from "./Certification";
import { UpcomingCard } from "./UpcomingCard";
import { Education } from "./Education";
import { Job } from "./Job";
import { EmptySummaryButton } from "./EmptySummaryButton";
import { DataContainer } from "containers/DataContainer";
import { v4 as uuid } from "uuid";
import { ICertification, IEducation, IJob } from "types";
import { ReactComponent as IconPerson } from "../core/assets/img/person.svg";
import "./dashboard.css";

interface DashboardProps {
  email?: string;
  jobDescription?: string;
  name?: string;
  bragSheetIndex?: number;
}

const Dashboard: React.FC<DashboardProps> = () => {
  const {
    certifications,
    educations,
    jobs,
    preferredName,
    user,
    userDataLoaded,
    EmptyCertification,
    EmptyJob,
    EmptyEducation,
    inactivateItem,
    setCertifications,
    setEducations,
    setJobs,
    subscriptionActive,
    workExperiences,
    userEmail,
    subscriptionStatus,
  } = DataContainer.useContainer();

  const navigate = useNavigate();

  const IconPersonSVG = <IconPerson />;

  const [promptsVisible, setPromptsVisible] = useState<boolean>(true);

  const experienceCount = workExperiences.length;

  // Once the user is used to adding work experience don’t show the prompt by default.
  useEffect(() => {
    if (experienceCount > 20) {
      setPromptsVisible(false);
    }
  }, [userDataLoaded]);

  const [newJob, setNewJob] = useState<number>(-1);
  const [newEducation, setNewEducation] = useState<number>(-1);
  const [newCertification, setNewCertification] = useState<number>(-1);

  const [isWorkSummary, setIsWorkSummary] = useState(true);
  const [isEducationSummary, setIsEducationSummary] = useState(false);
  const [isCertificationSummary, setIsCertificationSummary] = useState(false);

  const { pathname } = useLocation();
  const isWorkSummaryPath = matchPath("/work-summary", pathname);
  const isEducationSummaryPath = matchPath("/education-summary", pathname);
  const isCertificationSummaryPath = matchPath("/certification-summary", pathname);

  useEffect(() => {
    if (isWorkSummaryPath) {
      setIsWorkSummary(true);
      setIsEducationSummary(false);
      setIsCertificationSummary(false);
    }
    if (isEducationSummaryPath) {
      setIsWorkSummary(false);
      setIsEducationSummary(true);
      setIsCertificationSummary(false);
    }
    if (isCertificationSummaryPath) {
      setIsWorkSummary(false);
      setIsEducationSummary(false);
      setIsCertificationSummary(true);
    }
  }, []);

  const handleTabNavigation = (tabname: string) => {
    switch (tabname) {
      case "workSummary":
        setIsWorkSummary(true);
        setIsEducationSummary(false);
        setIsCertificationSummary(false);
        break;
      case "educationSummary":
        setIsWorkSummary(false);
        setIsEducationSummary(true);
        setIsCertificationSummary(false);
        break;
      case "certificationSummary":
        setIsWorkSummary(false);
        setIsEducationSummary(false);
        setIsCertificationSummary(true);
        break;
      default:
        if (
          process.env.REACT_APP_USER_BRANCH !== "master" ||
          (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
        ) {
          console.log("make sure to put in the value of the tab");
        }
    }
  };

  const Tabs = () => {
    return (
      <div className="pattern--tab-navigation dashboard-navigation">
        <button
          className={
            isWorkSummary ? "pattern--tab-nav-item pattern--tab-nav-item--active" : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("workSummary")}
        >
          Jobs
        </button>
        <button
          className={
            isEducationSummary
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("educationSummary")}
        >
          Education
        </button>
        <button
          className={
            isCertificationSummary
              ? "pattern--tab-nav-item pattern--tab-nav-item--active"
              : "pattern--tab-nav-item"
          }
          onClick={() => handleTabNavigation("certificationSummary")}
        >
          Certification
        </button>
      </div>
    );
  };

  return (
    <div className="dashboard-outer-wrapper">
      <section className="dashboard-container">
        <div className="dashboard-masthead">
          <div className="dashboard-masthead__profile-wrapper">
            <div className="dashboard-profile">
              <h1 className="type--heading-1">Hello {preferredName !== "" && preferredName}</h1>
            </div>
            <Button
              label="Update Profile"
              buttonTitle="Update your profile"
              buttonType="utility"
              leadingIcon={IconPersonSVG}
              onClick={() => navigate("profile")}
            />
          </div>
          <div className="dashboard-card-row">
            <div className="dashboard-card-row__activity dashboard-card">
              <UpcomingCard />
            </div>
            <div className="dashboard-card-row__goals dashboard-card">
              <SentimentCard />
            </div>
          </div>
        </div>
        <div className="work-education-summary-wrapper">
          <div className="nav-sidecar-wrapper">
            <Tabs />
            {isWorkSummary && (
              <div className="summary-wrapper summary-wrapper--job">
                <ExperiencePrompt
                  addExperienceButton={true}
                  borderRadius="grouping"
                  visible={promptsVisible}
                  setVisible={() => setPromptsVisible(!promptsVisible)}
                />
                <div className="work-summary">
                  <div className="summary__actions">
                    <Button
                      label="Experience Prompt"
                      buttonTitle="Get suggestions for your experience"
                      buttonType="utility"
                      theme="prompt"
                      disabled={promptsVisible ? true : false}
                      onClick={() => setPromptsVisible(true)}
                      analyticsTag="plausible-event-name=Prompt+Engagement"
                    />
                    <Button
                      label="Add Job"
                      buttonTitle="Add another job"
                      buttonType="utility"
                      disabled={!subscriptionActive || !userDataLoaded ? true : false}
                      onClick={() => {
                        setNewJob(0);
                        const updatedJobs = [...[new EmptyJob(user)], ...jobs];
                        setJobs(updatedJobs);
                      }}
                      analyticsTag="plausible-event-name=Dashboard+Add+Job"
                    />
                  </div>
                  <div className="summary-list summary-list--jobs">
                    <Fragment>
                      {jobs.length ? (
                        jobs
                          .sort((a: IJob, b: IJob) => a.sortOrder - b.sortOrder)
                          .map((job: IJob, index: number) => {
                            return (
                              <Job
                                inactivateItem={inactivateItem}
                                index={index}
                                key={uuid()}
                                newJob={newJob}
                                setNewJob={setNewJob}
                                job={job}
                                onDashboard={true}
                              />
                            );
                          })
                      ) : (
                        <EmptySummaryButton
                          headline="You haven’t entered any jobs yet"
                          description="Click here to add your first job"
                          onClick={() => {
                            setNewJob(0);
                            const updatedJobs = [...[new EmptyJob(user)], ...jobs];
                            setJobs(updatedJobs);
                          }}
                        />
                      )}
                    </Fragment>
                  </div>
                </div>
              </div>
            )}
            {isEducationSummary && (
              <div className="education-summary summary-wrapper">
                <div className="summary__actions">
                  <Button
                    label="Add Education"
                    buttonTitle="Add some education"
                    buttonType="utility"
                    disabled={!subscriptionActive || !userDataLoaded ? true : false}
                    onClick={() => {
                      setNewEducation(educations.length);
                      const newEducation = new EmptyEducation(user);
                      newEducation.sortOrder = educations.length;
                      const updatedEducations = [...educations, ...[newEducation]];
                      setEducations(updatedEducations);
                    }}
                    analyticsTag="plausible-event-name=Dashboard+Add+Education plausible-event-step=start"
                  />
                </div>
                <Fragment>
                  <div className="summary-list summary-list--education">
                    {educations.length ? (
                      educations
                        .sort((a, b) => {
                          // Treat undefined sortOrder as a large number to sort it to the end
                          const sortOrderA =
                            a.sortOrder !== undefined ? a.sortOrder : Number.MAX_SAFE_INTEGER;
                          const sortOrderB =
                            b.sortOrder !== undefined ? b.sortOrder : Number.MAX_SAFE_INTEGER;

                          return sortOrderA - sortOrderB;
                        })
                        .map((education: IEducation, index: number) => {
                          return (
                            <Education
                              inactivateItem={inactivateItem}
                              index={index}
                              key={uuid()}
                              newEducation={newEducation}
                              setNewEducation={setNewEducation}
                              education={education}
                              onDashboard={true}
                            />
                          );
                        })
                    ) : (
                      <EmptySummaryButton
                        headline="You haven’t entered any education yet"
                        description="Click here to add your first education"
                        onClick={() => {
                          setNewEducation(educations.length);
                          const updatedEducations = [...[new EmptyEducation(user)], ...educations];
                          setEducations(updatedEducations);
                        }}
                      />
                    )}
                  </div>
                </Fragment>
              </div>
            )}
            {isCertificationSummary && (
              <div className="certification-summary summary-wrapper">
                <div className="summary__actions">
                  <Button
                    label="Add Certification"
                    buttonTitle="Add a certification"
                    buttonType="utility"
                    disabled={!subscriptionActive || !userDataLoaded ? true : false}
                    onClick={() => {
                      setNewCertification(certifications.length);
                      const updatedCertifications = [...certifications, ...[new EmptyCertification(user)]];
                      setCertifications(updatedCertifications);
                    }}
                    analyticsTag="plausible-event-name=Dashboard+Add+Certification"
                  />
                </div>
                <Fragment>
                  <div className="summary-list summary-list--education">
                    {certifications.length ? (
                      certifications
                        .sort((a, b) => {
                          // Treat undefined sortOrder as a large number to sort it to the end
                          const sortOrderA =
                            a.sortOrder !== undefined ? a.sortOrder : Number.MAX_SAFE_INTEGER;
                          const sortOrderB =
                            b.sortOrder !== undefined ? b.sortOrder : Number.MAX_SAFE_INTEGER;

                          return sortOrderA - sortOrderB;
                        })
                        .map((certification: ICertification, index: number) => {
                          return (
                            <Certification
                              inactivateItem={inactivateItem}
                              index={index}
                              key={uuid()}
                              newCertification={newCertification}
                              setNewCertification={setNewCertification}
                              certification={certification}
                              onDashboard={true}
                            />
                          );
                        })
                    ) : (
                      <EmptySummaryButton
                        headline="You haven’t entered any certifications yet"
                        description="Click here to add your first certification"
                        onClick={() => {
                          setNewCertification(certifications.length);
                          const updatedCertifications = [
                            ...[new EmptyCertification(user)],
                            ...certifications,
                          ];
                          setCertifications(updatedCertifications);
                        }}
                      />
                    )}
                  </div>
                </Fragment>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
