import React, { useState, useEffect } from "react";
import { Button } from "components/core";
import { ReviewQuestion } from "./ReviewQuestion";
import { DataContainer } from "containers/DataContainer";
import { ReactComponent as IconPlus } from "../../core/assets/img/plus.svg";
import { IReview, IQuestion } from "types";
import "./reviewInterviewPrepTab.css";

export interface ReviewInterviewPrepTabProps {
  review: IReview;
}

export const ReviewInterviewPrepTab: React.FC<ReviewInterviewPrepTabProps> = ({ review }) => {
  const { reviews, setReviews } = DataContainer.useContainer();

  const isQuestion = (obj: any): obj is IQuestion => {
    return (
      typeof obj === "object" &&
      obj !== null &&
      typeof obj.question === "string" &&
      typeof obj.reason === "string"
    );
  };

  const isArrayOfQuestions = (arr: any): arr is IQuestion[] => {
    return Array.isArray(arr) && arr.every(isQuestion);
  };

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [newQuestionObject, setNewQuestionObject] = useState<IQuestion>({ question: "", reason: "" });
  const [newQuestion, setNewQuestion] = useState<boolean>(false);

  useEffect(() => {
    let parsedQuestions: any;
    try {
      parsedQuestions = JSON.parse(review.questions ? review.questions : "[]");
    } catch (error) {
      console.error("Invalid JSON string");
      parsedQuestions = [];
    }

    if (isArrayOfQuestions(parsedQuestions)) {
      setQuestions(parsedQuestions);
    } else {
      setQuestions([]);
    }
  }, [review.questions]);

  const saveQuestions = (updatedQuestions: IQuestion[]) => {
    const updatedReview = { ...review, questions: JSON.stringify(updatedQuestions) };
    const updatedReviews = reviews.map((r) => (r.itemId === review.itemId ? updatedReview : r));
    setReviews(updatedReviews);
  };

  const handleAddQuestion = (newQuestion: IQuestion) => {
    if (!newQuestion.question.trim() || !newQuestion.reason.trim()) {
      return; // Do not add a question with empty fields
    }

    const updatedQuestions = [newQuestion, ...questions];
    setQuestions(updatedQuestions);
    setNewQuestionObject({ question: "", reason: "" });
    setNewQuestion(false);
    saveQuestions(updatedQuestions);
  };

  const handleNewQuestionChange = (updated: IQuestion) => {
    setNewQuestionObject(updated);
  };

  const handleQuestionChange = (updated: IQuestion, questionIndex: number) => {
    const updatedQuestions = questions.map((question, index) =>
      index === questionIndex ? updated : question,
    );
    setQuestions(updatedQuestions);
    saveQuestions(updatedQuestions);
  };

  const handleQuestionDelete = (questionIndex: number) => {
    const updatedQuestions = questions.filter((_, index) => index !== questionIndex);
    setQuestions(updatedQuestions);
    saveQuestions(updatedQuestions);
  };

  return (
    <div className="review-interview-prep-wrapper">
      <div className="review-interview-prep-header">
        <h2 className="type--heading-3">Interview Questions</h2>
        <Button
          label="Add Question"
          buttonTitle="Add an Interview Question"
          buttonType="secondary"
          leadingIcon={<IconPlus />}
          onClick={() => setNewQuestion(true)}
        />
      </div>
      {questions.length > 0 || newQuestion ? (
        <>
          <p className="questions-intro type--body--large">
            Based on what you liked and didn’t like about your previous jobs, here are some potential
            questions you could ask to find out if the job would be a good fit for you.
          </p>
          <div className="review-interview-prep-content">
            {newQuestion && (
              <ReviewQuestion
                handleQuestionAdd={handleAddQuestion}
                handleQuestionChange={handleNewQuestionChange}
                handleQuestionDelete={() => setNewQuestion(false)}
                question={newQuestionObject}
                index={-1}
                newQuestion={true}
              />
            )}
            {questions.map((question, index) => (
              <ReviewQuestion
                key={`key${index + question.question.slice(0, 10).toLowerCase().replace(/\s/g, "")}`}
                handleQuestionAdd={handleAddQuestion}
                handleQuestionChange={handleQuestionChange}
                handleQuestionDelete={handleQuestionDelete}
                question={question}
                index={index}
                newQuestion={false}
              />
            ))}
          </div>
        </>
      ) : (
        <button
          className="empty-questions pattern--empty-state--fill-area"
          onClick={() => setNewQuestion(true)}
        >
          <h3 className="type--heading-3">You don’t have any interview questions</h3>
          <p className="type--body--large">Click here to add your first interview questions</p>
        </button>
      )}
    </div>
  );
};
