import React, { Fragment, useState, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import {
	Button,
	Dialog,
	FileDownload,
	Input,
	Reminders,
} from "components/core";
import { CheckoutForm } from "components/Subscription";
import { DataContainer } from "containers/DataContainer";
import { profileValidationSchema } from "utils/validations";
import { ReactComponent as IconWarning } from "components/core/assets/img/warning.svg";
import { ReactComponent as IconDonutSpinner } from "components/core/assets/img/donut-spinner.svg";
import { AccountDetails } from "./AccountDetails";
import { Help } from "./Help";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { saveAs } from "file-saver";
import {
	generateDataCSV,
	getSubscriptionStatusString,
	subscriptionCancelationExplanation,
	subscriptionCancelledBillingMessage,
} from "data";
import {
	getFutureDate,
	getDaysFromNow
} from "utils";
import { API } from "aws-amplify";
import "./profile.css";

interface Values {
	fullName: string;
	jobTitle: string;
	preferredName: string;
	reminderDay: string;
	reminderFrequency: string;
}

export const Profile: React.FC = () => {
	const {
		fullName,
		jobTitle,
		preferredName,
		reminderDay,
		reminderFrequency,
		setFullName,
		setJobTitle,
		setPreferredName,
		setReminderDay,
		setReminderFrequency,
		setSubmitProfile,
		subscriptionActive,
		subscriptionCanceling,
		subscriptionStatus,
		setSubscriptionCanceling,
		stripeCustomerID,
		stripeSubscriptionID,
		stripePrice,
		stripePlanInterval,
		stripePercentDiscount,
		stripeDiscountEndDateUnix,
		userDataLoaded,
		bragSheets,
		workExperiences,
		jobs,
		companies,
		projects,
		goals,
		educations,
		schools,
		certifications,
		getSubscriptionInfo,
		termsAgreementDate,
		userEmail,
	} = DataContainer.useContainer();

	const initialValues = {
		fullName,
		jobTitle,
		preferredName,
		reminderDay,
		reminderFrequency,
	};

	const [isPersonalDetails, setPersonalDetailsPane] = useState(true);
	const [isReminders, setRemindersPane] = useState(false);
	const [isAccount, setAccountPane] = useState(false);
	const [isSubscription, setSubscriptionPane] = useState(false);
	const [isSupport, setSupportPane] = useState(false);

	const { pathname } = useLocation();
	const isRemindersPath = matchPath("/profile/reminders", pathname);
	const isAccountPath = matchPath("/profile/account", pathname);
	const isSubscriptionPath = matchPath("/profile/subscription", pathname);
	const isSupportPath = matchPath("/profile/help", pathname);

	useEffect(() => {
		if (isRemindersPath) {
			setPersonalDetailsPane(false);
			setAccountPane(false);
			setRemindersPane(true);
			setSubscriptionPane(false);
			setSupportPane(false);
		}
		if (isAccountPath) {
			setPersonalDetailsPane(false);
			setAccountPane(true);
			setRemindersPane(false);
			setSubscriptionPane(false);
			setSupportPane(false);
		}
		if (isSubscriptionPath) {
			setPersonalDetailsPane(false);
			setAccountPane(false);
			setRemindersPane(false);
			setSubscriptionPane(true);
			setSupportPane(false);
		}
		if (isSupportPath) {
			setPersonalDetailsPane(false);
			setAccountPane(false);
			setRemindersPane(false);
			setSubscriptionPane(false);
			setSupportPane(true);
		}
	}, []);

	const [billingMessage, setBillingMessage] = useState<string>("");

	const [
		loadingSubscriptionData,
		setLoadingSubscriptionData,
	] = useState<boolean>(true);
	
	const defaultPrice = (): number => {
		const price = stripePrice !== undefined ? stripePrice : 0;
		return price;
	};
	
	const discountedPrice = (): number => {
		const discountPercent = stripePercentDiscount !== undefined ? stripePercentDiscount : 0;
		const price = stripePrice !== undefined ? stripePrice : 0;
		const finalPrice = price - (price * (discountPercent / 100));
		return finalPrice;
	};
	
	const waitFor = (conditionFunction: () => boolean): Promise<void> => {
		const poll = (resolve: () => void): void => {
			if (conditionFunction()) {
				resolve();
			} else {
				setTimeout(() => poll(resolve), 400);
			}
		};
		return new Promise<void>(poll);
	};

	const updateMessage = () => {
		if (subscriptionStatus) {
			if (subscriptionCanceling && subscriptionCanceling === true) {
				setBillingMessage(subscriptionCancelledBillingMessage);
			} else {
				const trialExpirationDate = getFutureDate(termsAgreementDate, 14, true);
				const trialEndsDaysFromNow = Math.round(getDaysFromNow(trialExpirationDate));
				switch (subscriptionStatus) {
					case "active":
						if (stripePercentDiscount && stripePercentDiscount > 0) {
							setBillingMessage(
								getSubscriptionStatusString(
									"long",
									"active",
									defaultPrice(),
									stripePlanInterval && stripePlanInterval === "month" ? "month" : "year",
									{
										discountedPrice: discountedPrice(),
										discountDate: Number(stripeDiscountEndDateUnix)
									}
								)
							);
						} else {
							setBillingMessage(
								getSubscriptionStatusString(
									"long",
									"active",
									defaultPrice(),
									stripePlanInterval && stripePlanInterval === "month" ? "month" : "year",
								)
							);
						}
						break;
					case "past_due":
						setBillingMessage(getSubscriptionStatusString("long", "past_due"));
						break;
					case "unpaid":
						setBillingMessage(getSubscriptionStatusString("long", "unpaid"));
						break;
					case "canceled":
						setBillingMessage(getSubscriptionStatusString("long", "canceled"));
						break;
					case "incomplete":
						setBillingMessage(getSubscriptionStatusString("long", "incomplete"));
						break;
					case "incomplete_expired":
						setBillingMessage(
							getSubscriptionStatusString("long", "incomplete_expired")
						);
						break;
					case "trialing":
						if (stripePercentDiscount && stripePercentDiscount > 0) {
							setBillingMessage(
								getSubscriptionStatusString(
									"long",
									"trialing",
									defaultPrice(),
									stripePlanInterval && stripePlanInterval === "month" ? "month" : "year",
									{
										discountedPrice: discountedPrice(),
										discountDate: Number(stripeDiscountEndDateUnix)
									},
									trialEndsDaysFromNow
								)
							);
						} else {
							setBillingMessage(
								getSubscriptionStatusString(
									"long",
									"trialing",
									defaultPrice(),
									stripePlanInterval && stripePlanInterval === "month" ? "month" : "year",
									undefined,
									trialEndsDaysFromNow
								)
							);
						}
						break;
					case "paused":
						setBillingMessage(getSubscriptionStatusString("long", "paused"));
						break;
					default:
						if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
							console.log("Unknown Status:", subscriptionStatus);
						}
						break;
				}
			}
			setLoadingSubscriptionData(false);
		}
	};

	const setSubscriptionData = async () => {
		await waitFor(() => userDataLoaded === true)
			.then(() => getSubscriptionInfo());
		updateMessage();
	};

	useEffect(() => {
		if (billingMessage !== "") {
			setLoadingSubscriptionData(false);
		}
	}, [billingMessage]);

	useEffect(() => {
		setSubscriptionData();
	}, []);
	
	useEffect(() => {
		setSubscriptionData();
	}, [userDataLoaded]);

	const IconDonutSpinnerSVG = <IconDonutSpinner />;

	const [launchingSubscriptionManagement, setLaunchingSubscriptionManagement] = useState<boolean>(false);

	const manageSubscriptions = async () => {
		setLaunchingSubscriptionManagement(true);
		const url = `${window.location.origin.toString()}/profile/subscription`;
		const fetchSession = async () => {
			const apiName = "careerminder";
			const apiEndpoint = "/subscription/manage";
			const body = {
				customerId: stripeCustomerID,
				returnUrl: url,
			};
			const session = await API.post(apiName, apiEndpoint, { body });
			return session;
		};
		const session = await fetchSession();
		window.location.href = session.url;
		setLaunchingSubscriptionManagement(false);
	};

	const [
		isCancelSubscriptionConfirmationOpened,
		setIsCancelSubscriptionConfirmationOpened,
	] = useState(false);

	const cancelSubscription = async () => {
		const fetchSession = async () => {
			const apiName = "careerminder";
			const apiEndpoint = "/subscription/cancel";
			const body = {
				subscription: stripeSubscriptionID,
			};
			const session = await API.post(apiName, apiEndpoint, { body });
			return session;
		};
		const response = await fetchSession();
		setSubscriptionCanceling(response.canceled);
		setSubmitProfile(true);
		setBillingMessage(subscriptionCancelledBillingMessage);
		setIsCancelSubscriptionConfirmationOpened(false);
	};

	const reverseCancelation = async () => {
		setLoadingSubscriptionData(true);
		const fetchSession = async () => {
			const apiName = "careerminder";
			const apiEndpoint = "/subscription/reverse-cancelation";
			const body = {
				subscription: stripeSubscriptionID,
			};
			const session = await API.post(apiName, apiEndpoint, { body });
			return session;
		};
		const response = await fetchSession();
		setTimeout(() => {
			getSubscriptionInfo();
			setLoadingSubscriptionData(false);
		}, 10000);
	};
	
	useEffect(() => {
		updateMessage();
	}, [subscriptionCanceling]);

	const downloadUserData = () => {
		const csv = generateDataCSV(
			bragSheets,
			workExperiences,
			jobs,
			companies,
			projects,
			goals,
			educations,
			schools,
			certifications
		);

		const blob = new Blob([csv], { type: "text/plain" });
		const fileName = "cm_data" + ".csv";
		saveAs(blob, fileName);
	};

	const IconWarningSVG = <IconWarning />;

	const handleTabNavigation = (tabname: string) => {
		switch (tabname) {
			case "personalDetails":
				setPersonalDetailsPane(true);
				setAccountPane(false);
				setRemindersPane(false);
				setSubscriptionPane(false);
				setSupportPane(false);
				break;
			case "reminders":
				setPersonalDetailsPane(false);
				setAccountPane(false);
				setRemindersPane(true);
				setSubscriptionPane(false);
				setSupportPane(false);
				break;
			case "account":
				setPersonalDetailsPane(false);
				setAccountPane(true);
				setRemindersPane(false);
				setSubscriptionPane(false);
				setSupportPane(false);
				break;
			case "subscription":
				setPersonalDetailsPane(false);
				setAccountPane(false);
				setRemindersPane(false);
				setSubscriptionPane(true);
				setSupportPane(false);
				break;
			case "support":
				setPersonalDetailsPane(false);
				setAccountPane(false);
				setRemindersPane(false);
				setSubscriptionPane(false);
				setSupportPane(true);
				break;
			default:
				if (process.env.REACT_APP_USER_BRANCH !== "master" || (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")) {
					console.log("make sure to put in the value of the tab");
				}
		}
	};
	

	const Subscription = () => {
		return (
			<div className="pattern--tab-pane">
				<h2 className="type--heading-3 pane-heading">Subscription Status</h2>
				{stripeCustomerID ? (
					<>
						<p className="type--body--large">{billingMessage}</p>
						<Button
							buttonType="utility"
							label={
								!subscriptionCanceling
									? "Manage Subscription"
									: "Reverse Cancelation"
							}
							disabled={!userDataLoaded ? true : false}
							buttonTitle={
								!subscriptionCanceling
									? "Manage your subscription"
									: "Reverse your Cancelation and resume subscription"
							}
							onClick={() =>
								!subscriptionCanceling
									? manageSubscriptions()
									: reverseCancelation()
							}
							analyticsTag={
								subscriptionCanceling
									? "plausible-event-name=Subscription+Cancelation+Reversal"
									: "plausible-event-name=Subscription+Manage"
							}
							leadingIcon={loadingSubscriptionData || launchingSubscriptionManagement && IconDonutSpinnerSVG}
						/>
						{!subscriptionCanceling && (
							<>
								<hr />
								<h2 className="type--heading-3 pane-heading">
									Cancel Subscription
								</h2>
								<p className="type--body--large">
									{subscriptionCancelationExplanation}
								</p>
								<Button
									buttonType="utility"
									label="Cancel Subscription"
									disabled={!userDataLoaded ? true : false}
									buttonTitle="Cancel your subscription"
									leadingIcon={IconWarningSVG}
									theme="destructive"
									onClick={() =>
										setIsCancelSubscriptionConfirmationOpened(true)
									}
								/>
								<Dialog
									isOpened={isCancelSubscriptionConfirmationOpened}
									setIsOpened={setIsCancelSubscriptionConfirmationOpened}
									primaryButtonType="primary"
									primaryButtonTitle="Cancel your subscription"
									primaryButtonLabel="Cancel Subscription"
									primaryButtonFunction={cancelSubscription}
									primaryButtonClosesDialog={true}
									primaryButtonAnalyticsTag="plausible-event-name=Subscription+Cancelation"
									secondaryButtonType="utility"
									secondaryButtonTitle="Keep my subscription"
									secondaryButtonLabel="Nevermind"
								>
									<div className="subscription-cancellation-message">
										<p className="type--body--large">
											Confirm that you want to cancel your Career Minder
											subscription.
										</p>
										<ul className="type--unordered-list">
											<li className="type--body--standard">
												You will be able to continue using Career Minder until
												the end of your current billing cycle.
											</li>
											<li className="type--body--standard">
												You may download your Career Minder Data below.
											</li>
										</ul>
									</div>
									<FileDownload
										fileName="cm_data.csv"
										buttonTitle="Download cm_data.csv"
										downloadFunction={() => downloadUserData()}
									/>
								</Dialog>
							</>
						)}
					</>
				) : (
					<>
						<p className="type--body--large">You do not have a subscription.</p>
						<CheckoutForm />
					</>
				)}
			</div>
		);
	};

	const PersonalDetails = () => {
		return (
			<Form className="pattern--tab-pane">
				<Input
					label="Full Name"
					name="fullName"
					placeholder="What's your full name?"
				/>
				<Input
					label="Preferred Name"
					name="preferredName"
					placeholder="What would you like us to call you?"
				/>
				<Input
					label="Job Title"
					name="jobTitle"
					placeholder="How do you describe what you do?"
					value={jobTitle}
				/>
				<Button
					buttonType="primary"
					disabled={!subscriptionActive || !userDataLoaded ? true : false}
					label="Save"
					type="submit"
				/>
			</Form>
		);
	};

	const Tabs = () => {
		return (
			<div className="pattern--tab-navigation">
				<button
					className={
						isPersonalDetails
							? "pattern--tab-nav-item pattern--tab-nav-item--active"
							: "pattern--tab-nav-item"
					}
					onClick={() => handleTabNavigation("personalDetails")}
				>
					Personal Details
				</button>
				<button
					className={
						isReminders
							? "pattern--tab-nav-item pattern--tab-nav-item--active"
							: "pattern--tab-nav-item"
					}
					onClick={() => handleTabNavigation("reminders")}
				>
					Reminders
				</button>
				<button
					className={
						isAccount
							? "pattern--tab-nav-item pattern--tab-nav-item--active"
							: "pattern--tab-nav-item"
					}
					onClick={() => handleTabNavigation("account")}
				>
					Account
				</button>
				<button
					className={
						isSubscription
							? "pattern--tab-nav-item pattern--tab-nav-item--active"
							: "pattern--tab-nav-item"
					}
					onClick={() => handleTabNavigation("subscription")}
				>
					Subscription
				</button>
				<button
					className={
						isSupport
							? "pattern--tab-nav-item pattern--tab-nav-item--active"
							: "pattern--tab-nav-item"
					}
					onClick={() => handleTabNavigation("support")}
				>
					Contact
				</button>
			</div>
		);
	};

	return (
		<div className="flex-page-outer-wrapper">
			<section className="flex-page-wrapper profile-page-wrapper">
				<h1 className="type--heading-1 pattern--container--flow__heading header--withoutaction">
					Profile
				</h1>
				<div className="main-content-card main-content-card--no-texture tabnav-container">
					<Tabs />
					<Formik
						initialValues={initialValues}
						onSubmit={async (values: Values) => {
							setFullName(values.fullName);
							setJobTitle(values.jobTitle);
							setPreferredName(values.preferredName);
							setReminderDay(values.reminderDay);
							setReminderFrequency(values.reminderFrequency);
							setSubmitProfile(true);
						}}
						validationSchema={profileValidationSchema}
					>
						{() => (
							<Fragment>
								{isPersonalDetails && <PersonalDetails />}
								{isReminders && (
									<div className="pattern--tab-pane">
										<Reminders showHeader={false} />
									</div>
								)}
								{isAccount && (
									<div className="pattern--tab-pane">
										<AccountDetails />
									</div>
								)}
								{isSubscription && <Subscription />}
								{isSupport && <div className="pattern--tab-pane"><Help /></div>}
							</Fragment>
						)}
					</Formik>
				</div>
			</section>
		</div>
	);
};
