import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, SequenceStepper } from "components/core";
import { terms } from "data";
import { DataContainer } from "containers";
import "./termsOfService.css";

const TermsOfService: React.FC = () => {
  const { setTermsAgreementDate, setSubmitProfile } = DataContainer.useContainer();
  const navigate = useNavigate();

  const agreeToTerms = () => {
    const todaysDate = new Date().toUTCString();
    setTermsAgreementDate(todaysDate);
    setSubmitProfile(true);
    navigate("/onboarding-profile", { replace: true });
    return;
  };

  return (
    <div className="body-wrapper">
      <section className="pattern--container--flow pattern--container--flow-narrow">
        <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
          <h1 className="type--heading-1">Terms of Service</h1>
          <div className="accessory--resizeable">
            <SequenceStepper length={6} currentIndex={0} />
          </div>
        </div>
        <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
          <div className="readonly-panel" dangerouslySetInnerHTML={{ __html: terms }}></div>
          <Button
            label="Agree to Terms of Service"
            buttonTitle="Agree to the above Terms of Service"
            buttonType="secondary"
            onClick={() => agreeToTerms()}
          />
        </div>
      </section>
    </div>
  );
};

export default TermsOfService;
