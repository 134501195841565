import React from "react";
import "./sequenceStepper.css";

export interface SequenceStepperProps  {
	length: number;
	currentIndex: number;
}

export const SequenceStepper: React.FC<SequenceStepperProps> = (props) => {
	const {
		length,
		currentIndex,
	} = props;
	const steps = Array.from(Array(length).keys());
	
	return (
		<div className="sequence-stepper-wrapper">
			{steps.map((step, index: number) => {
				return (
					<div className={`sequence-step ${index === currentIndex && "sequence-step--active"}`} key={`step${index}`}>
						<p className="type--body--large">{index + 1}</p>
					</div>
				)
			})}
		</div>
	);
};