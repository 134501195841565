import { API } from 'aws-amplify';
// import { DataContainer } from '../containers';

/**
 * @param input {string} the information the user input, in a support request or error this will be the subject
 * @param message {unknown} the message the user wants to send, in a support request this will be the email body, in the case of an error report the error object
 * @param type {string} one of: supportRequest - send an error message, testReminder - sends a test reminder
 * @returns 
 */

export const sendEmail = async (
        input: string,
        message: unknown,
        preferredName: string,
        type: string,
        userEmail: string
    ) => {
    // const { preferredName, userEmail } = DataContainer.useContainer();
    const apiName = "careerminder";
    const apiEndpoint = `/sendemail/${type.toLowerCase()}`;
    const body = {
        emailAddress: userEmail,
        message,
        input,
        preferredName,
    };
    const result = await API.post(apiName, apiEndpoint, { body });
    return await result;
};
