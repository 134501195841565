import React, { useState, useEffect } from "react";
import { Button } from "components/core";
import { ReactComponent as IconCheck } from "../../../core/assets/img/check.svg";
import { ReactComponent as IconClose } from "../../../core/assets/img/close.svg";
import { ReactComponent as IconEdit } from "../../../core/assets/img/edit.svg";
import { ReactComponent as IconTrash } from "../../../core/assets/img/trash.svg";
import { IQuestion } from "types";
import "./reviewQuestion.css";

export interface ReviewQuestionProps {
  handleQuestionChange: (questions: IQuestion, index: number) => void;
  handleQuestionAdd: (question: IQuestion) => void;
  handleQuestionDelete: (index: number) => void;
  question: IQuestion;
  index: number;
  newQuestion: boolean;
}

export const ReviewQuestion: React.FC<ReviewQuestionProps> = ({
  handleQuestionChange,
  handleQuestionAdd,
  handleQuestionDelete,
  question,
  newQuestion,
  index,
}) => {
  const [editing, setEditing] = useState<boolean>(newQuestion);
  const [updatedQuestion, setUpdatedQuestion] = useState<string>(question.question);
  const [updatedReason, setUpdatedReason] = useState<string>(question.reason);

  const saveEdit = () => {
    const updatedQuestionObject: IQuestion = { question: updatedQuestion, reason: updatedReason };
    if (newQuestion) {
      handleQuestionAdd(updatedQuestionObject);
    } else {
      handleQuestionChange(updatedQuestionObject, index);
    }
    setEditing(false);
  };

  return (
    <div className="interview-question" key={index}>
      <div className="interview-question__inner-wrapper">
        <div className="interview-question__content">
          <div className="interview-question__content__group">
            <h3 className="type--heading-6">What you could ask</h3>
            {!editing ? (
              <p className="type--body--large">{question.question}</p>
            ) : (
              <textarea
                value={updatedQuestion}
                className="pattern--input-field"
                rows={4}
                onChange={(e) => setUpdatedQuestion(e.target.value)}
              />
            )}
          </div>
          <div className="interview-question__content__group interview-reason">
            <h3 className="type--heading-6">Why?</h3>
            {!editing ? (
              <p className="type--body--standard">{question.reason}</p>
            ) : (
              <textarea
                value={updatedReason}
                className="pattern--input-field"
                rows={4}
                onChange={(e) => setUpdatedReason(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className={`interview-meta ${editing && "interview-meta--editing"}`}>
          {!editing ? (
            <Button
              label="Edit Question"
              buttonTitle="Edit this Question"
              buttonType="icon-only"
              size="small"
              leadingIcon={<IconEdit />}
              onClick={() => setEditing(true)}
            />
          ) : (
            <>
              <Button
                label="Delete Question"
                buttonTitle="Delete this Question"
                buttonType="icon-only"
                size="small"
                leadingIcon={<IconTrash />}
                onClick={() => handleQuestionDelete(index)}
              />
              <div className="question-save-cancel">
                <Button
                  label="Cancel Edits"
                  buttonTitle="Cancel edits to this Question"
                  buttonType="icon-only"
                  size="small"
                  leadingIcon={<IconClose />}
                  onClick={() => {
                    setUpdatedQuestion(question.question);
                    setUpdatedReason(question.reason);
                    setEditing(false);
                  }}
                />
                <Button
                  label="Save Edits"
                  buttonTitle="Save edits to this Question"
                  buttonType="icon-only"
                  size="small"
                  leadingIcon={<IconCheck />}
                  onClick={saveEdit}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
