import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Formik, Form, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { CredentialsContainer } from "containers";
import { confirmationCodeValidationSchema } from "utils/validations";
import { Button, ErrorBanner, Input } from "components/core";
import "./authenticator.css";

interface Values {
	confirmationNumber: string;
}

export const Confirm: React.FC = () => {
	const navigate = useNavigate();
	const { email } = CredentialsContainer.useContainer();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	const [isErroring, setIsErroring] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const initialValues = {
		confirmationNumber: "",
	};

	const resendConfirmationCode = async () => {
		try {
			// await Auth.resendSignUp(email);
			setModalIsOpen(true);
			setIsErroring(false);
		} catch (error) {
			let message = "Unknown error";
			if (error instanceof Error) message = error.message;
			setErrorMessage(message);
			setIsErroring(true);
			console.log("error resending code: ", error);
		}
	};

	return (
		<div className="body-wrapper">
			<section className="pattern--container--flow pattern--container--flow-narrow">
				<h1 className="type--heading-1 pattern--container--flow__heading">
					Sign Up
				</h1>
				<div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
					<Formik
						initialValues={initialValues}
						onSubmit={async (
							values: Values,
							{ setSubmitting }: FormikHelpers<Values>
						) => {
							try {
								await Auth.confirmSignUp(email, values.confirmationNumber);
								setSubmitting(false);
								navigate("/login", { replace: true });
							} catch (error) {
								console.log("error signing in", error);
							}
						}}
						validationSchema={confirmationCodeValidationSchema}
					>
						<Form className="pattern--login-signup-form">
							<Modal
								isOpen={modalIsOpen}
								onRequestClose={() => setModalIsOpen(false)}
								contentLabel="Confirmation Code"
							>
								Confirmation code sent.
							</Modal>
							<Input label="Confirmation Code:" name="confirmationNumber" />
							{isErroring && <ErrorBanner message={errorMessage} />}
							<Button label="Confirm" type="submit" />
							<div className="pattern--login-signup-form__supplements">
								<button
									className="resendButton type--body--standard"
									onClick={resendConfirmationCode}
								>
									Resend confirmation code
								</button>
							</div>
						</Form>
					</Formik>
				</div>
			</section>
		</div>
	);
};
