import React from "react";
import { ReactComponent as SentimentometerBackground } from "../assets/img/sentimentometer.svg";
import { ReactComponent as SentimentometerPointer } from "../assets/img/sentimentometer-pointer.svg";
import "./sentimentometer.css";

export interface SentimentometerProps {
  rotation: number;
}

export const Sentimentometer: React.FC<SentimentometerProps> = (props) => {
  const { rotation } = props;
  const steps = Array.from(Array(length).keys());

  return (
    <div className="sentimentometer-wrapper">
      <div className="sentimentometer">
        <SentimentometerBackground className="sentimentometer__background" />
        <SentimentometerPointer
          style={{ transform: `rotate(${rotation}deg)` }}
          className="sentimentometer__pointer"
        />
      </div>
    </div>
  );
};
