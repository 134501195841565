import React from "react";
import { Link } from "react-router-dom";
import "./authenticator.css";

export const PasswordReset: React.FC = () => {
	return (
		<div className="body-wrapper">
			<section className="pattern--container--flow pattern--container--flow-narrow">
				<h1 className="type--heading-1 pattern--container--flow__heading">Password Reset</h1>
				<div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
					Your password has been reset. Please log in.
					<Link to="/login">Login</Link>
				</div>
			</section>
		</div>
	);
};
