import React from "react";
import "./radio.css";

export interface RadioProps {
	label: string;
	value: string;
	name: string;
	checked?: boolean;
	onClick: () => void;
}

export const Radio: React.FC<RadioProps> = (props) => {
	
	return (
		<label className="radio-wrapper">
			<input 
				type="radio" 
				name={props.name} 
				value={props.value} 
				defaultChecked={props.checked} 
				onClick={props.onClick} 
			/>
			<span className="radio-label type--body--large">{props.label}</span>
		</label>	
	);
};