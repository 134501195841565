import React from "react";
import { ReactComponent as Spinner } from "../assets/img/spinner.svg";
import "./loader.css";

export interface LoaderProps {
	isLoading: boolean;
}

export const Loader: React.FC<LoaderProps> = (props) => {
	return (
		<div className={`loader ${props.isLoading && "loader--loading"}`}>
			<Spinner />
		</div>
	);
};