import React from "react";
import { CheckoutForm } from "./CheckoutForm";
import { SequenceStepper } from "components/core";
import "./payment.css";

interface PaymentProps {
  name?: string;
}

export const Payment: React.FC<PaymentProps> = () => {
  return (
    <section className="pattern--container--flow pattern--container--flow-narrow">
      <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
        <h1 className="type--heading-1">Payment</h1>
        <div className="accessory--resizeable">
          <SequenceStepper length={6} currentIndex={2} />
        </div>
      </div>
      <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
        <CheckoutForm />
      </div>
    </section>
  );
};
