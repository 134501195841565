import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CredentialsContainer } from "containers/CredentialsContainer";

interface PrivateRouteProps {
	children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
	const { children } = props;
	const {
		checkAuthenticated,
		checkUser,
		isAuthenticated,
	} = CredentialsContainer.useContainer();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	

	if (!isAuthenticated) {
		checkAuthenticated().then((res) => {
			if (!res.authenticated) {
				if (pathname !== "") {
					navigate("/login#" + pathname);
				} else {
					navigate("/login");
				}
			} else {
				checkUser();
			}
		});
	}
	return children;
};

export default PrivateRoute;
