import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Formik, Form } from "formik";
import { CredentialsContainer } from "containers";
import { useNavigate, Link } from "react-router-dom";
import { Button, ErrorBanner, Input } from "components/core";
import { signUpValidationSchema } from "utils/validations";
import { signUpEmailExplanation } from "data";
import "./authenticator.css";

interface Values {
	emailAddress: string;
	password: string;
	confirmPassword: string;
}

export const Signup: React.FC = () => {
	const navigate = useNavigate();
	const { setEmail } = CredentialsContainer.useContainer();

	const initialValues = {
		emailAddress: "",
		password: "",
		confirmPassword: "",
	};

	const [isErroring, setIsErroring] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const signUp = async (email: string, password: string) => {
		try {
			setIsErroring(false);
			await Auth.signUp(email, password);
			navigate("/confirm", { replace: true });
		} catch (error) {
			let message = "Unknown error";
			if (error instanceof Error) message = error.message;
			setErrorMessage(message);
			setIsErroring(true);
		}
	};

	return (
		<div className="body-wrapper">
			<section className="pattern--container--flow pattern--container--flow-narrow">
				<h1 className="type--heading-1 pattern--container--flow__heading">
					Sign Up
				</h1>
				<div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body onboarding-flow">
					<div className="note-panel">
						<p className="type--body--standard">{signUpEmailExplanation}</p>
					</div>
					<Formik
						initialValues={initialValues}
						isValid
						onSubmit={async (values: Values) => {
							try {
								setEmail(values.emailAddress);
								signUp(values.emailAddress, values.password);
							} catch (error) {
								console.log("error signing in", error);
							}
						}}
						validationSchema={signUpValidationSchema}
					>
						{({ dirty, isValid }) => (
							<Form className="pattern--login-signup-form">
								<Input label="Email Address" name="emailAddress" type="email" />
								<Input label="Password" name="password" type="password" />
								<Input
									label="Confirm Password"
									name="confirmPassword"
									type="password"
								/>
								{isErroring && <ErrorBanner message={errorMessage} />}
								<Button
									disabled={!(isValid && dirty)}
									label="Sign Up"
									type="submit"
								/>
								<div className="pattern--login-signup-form__supplements">
									<p className="type--body--standard">
										Forgot password?{" "}
										<Link to="/passwordreset">Reset Password</Link>
									</p>
									<p className="type--body--standard">
										Have an account already? <Link to="/login">Login</Link>
									</p>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</section>
		</div>
	);
};
