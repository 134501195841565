import React from "react";
import {
  CategoryScale,
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ReactComponent as SentimentNegative } from "../../../core/assets/img/sentiment-negative.svg";
import { ReactComponent as SentimentNeutral } from "../../../core/assets/img/sentiment-neutral.svg";
import { ReactComponent as SentimentPositive } from "../../../core/assets/img/sentiment-positive.svg";
import { ISentiment } from "types";
import { formatDate, isSameDate } from "utils";
import "./sentimentTimelineChart.css";

interface DateWithValue {
  date: string;
  value: number;
}

export interface SentimentTimelineChartProps {
  applicableSentiments: ISentiment[];
  startDate: Date;
  endDate: Date;
}

/**
 * Primary UI component for user interaction
 */
export const SentimentTimelineChart: React.FC<SentimentTimelineChartProps> = ({
  applicableSentiments,
  startDate,
  endDate,
}) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
  );

  const getDatesBetween = (start: Date, end: Date): Date[] => {
    const dates: Date[] = [];
    const current = new Date(start);

    while (current <= end) {
      dates.push(new Date(current));
      current.setDate(current.getDate() + 1); // Move to the next day
    }

    return dates;
  };

  const daysArray = getDatesBetween(startDate, endDate);

  const labelsForTrendGraph: string[] = [];
  const dataForTrendGraph: any[] = [];

  daysArray.map((date: Date) => {
    const sentimentsForDay = applicableSentiments.filter(
      (sentiment: ISentiment) =>
        isSameDate(date, new Date(sentiment.dateCreated)),
    );

    labelsForTrendGraph.push(formatDate(date.toUTCString()));

    if (sentimentsForDay.length > 0) {
      if (sentimentsForDay.length > 1) {
        const totalFeeling = sentimentsForDay.reduce(
          (sum, sentiment) => sum + sentiment.feeling,
          0,
        );
        const averageFeeling = totalFeeling / sentimentsForDay.length;
        dataForTrendGraph.push(averageFeeling);
      } else {
        dataForTrendGraph.push(sentimentsForDay[0].feeling);
      }
    } else {
      dataForTrendGraph.push(null);
    }
  });

  const buildCombinedGraphData = (): DateWithValue[] => {
    const result: DateWithValue[] = [];

    labelsForTrendGraph.map((label: string, index: number) => {
      if (dataForTrendGraph[index] !== null) {
        const dateWithValue: DateWithValue = {
          date: label,
          value: dataForTrendGraph[index],
        };
        result.push(dateWithValue);
      }
    });

    return result;
  };

  const filterToEvenlySpacedDatesWithAverage = (
    datesWithValues: DateWithValue[],
  ): DateWithValue[] => {
    const result: DateWithValue[] = [];
    const totalDates = datesWithValues.length;
    const step = Math.max(1, Math.floor(totalDates / 25)); // Adjust step size to have approximately 100 dates

    for (let i = 0; i < totalDates; i += step) {
      let sum = datesWithValues[i].value;
      const endIdx = Math.min(i + step - 1, totalDates - 1);
      for (let j = i + 1; j <= endIdx; j++) {
        sum += datesWithValues[j].value;
      }
      const average = sum / (endIdx - i + 1);
      result.push({ date: datesWithValues[i].date, value: average });
    }

    return result;
  };

  const extractDataFromCombined = (datesWithValues: DateWithValue[]): any[] => {
    const values: any[] = [];

    datesWithValues.map((dateWithValue: DateWithValue) => {
      values.push(dateWithValue.value);
    });

    return values;
  };

  const extractLabelsFromCombined = (
    datesWithValues: DateWithValue[],
  ): any[] => {
    const values: any[] = [];

    datesWithValues.map((dateWithValue: DateWithValue) => {
      values.push(dateWithValue.date);
    });

    return values;
  };

  const combinedGraphData: DateWithValue[] = buildCombinedGraphData();

  const reducedCombinedDataForTrendGraph: DateWithValue[] =
    filterToEvenlySpacedDatesWithAverage(combinedGraphData);
  const reducedDataForTrendGraph = extractDataFromCombined(
    reducedCombinedDataForTrendGraph,
  );
  const reducedLabelsForTrendGraph = extractLabelsFromCombined(
    reducedCombinedDataForTrendGraph,
  );

  const lineData = {
    labels:
      applicableSentiments.length < 26
        ? labelsForTrendGraph
        : reducedLabelsForTrendGraph,
    datasets: [
      {
        label: applicableSentiments.length < 26 ? "Feeling" : "Average Feeling",
        data:
          applicableSentiments.length < 26
            ? dataForTrendGraph
            : reducedDataForTrendGraph,
        fill: false,
        borderColor: "#B5D7E3",
        pointBorderColor: "#479CB8",
        pointBorderWidth: 4,
        backgroundColor: "#479CB8",
        borderWidth: 2,
        spanGaps: true,
        clip: 10,
        options: {
          animation: false,
        },
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const lineOptions = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maintainAspectRatio: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scales: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      y: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        min: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        max: 100,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        grid: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: "#F0F0F0",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ticks: {
          // forces step size to be 50 units
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stepSize: 50,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: "rgba(0,0,0,0)",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        border: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          display: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        grid: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: "F0F0F0",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          display: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        border: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          display: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ticks: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          font: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            family: "'Fira Sans', sans-serif" as const,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            size: 10 as const,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            weight: 600 as const,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: "#666666",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          maxRotation: 0,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          padding: 16,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    layout: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      padding: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        top: 20,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        right: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bottom: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        left: 20,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plugins: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tooltip: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        titleFont: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          family: "'Fira Sans', sans-serif" as const,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          size: 10 as const,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          weight: 300 as const,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bodyFont: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          family: "'Fira Sans', sans-serif" as const,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          size: 10 as const,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          weight: 600 as const,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        displayColors: false as const,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } as const,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  };

  return (
    <div className="line-chart-wrapper">
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore */}
      <Line data={lineData} options={lineOptions} />
      <div className="trends-sentiment-positioning-wrapper trends-sentiment-positioning-wrapper--positive">
        <SentimentPositive />
      </div>
      <div className="trends-sentiment-positioning-wrapper trends-sentiment-positioning-wrapper--neutral">
        <SentimentNeutral />
      </div>
      <div className="trends-sentiment-positioning-wrapper trends-sentiment-positioning-wrapper--negative">
        <SentimentNegative />
      </div>
    </div>
  );
};
