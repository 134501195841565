import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Confirm, Forgot, ForgotConfirmation, Login, PasswordReset, Signup } from "components/Authenticator";
import { ReactComponent as CareerMinderInitials } from "components/core/assets/img/career-minder-initials.svg";
import Dashboard from "components/Dashboard";
import Experience from "components/Experience";
import BragSheets from "components/BragSheets";
import { Reviews } from "components/Reviews";
import { CatchUpAuthoring } from "components/CatchUpAuthoring";
import { CatchUps } from "components/CatchUps";
import { CatchUpDetail } from "components/CatchUpDetail";
import { ReviewAuthoring } from "components/ReviewAuthoring";
import { ReviewDetail } from "components/ReviewDetail";
import Goals from "components/Goals";
import BragSheetAuthoring from "components/BragSheetAuthoring";
import TermsOfService from "components/TermsOfService";
import PersonalDetails from "components/PersonalDetails";
import NotFound from "components/NotFound";
import FullPageLoading from "components/FullPageLoading";
import LogoutButton from "components/LogoutButton";
import { ResumeUpload } from "components/ResumeUpload";
import { ResumeReview } from "components/ResumeUpload/ResumeReview";
import { OnboardingGoals } from "components/OnboardingGoals";
import { OnboardingCatchup } from "components/OnboardingCatchup";
import { OnboardingCatchupReview } from "components/OnboardingCatchup/OnboardingCatchupReview";
import { Button, Menu, MenuItem, Toast, Loader, SubscriptionPausedModal } from "components/core/";
import { ReactComponent as IconClose } from "components/core/assets/img/close.svg";
import { ReactComponent as IconMenu } from "components/core/assets/img/menu.svg";
import { ReactComponent as IconWarning } from "components/core/assets/img/warning.svg";
import PrivateRoute from "components/PrivateRoute";
import { Profile } from "components/Profile";
import { Payment } from "components/Subscription";
import { Success } from "components/Subscription";
import useLocalStorage from "use-local-storage";
import { Auth } from "aws-amplify";
import { CredentialsContainer } from "containers/CredentialsContainer";
import { DataContainer } from "containers/DataContainer";
import { getFutureDate, getDaysFromNow } from "utils";
import { getSubscriptionStatusString } from "data";

export const AppRoutes: React.FC = () => {
  //assigning location variable
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, setUser } = CredentialsContainer.useContainer();
  const {
    subscriptionActive,
    subscriptionStatus,
    userDataLoaded,
    userEmail,
    stripePercentDiscount,
    stripeDiscountEndDateUnix,
    termsAgreementDate,
  } = DataContainer.useContainer();

  // let locationHash = "";
  //
  // const waitFor = (conditionFunction:any) => {
  //
  // 	const poll = (resolve:any) => {
  // 	if(conditionFunction()) resolve();
  // 		else setTimeout(() => poll(resolve), 100);
  // 	}
  //
  // 	return new Promise(poll);
  // }
  //
  // waitFor(() => userDataLoaded === true).then(() => {
  // 	if (location.hash !== "") {
  // 		locationHash = location.hash;
  // 	}
  // });

  const IconWarningSVG = <IconWarning />;

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const CareerMinderInitialsSVG = <CareerMinderInitials />;

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme] = useLocalStorage("theme", defaultDark ? "dark" : "light");

  const IconCloseSVG = <IconClose />;

  const [displayMobileNav, setDisplayMobileNav] = useState<boolean>(false);
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState<boolean>(false);

  // TODO: Trying to automatically dismiss the mobile menu whenever location changes

  useEffect(() => {
    setDisplayMobileNav(false);
  }, [location]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      if (
        process.env.REACT_APP_USER_BRANCH !== "master" ||
        (process.env.REACT_APP_USER_BRANCH === "master" && userEmail === "releasetest@careerminder.io")
      ) {
        console.log("error signing out", error);
      }
    }
  };

  const buildStatusString = (): string => {
    let message = "";
    const trialExpirationDate = getFutureDate(termsAgreementDate, 14, true);
    const trialEndsDaysFromNow = Math.round(getDaysFromNow(trialExpirationDate));
    switch (subscriptionStatus) {
      case "active":
        message = getSubscriptionStatusString("short", "active");
        break;
      case "past_due":
        message = getSubscriptionStatusString("short", "past_due");
        break;
      case "unpaid":
        message = getSubscriptionStatusString("short", "unpaid");
        break;
      case "canceled":
        message = getSubscriptionStatusString("short", "canceled");
        break;
      case "incomplete":
        message = getSubscriptionStatusString("short", "incomplete");
        break;
      case "incomplete_expired":
        message = getSubscriptionStatusString("short", "incomplete_expired");
        break;
      case "trialing":
        message = getSubscriptionStatusString(
          "short",
          "trialing",
          undefined,
          undefined,
          undefined,
          trialEndsDaysFromNow,
        );
        break;
      case "paused":
        message = getSubscriptionStatusString("short", "paused");
        break;
      default:
        message = "Your subscription is unknown.";
        break;
    }
    return message;
  };

  return (
    <div
      className={`App ${
        (isAuthenticated &&
          !subscriptionActive &&
          userDataLoaded &&
          splitLocation[1] !== "payment" &&
          splitLocation[1] !== "terms-of-service" &&
          splitLocation[1] !== "onboarding-profile" &&
          splitLocation[1] !== "forgotconfirmation" &&
          splitLocation[1] !== "passwordreset" &&
          splitLocation[1] !== "forgot") ||
        (isAuthenticated &&
          userDataLoaded &&
          subscriptionActive === true &&
          subscriptionStatus === "trialing" &&
          stripePercentDiscount !== 100 &&
          stripeDiscountEndDateUnix !== undefined &&
          "status-toast--activated")
      }`}
      data-theme={theme}
    >
      <header
        className={`App-header ${
          splitLocation[1] === "signup" ||
          splitLocation[1] === "login" ||
          splitLocation[1] === "forgot" ||
          splitLocation[1] === "forgotconfirmation" ||
          splitLocation[1] === "passwordreset" ||
          splitLocation[1] === "confirm" ||
          splitLocation[1] === "payment" ||
          splitLocation[1] === "terms-of-service" ||
          splitLocation[1] === "onboarding-profile" ||
          splitLocation[1] === "onboarding"
            ? "App-header--login"
            : ""
        }`}
      >
        <div className="primary-nav-wrapper">
          <nav className="primary-nav">
            <div className={`logo-nav ${splitLocation[1] === "" ? "active" : ""}`}>
              <Link to="/">{CareerMinderInitialsSVG}</Link>
            </div>
            {splitLocation[1] !== "login" &&
            splitLocation[1] !== "signup" &&
            splitLocation[1] !== "payment" &&
            splitLocation[1] !== "confirm" &&
            splitLocation[1] !== "terms-of-service" &&
            splitLocation[1] !== "onboarding-profile" &&
            splitLocation[1] !== "forgotconfirmation" &&
            splitLocation[1] !== "passwordreset" &&
            splitLocation[1] !== "forgot" &&
            splitLocation[1] !== "onboarding" ? (
              <ul>
                <li className={splitLocation[1] === "" ? "active" : ""}>
                  <Link to="/">Dashboard</Link>
                </li>
                <li className={splitLocation[1] === "experience" ? "active" : ""}>
                  <Link to="/experience">Experience</Link>
                </li>
                <li className={splitLocation[1] === "bragsheets" ? "active" : ""}>
                  <Link to="/bragsheets">Brag Sheets</Link>
                </li>
                <li className={splitLocation[1] === "catchups" ? "active" : ""}>
                  <Link to="/catchups">Catch Ups</Link>
                </li>
                <li className={splitLocation[1] === "reviews" ? "active" : ""}>
                  <Link to="/reviews">Reviews</Link>
                </li>
                <li className={splitLocation[1] === "goals" ? "active" : ""}>
                  <Link to="/goals">Goals</Link>
                </li>
                {process.env.REACT_APP_USER_BRANCH !== "master" && (
                  <li className={splitLocation[1] === "onboarding" ? "active" : ""}>
                    <Link to="/onboarding/goals">Onboarding</Link>
                  </li>
                )}
              </ul>
            ) : (
              process.env.REACT_APP_USER_BRANCH !== "master" && (
                <ul>
                  <li>
                    <Link to="/">Dashboard</Link>
                  </li>
                </ul>
              )
            )}
          </nav>
          {splitLocation[1] !== "login" &&
            splitLocation[1] !== "signup" &&
            splitLocation[1] !== "payment" &&
            splitLocation[1] !== "confirm" &&
            splitLocation[1] !== "terms-of-service" &&
            splitLocation[1] !== "onboarding-profile" &&
            splitLocation[1] !== "forgotconfirmation" &&
            splitLocation[1] !== "passwordreset" &&
            splitLocation[1] !== "forgot" &&
            splitLocation[1] !== "onboarding" && (
              <>
                <nav className="profile-menu">
                  <div className="pattern--menu-container">
                    <button
                      type="button"
                      className="menudropdown__title"
                      aria-expanded="false"
                      aria-controls="myaccount-dropdown"
                      onClick={() => setAccountMenuIsOpen(!accountMenuIsOpen)}
                    >
                      My Account
                    </button>
                    <Menu isOpened={accountMenuIsOpen} position="right" theme="dark">
                      <MenuItem
                        type="action"
                        label="Profile"
                        title="Visit Profile"
                        theme="dark"
                        primaryAction={() => {
                          navigate("/profile");
                          setAccountMenuIsOpen(!accountMenuIsOpen);
                        }}
                      />
                      <MenuItem
                        type="action"
                        label="Sign Out"
                        title="Sign Out of Career Minder"
                        theme="dark"
                        primaryAction={() => {
                          signOut();
                          setAccountMenuIsOpen(!accountMenuIsOpen);
                        }}
                      />
                    </Menu>
                  </div>
                </nav>
                <button className="footer-nav-toggle" onClick={() => setDisplayMobileNav(true)}>
                  <span className="type--body--standard footer-nav-toggle__label">
                    <strong>Menu</strong>
                  </span>
                  <IconMenu />
                </button>
              </>
            )}
        </div>
      </header>
      <Routes>
        <Route
          path="*"
          element={<PrivateRoute>{userDataLoaded ? <NotFound /> : <FullPageLoading />}</PrivateRoute>}
        />
        <Route path="/about" element={<About />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/forgotconfirmation" element={<ForgotConfirmation />} />
        <Route path="/login" element={<Login />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/terms-of-service"
          element={
            <PrivateRoute>
              <TermsOfService />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding-profile"
          element={
            <PrivateRoute>
              <PersonalDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/cancel"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/success"
          element={
            <PrivateRoute>
              <Success />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/resume"
          element={
            <PrivateRoute>
              <ResumeUpload />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/goals"
          element={
            <PrivateRoute>
              <OnboardingGoals />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/resume"
          element={
            <PrivateRoute>
              <ResumeUpload />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/resume/review"
          element={
            <PrivateRoute>
              <ResumeReview />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/catchup"
          element={
            <PrivateRoute>
              <OnboardingCatchup />
            </PrivateRoute>
          }
        />
        <Route
          path="/onboarding/catchup/:uniqueId"
          element={
            <PrivateRoute>
              <OnboardingCatchupReview catchUpId={`catchup${location.hash}`} />
            </PrivateRoute>
          }
        />
        <Route
          path="/catchups"
          element={
            <PrivateRoute>
              <CatchUps />
            </PrivateRoute>
          }
        />
        <Route
          path="/catchups/new"
          element={
            <PrivateRoute>
              <CatchUpAuthoring />
            </PrivateRoute>
          }
        />
        {userDataLoaded && (
          <Route
            path="/catchups/:uniqueId"
            element={
              <PrivateRoute>
                <CatchUpDetail catchUpId={`catchup${location.hash}`} />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/experience"
          element={
            <PrivateRoute>
              <Experience />
            </PrivateRoute>
          }
        />
        <Route
          path="/experience/new"
          element={
            <PrivateRoute>
              <Experience />
            </PrivateRoute>
          }
        />
        <Route
          path="/bragsheets"
          element={
            <PrivateRoute>
              <BragSheets />
            </PrivateRoute>
          }
        />
        {userDataLoaded && (
          <Route
            path="/bragsheets/:uniqueId"
            element={
              <PrivateRoute>
                <BragSheetAuthoring bragSheetId={`bragsheet${location.hash}`} />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/bragsheets/new"
          element={
            <PrivateRoute>
              <BragSheetAuthoring />
            </PrivateRoute>
          }
        />
        <Route
          path="/reviews"
          element={
            <PrivateRoute>
              <Reviews />
            </PrivateRoute>
          }
        />
        <Route
          path="/reviews/new"
          element={
            <PrivateRoute>
              <ReviewAuthoring />
            </PrivateRoute>
          }
        />
        {userDataLoaded && (
          <Route
            path="/reviews/:uniqueId"
            element={
              <PrivateRoute>
                <ReviewDetail reviewId={`review${location.hash}`} />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/goals"
          element={
            <PrivateRoute>
              <Goals />
            </PrivateRoute>
          }
        />
        <Route
          path="/goals/new"
          element={
            <PrivateRoute>
              <Goals />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/*"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>
      {splitLocation[1] !== "login" &&
        splitLocation[1] !== "signup" &&
        splitLocation[1] !== "payment" &&
        splitLocation[1] !== "confirm" &&
        splitLocation[1] !== "terms-of-service" &&
        splitLocation[1] !== "onboarding-profile" &&
        splitLocation[1] !== "forgotconfirmation" &&
        splitLocation[1] !== "passwordreset" &&
        splitLocation[1] !== "forgot" &&
        splitLocation[1] !== "onboarding" && (
          <footer className={`footer-nav-container ${displayMobileNav && "footer-nav-container--visible"}`}>
            <nav className="footer-nav">
              <Button
                buttonType="icon-only"
                size="small"
                label="Close Menu"
                buttonTitle="Close Navigation Menu"
                leadingIcon={IconCloseSVG}
                onClick={() => setDisplayMobileNav(false)}
              />
              <ul className="footer-nav__menu mobile-nav">
                <li>
                  <Link
                    to="/"
                    className={`type--heading-3 mobile-nav-link ${splitLocation[1] === "" ? "active" : ""}`}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/experience"
                    className={`type--heading-3 mobile-nav-link ${
                      splitLocation[1] === "experience" ? "active" : ""
                    }`}
                  >
                    Experience
                  </Link>
                </li>
                <li>
                  <Link
                    to="/bragsheets"
                    className={`type--heading-3 mobile-nav-link ${
                      splitLocation[1] === "bragsheets" ? "active" : ""
                    }`}
                  >
                    Brag Sheets
                  </Link>
                </li>
                <li>
                  <Link
                    to="/catchups"
                    className={`type--heading-3 mobile-nav-link ${
                      splitLocation[1] === "catchups" ? "active" : ""
                    }`}
                  >
                    Catch Ups
                  </Link>
                </li>
                <li>
                  <Link
                    to="/reviews"
                    className={`type--heading-3 mobile-nav-link ${
                      splitLocation[1] === "reviews" ? "active" : ""
                    }`}
                  >
                    Reviews
                  </Link>
                </li>
                <li>
                  <Link
                    to="/goals"
                    className={`type--heading-3 mobile-nav-link ${
                      splitLocation[1] === "goals" ? "active" : ""
                    }`}
                  >
                    Goals
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profile"
                    className={`type--heading-3 mobile-nav-link mobile-nav-link--secondary ${
                      splitLocation[1] === "profile" ? "active" : ""
                    }`}
                  >
                    Profile
                  </Link>
                </li>
                <li>
                  <LogoutButton />
                </li>
              </ul>
            </nav>
          </footer>
        )}
      {isAuthenticated &&
        !subscriptionActive &&
        userDataLoaded &&
        splitLocation[1] !== "payment" &&
        splitLocation[1] !== "terms-of-service" &&
        splitLocation[1] !== "onboarding-profile" &&
        splitLocation[1] !== "forgotconfirmation" &&
        splitLocation[1] !== "passwordreset" &&
        splitLocation[1] !== "forgot" &&
        splitLocation[1] !== "onboarding" && (
          <Toast
            isOpen={!subscriptionActive}
            message={buildStatusString()}
            lightTheme={true}
            icon={IconWarningSVG}
            buttonAction={() => navigate("/profile/subscription")}
            buttonLabel="See Details"
          />
        )}
      {isAuthenticated &&
        userDataLoaded &&
        subscriptionActive === true &&
        subscriptionStatus === "trialing" &&
        stripePercentDiscount !== 100 &&
        stripeDiscountEndDateUnix !== undefined && (
          <Toast
            isOpen={true}
            message={buildStatusString()}
            lightTheme={true}
            buttonAction={() => navigate("/profile/subscription")}
            buttonLabel="See Details"
          />
        )}
      {isAuthenticated && <Loader isLoading={!userDataLoaded} />}
      {isAuthenticated && userDataLoaded && subscriptionStatus === "paused" && <SubscriptionPausedModal />}
    </div>
  );
};

const About = () => {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
};

const Users = () => {
  return (
    <div>
      <h2>User</h2>
    </div>
  );
};

export default AppRoutes;
