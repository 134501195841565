import React from "react";
import { Button } from "components/core";
import { ReactComponent as IconDocument } from "../assets/img/document.svg";
import { ReactComponent as IconDownload } from "../assets/img/download.svg";
import "./fileDownload.css";

export interface FileDownloadProps {
	fileName: string;
	downloadFunction: () => void;
	buttonLabel?: string;
	buttonTitle?: string;
	analyticsTag?: string;
	fillContainer?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const FileDownload: React.FC<FileDownloadProps> = ({
	fileName,
	buttonLabel = "Download",
	buttonTitle = "Download file",
	downloadFunction,
	analyticsTag,
	fillContainer = true,
}) => {
	const IconDownloadSVG = <IconDownload />;
	return (
		<div className={`file-download  ${!fillContainer && "file-download--contained"}`}>
			<div className="file-download__file">
				<IconDocument />
				<p className="type--body--standard">{fileName}</p>
			</div>
			<Button
				label={buttonLabel}
				buttonTitle={buttonTitle}
				buttonType="utility"
				size="small"
				onClick={downloadFunction}
				trailingIcon={IconDownloadSVG}
				analyticsTag={analyticsTag && analyticsTag}
			/>
		</div>
	);
};