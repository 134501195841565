import React, { useState, useEffect } from "react";
import { Sentimentometer } from "components/core";
import { BulkSentimentAssignment } from "./BulkSentimentAssignment";
import { ReactComponent as SentimentNegativeSmall } from "components/core/assets/img/sentiment-negative-small.svg";
import { ReactComponent as SentimentNeutralSmall } from "components/core/assets/img/sentiment-neutral-small.svg";
import { ReactComponent as SentimentPositiveSmall } from "components/core/assets/img/sentiment-positive-small.svg";
import { DataContainer } from "containers/DataContainer";
import { IJob, ISentiment } from "types";
import { getUnixTimestamp } from "utils";
import "./sentiments.css";

interface SentimentsProps {
  editing: boolean;
  job: IJob;
  open: boolean;
  sentimentsToUpdate: ISentiment[];
  setSentimentsToUpdate: (value: ISentiment[]) => void;
}

export const Sentiments: React.FC<SentimentsProps> = (props) => {
  const {
    editing,
    job,
    open = true,
    sentimentsToUpdate,
    setSentimentsToUpdate,
  } = props;

  const { sentiments } = DataContainer.useContainer();

  const today = new Date().toUTCString();

  const gatherApplicableSentiments = (): ISentiment[] => {
    return sentiments.filter(
      (sentiment: ISentiment) =>
        getUnixTimestamp(sentiment.dateCreated) >=
          getUnixTimestamp(job.startDate) &&
        getUnixTimestamp(sentiment.dateCreated) <=
          getUnixTimestamp(job.endDate ? job.endDate : today) &&
        sentiment.jobId &&
        sentiment.jobId === job.itemId,
    );
  };

  const [applicableSentiments, setApplicableSentiments] = useState<
    ISentiment[]
  >(gatherApplicableSentiments());

  const countNegativeSentiments = (): number => {
    return applicableSentiments.filter(
      (sentiment: ISentiment) => sentiment.feeling === 0,
    ).length;
  };

  const [negativeSentiments, setNegativeSentiments] = useState<number>(
    countNegativeSentiments(),
  );

  const countNeutralSentiments = (): number => {
    return applicableSentiments.filter(
      (sentiment: ISentiment) => sentiment.feeling === 50,
    ).length;
  };

  const [neutralSentiments, setNeutralSentiments] = useState<number>(
    countNeutralSentiments(),
  );

  const countPositiveSentiments = (): number => {
    return applicableSentiments.filter(
      (sentiment: ISentiment) => sentiment.feeling === 100,
    ).length;
  };

  const [positiveSentiments, setPositiveSentiments] = useState<number>(
    countPositiveSentiments(),
  );

  const buildSentimentsAverage = (sentimentsToAvg: ISentiment[]): number => {
    if (sentimentsToAvg.length === 0) {
      return 0;
    }

    const totalFeeling = sentimentsToAvg.reduce(
      (sum, sentiment) => sum + sentiment.feeling,
      0,
    );
    return totalFeeling / sentimentsToAvg.length;
  };

  const [sentimentAverage, setSentimentAverage] = useState<number>(
    buildSentimentsAverage(applicableSentiments),
  );

  useEffect(() => {
    setApplicableSentiments(gatherApplicableSentiments());
  }, [sentiments]);

  useEffect(() => {
    setNegativeSentiments(countNegativeSentiments());
    setNeutralSentiments(countNeutralSentiments());
    setPositiveSentiments(countPositiveSentiments());
    setSentimentAverage(buildSentimentsAverage(applicableSentiments));
  }, [applicableSentiments]);

  return (
    <div className={`job-sentiments`}>
      <details open={open}>
        <summary>
          <h3 className="type--heading-6">Sentiments</h3>
          <span className="sentiment-tag type--heading-6">
            <SentimentNegativeSmall />
            {negativeSentiments}
          </span>
          <span className="sentiment-tag type--heading-6">
            <SentimentNeutralSmall />
            {neutralSentiments}
          </span>
          <span className="sentiment-tag type--heading-6">
            <SentimentPositiveSmall />
            {positiveSentiments}
          </span>
        </summary>
        <div
          className={`job-sentiments__content ${editing && "job-sentiments__content--editing"}`}
        >
          {!editing ? (
            <div className="sentiment-average-wrapper">
              <Sentimentometer
                rotation={Math.round((180 * sentimentAverage) / 100)}
              />
              <h4 className="type--heading-6">Sentimentometer</h4>
            </div>
          ) : (
            <BulkSentimentAssignment
              sentimentsToUpdate={sentimentsToUpdate}
              setSentimentsToUpdate={setSentimentsToUpdate}
              job={job}
            />
          )}
        </div>
      </details>
    </div>
  );
};
