import React from "react";
import "./button.css";

export interface ButtonProps {
	/**
	 * Button title, defaults to label if empty
	 */
	buttonTitle?: string;
	/**
	 * Button display type, defaults to label if empty
	 */
	buttonType?:
		| "icon-only"
		| "icon-only-key"
		| "icon-only-utility"
		| "primary"
		| "secondary"
		| "utility"
		| "goal";
	/**
	 * button's disabled if true
	 */
	disabled?: boolean;
	/**
	 * Button contents
	 */
	label: string;
	/**
	 * Should the button have a leading icon?
	 */
	leadingIcon?: unknown;
	/**
	 * Optional click handler
	 */
	onClick?: () => void;
	/**
	 * How large should the button be?
	 */
	size?: "small" | "medium" | "large";
	/**
	 * Should the button have a trailing icon?
	 */
	trailingIcon?: unknown;
	/**
	 * Button type for form handling
	 */
	type?: "button" | "submit" | "reset" | undefined;
	
	theme?: "default" | "prompt" | "destructive" | "goal";
	
	analyticsTag?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
	buttonTitle,
	buttonType = "utility",
	disabled = false,
	label,
	leadingIcon,
	size = "medium",
	trailingIcon,
	theme = "default",
	analyticsTag,
	...props
}) => {
	return (
		<button
			className={`button button--${size} button--${buttonType} ${
				leadingIcon ? "button--leading-icon" : ""
			} ${trailingIcon ? "button--trailing-icon" : ""} ${disabled && "button--disabled"} ${analyticsTag && analyticsTag} button--theme-${theme}`}
			disabled={disabled}
			title={buttonTitle ? buttonTitle : label}
			{...props}
		>
			{leadingIcon && leadingIcon}
			<span>{label}</span>
			{trailingIcon && trailingIcon}
		</button>
	);
};
