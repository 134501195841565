import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SequenceStepper } from "components/core";
import { DataContainer } from "containers/DataContainer";
import "./success.css";

interface SuccessProps {
  prop?: string;
}

export const Success: React.FC<SuccessProps> = () => {
  const { preferredName, getSubscriptionInfo } = DataContainer.useContainer();

  const [subscriptionDataLoaded, setSubscriptionDataLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setSubscriptionDataLoaded(true), 1000);
  }, []);

  return (
    <section className="pattern--container--flow pattern--container--flow-narrow">
      <div className="pattern--container--flow__heading pattern--container--flow__heading--accessory">
        <h1 className="type--heading-1">Payment</h1>
        <div className="accessory--resizeable">
          <SequenceStepper length={6} currentIndex={2} />
        </div>
      </div>
      <div className="main-content-card main-content-card--no-texture pattern--form-sheet pattern--container--flow__body">
        <h2 className="type--heading-2">Welcome, {preferredName}</h2>
        <p className="type--body--large ready-message">
          You are ready to start using Career Minder. <strong>Congratulations!</strong>
        </p>
        <p className="type--body--large ready-message">To help you get started, let’s add the basics:</p>
        <ol className="type--body--large ready-message">
          <li>Add your goals</li>
          <li>Import your resume</li>
          <li>Catch up on your recent work</li>
        </ol>
        <Button
          buttonType="secondary"
          label="Next"
          buttonTitle="Proceed to Goals"
          onClick={async () => {
            await getSubscriptionInfo();
            navigate("/onboarding/goals");
          }}
          disabled={!subscriptionDataLoaded}
        />
      </div>
    </section>
  );
};
