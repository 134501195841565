import { IWorkExperience, IScoredExperience } from "types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const similarity = require("compute-cosine-similarity");
import { getUnixTimestamp, getPastDate, getFutureDate } from "utils";

export const scoreExperienceSingle = (
  experience: IWorkExperience,
  compareAgainst?: string,
): IScoredExperience => {
  const scoredExperience: IScoredExperience = {
    workExperience: experience,
    score: compareAgainst ? similarity(JSON.parse(experience.vector), JSON.parse(compareAgainst)) : 0,
  };

  return scoredExperience;
};

export const scoreExperiences = (
  experiences: IWorkExperience[],
  compareAgainst: string,
): IScoredExperience[] => {
  const scoredExperiences: IScoredExperience[] = [];
  experiences.map((experience: IWorkExperience) => {
    const scoredExperience: IScoredExperience = scoreExperienceSingle(experience, compareAgainst);
    scoredExperiences.push(scoredExperience);
  });
  return scoredExperiences;
};

export const rescoreExperiences = (
  scoredExperiences: IScoredExperience[],
  compareAgainst: string,
): IScoredExperience[] => {
  const rescoredExperiences: IScoredExperience[] = [];
  scoredExperiences.map((scoredExperience: IScoredExperience) => {
    const rescoredExperience: IScoredExperience = scoreExperienceSingle(
      scoredExperience.workExperience,
      compareAgainst,
    );
    rescoredExperiences.push(rescoredExperience);
  });
  return rescoredExperiences;
};

export const scoreExperienceWithinRange = (
  experiences: IWorkExperience[],
  compareAgainst?: string,
  startDate?: string,
  endDate?: string,
): IScoredExperience[] => {
  const experiencesToScore: IWorkExperience[] = [];
  const today = new Date();
  const startDateForRange = startDate ? startDate : getPastDate(today.toString(), 36500, false);
  const endDateForRange = endDate ? endDate : getFutureDate(today.toString(), 1, false);
  experiences.map((experience: IWorkExperience) => {
    const experienceDate = experience.completionDate ? experience.completionDate : experience.dateCreated;
    if (
      getUnixTimestamp(experienceDate) < getUnixTimestamp(endDateForRange) &&
      getUnixTimestamp(experienceDate) > getUnixTimestamp(startDateForRange)
    ) {
      experiencesToScore.push(experience);
    }
  });
  if (compareAgainst) {
    const scoredExperiences: IScoredExperience[] = scoreExperiences(experiencesToScore, compareAgainst);
    return scoredExperiences;
  } else {
    const unscoredExperiences: IScoredExperience[] = experiencesToScore.map((experience: IWorkExperience) => {
      return {
        workExperience: experience,
        score: 0,
      };
    });
    return unscoredExperiences;
  }
};
