import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/core";
import { ReactComponent as IllustrationNotFound } from "../core/assets/img/ghost-mountains.svg";
import "./notFound.css";

interface NotFoundProps {
	message?: string;
}

const NotFound: React.FC<NotFoundProps> = () => {
	const navigate = useNavigate();
	
	return (
		<section className="flex-page-wrapper not-found-page-wrapper">
			<h1 className="type--heading-1 pattern--container--flow__heading header--withoutaction">
				404
			</h1>
			<div className="main-content-card not-found-card-wrapper">
				<div className="not-found-container">
					<IllustrationNotFound className="not-found-illustration" />
					<div className="not-found-content">
						<h2 className="type--heading-3">Sorry, nothing found</h2>
						<p className="type--body--large">Would you like to…</p>
						<div className="not-found-options">
							<Button
								buttonTitle="Review your Experience"
								buttonType="utility"
								label="Review Experience"
								onClick={() => navigate("experience")}
							/>
							<Button
								buttonTitle="Review your Brag Sheets"
								buttonType="utility"
								label="Review Brag Sheets"
								onClick={() => navigate("bragsheets")}
							/>
							<Button
								buttonTitle="Review your Goals"
								buttonType="utility"
								label="Review Goals"
								onClick={() => navigate("goals")}
							/>
						</div>
					</div>
					
				</div>
			</div>
		</section>
	);
};

export default NotFound;