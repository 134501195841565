import React from "react";
import "./fullPageLoading.css";

export interface FullPageLoadingProps {
	something?: string;
}

/**
 * Primary UI component for user interaction
 */
const FullPageLoading: React.FC<FullPageLoadingProps> = () => {
	return (
		<div className="full-page-loading"></div>
	);
};

export default FullPageLoading;