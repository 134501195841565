import React, { useState, useEffect } from "react";
import { Toggle } from "components/core";
import { DataContainer } from "containers/DataContainer";
import { CheckoutButton } from "../CheckoutButton";
import "./checkoutForm.css";

interface CheckoutFormProps {
	annualPricingAlreadySetUp?: boolean;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
	const { stripeCustomerID } = DataContainer.useContainer();

	const monthlyPricingStripeToken: string = process.env
		.REACT_APP_STRIPE_MONTHLY_PRICE as string;
	const annualPricingStripeToken: string = process.env
		.REACT_APP_STRIPE_ANNUAL_PRICE as string;

	const monthlyPricingDollarAmount = 15;
	const annualPricingDollarAmount = 150;

	const monthlyPricingMessaging = "Charged once a month";
	const annualPricingMessaging = "Charged once a year";

	const [annualPricing, setAnnualPricing] = useState<boolean>(
		props.annualPricingAlreadySetUp ? true : false
	);

	const [stripePrice, setStripePrice] = useState<string>(
		annualPricing ? annualPricingStripeToken : monthlyPricingStripeToken
	);

	const [price, setPrice] = useState<number>(
		annualPricing ? annualPricingDollarAmount : monthlyPricingDollarAmount
	);

	const [priceMessaging, setPriceMessaging] = useState<string>(
		annualPricing ? annualPricingMessaging : monthlyPricingMessaging
	);

	useEffect(() => {
		if (annualPricing) {
			setStripePrice(annualPricingStripeToken);
			setPrice(annualPricingDollarAmount);
			setPriceMessaging(annualPricingMessaging);
		} else {
			setStripePrice(monthlyPricingStripeToken);
			setPrice(monthlyPricingDollarAmount);
			setPriceMessaging(monthlyPricingMessaging);
		}
	}, [annualPricing]);

	return (
		<div className="subscription-form-wrapper">
			<div className="monthly-annual-toggle-wrapper">
				<span className="type--body--standard">Monthly</span>
				<Toggle
					label="Annual"
					value=""
					name="enabled"
					checked={annualPricing}
					onClick={() => setAnnualPricing(!annualPricing)}
				/>
			</div>
			<div className="pricing-card">
				<div className="pricing-card__price">
					<h3>${price}</h3>
					<div className="pricing-card__price__messaging">
						<p className="type--body--standard">{priceMessaging}</p>
					</div>
				</div>
				<div className="pricing-card__actions">
					<CheckoutButton
						stripePrice={stripePrice}
						customer={stripeCustomerID}
					/>
					<p className="type--body--standard">14 day free trial with no credit card required, cancel anytime</p>
				</div>
			</div>
		</div>
	);
};
