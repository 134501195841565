import React from "react";
import { Field, FieldProps } from "formik";

export interface DatePickerProps {
	helperPrompt?: string;
	label?: string;
	// TODO: remove the optional when done with the Formik refactor
	name?: string;
	// TODO: remove setValue when done with Formik refactor
	setValue?: (value: Date) => void;
	value?: Date;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
	const { label, name } = props;

	const FieldComponent = ({
		field, // { name, value, onChange, onBlur }
		form: { errors, touched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	}: FieldProps) => (
		<>
			<input
				className="pattern--input-field"
				type="date"
				onChange={(e) => {
					if (e.target.value !== "") {
						const isoDate: Date = new Date(e.target.value);
						isoDate.setMinutes(
							isoDate.getMinutes() + isoDate.getTimezoneOffset()
						);
						setFieldValue(field.name, isoDate.toISOString());
					}
				}}
				value={
					field.value !== undefined && field.value !== null
						? field.value.substring(0, 10)
						: null
				}
			/>
			{touched[field.name] && errors[field.name] && (
				<div className="error">{errors[field.name]}</div>
			)}
		</>
	);

	return (
		<div className="field-wrapper pattern--field-wrapper">
			{label && (
				<>
					<span className="pattern--label type--heading-4">{label}</span>
					<p className="type--body--large pattern--field--helper-prompt">
						{props.helperPrompt && props.helperPrompt}
					</p>
				</>
			)}
			<div className="pattern--input-container">
				<Field name={name} component={FieldComponent} />
			</div>
		</div>
	);
};
