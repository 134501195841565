import React, { Fragment, useState, useEffect, useRef } from "react";
import { matchPath, useLocation, useNavigate, Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Button, ExperienceItem, SearchPrimary, StatusPill } from "components/core";
import { ReactComponent as IconSearch } from "../core/assets/img/search.svg";
import { DataContainer } from "containers/DataContainer";
import { IWorkExperience } from "types";
import "./experience.css";

interface ExperienceProps {
	bragSheetIndex?: number;
	email?: string;
	jobDescription?: string;
	name?: string;
}

const Experience: React.FC<ExperienceProps> = () => {
	const {
		user,
		workExperiences,
		EmptyWorkExperience,
		subscriptionActive,
		userDataLoaded,
		reminderEnabled,
	} = DataContainer.useContainer();
	
	const navigate = useNavigate();

	const [newExperienceItem, setNewExperienceItem] = useState<boolean>(false);
		
	const [searching, setSearching] = useState<boolean>(false);
		
	const [searchTerm, setSearchTerm] = useState<string>("");
		
	const [searchResults, setSearchResults] = useState<IWorkExperience[]>([]);
	
	const [searchDeletion, setSearchDeletion] = useState<boolean>(false);
	
	const onChangeSearch = (value: string) => {
		setSearchTerm(value);
	};
	
	useEffect(() => {
		if (searchTerm !== "") {
			setSearchResults(workExperiences.filter((workExperience: IWorkExperience) => {
				return workExperience.description.toLowerCase().includes(searchTerm.toLowerCase());
			}));
		} else {
			setSearchResults([]);
		}
	}, [searchTerm]);
	
	const clearSearch = () => {
		setSearchTerm("");
	};
	
	useEffect(() => {		
		if (searching && searchTerm !== "") {
			setSearchResults(workExperiences.filter((workExperience: IWorkExperience) => {
				return workExperience.description.toLowerCase().includes(searchTerm.toLowerCase());
			}));
		}
	}, [searchDeletion]);

	const { pathname } = useLocation();
	const isNewPath = matchPath("/experience/new", pathname);

	// useEffect & useRef ensures that createNewExperience happens once if the URL is experience/new as components re-render

	const experienceLoaded = useRef<boolean>(false);

	useEffect(() => {
		if (isNewPath && userDataLoaded && !experienceLoaded.current) {
			createNewWorkExperience();
			experienceLoaded.current = true;
		}
	}, [workExperiences]);

	const createNewWorkExperience = () => {
		setSearchTerm("");
		setSearchResults([]);
		setSearching(false);
		setNewExperienceItem(true);
	};
	
	const IconSearchSVG = <IconSearch />;

	return (
		<Fragment>
			<div className="flex-page-outer-wrapper">
				<div className="flex-page-wrapper">
					<div className="header--withaction experience-masthead">
						<div className="header--withaction__title-grouping">
							<h1 className="type--heading-1">Experience</h1>
							<Link
								to="/profile/reminders"
								title="See Reminders settings"
								className="statusPill-wrapper"
							>
								<StatusPill
									label="Reminders"
									size="small"
									status={reminderEnabled ? "affirmative" : "indeterminate"}
									theme="secondary"
								/>
							</Link>
						</div>
						<div className="header--withaction__action-grouping">
							<Button
								buttonType="icon-only"
								label="Search Experience"
								buttonTitle="Start a search of your experience"
								disabled={searching ? true : false}
								onClick={() => setSearching(true)}
								leadingIcon={IconSearchSVG}
								type="button"
							/>
							<Button
								buttonType="utility"
								key="`startCatchUp"
								label="Start Catch Up"
								disabled={!subscriptionActive || !userDataLoaded ? true : false}
								onClick={() => navigate("catchups/new")}
								type="button"
							/>
							<Button
								buttonType="primary"
								key="`addWorkExperience"
								label="Add Experience"
								disabled={!subscriptionActive || !userDataLoaded ? true : false}
								onClick={() => createNewWorkExperience()}
								type="button"
								analyticsTag="plausible-event-name=Add+Experience plausible-event-placement=Experience+Header"
							/>
						</div>
					</div>
					<div className="main-content-card experience-log">
							{searching ? (
								<>
									<SearchPrimary
										clearSearch={clearSearch}
										closeSearch={() => setSearching(false)}
										fieldOnChange={onChangeSearch}
										searchResultsLength={searchResults.length}
										searchValue={searchTerm}
										shouldAutoFocus={true}
									/>
									{searchResults.length > 0 &&
										<>
											{workExperiences.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map(
												(workExperience: IWorkExperience, index: number) => {
													return (
														<ExperienceItem
															index={index}
															key={uuid()}
															newExperienceItem={newExperienceItem}
															setNewExperienceItem={setNewExperienceItem}
															workExperience={workExperience}
															hidden={searchResults.findIndex(i => i.itemId === workExperience.itemId) !== -1 ? false : true}
															searchDeletion={searchDeletion}
															setSearchDeletion={setSearchDeletion}
														/>
													);
												}
											)}
										</>
									}
								</>
							) : (
								<>
								{newExperienceItem &&
									<ExperienceItem
										index={-1}
										key={uuid()}
										newExperienceItem={newExperienceItem}
										setNewExperienceItem={setNewExperienceItem}
										workExperience={new EmptyWorkExperience(user)}
									/>
								}
								{workExperiences.length ? (
									<>
									{workExperiences.sort((a, b) => new Date(b.dateCreated).valueOf() - new Date (a.dateCreated).valueOf()).map(
										(workExperience: IWorkExperience, index: number) => {
											return (
												<ExperienceItem
													index={index}
													key={uuid()}
													newExperienceItem={newExperienceItem}
													setNewExperienceItem={setNewExperienceItem}
													workExperience={workExperience}
												/>
											);
										}
									)}
									</>
								) : (
									<div className="experience-log-empty-state pattern--empty-state--fill-area">
										<h3 className="type--heading-3">No Experience</h3>
										<p className="type--body--large">
											Start adding some experience with the button above.
										</p>
									</div>
								)}
								</>
							)
							}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Experience;
